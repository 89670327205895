import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { createSelector } from 'reselect'

import { initialState } from './reducer'
import { convertScreeningOptionToPayload, getProOptionPayload } from './utils'

export const getTenantScreeningRequest = state =>
  get(state, 'tenantScreeningRequestV2.2', initialState)

export const selectPriceSchema = createSelector(getTenantScreeningRequest, tenantScreeningRequest =>
  get(tenantScreeningRequest, 'priceSchema', initialState.priceSchema),
)

export const selectCreateScreeningRequestPayload = createSelector(
  getTenantScreeningRequest,
  tenantScreeningRequest => {
    const requestOption = get(tenantScreeningRequest, 'requestOption', {})
    const source = get(tenantScreeningRequest, 'source')

    const payload = convertScreeningOptionToPayload({ requestOption, source })
    const proOption = getProOptionPayload({
      documentProOption: get(tenantScreeningRequest, 'requestOption.documentOption', {}),
      incomeVerificationOption: get(
        tenantScreeningRequest,
        'requestOption.incomeVerificationOption',
        false,
      ),
      application: get(payload, 'application', false),
    })

    if (!isEmpty(proOption)) payload.proOption = proOption
    return payload
  },
)

export const selectUserPreferencePayload = createSelector(
  getTenantScreeningRequest,
  tenantScreeningRequest => {
    const requestOption = get(tenantScreeningRequest, 'requestOption', {})
    const isAcceptedBackgroundDisclaimer = get(
      tenantScreeningRequest,
      'userPreference.isAcceptedBackgroundDisclaimer',
    )

    const {
      criminal,
      eviction,
      credit_report: creditReport,
      premium,
      application,
      payerType,
      applicationType,
      income,
    } = convertScreeningOptionToPayload({ requestOption })
    const { documents, references } = getProOptionPayload({
      documentProOption: get(tenantScreeningRequest, 'requestOption.documentOption', {}),
      incomeVerificationOption: get(
        tenantScreeningRequest,
        'requestOption.incomeVerificationOption',
        false,
      ),
      application,
    })
    return {
      defaultScreeningOption: {
        criminal,
        eviction,
        credit_report: creditReport,
        application,
        premium,
        refCheckOption: references || [],
        documentOption: documents || [],
        income,
      },
      payerType: payerType !== '' ? payerType : undefined,
      isAcceptedBackgroundDisclaimer,
      applicationType,
    }
  },
)

export const selectIsAcceptedBackgroundDisclaimer = createSelector(
  getTenantScreeningRequest,
  tenantScreeningRequest =>
    get(
      tenantScreeningRequest,
      'userPreference.isAcceptedBackgroundDisclaimer',
      initialState.userPreference.isAcceptedBackgroundDisclaimer,
    ),
)

export const selectScreeningPlan = createSelector(
  getTenantScreeningRequest,
  tenantScreeningRequest => get(tenantScreeningRequest, 'screeningPlans', []),
)

export const selectProperty = createSelector(getTenantScreeningRequest, tenantScreeningRequest =>
  get(tenantScreeningRequest, 'property', {}),
)

export const selectRequestOptions = createSelector(
  getTenantScreeningRequest,
  tenantScreeningRequest => get(tenantScreeningRequest, 'requestOption', {}),
)

export const selectRequestOptionsForStepAction = createSelector(
  getTenantScreeningRequest,
  tenantScreeningRequest => {
    const { payerType, applicationType, creditReport, criminal, eviction, isInitial } = get(
      tenantScreeningRequest,
      'requestOption',
      {},
    )

    return {
      payer: payerType,
      isInitial,
      selectingItems: {
        application: applicationType,
        credit_report: creditReport,
        criminal,
        eviction,
      },
    }
  },
)

export const selectDocumentOption = createSelector(
  getTenantScreeningRequest,
  tenantScreeningRequest =>
    get(
      tenantScreeningRequest,
      'requestOption.documentOption',
      initialState.requestOption.documentOption,
    ),
)

export const selectRequestStepControl = createSelector(
  getTenantScreeningRequest,
  tenantScreeningRequest => get(tenantScreeningRequest, 'requestStepControl', {}),
)

export const selectScreeningRequest = createSelector(
  getTenantScreeningRequest,
  tenantScreeningRequest => get(tenantScreeningRequest, 'screeningRequest', {}),
)

const getLegacyProperty = state => get(state, 'property', {})

export const selectCombinedProperty = createSelector(
  [getLegacyProperty, selectProperty],
  (legacyProperty, property) => {
    // shortenlink and permalink is still in legacy property state.
    // TODO: extract them out tof property
    const combinedProperty = {
      ...legacyProperty,
      property: {
        ...(legacyProperty ? legacyProperty.property : {}),
        ...property,
      },
    }
    return combinedProperty
  },
)

export const selectPermalink = createSelector(getTenantScreeningRequest, tenantScreeningRequest =>
  get(tenantScreeningRequest, 'permalink', initialState.permalink),
)

export const selectIsFetchingScreeningPlan = createSelector(
  getTenantScreeningRequest,
  tenantScreeningRequest =>
    get(tenantScreeningRequest, 'isFetchingScreeningPlan', initialState.isFetchingScreeningPlan),
)

export const selectIsFetchingUserPreference = createSelector(
  getTenantScreeningRequest,
  tenantScreeningRequest =>
    get(tenantScreeningRequest, 'isFetchingUserPreference', initialState.isFetchingUserPreference),
)

export const selectUserPreference = createSelector(
  getTenantScreeningRequest,
  tenantScreeningRequest =>
    get(tenantScreeningRequest, 'userPreference', initialState.userPreference),
)

export const selectUserExperience = createSelector(
  getTenantScreeningRequest,
  tenantScreeningRequest =>
    get(tenantScreeningRequest, 'userExperience', initialState.userExperience),
)

export const selectAutoCreateTenantScreening = createSelector(
  getTenantScreeningRequest,
  tenantScreeningRequest =>
    get(
      tenantScreeningRequest,
      'autoCreateTenantScreening',
      initialState.autoCreateTenantScreening,
    ),
)
export const selectSubdomain = ({ subdomain }) => get(subdomain, 'subdomain.sub', null)
