import produce from 'immer'
import { generateAddress } from '@rentspree/helper'
import isBoolean from 'lodash/isBoolean'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import omit from 'lodash/omit'

import {
  GET_SCREENING_PLANS_REQUEST,
  GET_SCREENING_PLANS_SUCCESS,
  GET_SCREENING_PLANS_FAILURE,
  GET_USER_PREFERENCE_REQUEST,
  GET_USER_PREFERENCE_SUCCESS,
  GET_USER_PREFERENCE_FAILURE,
  SELECT_PAYER_TYPE,
  SELECT_APPLICATION_TYPE,
  CREATE_SCREENING_REQUEST_REQUEST,
  CREATE_SCREENING_REQUEST_SUCCESS,
  CREATE_SCREENING_REQUEST_FAILURE,
  ACCEPT_BACKGROUND_DISCLAIMER,
  UPDATE_DOCUMENT_PRO_OPTION,
  UPDATE_REF_CHECK_PRO_OPTION,
  UPDATE_INCOME_VERIFICATION_OPTION,
  CLEAR_REQUEST_OPTIONS,
  GET_PROPERTY_REQUEST,
  GET_PROPERTY_SUCCESS,
  GET_PROPERTY_FAILURE,
  CREATE_SCREENING_REQUEST_WO_PROPERTY_REQUEST,
  CREATE_SCREENING_REQUEST_WO_PROPERTY_SUCCESS,
  CREATE_SCREENING_REQUEST_WO_PROPERTY_FAILURE,
  UPDATE_REQUEST_STEP_CONTROL,
  SELECT_CREDIT_REPORT_ITEMS,
  CLEAR_SCREENING_WITH_PROPERTY_PRO_OPTIONS,
  CLEAR_PROPERTY,
  UPDATE_USER_PREFERENCE_REQUEST,
  UPDATE_USER_PREFERENCE_SUCCESS,
  UPDATE_USER_PREFERENCE_FAILURE,
  SET_SOURCE,
  GENERATE_PERMALINK_REQUESTED,
  GENERATE_PERMALINK_SUCCESS,
  GENERATE_PERMALINK_FAILED,
  USER_EXPERIENCE,
  PRO_PLAN,
  DEFAULT_DOCUMENT_OPTIONS,
  UPDATE_USER_PLAN,
  AUTO_CREATE_TENANT_SCREENING_REQUEST_START,
  AUTO_CREATE_TENANT_SCREENING_REQUEST_SUCCESS,
  AUTO_CREATE_TENANT_SCREENING_REQUEST_FAILURE,
} from './constants'
import {
  convertPayloadToRequestOption,
  mapPriceSchema,
  preselectCustomScreeningOptionsWithPriceSchema,
} from './utils'
import { CLEAR_SCREENING_WITHOUT_PROPERTY_PRO_OPTIONS } from '../request/constants'

export const initialState = {
  priceSchema: {},
  screeningPlans: [
    {
      payerType: 'all',
      subdomain: 'rentspree',
      application: {
        price: 0,
        disabled: false,
      },
      credit_report: {
        price: 0,
        disabled: false,
      },
      eviction: {
        price: 0,
        disabled: false,
      },
      criminal: {
        price: 0,
        disabled: false,
      },
      premium: {
        price: 0,
        disabled: false,
      },
    },
  ],
  requestOption: {
    payerType: '',
    applicationType: '',
    creditReport: false,
    eviction: false,
    criminal: false,
    premium: false,
    documentOption: [],
    refCheckOption: false,
    incomeVerificationOption: false,
    isInitial: true,
    isSubmitting: false,
  },
  property: {
    fullNameAddress: '',
    propertyType: '',
    origin: '',
    _id: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    unitNumber: '',
    landlord_id: '',
  },
  userPreference: {
    defaultScreeningOption: {},
    agentDashBoardV: null,
    isAcceptedBackgroundDisclaimer: false,
    payerType: '',
    applicationType: 'rentspree',
  },
  userPlan: '',
  isFetchingProperty: false,
  isFetchingScreeningPlan: false,
  isFetchingUserPreference: false,
  error: null,
  requestStepControl: {
    payerBox: {
      isEnabled: true,
      enabledItems: {
        applicants: true,
        me: true,
      },
    },
    applicationTypeBox: {
      isEnabled: true,
      enabledItems: {
        CAR: true,
        standard: true,
        noApplication: true,
      },
    },
    customScreeningBox: {
      isEnabled: true,
      enabledBoxes: {
        creditReport: {
          isEnabled: true,
          enabledItems: {
            eviction: true,
            criminal: true,
            income_verification: true,
          },
        },
      },
    },
  },
  screeningRequest: {
    id: null,
  },
  source: 'property',
  isGeneratingPermalink: false,
  permalink: undefined,
  userExperience: null,
  autoCreateTenantScreening: {
    loading: false,
    screening: false,
  },
}

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type, payload = {} }) =>
  produce(state, draft => {
    switch (type) {
      case SELECT_PAYER_TYPE: {
        const { payerType } = payload
        draft.requestOption.payerType = payerType
        draft.priceSchema = mapPriceSchema(draft.screeningPlans, draft.requestOption)
        draft.requestOption = preselectCustomScreeningOptionsWithPriceSchema(
          draft.requestOption,
          draft.priceSchema,
        )
        break
      }

      case SELECT_APPLICATION_TYPE: {
        const { applicationType } = payload
        draft.requestOption.applicationType = applicationType
        draft.requestOption = preselectCustomScreeningOptionsWithPriceSchema(
          draft.requestOption,
          draft.priceSchema,
        )
        break
      }

      case SELECT_CREDIT_REPORT_ITEMS: {
        const { creditReportItems, userPlan } = payload
        const { creditReport, eviction, criminal } = creditReportItems
        if (isBoolean(creditReport)) {
          draft.requestOption.creditReport = creditReport
          if (!creditReport) {
            draft.requestOption.eviction = false
            draft.requestOption.criminal = false
            if (userPlan !== PRO_PLAN) {
              draft.requestOption.incomeVerificationOption = false
            }
          }
        }
        if (isBoolean(eviction)) {
          draft.requestOption.eviction = eviction
        }
        if (isBoolean(criminal)) {
          draft.requestOption.criminal = criminal
        }
        break
      }
      case GET_SCREENING_PLANS_REQUEST:
        draft.isFetchingScreeningPlan = true
        break
      case GET_USER_PREFERENCE_REQUEST:
        draft.isFetchingUserPreference = true
        break
      case UPDATE_USER_PREFERENCE_REQUEST:
        draft.error = null
        break
      case UPDATE_USER_PREFERENCE_SUCCESS:
        draft.userPreference = payload
        break
      case GET_USER_PREFERENCE_SUCCESS: {
        const { result } = payload
        draft.userPreference = result
        if (!draft.userExperience) {
          if (isBoolean(draft.userPreference?.defaultScreeningOption?.premium)) {
            draft.userExperience = USER_EXPERIENCE.SCREENING_V2_EXPERIENCED
          } else if (!isEmpty(draft.userPreference?.defaultScreeningOption)) {
            draft.userExperience = USER_EXPERIENCE.SCREENING_EXPERIENCED
          } else {
            draft.userExperience = USER_EXPERIENCE.FIRST_TIME_SCREENING
          }
        }
        // eslint-disable-next-line no-underscore-dangle
        const isScreeningWithoutProperty = isEmpty(draft.property._id)
        const priceSchema = cloneDeep(draft.priceSchema)
        const isPro = draft.userPlan === PRO_PLAN
        const requestOption = convertPayloadToRequestOption(
          result,
          priceSchema,
          draft.userExperience,
          isPro,
          isScreeningWithoutProperty,
        )
        draft.priceSchema = mapPriceSchema(draft.screeningPlans, requestOption)
        const priceSchema2 = cloneDeep(draft.priceSchema)
        const newRequestOption = convertPayloadToRequestOption(
          result,
          priceSchema2,
          draft.userExperience,
          isPro,
          isScreeningWithoutProperty,
        )
        draft.requestOption = { ...draft.requestOption, ...newRequestOption }
        draft.isFetchingUserPreference = false
        break
      }
      case GET_USER_PREFERENCE_FAILURE: {
        // eslint-disable-next-line no-underscore-dangle
        const isScreeningWithoutProperty = isEmpty(draft.property._id)
        const screeningPlans = cloneDeep(draft.screeningPlans)

        draft.requestOption = cloneDeep(initialState.requestOption)
        draft.userPreference = cloneDeep(initialState.userPreference)
        draft.userExperience = USER_EXPERIENCE.FIRST_TIME_SCREENING
        draft.isFetchingUserPreference = false

        const requestOption = convertPayloadToRequestOption(
          {},
          screeningPlans,
          draft.userExperience,
          draft.userPlan === PRO_PLAN,
          isScreeningWithoutProperty,
        )
        draft.requestOption = { ...draft.requestOption, ...requestOption }
        break
      }
      case UPDATE_USER_PREFERENCE_FAILURE:
        draft.error = payload
        break
      case GET_SCREENING_PLANS_SUCCESS: {
        draft.screeningPlans = payload
        draft.priceSchema = mapPriceSchema(payload, draft.requestOption)
        draft.isFetchingScreeningPlan = false
        break
      }
      case GET_SCREENING_PLANS_FAILURE:
        draft.isFetchingScreeningPlan = false
        break
      case GET_PROPERTY_REQUEST:
        draft.isFetchingProperty = true
        draft.error = null
        break
      case GET_PROPERTY_SUCCESS:
        draft.property = cloneDeep(payload)
        draft.property.fullNameAddress = generateAddress(payload)
        draft.isFetchingProperty = false
        break
      case GET_PROPERTY_FAILURE:
        draft.isFetchingProperty = false
        break
      case UPDATE_REQUEST_STEP_CONTROL:
        if (isBoolean(payload.creditReport)) {
          draft.requestStepControl.customScreeningBox.enabledBoxes.creditReport.isEnabled =
            payload.creditReport
        }
        if (isBoolean(payload.eviction)) {
          draft.requestStepControl.customScreeningBox.enabledBoxes.creditReport.enabledItems.eviction =
            payload.eviction
        }
        if (isBoolean(payload.criminal)) {
          draft.requestStepControl.customScreeningBox.enabledBoxes.creditReport.enabledItems.criminal =
            payload.criminal
        }
        if (isBoolean(payload.CAR)) {
          draft.requestStepControl.applicationTypeBox.enabledItems.CAR = payload.CAR
        }
        if (isBoolean(payload.premium)) {
          draft.requestStepControl.customScreeningBox.enabledBoxes.creditReport.enabledItems.income_verification =
            payload.premium
        }
        break
      case CREATE_SCREENING_REQUEST_REQUEST: {
        draft.requestOption.isSubmitting = true
        break
      }
      case CREATE_SCREENING_REQUEST_SUCCESS:
        draft.requestOption.isSubmitting = false
        draft.screeningRequest.id = payload.id
        draft.userExperience = null
        break
      case CREATE_SCREENING_REQUEST_FAILURE:
        draft.requestOption.isSubmitting = false
        draft.error = payload
        break
      case ACCEPT_BACKGROUND_DISCLAIMER:
        draft.userPreference.isAcceptedBackgroundDisclaimer = payload
        break
      case UPDATE_DOCUMENT_PRO_OPTION:
        draft.requestOption.documentOption = payload
        break
      case UPDATE_REF_CHECK_PRO_OPTION:
        draft.requestOption.refCheckOption = !draft.requestOption.refCheckOption
        break
      case UPDATE_INCOME_VERIFICATION_OPTION:
        draft.requestOption.incomeVerificationOption = !draft.requestOption.incomeVerificationOption

        if (draft.requestOption.incomeVerificationOption) {
          if (!draft.requestOption.creditReport && draft.userPlan !== PRO_PLAN) {
            draft.requestOption.creditReport = true
            draft.requestOption.criminal =
              draft.requestStepControl.customScreeningBox.enabledBoxes.creditReport.enabledItems.criminal
            draft.requestOption.eviction =
              draft.requestStepControl.customScreeningBox.enabledBoxes.creditReport.enabledItems.eviction
          }

          draft.requestOption.documentOption = !isEmpty(
            draft?.userPreference?.defaultScreeningOption?.documentOption,
          )
            ? [...draft.userPreference.defaultScreeningOption.documentOption]
            : [...DEFAULT_DOCUMENT_OPTIONS]
        } else {
          draft.requestOption = omit(draft.requestOption, ['documentOption'])
        }
        break
      case UPDATE_USER_PLAN:
        draft.userPlan = payload
        break
      case CREATE_SCREENING_REQUEST_WO_PROPERTY_REQUEST: {
        draft.requestOption.isSubmitting = true
        draft.requestOption.isInitial = false
        break
      }
      case CREATE_SCREENING_REQUEST_WO_PROPERTY_SUCCESS: {
        draft.requestOption.isSubmitting = false
        break
      }
      case CREATE_SCREENING_REQUEST_WO_PROPERTY_FAILURE: {
        draft.requestOption.isSubmitting = false
        draft.error = payload
        break
      }
      case CLEAR_REQUEST_OPTIONS: {
        draft.requestOption = cloneDeep(initialState.requestOption)
        break
      }
      case CLEAR_SCREENING_WITHOUT_PROPERTY_PRO_OPTIONS:
      case CLEAR_SCREENING_WITH_PROPERTY_PRO_OPTIONS:
        draft.requestOption.documentOption = cloneDeep(initialState.requestOption.documentOption)
        draft.requestOption.refCheckOption = initialState.requestOption.refCheckOption
        break
      case CLEAR_PROPERTY:
        if (payload.isCalledFromUseEffect) {
          draft.property = cloneDeep(initialState.property)
        }
        break
      case SET_SOURCE:
        draft.source = payload
        break
      case GENERATE_PERMALINK_REQUESTED:
        draft.isGeneratingPermalink = true
        break
      case GENERATE_PERMALINK_SUCCESS:
        draft.isGeneratingPermalink = false
        draft.permalink = payload
        break
      case GENERATE_PERMALINK_FAILED:
        draft.isGeneratingPermalink = false
        break
      case AUTO_CREATE_TENANT_SCREENING_REQUEST_START:
        draft.autoCreateTenantScreening.loading = true
        draft.autoCreateTenantScreening.screening = false
        break
      case AUTO_CREATE_TENANT_SCREENING_REQUEST_SUCCESS:
        draft.autoCreateTenantScreening.loading = false
        draft.autoCreateTenantScreening.screening = true
        break
      case AUTO_CREATE_TENANT_SCREENING_REQUEST_FAILURE:
        draft.autoCreateTenantScreening.loading = false
        draft.autoCreateTenantScreening.screening = false
        break
      default:
        break
    }
    return draft
  })

export default reducer
