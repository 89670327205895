import React, { useState } from 'react'

import { Sheet } from '@rentspree/component-2023.components.templates.sheet'
import passportsSVG from 'images/passports.svg'
import { capitalize } from 'lodash'
import {
  TabSubtitle,
  MessageBody,
  SecondaryContainedButton,
  SecondaryTextButton,
} from '../components'

const screeningReportNames = {
  criminal: 'background reports',
  eviction: 'eviction records',
  'credit-report': 'credit reports',
}

const PendingConditionalAcceptanceSheet = ({
  isOpen,
  handleCloseSheet,
  reportType,
  handleConditionalAcceptance,
}) => {
  const [complianceStep, setNextComplianceStep] = useState(0)

  const reportName = capitalize(screeningReportNames[reportType])

  const handleGotItClick = () => {
    setNextComplianceStep(1)
  }

  const handleContainedBtnClick = () => {
    handleConditionalAcceptance()
    handleClose()
  }

  const handleClose = () => {
    handleCloseSheet()
    setNextComplianceStep(0)
  }

  const stepContents = [
    {
      title: 'Ready to access this report?',
      subtitle: <TabSubtitle variant="bodyLarge" />,
      cta: (
        <SecondaryContainedButton
          text="Got it"
          handleClick={handleGotItClick}
          sx={{ width: '100%' }}
        />
      ),
      imgSrc: passportsSVG,
      imgAltText: 'three cartoon passports',
      textAlignment: 'center',
    },
    {
      title: 'Can you confirm this applicant has been conditionally accepted?',
      subtitle: (
        <p>{`${reportName} are only available for applicants who have been conditionally accepted,
          following local regulations`}</p>
      ),
      cta: (
        <>
          <SecondaryContainedButton
            text="Yes, confirm"
            handleClick={handleContainedBtnClick}
            sx={{ width: '100%' }}
          />
          <SecondaryTextButton
            text="No, go back"
            handleClick={handleClose}
            sx={{ width: '100%' }}
          />
        </>
      ),
      textAlignment: 'left',
    },
  ]

  const { title, subtitle, cta, imgSrc, imgAltText, textAlignment } = stepContents[complianceStep]

  return (
    <Sheet actionSection={cta} hideActionDivider open={isOpen} onClose={handleCloseSheet}>
      <MessageBody
        imgSrc={imgSrc}
        imgAltText={imgAltText}
        title={title}
        subtitle={subtitle}
        textAlignment={textAlignment}
      />
    </Sheet>
  )
}
export default PendingConditionalAcceptanceSheet
