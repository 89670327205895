import isEmpty from 'lodash/isEmpty'

import {
  QUOTATION_TYPE,
  MONTH_TO_MONTH_LABEL,
  RECURRING_PAYMENT_TERM,
} from 'v3/containers/rent-payment/constants'

import { PAYMENT_CATEGORIES } from './constants'

const calculateDescription = ({ paymentType, monthlyDescription, oneTimeDescription }) => {
  const description =
    paymentType === QUOTATION_TYPE.RECURRING ? monthlyDescription || '' : oneTimeDescription || ''

  return isEmpty(description) ? undefined : description
}

const calculateFeeCollection = ({
  paymentType,
  selectedFeeCollection,
  isPaymentFeeAdjustmentsEnabled,
}) => {
  if (!isPaymentFeeAdjustmentsEnabled) {
    return { ...selectedFeeCollection }
  }

  return paymentType === QUOTATION_TYPE.RECURRING
    ? { bank: 'payee', card: 'payerAndPayee' }
    : { bank: 'none', card: 'payer' }
}

const calculateRecurringDetails = ({ paymentType, firstPayment, lastPayment }) => {
  if (paymentType !== QUOTATION_TYPE.RECURRING) {
    return {}
  }

  const startDate = firstPayment

  if (lastPayment === MONTH_TO_MONTH_LABEL) {
    return {
      startDate,
      endDate: null,
      term: RECURRING_PAYMENT_TERM.PERPETUAL,
    }
  }

  return {
    startDate,
    endDate: lastPayment,
    term: RECURRING_PAYMENT_TERM.FIXED,
  }
}

export const generateQuotationByType = ({
  category,
  customCategory,
  monthlyDescription,
  oneTimeDescription,
  amount,
  paymentType,
  firstPayment,
  lastPayment,
  dueOn,
  dueDate,
  selectedFeeCollection,
  isPaymentFeeAdjustmentsEnabled = false,
}) => {
  const description = calculateDescription({
    paymentType,
    monthlyDescription,
    oneTimeDescription,
  })

  const feeCollection = calculateFeeCollection({
    paymentType,
    selectedFeeCollection,
    isPaymentFeeAdjustmentsEnabled,
  })

  const recurringDetails = calculateRecurringDetails({
    paymentType,
    firstPayment,
    lastPayment,
  })

  return {
    type: paymentType,
    category: category === PAYMENT_CATEGORIES.OTHER ? customCategory : category,
    description,
    amount,
    dueDate,
    dueOn,
    feeCollection,
    ...recurringDetails,
  }
}
