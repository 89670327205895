import React from 'react'
import { capitalize } from 'lodash'
import Link from '@rentspree/component-2023.components.atoms.link'
import { Sheet } from '@rentspree/component-2023.components.templates.sheet'

import personTouchingDocumentsSVG from 'images/person-touching-documents.svg'
import { MessageBody, SecondaryContainedButton } from '../components'
import { screeningReportNames, supportUrl } from '../constants'

const PendingAvailabilitySheet = ({ isOpen, handleCloseSheet, reportType }) => {
  const reportName = capitalize(screeningReportNames[reportType])

  /** tab props */
  const pendingAvailabilityProps = {
    title: `${reportName} will be ready soon`,
    subtitle: (
      <>
        <p>
          TransUnion is reviewing millions of records to provide accurate applicant info. This
          typically takes 1-2 days.
        </p>
        <br></br>
        <p>{`We'll email you as soon as it's ready.`}</p>
      </>
    ),
    cta: (
      <>
        <SecondaryContainedButton
          text="Got it"
          handleClick={handleCloseSheet}
          sx={{ width: '100%' }}
        />
        <Link
          target="_blank"
          href={supportUrl}
          color="black"
          sx={{ width: '100%' }}
          style={{ lineHeight: '40px' }}
        >
          View FAQ
        </Link>
      </>
    ),
    textAlignment: 'center',
  }

  const { title, subtitle, cta, textAlignment } = pendingAvailabilityProps

  return (
    <Sheet actionSection={cta} hideActionDivider open={isOpen} onClose={handleCloseSheet}>
      <MessageBody
        imgSrc={personTouchingDocumentsSVG}
        imgAltText="person touching documents"
        title={title}
        subtitle={subtitle}
        textAlignment={textAlignment}
      />
    </Sheet>
  )
}
export default PendingAvailabilitySheet
