import React from 'react'
import { compose } from 'redux'
import get from 'lodash/get'
import OnImagesLoaded from 'react-on-images-loaded'
import { withRouter } from 'react-router-dom'
import { useGateValue } from '@statsig/react-bindings'

import { ReportLoading } from 'components/reports/loading'
import featureFlags from 'constants/feature-flags'
import EvictionReportComponent from 'components/reports/screening-reports/shareable/eviction'
import { getComplianceStatusFromRules } from 'legacy/containers/application/utils'
import { useUpdateComplianceActionsMutation } from 'hooks/use-compliance-rules-api'
import IcraaDisclaimerReport from 'components/reports/screening-reports/shareable/disclaimer-report/icraa-disclaimer-report'
import { shouldRenderIcraaDisclaimer } from 'containers/reports/helper'
import { REPORT_TYPES } from '../../constants'
import { withConnect } from '../connect'
import withReportAvailable from '../../with-report-available'
import withExpired from '../with-expired'
import withScreeningReportError from '../with-error'
import { shouldGetCreditReport } from '../helper'

// TODO: lnr-2991 clean up duplicated container
export const ShareableEviction = ({
  creditReportDetail,
  isFetchingCreditReport,
  getCreditReport,
  fetchedEvictionImage,
  applicant,
  externalRenterDetail,
  generatedOn,
  rentalDetail,
  showHeaderComponent = true,
  customBorder,
  customMargin,
  applicationCreationDate,
  userType,
  match,
}) => {
  const useScreeningEndpoint = useGateValue(
    featureFlags.shouldCallScreeningServiceFullReportEndpoint,
  )
  const rentalSubmissionId = get(rentalDetail, '_id', '')
  const creditReportId = get(rentalDetail, 'credit_report._id', '')
  const landlordId = get(rentalDetail, 'landlord_id', '')
  const updateComplianceActionsMutation = useUpdateComplianceActionsMutation()
  const complianceRules = get(creditReportDetail, 'rules', {})
  const screeningReports = get(creditReportDetail, 'tuApplication.Applicants[0]', {})
  const renderIcraaDisclaimerReport = shouldRenderIcraaDisclaimer(rentalDetail)

  React.useEffect(() => {
    if (shouldGetCreditReport(rentalDetail, creditReportDetail)) {
      getCreditReport({ rentalId: get(rentalDetail, '_id'), useScreeningEndpoint })
    }
  }, [rentalDetail])

  const updateConditionalAcceptance = async () => {
    await updateComplianceActionsMutation.mutateAsync({
      userId: landlordId,
      creditReportId,
      rentalSubmissionId,
    })

    getCreditReport({ rentalId: rentalSubmissionId, useScreeningEndpoint })
  }

  const { needsConditionalAcceptance } = getComplianceStatusFromRules({
    tabName: 'EVICTION',
    complianceRules,
    screeningReports,
  })

  if (isFetchingCreditReport) {
    return <ReportLoading />
  }
  const evictionReportProps = {
    generatedOn,
    renter: externalRenterDetail,
    evictionRecords: get(applicant, 'EvictionRecords[0].reportData'),
    showHeaderComponent,
    applicationCreationDate,
    rentalDetail,
    userType,
    match,
  }

  const reportName = 'Eviction Related Proceedings'

  return (
    <OnImagesLoaded
      onLoaded={fetchedEvictionImage}
      onTimeout={fetchedEvictionImage}
      timeout={30000}
    >
      {renderIcraaDisclaimerReport && (
        <IcraaDisclaimerReport
          generatedOn={generatedOn}
          renter={externalRenterDetail}
          customBorder={customBorder}
          customMargin={customMargin}
          reportName={reportName}
        />
      )}
      <EvictionReportComponent
        {...evictionReportProps}
        handleConditionalAcceptance={updateConditionalAcceptance}
        reportViewingLocked={needsConditionalAcceptance}
        customBorder={customBorder}
        customMargin={customMargin}
        reportName={reportName}
      />
    </OnImagesLoaded>
  )
}

export default compose(
  withReportAvailable(REPORT_TYPES.EVICTION),
  withScreeningReportError,
  withExpired,
  withConnect,
  withRouter,
)(ShareableEviction)
