import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { compose } from 'redux'
import moment from 'moment'
import { ReportLoading } from 'components/reports/loading'
import { countEviction, countCriminal } from 'legacy/components/helper/count-score'

import CoverPage from 'components/reports/cover'
import get from 'lodash/get'
import withExpired from '../screening-reports/with-expired'
import { withConnect } from './connect'

export const Cover = ({
  applicationDetail,
  rentalDetail,
  isAppAndCreditFetching,
  creditReportDetail,
  applicant,
  isSharedReport,
  shareOption,
}) => {
  const { screeningOption = {} } = rentalDetail
  const isShownCoverPage = isSharedReport
    ? shareOption.application && shareOption.screeningReport
    : screeningOption.application && screeningOption.credit_report

  if (!isShownCoverPage || isEmpty(applicant)) return <></>

  const creditReport = get(applicant, 'CreditReport[0].reportData.applicants[0]')
  const criminalRecords = get(applicant, 'CriminalRecords[0].reportData.identities')
  const evictionRecords = get(applicant, 'EvictionRecords[0].reportData.records') ?? get(applicant, 'EvictionRecords[0].reportData.housing.activities')

  const { generated_at: generatedAt, requestedOption } = creditReportDetail
  const expiredOn = moment(generatedAt).add(30, 'days')
  const { property } = rentalDetail

  const evictionScore = !isEmpty(evictionRecords) ? evictionRecords : []
  const criminalScore = !isEmpty(criminalRecords) ? criminalRecords : []

  const score = {
    creditScore: get(creditReport, 'scores[0].score', 0),
    evictionRecords: countEviction(evictionScore),
    criminalRecords: countCriminal(criminalScore || []),
  }

  const coverPageProps = {
    rentalDetail,
    creditReport,
    criminalRecords,
    evictionRecords,
    score,
    property,
    application: applicationDetail,
    generatedOn: generatedAt,
    expiresOn: expiredOn,
    requestedOption,
  }

  if (isAppAndCreditFetching) {
    return <ReportLoading />
  }
  return <CoverPage {...coverPageProps} />
}

export default compose(withExpired, withConnect)(Cover)
