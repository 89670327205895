import { API_URL, USER_API_URL, PRINT_PDF_URL, FILES_URL, MAIN_URL } from 'env'

export const ROOT = '/'
export const DASHBOARD = process.env.BASE_NAME || '/dashboard/v2'
export const RENTAL = '/rental-submission'
export const RENTAL_SUBMISSION = '/rental-submissions'
export const GETTING_STARTED = '/getting-started'
export const HOW_TO = '/how-to'
export const PROPERTY = '/properties'
// TECH-22132 - TODO: this LEGACY_LEASE_AGREEMENT route is to be deprecated
export const LEGACY_LEASE_AGREEMENT = '/esign-documents'
export const LEASE_AGREEMENT = '/lease-agreements'
export const APPLICATION = '/rental'
export const NOT_FOUND = '/not-found'
export const LISTING_PUBLIC = '/listing/:slug'

// ====================== Step Request Rental-Submission Routes ======================
export const REQUEST = '/request'
export const REQUEST_UPDATE_INFO = `${REQUEST}/update-info`
export const REQUEST_UPDATE_PROPERTY = `${REQUEST}/update-property`
export const REQUEST_UPDATE_PROPERTY_DETAILS = `${REQUEST}/property/:propertyId/details`
export const REQUEST_UPDATE_DRE_LICENSE = `${REQUEST}/dre-license/:propertyId`
export const REQUEST_UPDATE_SCREENING_FEE = `${REQUEST}/screening-fee/:propertyId`
export const PERMALINK_ACTIONS = `${REQUEST}/application`
export const PERMALINK_ACTIONS_ID = `${REQUEST}/application/:propertyId`
export const PERMALINK_ACTIONS_SCREENING = `${REQUEST}/application/:propertyId/actions`
// ================== End of Step Request Rental-Submission Routes ===================

export const SUPPORT_URL = process.env.SUPPORT_URL || 'https://support.rentspree.com'
export const SUPPORT_PROMO_CODE_URL = `${SUPPORT_URL}/how-to-apply-coupon`
export const SUPPORT_REFUND_URL = `${SUPPORT_URL}/rentspree-refund-policy`
export const SUPPORT_TERMS_URL = `${MAIN_URL}/rentspree-supplemental-terms-rentspree-pro`
export const SUPPORT_HELP_PRO_URL = `${SUPPORT_URL}/en/rentspree-pro`
export const SUPPORT_HELP_REQUEST_DOCS_URL = `${SUPPORT_URL}/what-is-document-upload`
export const SUPPORT_PARTICIPATING_PARTNERS_URL = `${SUPPORT_URL}/rentspree-pro-participating-partners`
export const SUPPORT_FAQ_URL = `${SUPPORT_URL}/refer-friends-and-colleagues`
export const SUPPORT_TERM_AND_CONDITION_URL = `${SUPPORT_URL}/rentspree-referral-terms`
export const SUPPORT_LINK_ZIPFORM_ACCOUNT_URL = `${SUPPORT_URL}/link-rentspree-zipform-plus`
export const SUPPORT_HELP_RENT_PAYMENT_URL = `${SUPPORT_URL}/en/collect-rent`
export const SUPPORT_HELP_RENTSPREE_PRO_URL = `${SUPPORT_URL}/en/rentspree-pro`
export const REFERRAL_FORM_PAGE = '/referral-form'
export const PRO_PAGE = '/rentspree-pro'
export const REQUEST_DOCS_PAGE = `${PRO_PAGE}/request-docs`
export const REF_CHECKS_PAGE = `${PRO_PAGE}/reference-checks`
export const PAYMENT_PAGE = '/payment'
export const SUBSCRIPTION_PAYMENT = `${PRO_PAGE}${PAYMENT_PAGE}`
export const WEB_CONTACT_PAGE = `${MAIN_URL}/contact-us`
export const VERSION_CHECK_API = `${process.env.BASE_NAME}/version`
export const VERSION_META_CHECK_API = `${process.env.BASE_NAME}/htmlversion`
export const RENT_ESTIMATE_PAGE = '/rental-estimate'
export const RENT_ESTIMATE_PAYMENT_PROCESSING =
  '/rent-estimate/:rentEstimateId/:process(complete|cancel)'

export const SINGLE_PROPERTY = `${PROPERTY}/:propertyId`
export const SINGLE_PROPERTY_RENT_PAYMENT = `${SINGLE_PROPERTY}/rent-payments/:rentalPaymentId`
export const SINGLE_PROPERTY_WITH_TYPE = `${SINGLE_PROPERTY}/:actionType`
export const SINGLE_PROPERTY_APPLICATION_LIST = `${SINGLE_PROPERTY}/rental`
export const SINGLE_APPLICATION = `${SINGLE_PROPERTY_APPLICATION_LIST}/:rentalAppId`
export const SINGLE_APPLICATION_PAYMENT = `${SINGLE_APPLICATION}/payment`
export const SINGLE_APPLICATION_PAYMENT_PROCESSING = `${SINGLE_APPLICATION_PAYMENT}/:process(complete|cancel)`
export const SINGLE_PROPERTY_EMPTY_RENT_PAYMENT = `${SINGLE_PROPERTY}/rent-payments`

export const SINGLE_APPLICATION_REGEXP = new RegExp(`${PROPERTY}/[^/]*${APPLICATION}/[^/]*`)
export const RENT_ESTIMATE_PROPERTY = `${PROPERTY}/:propertyId/rent-estimate`
export const DOWNLOAD = '/download'
export const DOCUMENT = `${DOWNLOAD}/rental/:rentalAppId/documents/:documentId/download`
export const DOCUMENT_FULL = `${DASHBOARD}${DOCUMENT}`
export const REPORT_TYPE = `${DOWNLOAD}/:reportType`
export const EXPLORE_PAGE = '/explore'
export const LRA_SIGN_PAGE = `${SINGLE_PROPERTY}/rental/:rentalId/application/:applicationId/sign`
export const DOCUMENTS_ABSOLUTE_PATH = `${MAIN_URL}${DASHBOARD}/properties/:propertyId/rental/:rentalAppId/documents`
export const VIEW_DOCUMENT = `/document/:documentId/`
export const VIEW_SINGLE_DOCUMENT_FILE = `/document/:documentId/file/:fileId`
export const REFERENCE_CHECKS_ABSOLUTE_PATH = `${MAIN_URL}${DASHBOARD}/properties/:propertyId/rental/:rentalAppId/reference-checks`
export const APPLICATION_SCREENING_REPORTS = `/properties/:propertyId/rental/:rentalAppId/:type`

// PROPERTY TYPE UPDATE
export const PROPERTY_TYPE_FORM_PAGE = `${PROPERTY}/:propertyId/property-type`

// PRINT REPORTS PAGE
export const IFRAME_PATH = '/iframe/:userType(landlord|renter)'
export const OLD_PRINT_REPORTS_PAGE = `${RENTAL}/:rentalAppId`
export const OLD_PRINT_REPORTS_IFRAME_PAGE = `${IFRAME_PATH}${RENTAL}/:rentalAppId`
export const PRINT_REPORTS_PAGE = `/reports${SINGLE_PROPERTY}/rental-submissions/:rentalAppId`
export const PRINT_REPORTS_RENTER_PAGE = `/reports/rental-submissions/:rentalAppId`
export const PRINT_REPORTS_IFRAME_PAGE = `${IFRAME_PATH}${PRINT_REPORTS_PAGE}`
export const PRINT_RENTAL_SUBMISSION = `${DASHBOARD}${PRINT_REPORTS_PAGE}/:type`
export const PRINT_RENTAL_SUBMISSION_WITHOUT_PROPERTY = `${DASHBOARD}${PRINT_REPORTS_RENTER_PAGE}/:type`
// OUTSIDE PATH
export const UPLOAD_DOCUMENT_VIDEO = 'https://www.youtube.com/embed/LyHQWnlaHZw?rel=0&showinfo=0'
export const HOW_TO_REQUEST_DOC_VIDEO = 'https://www.youtube.com/embed/1qypEx2co6E?rel=0&showinfo=0'
// API PATH

// Agent Property API
export const PROPERTY_API_V2 = `${API_URL}/api/v2/agent/properties`
export const PROPERTY_DETAIL_V2 = `${PROPERTY_API_V2}/:propertyId`
export const APPLICATION_LIST_API = `${PROPERTY_API_V2}/:propertyId/rental-submissions`
export const REACTIVATE_PROPERTY_API = `${PROPERTY_API_V2}/:propertyId/deactivate`
export const CREATE_SCREENING_REQUEST_API = `${PROPERTY_API_V2}/:propertyId/screening-requests`
export const GET_SUBMISSION_PARTICIPANT = `${APPLICATION_LIST_API}/:rentalId`

// Agent Screening Reqesut API
export const SCREENING_REQUEST_API = `${API_URL}/api/v2/agent/screening-requests`
export const REQUEST_RENTAL_API = `${SCREENING_REQUEST_API}/:screeningRequestId/request`
export const GET_APPLY_LINK_API = `${SCREENING_REQUEST_API}/:screeningRequestId/link`

// Portals API
export const ZIPLOGIX_PROPERTY_API = `${API_URL}/api/portals/ziplogix/property/:transactionId`
export const PROPERTY_DROPDOWN_API = `${API_URL}/api/portals/properties`

// Permalink Submission API

// Rental Submission API
export const RENTAL_SUBMISSION_API_V2 = `${API_URL}/api/v2/rental-submissions/:rentalId`
export const FORM_AVAILABLE_API = `${RENTAL_SUBMISSION_API_V2}/available`
export const REFERENCES_API = `${RENTAL_SUBMISSION_API_V2}/reference-checks`
export const RENTAL_DOCUMENT_API_V2 = `${RENTAL_SUBMISSION_API_V2}/documents`
export const RENTAL_DOCUMENT_TOKEN_V2 = `${RENTAL_DOCUMENT_API_V2}/:documentId`
export const PAYMENT_API_V2 = `${RENTAL_SUBMISSION_API_V2}/credit-report/payment`

// Submission Participant APIs
export const AGENT_RENTAL_SUBMISSION_API_V2 = `${API_URL}/api/v2/agent/rental-submissions/:rentalId`
export const AGENT_OPEN_REPORT_EXECUTION_API = `${AGENT_RENTAL_SUBMISSION_API_V2}/submission-participants/open`
export const AGENT_GET_SUBMISSION_PARTICIPANT_BY_EMAIL_API = `${AGENT_RENTAL_SUBMISSION_API_V2}/submission-participants`

// Other Documents API
export const OTHER_DOCS_API = `${API_URL}/api/v2/other-documents`
export const GET_OTHER_DOCS = `${OTHER_DOCS_API}/document-sets`
export const REQUEST_OTHER_DOCUMENTS_API = `${OTHER_DOCS_API}/documents/request`
export const CANCEL_DOCUMENT_REQUEST_API = `${GET_OTHER_DOCS}/:documentSetId/documents/:documentId/cancel`
// RentEstimate
export const RENT_ESTIMATE_API = `${API_URL}/api/v2/rent-estimate`
// Application API
export const APPLICATION_V2_DETAIL_API = `${RENTAL_SUBMISSION_API_V2}/application`
// Accept Deny
export const ACCEPT_DENY_API = `${RENTAL_SUBMISSION_API_V2}/applicant/:type`

// ====================== Get Other Documents API Endpoint ======================
export const DOWNLOAD_DOCUMENT_API = `${FILES_URL}/rental-submission/:rentalId/documents/:documentId`
export const DOWNLOAD_DOCUMENT_FILE_API = `${DOWNLOAD_DOCUMENT_API}/files/:fileId`
// ====================== End of Get Other Documents API Endpoint ======================

// Credit Report API
export const OPEN_CREDIT_REPORT_API_V2 = `${RENTAL_SUBMISSION_API_V2}/credit-report/open`
export const GET_CREDIT_REPORT_API_V2 = `${RENTAL_SUBMISSION_API_V2}/credit-report/full-report`
export const GET_CREDIT_REPORT_SCREENING_API_V1 = `${API_URL}/api/v1/reports/full-report/:rentalId`

// Compliance endpoints
export const COMPLIANCE_RULES_READ = '/api/v1/compliance/properties/:propertyId'
export const COMPLIANCE_ACTIONS_UPDATE = '/api/v1/compliance/action'

// Generate PDF API
export const GENERATE_REPORT_API = `${PRINT_PDF_URL}/:reportType`

// Get LRA PDF API
export const LRA_PDF_API = `${PRINT_PDF_URL}/rental-submission/:rentalId/lra`

// Screening Plan API
export const SCREENING_PLAN_API = `${API_URL}/api/v2/screening-plans`

// Subscription API V2
export const SUBSCRIPTION_API_V2 = `${API_URL}/api/v2/subscriptions/users`
export const SUBSCRIPTION_PLAN_API_V2 = `${SUBSCRIPTION_API_V2}/plans`

// User API
export const USER_API = `${USER_API_URL}/api/v2/user`
export const USER_ME_API = `${USER_API}/me`
export const USER_INTEGRATION_API = `${USER_ME_API}/integration`
export const USER_PREFERENCE_API = `${USER_ME_API}/preference`
export const USER_PREFERENCE_API_V2 = `${USER_API_URL}/api/v2/user/me/screening-preference`
export const USER_TERMS_API = `${USER_ME_API}/terms`
export const USER_LINK_ACCOUNT_API = `${USER_API}/link-account`
export const USER_WHITELISTING_API = `${USER_ME_API}/whitelisting`

// Email Verification API
export const EMAIL_VERIFICATION_SEND_API = `${USER_API_URL}/api/v2/email/verification/resend`

// LRA APU
export const LRA_FORM_API = `${API_URL}/api/v2/rental-submissions/:rentalSubmissionId/forms`

export const DOWNLOAD_PDF_API = `${FILES_URL}/rental-submission/:rentalSubmissionId/full-documents`

// Full Document Zip API
export const FULL_DOC_ZIP_API = `${FILES_URL}/rental-submission/:rentalId/documents`

// MORE PAGE
export const MORE_PAGE = `${PROPERTY}/:propertyId/more`

// envelope upload multiple file
export const UPLOAD_ENVELOPE_FILE = `${FILES_URL}/digidocs/files/upload`
export const BASE_DIGIDOC_URL = `${FILES_URL}/digidocs/`
export const DIGIDOC_GET_FILE = `${FILES_URL}/digidocs/envelope/:envelopeId/files`
export const CREATE_ENVELOPE = `${API_URL}/api/v2/digidocs/envelope`
export const UPDATE_ENVELOPE = `${API_URL}/api/v2/digidocs/envelope/:envelopeId`
export const UPDATE_ENVELOPE_SIGN = `${API_URL}/api/v2/digidocs/envelope/:envelopeId/sign`
export const VOID_ENVELOPE = `${API_URL}/api/v2/digidocs/envelope/:envelopeId/void`
// TODO: [TECH-17060] Clean up old delete envelope api
export const DELETE_ENVELOPE = `${API_URL}/api/v2/digidocs/envelope/:envelopeId`
export const RESEND_ENVELOPE = `${API_URL}/api/v2/digidocs/envelope/:envelopeId/share/resend`
export const GET_ENVELOPE = `${API_URL}/api/v2/digidocs/envelope/:envelopeId`
export const SHARE_ENVELOPE = `${API_URL}/api/v2/digidocs/envelope/:envelopeId/share`
export const EXCHANGE_TOKEN_API = `${API_URL}/api/v2/digidocs/envelope/:envelopeId/exchange`
export const UPLOAD_SIGNATURE = `${FILES_URL}/digidocs/envelope/:envelopeId/signature`
export const UPDATE_ENVELOPE_CONSENT = `${API_URL}/api/v2/digidocs/envelope/:envelopeId/consent`

export const UPDATE_TEMPLATE_ENVELOPE = `${API_URL}/api/v2/digidocs/envelope/:envelopeId/template`
export const ESIGN_TEMPLATE_LIST_API = `${API_URL}/api/v2/digidocs/template`
export const ESIGN_TEMPLATE_BY_ID_API = `${API_URL}/api/v2/digidocs/template/:digitalDocTemplateId`
export const REQUEST_NEW_TOKEN_API = `${API_URL}/api/v2/digidocs/envelope/:envelopeId/requestToken`

// Lease Agreement
export const LEASE_AGREEMENT_LIST = `${PROPERTY}/:propertyId${LEASE_AGREEMENT}`
// TECH-22132 - TODO: this LEGACY_LEASE_AGREEMENT_LIST route is to be deprecated
export const LEGACY_LEASE_AGREEMENT_LIST = `${PROPERTY}/:propertyId${LEGACY_LEASE_AGREEMENT}`

// ----- TODO: This group of routes is to be deprecated
export const LEASE_AGREEMENT_REQUEST = `${LEASE_AGREEMENT_LIST}/create`
export const LEASE_AGREEMENT_DETAILS = `${LEASE_AGREEMENT_LIST}/:leaseAgreementId`
export const LEASE_AGREEMENT_EDIT = `${LEASE_AGREEMENT_DETAILS}/edit`
export const LEASE_AGREEMENT_UNSUPPORT_PAGE = `${LEASE_AGREEMENT_DETAILS}/unsupport`
export const LEASE_AGREEMENT_SELECT_DOCUMENTS = `${LEASE_AGREEMENT_DETAILS}/select-documents`
// ------ End of deprecated routes

// lease Agreement API
export const API_LEASE_AGREEMENT_BY_ID =
  '/api/v2/properties/:propertyId/lease-agreements/:leaseAgreementId'

export const API_CREATE_ENVELOPE_FOR_LEASE_AGREEMENT = `${API_LEASE_AGREEMENT_BY_ID}/envelope`

// ----- TODO: This group of routes is to be deprecated
// envelope
export const ENVELOPE = `${LEASE_AGREEMENT_DETAILS}/envelope/:envelopeId`
export const ENVELOPE_UPLOAD_PAGE = `${ENVELOPE}/uploads`
export const ENVELOPE_RECIPIENTS_PAGE = `${ENVELOPE}/recipients`
export const ENVELOPE_DOCUMENT_PAGE = `${ENVELOPE}/documents`
export const ENVELOPE_REVIEW_PAGE = `${ENVELOPE}/review`
export const ENVELOPE_SIGN = `${ENVELOPE}/sign`
export const ENVELOPE_VIEW = `${ENVELOPE}/view`
export const ENVELOPE_EXCHANGE = `${ENVELOPE}/exchange`
export const ENVELOPE_FINISH_SIGN = `${ENVELOPE}/finish`
export const ENVELOPE_VOIDED = `${ENVELOPE}/voided`
export const ENVELOPE_UNSUPPORT_MOBILE_PAGE = `${LEASE_AGREEMENT_DETAILS}/unsupport/mobile`
export const ENVELOPE_LINK_EXPIRED_PAGE = `${ENVELOPE}/refresh-link` // ?refreshToken={refreshToken}
export const ENVELOPE_LINK_EXPIRED_REQUEST_SUCCESS_PAGE = `${ENVELOPE}/refresh-link-success`
export const ENVELOPE_ACCESS_DENIED_PAGE = `${ENVELOPE}/access-denied` // ?refreshToken={refreshToken}
// ------ End of deprecated routes
export const GET_ENVELOPE_PDF = `${API_URL}/api/v2/rental-submissions/:rentalId/envelope/:envelopeId/pdf`

// esign dashboard
export const ESIGN_DASHBOARD_BASE_URL = `/esign-dashboard`
export const ESIGN_LEASE_AGREEMENT_DETAILS = `/properties/:propertyId/esign-documents/:leaseAgreementId`
export const ESIGN_ENVELOPE = `${ESIGN_LEASE_AGREEMENT_DETAILS}/envelope/:envelopeId`
export const ESIGN_DASHBOARD_URL = `/esign-dashboard${ESIGN_LEASE_AGREEMENT_DETAILS}`
export const ESIGN_DASHBOARD_ENVELOPE_URL = `/esign-dashboard${ESIGN_ENVELOPE}`
// TODO: [ESign-Legacy-Improvement] - Clean up: deprecated using select method route
export const ESIGN_DASHBOARD_SELECT_DOCUMENTS = `${ESIGN_DASHBOARD_URL}/select-documents`
export const ESIGN_DASHBOARD_UPLOAD_PAGE = `${ESIGN_DASHBOARD_ENVELOPE_URL}/uploads`
export const ESIGN_DASHBOARD_RECIPIENTS_PAGE = `${ESIGN_DASHBOARD_ENVELOPE_URL}/recipients`
export const ESIGN_DASHBOARD_REVIEW_PAGE = `${ESIGN_DASHBOARD_ENVELOPE_URL}/review`
// TODO: [ESign-Legacy-Improvement] - Clean up: deprecated this route from calling via Dashboard V2
export const ESIGN_DASHBOARD_LEASE_BUILDER_INITIATE = `${ESIGN_DASHBOARD_BASE_URL}/lease-builder/initiate`
// TODO: TECH-17060 - Clean up this route and its usage in old lease-agreement detail page
export const ESIGN_DASHBOARD_LEASE_BUILDER_RECIPIENT_STEP = `${ESIGN_DASHBOARD_BASE_URL}/lease-builder/properties/:propertyId/leases/:leaseAgreementId/records/:recordId/recipient`
export const ESIGN_DASHBOARD_LEASE_BUILDER_SUMMARY_STEP = `${ESIGN_DASHBOARD_BASE_URL}/lease-builder/properties/:propertyId/leases/:leaseAgreementId/records/:recordId/summary`
export const ESIGN_DASHBOARD_LEASE_BUILDER_V2_CONFIRM_STEP = `${ESIGN_DASHBOARD_BASE_URL}/lease-builder/v2/properties/:propertyId/leases/:leaseAgreementId/records/:recordId/confirm`
export const ESIGN_DASHBOARD_SELECT_METHODS = `${ESIGN_DASHBOARD_BASE_URL}/properties/:propertyId/select-methods`

// Application Envelope URLs Path
const PROPERTY_URL = '/properties/:propertyId'
const RENTAL_URL = '/rental/:rentalId'
const APPLICATION_URL = '/application/:applicationId'
export const APPLICATION_ENVELOPE_SIGN = `${PROPERTY_URL}${RENTAL_URL}${APPLICATION_URL}/sign`

// Messageing
export const MESSAGING_PAGE = `/messaging`
export const PUBLIC_MESSAGING_PAGE = `/public/messaging`

// OVERVIEW PAGE
export const PROPERTY_OVERVIEW_PAGE = `${SINGLE_PROPERTY}/overview`

// RENTER INSURANCE
export const RENTER_INSURANCE = '/renter-insurance'
export const RENTER_INSURANCE_PROPERTY = `${RENTER_INSURANCE}/property`
export const RENTER_INSURANCE_PAGE = `${SINGLE_PROPERTY}${RENTER_INSURANCE}`
export const RENTER_INSURANCE_REQUEST_PAGE = `${RENTER_INSURANCE_PAGE}/request`
export const RENTER_INSURANCE_DETAIL = `${SINGLE_PROPERTY}${RENTER_INSURANCE}/:rentersInsuranceId`
export const RENTER_INSURANCE_SELECT_PROPERTY = `${RENTER_INSURANCE_PROPERTY}/select`
export const RENTER_INSURANCE_CREATE_PROPERTY = `${RENTER_INSURANCE_PROPERTY}/create`

// RENTER INSURANCE API
export const REQUEST_RENTER_INSURANCE_API = `${API_URL}/api/v2/properties/:propertyId/insurance/renter`
export const RENTER_INSURANCE_FILE_API = `${FILES_URL}/insurance/properties/:propertyId/renter/:rentersInsuranceId/files`

// RENT PAYMENT
export const RENT_PAYMENT = `/rent-payments`
export const RENT_PAYMENT_SETTINGS = `${RENT_PAYMENT}/settings`

export const RENT_PAYMENT_PROPERTY_PAGE = `${SINGLE_PROPERTY}${RENT_PAYMENT}`
export const RENT_PAYMENT_REQUEST_PAGE = `${RENT_PAYMENT}/requests`
export const RENT_PAYMENT_SETUP_REQUEST_PAGE = `${RENT_PAYMENT}/setup`
export const RENT_PAYMENT_REQUEST_AGENT_INITIATED_PAGE = `${RENT_PAYMENT_REQUEST_PAGE}/agent-initiated`
export const RENT_PAYMENT_REQUEST_AGENT_INITIATED_CONFIRMATION_PAGE = `${RENT_PAYMENT_REQUEST_PAGE}/agent-initiated-confirmation`
export const RENT_PAYMENT_REQUEST_AGENT_INITIATED_SUCCESS_PAGE = `${RENT_PAYMENT_REQUEST_PAGE}/agent-initiated-success`
export const RENT_PAYMENT_REQUEST_PAYMENT_DETAILS_PAGE = `${RENT_PAYMENT_REQUEST_PAGE}/payment-details`
export const RENT_PAYMENT_REQUEST_CONFIRMATION_PAGE = `${RENT_PAYMENT_REQUEST_PAGE}/confirmation`
export const RENT_PAYMENT_REQUEST_BANK_SETUP = `${RENT_PAYMENT_REQUEST_PAGE}/bank-setup`

export const RENT_PAYMENT_SELECT_PAYMENT_RECEIVER = `${RENT_PAYMENT}/payment-receiver`
// TODO : Re check then delete unused route
export const RENT_PAYMENT_REQUEST_TENANT_PAGE = `${RENT_PAYMENT_REQUEST_PAGE}/tenants`
export const RENT_PAYMENT_REQUEST_RENT_INFO_PAGE = `${RENT_PAYMENT_REQUEST_PAGE}/rent-info`
export const RENT_PAYMENT_REQUEST_PAYMENT_DETAIL_MONTHLY_PAGE = `${RENT_PAYMENT_REQUEST_PAYMENT_DETAILS_PAGE}/monthly`
export const RENT_PAYMENT_REQUEST_PAYMENT_DETAIL_ONE_TIME_PAGE = `${RENT_PAYMENT_REQUEST_PAYMENT_DETAILS_PAGE}/one-time`
export const RENT_PAYMENT_REQUEST_RECEIVING_ACCOUNTS_PAGE = `${RENT_PAYMENT_REQUEST_PAGE}/receiving-accounts`
export const RENT_PAYMENT_REQUEST_ONBOARDING_PAGE = `${RENT_PAYMENT_REQUEST_PAGE}/onboarding`

export const RENT_PAYMENT_ID_PAGE = `${RENT_PAYMENT_PROPERTY_PAGE}/:rentPaymentId`
export const RENT_PAYMENT_ID_TRANSACTION_PAGE = `${RENT_PAYMENT_ID_PAGE}/transactions`
export const RENT_PAYMENT_ID_HISTORY_PAGE = `${RENT_PAYMENT_ID_PAGE}/history`
export const RENT_PAYMENT_ID_INFO_PAGE = `${RENT_PAYMENT_ID_PAGE}/info`

// Rent Payment IA PATH
export const IA_RENT_PAYMENT_ID_PAGE = `${RENT_PAYMENT}/:rentPaymentId`

// Rent Payment Success
export const SUCCESS_ACTION_TAKEOVER_BASE = 'success-action-overlay'
export const IA_SUCCESS_TAKEOVER_RENT_PAYMENT_LANDING = `${RENT_PAYMENT}/${SUCCESS_ACTION_TAKEOVER_BASE}`
export const IA_SUCCESS_TAKEOVER_RENT_PAYMENT_ID_PAGE = `${IA_RENT_PAYMENT_ID_PAGE}/${SUCCESS_ACTION_TAKEOVER_BASE}`

/* Optimize RP Setup */
export const RENT_PAYMENT_VERIFY_TAX = `${RENT_PAYMENT}/verify-tax`
export const RENT_PAYMENT_VERIFY_TAX_WITH_STRIPE_EMBEDDED = `${RENT_PAYMENT}/verify-tax-stripe-embedded`
export const RENT_PAYMENT_ONBOARDING_REDIRECT = `${RENT_PAYMENT}/redirect`
/* END Optimize RP Setup */

// RP : Identity Verification
export const TAX_INFORMATION_BASE_PATHNAME = 'tax-information'
export const IA_SETTING_TAX_INFORMATION_TAKEOVER = `${RENT_PAYMENT_SETTINGS}/${TAX_INFORMATION_BASE_PATHNAME}`
export const IA_SETTING_TAX_INFORMATION_TAKEOVER_BUSINESS_TYPE = `${IA_SETTING_TAX_INFORMATION_TAKEOVER}/business-type`
export const IA_SETTING_TAX_INFORMATION_TAKEOVER_PERSON_DETAIL = `${IA_SETTING_TAX_INFORMATION_TAKEOVER}/personal-details`
export const RENT_PAYMENT_CONFIRMATION_TAX_INFORMATION_TAKEOVER = `${RENT_PAYMENT_REQUEST_CONFIRMATION_PAGE}/${TAX_INFORMATION_BASE_PATHNAME}`
export const RENT_PAYMENT_CONFIRMATION_TAX_INFORMATION_TAKEOVER_BUSINESS_TYPE = `${RENT_PAYMENT_CONFIRMATION_TAX_INFORMATION_TAKEOVER}/business-type`
export const RENT_PAYMENT_CONFIRMATION_TAX_INFORMATION_TAKEOVER_PERSON_DETAIL = `${RENT_PAYMENT_CONFIRMATION_TAX_INFORMATION_TAKEOVER}/personal-details`
// TENANT SCREENING
export const TENANT_SCREENING = `/tenant-screening`
export const TENANT_SCREENING_LANDING = `${TENANT_SCREENING}/landing`
export const SCREENING_NEW_TENANT = `${TENANT_SCREENING}/request`
export const SELECT_PROPERTY_BEFORE_SCREENING_NEW_TENANT = `${TENANT_SCREENING}/request/select-property`
export const SELECT_AGENT_TYPE = `${TENANT_SCREENING}/request/select-agent-type`
export const SELECT_AGENT_TYPE_WITH_PROPERTY_ID = `${TENANT_SCREENING}/request/select-agent-type/:propertyId`
export const SCREENING_NEW_TENANT_ACTION = `${TENANT_SCREENING}/request/actions`
export const SCREENING_NEW_TENANT_ACTION_V2 = `${TENANT_SCREENING}/request/v2/actions`
export const TENANT_SCREENING_DETAIL = `${TENANT_SCREENING}/:submissionParticipantId`
export const ASSIGN_PROPERTY = `${TENANT_SCREENING}/:submissionParticipantId/assign-property`
export const TENANT_SCREENING_PENDING = `${TENANT_SCREENING_DETAIL}/pending`
export const TENANT_SCREENING_WITH_RENTAL_ID = `${TENANT_SCREENING}/rental/:rentalAppId`
export const TENANT_SCREENING_WITH_RENTAL_ID_PAYMENT = `${TENANT_SCREENING_WITH_RENTAL_ID}/payment`
export const TENANT_SCREENING_WITH_RENTAL_ID_PAYMENT_PROCESSING = `${TENANT_SCREENING_WITH_RENTAL_ID_PAYMENT}/:process(complete|cancel)`

// RENTER DASHBOARD
export const RENTER_DASHBOARD = `/dashboard/overview`

// LISTING INFORMATION
export const CREATE_LISTING_REDIRECT = `/request-create-listing`
export const CREATE_LISTING = `${SINGLE_PROPERTY}/create-listing`
export const CREATE_LISTING_INFORMATION_STEP = `${CREATE_LISTING}/listing-info`
export const CREATE_LISTING_DESCRIPTION_STEP = `${CREATE_LISTING}/description`
export const CREATE_LEASING_INFORMATION_STEP = `${CREATE_LISTING}/leasing-info`
export const CREATE_UTILITIES_AMENITIES_STEP = `${CREATE_LISTING}/utilities-amenities`
export const CREATE_PREVIEW_STEP = `${CREATE_LISTING}/preview`
export const SHARE_LISTING_STEP = `${CREATE_LISTING}/share`
export const SHARE_APPLY_LINK = `${SINGLE_PROPERTY}/share-apply-link`
export const LISTING_MANAGE = `${SINGLE_PROPERTY}/listing/manage`
export const CREATE_LISTING_SCREENING_REQUEST_API = `/api/v2/properties/:propertyId/listing/screening-request`
export const TOGGLE_ACCEPTING_STATUS_API = `/api/v2/properties/:propertyId/listing/:status`
export const IMPORT_MLS_LISTING = `${SINGLE_PROPERTY}/listing/import`
export const IMPORT_MLS_LISTING_API = `${API_URL}/api/v2/properties/:propertyId/listing/import`
export const IMPORTABLE_LISTING_API = `${API_URL}/api/v2/properties/:propertyId/listing/importable`
export const LISTING_LANDING_PAGE = `/listing-page`
export const LISTING_LANDING_SELECT_PROPERTY_PAGE = `${LISTING_LANDING_PAGE}/request/select-property`
export const LISTING_REQUEST_CREATE_SCREENING_OPTIONS = `${SINGLE_PROPERTY}/listing-request-create-screening-options`
export const AUTO_IMPORT_MLS_LISTING = `${SINGLE_PROPERTY}/listing/auto-import`
export const AUTO_CREATE_LISTING_SCREENING_REQUEST_API = `/api/v2/properties/:propertyId/listing/auto-screening-request`
// LISTING SYNDICATION
export const GET_LISTING_SYNDICATION_API = `${API_URL}/api/v2/agent/properties/:propertyId/listing-syndication`
export const CREATE_LISTING_SYNDICATION_API = `${API_URL}/api/v2/agent/properties/:propertyId/listing-syndication`
export const ENABLE_LISTING_SYNDICATION_API = `${API_URL}/api/v2/agent/properties/:propertyId/listing-syndication/enable`
export const DISABLE_LISTING_SYNDICATION_API = `${API_URL}/api/v2/agent/properties/:propertyId/listing-syndication/disable`
export const UPDATE_CONTACT_DETAILS_API = `${API_URL}/api/v2/agent/properties/:propertyId/listing-syndication/contact`

// CONTACTS
export const CONTACT_GROUP = {
  STARRED: 'starred',
  RENTSPREE_APPLICATION: 'rentspree-application',
  RECENT_ACTIVITY: 'recent-activity',
  UPCOMING_EVENT: 'upcoming-event',
}
export const CONTACTS = '/client-manager'
export const CONTACTS_LIST = `${CONTACTS}/:contactGroup(${CONTACT_GROUP.STARRED}|${CONTACT_GROUP.RENTSPREE_APPLICATION}|${CONTACT_GROUP.RECENT_ACTIVITY}|${CONTACT_GROUP.UPCOMING_EVENT})`
export const CONTACT_DETAIL = `${CONTACTS}/:contactId`
export const CONTACT_IMPORT_CSV = `${CONTACTS}/import-csv`
export const CONTACT_REDIRECT = `${CONTACTS}/redirect`

// TASKS
export const TASK_GROUP = {
  OUTSTANDING: 'outstanding',
  COMPLETED: 'completed',
}
export const TASKS = '/tasks'
export const TASKS_LIST = `${TASKS}/:tasksGroup(${TASK_GROUP.COMPLETED})`

// AGENT PROFILE
export const AGENT_PROFILE_API = '/api/v3/agent/agent-profile'

/* =================== Agreement Page Zone =================== */
export const AGREEMENT_PAGE = '/agreement/:type'
/* =================== End of Agreement Page Zone =================== */

/* =================== Application Page Zone =================== */
export const APPLICATION_ALL_REPORTS_PAGE = `${PROPERTY_URL}${RENTAL_URL}/all`
export const APPLICATION_ALL_REPORTS_PAGE_WITHOUT_PROPERTY = `${TENANT_SCREENING_WITH_RENTAL_ID}/all`
export const REFERENCE_CHECKS_PAGE_WITHOUT_PROPERTY = `${TENANT_SCREENING_WITH_RENTAL_ID}/reference-checks`
export const DOCUMENTS_REPORT_PAGE_WITHOUT_PROPERTY = `${TENANT_SCREENING_WITH_RENTAL_ID}/documents`
/* =================== End of Application Page Zone =================== */

// Note: Currently, This route is used for CRMLS Event Tracking (Agent View Report)
export const PARTNER_EVENT_TRACKER_API = `${API_URL}/api/v2/partners/event`

// GENERATE & POLLING PATH
export const GENERATE_REPORTS_PAGE = '/generate/:reportType'

// esign dashboard
export const LRA_ESIGN_SIGN_PAGE = `${MAIN_URL}/esign-dashboard/properties/:propertyId/rental/:rentalId/application/:applicationId/sign`

// ERROR PAGES
export const REPORT_GENERATION_LIMIT_PAGE = '/report-generation-limit'

export const GET_USER_GET_STARTED_ACTION = `${API_URL}/api/v2/announcement/user-get-started-actions/me`

export const VERIFY_PROPERTY_ADDRESS_API = `${API_URL}/api/v2/address-verification`

// Auth management
export const AUTH_SESSION_TIMEOUT = "/account/session-timeout"
