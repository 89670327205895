import get from 'lodash/get'
import snakeCase from 'lodash/snakeCase'

import { REPORT_TYPES, SHARED_REPORT_TYPES } from './constants'
import { AGENT_TYPES } from '../tenant-screening/request/constants'

const { RENTER_AGENT } = AGENT_TYPES
// use to check that report is not requested or shared for application and screening report
export const getIsSelectedReportUnavailable = (
  reportType,
  isSharedReport,
  screeningOption,
  shareOption,
) => {
  const isSelectedReportRequested = screeningOption[snakeCase(reportType)]
  let isSelectedReportShared

  switch (reportType) {
    case REPORT_TYPES.APPLICATION:
      isSelectedReportShared = get(shareOption, SHARED_REPORT_TYPES.APPLICATION, false)
      break
    case REPORT_TYPES.INCOME_VERIFICATION:
      isSelectedReportShared = get(
        shareOption,
        SHARED_REPORT_TYPES.INCOME_VERIFICATION_REPORT,
        false,
      )
      break
    case REPORT_TYPES.CREDIT_REPORT:
    case REPORT_TYPES.EVICTION:
    case REPORT_TYPES.CRIMINAL:
      isSelectedReportShared = get(shareOption, SHARED_REPORT_TYPES.SCREENING_REPORT, false)
      break
    default:
      isSelectedReportShared = false
      break
  }

  return isSelectedReportRequested === false || (isSharedReport && !isSelectedReportShared)
}

export const getIsRefCheckUnavailable = (references, isSharedReport, shareOption) =>
  (references && references.length === 0) || (isSharedReport && !shareOption.referenceCheck)

export const getIsOtherDocsUnavailable = (
  rentalDocuments,
  havePdf,
  isSharedReport,
  shareOption,
) => {
  const isOtherDocsNotUploaded = havePdf !== null && !havePdf
  const isOtherDocsNotShared = isSharedReport && !shareOption.otherDocs
  const isOtherDocsNotRequested = rentalDocuments && rentalDocuments.length === 0
  return isOtherDocsNotUploaded || isOtherDocsNotShared || isOtherDocsNotRequested
}

export const isAllowAcceptDeny = agentType => agentType !== RENTER_AGENT

export const generateLandlordFullName = (firstname, lastname) => {
  if (firstname && lastname) {
    return `${firstname} ${lastname}`
  }
  if (firstname) {
    return firstname
  }
  return undefined
}

export const hasInLieuOfCredit = application =>
  get(application, 'occupation.rentSubsidy.inLieuOfCredit', false)

export const shouldRenderIcraaDisclaimer = ({ rentalDetail }) => {
  const isPropertyInCalifornia = get(rentalDetail, 'property.state') === 'CA'
  // ICRAA is just a dummy name for rn until we know the actual name thats being sent back
  if (rentalDetail && rentalDetail.rules) {
    const icraaRule = rentalDetail.rules.requiredDisclaimers.find(
      disclaimer => disclaimer.name === 'ICRAA',
    )
    // acceptanceRequired is either true / false
    return icraaRule.acceptanceRequired && isPropertyInCalifornia
  }

  return false
}
