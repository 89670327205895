/* eslint react/no-array-index-key: "off" */

import React from 'react'
import moment from 'moment'
import ImageLoader from 'react-imageloader'
import ReactSVG from 'react-svg'
import { Icon } from '@rentspree/component-v2/dist/icon'
import isNaN from 'lodash/isNaN'
import isEmpty from 'lodash/isEmpty'
import classNames from 'classnames/bind'
import { convert } from '../../helper/convert'
import { isFirefoxAndroid, isDeviceSafari } from '../../helper/ua-parser-js'
import style from './doc-components.scss'
import CompanyInfo from '../../reports-v2/share/company-info-footer'

const c = classNames.bind(style)

const ImgPreloader = require('../../../images/gifs/loader-white.svg')
const defaultProfile = require('../../../images/credit-report/rentspree-profile.jpg')
const DEFAULT_LOGO = require('../../../images/credit-report/transunion-logo-white.png')

export const Preloader = () => <img src={ImgPreloader} width="90" alt="preloader" />
// ================= Credit Report Only ================= //
export const CreditScoreContent = ({ title, text }) => (
  <div className={c('credit-score-content')}>
    <div>
      <p className={c('credit-score-title')}>
        <span>{title}</span>
      </p>
      <p className={c('credit-score-text')}>
        <span>{text}</span>
      </p>
    </div>
  </div>
)

function isNumeric(value) {
  return !isNaN(value - parseFloat(value))
}

export const CreditScoreFactor = ({ scoreFactor, scoreFactorCode }) => (
  <div className={c('credit-score-factor')}>
    <StripedTable>
      <StripedTableHeader>
        <StripedTableCell text="Score Factors" />
      </StripedTableHeader>

      {Array.isArray(scoreFactor) && scoreFactor.length > 0 ? (
        scoreFactor.map((item, index) => (
          <StripedTableRow key={`scoreFactor ${index}`}>
            <StripedTableCell text={isNumeric(item) ? scoreFactorCode[item] : item} />
          </StripedTableRow>
        ))
      ) : (
        <StripedTableRow>
          {isEmpty(scoreFactor) ? (
            <StripedTableCell />
          ) : (
            <StripedTableCell
              text={isNumeric(scoreFactor) ? scoreFactorCode[scoreFactor] : scoreFactor}
            />
          )}
        </StripedTableRow>
      )}
    </StripedTable>
  </div>
)

export const getClassPaymentPattern = code => {
  switch (code) {
    case '1':
      // Paid or paying as agreed
      return 'rating_01'
    case '2':
      // 30 days past due
      return 'rating_02'
    case '3':
      // 60 days past due
      return 'rating_03'
    case '4':
      // 90 days past due
      return 'rating_04'
    case '5':
      // 120 days past due
      return 'rating_05'
    default:
      return 'unclassified'
  }
}

export const generatePaymentHistory = (paymentPatternStartDate, paymentPattern) => {
  // TODO: refactor 1 for
  let startMonth = moment(paymentPatternStartDate).month()
  let startYear = moment(paymentPatternStartDate).year()
  const endMonth = paymentPattern.length
  const countYear = Math.round((startMonth + endMonth) / 12)
  const paymentHistory = []

  for (let i = 0; i < countYear; i += 1) {
    paymentHistory.push({
      year: startYear,
      monthsRevers: [],
    })
    startYear = parseInt(startYear, 10) - 1
  }
  let indexPattern = 0
  paymentHistory.forEach(obj => {
    for (let i = 12; i > 0; i -= 1) {
      if (i <= startMonth + 1 && indexPattern < endMonth) {
        obj.monthsRevers.push(getClassPaymentPattern(paymentPattern[indexPattern]))
        indexPattern += 1
      } else {
        obj.monthsRevers.push(null)
      }
    }
    startMonth = 12
  })

  return paymentHistory
}

export const countAccountRating = (paymentPattern, code) => {
  const regex = new RegExp(`[^${code}]`, 'g')

  return paymentPattern.replace(regex, '').length
}

const isChrome = () => {
  if (window.printMode) {
    return true
  }
  try {
    const IS_CHROME = !!window.chrome && !!window.chrome.webstore
    return IS_CHROME
  } catch (e) {
    return false
  }
}

const isIE = () => {
  try {
    return /* @cc_on!@ */ false || !!document.documentMode
  } catch (e) {
    return false
  }
}

// ================= Universal Components ================= //
export const FullReportDetailBody = ({
  requestedDate,
  docClassName,
  children,
  title,
  logo,
  download,
  onPrint,
  expiresOn,
}) => (
  <div
    className={c(
      'full-report-detail-part-body',
      docClassName,
      `${isChrome() && 'print-chrome'}`,
      `${isDeviceSafari ? 'print-safari' : ''}`,
    )}
  >
    <div className={c('full-report-detail-part-header', `${!isChrome() && 'is-not-chrome'}`)}>
      <div className={c('wrapper-button')}>
        <ButtonPrint onPrint={onPrint} />
        <ButtonPDF onClick={download.onClick} isFetching={download.isFetching} />
        <ButtonPDF
          onClick={download.onMobileClick}
          isFetching={download.isFetching}
          additionalClass="-mobile"
        />
      </div>
      <img
        src={logo || DEFAULT_LOGO}
        className={c('transunion-logo')}
        alt="TransUnion logo white"
      />
    </div>
    <span className={c('full-report-doc-type')}>{title}</span>
    {requestedDate && (
      <span className={c('full-report-doc-date')}>
        {moment(requestedDate).format('MMM DD, YYYY')}
      </span>
    )}
    <br />
    <span className={c('full-report-expires-on')}>{expiresOn && expiresOn}</span>

    {children}
  </div>
)

export const FullReportDetailSection = ({ title, icon, children, img, isNewPage = '' }) => (
  <div className={c('full-report-detail-part-section', `${isNewPage && 'new-page'}`)}>
    <div className={c('section-title')}>
      {icon && <i className={c('section-icon', icon)} />}
      {img && (
        <div className={c('section-icon-img')}>
          <ReactSVG
            path={img}
            className={c('section-icon', 'icon-img', `${isIE() && 'is-not-chrome'}`)}
          />
        </div>
      )}
      <h2 className={c('section-title')}>{title}</h2>
    </div>
    {children}
  </div>
)

export const FullReportApplicantSection = ({
  profileImg,
  name,
  email,
  birthDate,
  address,
  employments,
}) => (
  <div className={c('full-report-applicant-section')}>
    <div className={c('applicant-image')}>
      <ImageLoader src={profileImg} preloader={Preloader}>
        <img src={defaultProfile} alt="default profile" />
      </ImageLoader>
    </div>

    <div className={c('applicant-name')}>{name}</div>
    {!isEmpty(email) && <div className={c('applicant-email')}>{email}</div>}
    {!isEmpty(birthDate) && (
      <div className={c('applicant-dob')}>
        Date of Birth : {convert(birthDate, 'dateOfUTC', 'MMM DD, YYYY')}
      </div>
    )}
    {(employments.length > 0 || address.length > 0) && (
      <div className={c('applicant-content')}>
        {employments.length > 0 && (
          <div className={c('applicant-highlight')}>
            <div className={c('applicant-highlight-title')}>Employer</div>
            <StripedTable>
              {employments.map((item, index) => (
                <StripedTableRow>
                  <StripedTableCell key={`employment${index}`} text={item} />
                </StripedTableRow>
              ))}
            </StripedTable>
          </div>
        )}
        {address.length > 0 && (
          <div className={c('applicant-highlight')}>
            <div className={c('applicant-highlight-title')}>Current Address</div>
            <StripedTable>
              {address.map((a, index) => (
                <StripedTableRow>
                  <StripedTableCell key={`a${index}`} text={a} />
                </StripedTableRow>
              ))}
            </StripedTable>
          </div>
        )}
      </div>
    )}
  </div>
)
export const Loader = () => (
  <div className={c('loader-wrapper')}>
    <i className="fas fa-circle-notch fa-spin" />
  </div>
)
export const ButtonPrint = ({ onPrint, propsClass }) => {
  if (isFirefoxAndroid) {
    return <div />
  }
  return (
    <div className={c('print-button', '-margin', propsClass)}>
      <ButtonInverse size="m" onClick={onPrint}>
        <i className={c('icon-printer')} />
        &nbsp;&nbsp; Print
      </ButtonInverse>
    </div>
  )
}
// ToDo: remove after refactor route-report
export const ButtonShareMobile = ({ onClick, propsClass }) => (
  <div className={c('share-button', propsClass)}>
    <ButtonInverse size="s" onClick={onClick}>
      <span className={c('vertical-btn')}>
        <Icon name="share" size="md" />
        Share
      </span>
    </ButtonInverse>
  </div>
)
export const ButtonViewMobile = ({ onView, propsClass }) => (
  <div className={c('view-button', propsClass)}>
    <ButtonInverse size="s" onClick={onView}>
      <span className={c('vertical-btn')}>
        <Icon name="doc-plain" size="md" />
        View
      </span>
    </ButtonInverse>
  </div>
)
export const ButtonPrintMobile = ({ onPrint, propsClass }) => {
  if (isFirefoxAndroid) {
    return <div />
  }
  return (
    <div className={c('print-button', propsClass)}>
      <ButtonInverse size="s" onClick={onPrint}>
        <span className={c('vertical-btn')}>
          <Icon name="print" size="md" />
          Print
        </span>
      </ButtonInverse>
    </div>
  )
}
export const ButtonPDFMobile = ({ onClick, isFetching, additionalClass }) => (
  <div style={{ width: '38%' }} className={c('download-button', additionalClass)}>
    <ButtonInverse
      size="s"
      onClick={event => {
        event.preventDefault()
        event.stopPropagation()
        onClick()
      }}
      disabled={isFetching}
    >
      <span className={c('vertical-btn')}>
        <Icon name="download" size="md" />
        Download
      </span>
    </ButtonInverse>
  </div>
)
export const ButtonPDF = ({ onClick, isFetching, additionalClass }) => (
  <div className={c('download-button', '-margin', additionalClass)}>
    <ButtonInverse
      size="m"
      onClick={event => {
        event.preventDefault()
        event.stopPropagation()
        onClick()
      }}
      disabled={isFetching}
    >
      {isFetching ? (
        <span>
          <Loader />
          &nbsp;&nbsp; Save as PDF
        </span>
      ) : (
        <span>
          <i className={c('far fa-file-pdf', 'icon')} />
          &nbsp;&nbsp; Save as PDF
        </span>
      )}
    </ButtonInverse>
  </div>
)
export const ButtonInverse = props => {
  const styleButton = `button--size-${props.size || 's'}`
  return (
    <button
      className={c('btn', 'btn--normal', 'button--round-s', `${styleButton}`)}
      type="button"
      {...props}
    >
      {props.children}
    </button>
  )
}

export const InfoSingleSection = ({ children, hide_border: hideBorder }) => (
  <div className={c('info-single-section', { hide_border: hideBorder })}>{children}</div>
)

export const InfoSingleGroup = ({ children }) => (
  <div className={c('info-single-group')}>{children}</div>
)

export const InfoSingleContent = ({ title, text, type, format, isHide, textNotData }) => (
  <div className={c('info-single-content')}>
    {!isHide && !textNotData && (
      <div>
        <div className={c('info-single-title')}>
          <span>{title}</span>
        </div>
        <div className={c('info-single-text')}>
          <span>{convert(text, type, format)}</span>
        </div>
      </div>
    )}
    {textNotData && (
      <div>
        <div className={c('info-single-title')}>
          <span>{textNotData}</span>
        </div>
      </div>
    )}
  </div>
)

export const StripedTable = ({ children }) => (
  <table className={c('striped-table')}>
    <tbody>{children}</tbody>
  </table>
)

export const StripedTableHeader = ({ children }) => (
  <tr className={c('striped-table-header')}>{children}</tr>
)

export const StripedTableRow = ({ children }) => (
  <tr className={c('striped-table-row')}>{children}</tr>
)

export const StripedTableCell = ({ text, type }) => (
  <td className={c('striped-table-cell')}>{convert(text, type)}</td>
)

export const InfoGroupContent = ({ title, text, type, format }) => (
  <div className={c('info-group')}>
    <div className={c('info-single-title')}>{title}</div>
    <div className={c('info-single-text')}>{convert(text, type, format)}</div>
  </div>
)

export const NoReport = ({ text }) => (
  <div className={c('no-report')}>
    <span className={c('material-symbols-outlined symbols')}>
      unknown_document
    </span>
    <div className={c('text')}>{text}</div>
  </div>
)

export const InfoContentSpace = ({ title, text, type, format, converter = convert }) => (
  <div className={c('info-content-space')}>
    <div className={c('info-content-title')}>
      <span>{title}</span>
    </div>
    <div className={c('info-content-text')}>
      <span>{converter(text, type, format)}</span>
    </div>
  </div>
)
export const CompanyInfoFooter = () => (
  <div className={c('full-report-detail-part-section')}>
    <CompanyInfo />
  </div>
)

export const FooterReport = () => (
  <div className={c('footer-report')}>
    <h4 className={c('footer-head')}>ATTENTION</h4>
    <div className={c('footer-text', 'margin-bottom')}>
      All data provided is intended solely for the customer who initially receives such data from
      the provider. The provider cannot guarantee or warrant the accuracy, correctness, or
      completeness of the data. The provider delivers all data to customers on an &quot;as is&quot;
      &quot;as available&quot; basis without any express or implied warranty, guaranty, or
      representation of any kind concerning the data itself, its merchantability, or its fitness for
      a particular purpose or function. Neither the provider nor any of their affiliates shall be
      liable for any damages of whatever kind may result from the customer&quot;s reliance on (or
      use of) the data provided, even if the provider, or any of their affiliates has been alerted
      to the possibility of such damages. The data information may include records that have been
      expunged, sealed, or otherwise have become inaccessible to the public since that date. By
      accessing any such data, the customer acknowledges and agrees that the customer has not relied
      on anything that may be inconsistent with this Legal Statement.
    </div>
    <div className={c('footer-text', 'bold')}>
      RentSpree does not guarantee the accuracy or truthfulness of the information that is the
      subject of the investigation, only that the information is accurately copied from public
      records. Information generated as a result of identify theft, including evidence of criminal
      activity, may be inaccurately associated with the consumer who is the subject of the report.
    </div>
  </div>
)

// ================= Criminal Records ================= //

export const CriminalItem = ({ children }) => <div className={c('criminal-item')}>{children}</div>

export const CriminalPersonDigitalImage = ({ img }) => (
  <div className={c('criminal-img')}>
    <img src={!isEmpty(img) ? img : defaultProfile} alt="Default profile" />
  </div>
)

export const CriminalPersonDetail = ({ children }) => (
  <div className={c('criminal-person-detail')}>{children}</div>
)

// ===================== Eviction Records ================= //
export const EvictionItem = ({ children }) => <div className={c('eviction-item')}>{children}</div>
