const featureFlags = {
  shouldHideScreeningWithoutProperty: 'hide_screening_tenants_without_assigned_property',
  shouldCallScreeningServiceFullReportEndpoint: 'call_screening_service_full_report_endpoint',
  shouldUseCriminalRecordNewJsonFormatting: 'tu_criminal_migration',
  enablePropertyRevampPhase1: 'init-849_property_l1_l2_revamp_phase1',
  applyCriminalMigrationV2: 'tu_criminal_migration',
  enableIcraaAttestationLogic: 'icraa_attestation_for_agents_and_landlords',
}

export default featureFlags
