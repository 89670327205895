import { createSelector } from 'reselect'
import get from 'lodash/get'
import { v4 as uuidv4 } from 'uuid'

import { initialState } from './reducer'

const selectListing = (listing = {}) => listing['create-listing'] || initialState
const selectProperty = ({ property = {} }) => property || get(initialState, 'property', {})

export const makeSelectProperty = () => createSelector(selectListing, selectProperty)

export const makeSelectPropertyDetails = () =>
  createSelector(makeSelectProperty(), ({ propertyDetails = {} } = {}) => propertyDetails)

export const makeSelectIsLoading = () =>
  createSelector(selectListing, ({ isLoading = false } = {}) => isLoading)

export const makeSelectLeasingInformation = () =>
  createSelector(
    makeSelectProperty(),
    ({ listing = {} }) =>
      listing?.leasingInformation || get(initialState, 'property.listing.leasingInformation', {}),
  )

export const makeSelectUtilitiesAndAmenities = () =>
  createSelector(
    makeSelectProperty(),
    ({ listing = {} }) =>
      listing?.utilitiesAndAmenities ||
      get(initialState, 'property.listing.utilitiesAndAmenities', {}),
  )

export const makeSelectShouldShowErrorPanel = () =>
  createSelector(selectListing, ({ shouldShowErrorPanel = false } = {}) => shouldShowErrorPanel)

export const makeSelectShouldShowSuccessfulPanel = () =>
  createSelector(
    selectListing,
    ({ shouldShowSuccessfulPanel = false } = {}) => shouldShowSuccessfulPanel,
  )

export const makeSelectShouldShowGeneralError = () =>
  createSelector(selectListing, ({ shouldShowGeneralError = false } = {}) => shouldShowGeneralError)

export const makeSelectIsUpdatingListing = () =>
  createSelector(selectListing, ({ isUpdatingListing = false } = {}) => isUpdatingListing)

export const makeSelectIsUploadingPhoto = () =>
  createSelector(selectListing, ({ isUploadingPhoto = false } = {}) => isUploadingPhoto)

export const makeSelectPhotos = () =>
  createSelector(makeSelectProperty(), ({ listing = {} }) =>
    (listing?.photos || get(initialState, 'property.listing.photos', [])).map(item =>
      typeof item === 'string'
        ? {
            id: uuidv4(),
            loadingProgress: false,
            progress: 100,
            preview: item,
          }
        : item,
    ),
  )

export const selectPhoto = makeSelectPhotos()

export const makeSelectSlug = () =>
  createSelector(
    makeSelectProperty(),
    ({ listing = {} }) => listing?.slug || get(initialState, 'property.listing.slug'),
  )

export const makeSelectCoordinate = () =>
  createSelector(makeSelectProperty(), ({ listing = {} }) => ({
    lat: listing?.lat || get(initialState, 'property.listing.lat'),
    lng: listing?.lng || get(initialState, 'property.listing.lng'),
  }))

export const makeSelectError = () =>
  createSelector(selectListing, ({ errorMessage = '' } = {}) => errorMessage)

export const makeSelectVideo = () =>
  createSelector(makeSelectProperty(), ({ listing = {} }) => listing?.video)

export const makeSelectFullNameAddress = () =>
  createSelector(selectListing, ({ fullNameAddress = '' } = {}) => fullNameAddress)

export const makeSelectListingApplicationIsDisabled = () =>
  createSelector(
    selectListing,
    ({ disabledFeatures } = {}) => !!disabledFeatures?.listingApplication,
  )

export const makeSelectHasPublished = () =>
  createSelector(makeSelectProperty(), ({ listing = {} }) => !!listing?.publishedAt)

export const makeSelectShouldShowPublishSuccess = () =>
  createSelector(selectListing, ({ shouldShowPublishSuccess = false }) => shouldShowPublishSuccess)

export const selectFeaturesEnabled = createSelector(selectProperty, ({ property }) => {
  const listingApplicationIsEnabled = !property?.disabledFeatures?.listingApplication
  const propertyHasScreeningRequest = !!property?.listing?.screeningRequest

  return {
    listingApplication: propertyHasScreeningRequest && listingApplicationIsEnabled,
  }
})

export const makeSelectGenerateDescriptionResponse = () =>
  createSelector(
    selectListing,
    ({ generateDescriptionResponse = {} } = {}) => generateDescriptionResponse,
  )

export const makeSelectIsRatingGeneratedDescription = () =>
  createSelector(
    selectListing,
    ({ isRatingGeneratedDescription = false } = {}) => isRatingGeneratedDescription,
  )

export const makeSelectIsRequiredIdv = () =>
  createSelector(selectListing, ({ showIdvModal = false } = {}) => showIdvModal)

export const makeSelectIsIdvVerificationFailure = () =>
  createSelector(selectListing, ({ showIdvFailure = false } = {}) => showIdvFailure)

export const makeSelectListingApprovalStatus = () =>
  createSelector(makeSelectProperty(), ({ listing = {} }) => listing.approvalStatus)

export const selectSubdomain = ({ subdomain }) => get(subdomain, 'subdomain.sub', null)
