import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  actionChannel,
  take,
} from 'redux-saga/effects'
import { buildPath, stringify } from '@rentspree/path'
import {
  apiInstance,
  apiInstanceWithErrorHandler,
  injectErrorWithSentryApiInstance,
} from 'utils/api-interceptor'
import { push, replace } from 'connected-react-router'
import Scroll from 'react-scroll'
import * as PATH from 'constants/route'
import { generateAddress } from '@rentspree/helper'
import cleanDeep from 'clean-deep'
import omit from 'lodash/omit'
import tracker from 'tracker'
import { LISTING_MANAGEMENT_EVENT, LISTING_SYNDICATION_EVENT } from 'tracker/const'
import * as Sentry from '@sentry/browser'
import axios from 'axios'
import { selectIDVStatus } from 'containers/verification/selectors'
import { IDV_STATUS } from 'containers/verification/constants'
import { createRequestToken } from '@castleio/castle-js'
import {
  GENERATE_DESCRIPTION,
  GET_PROPERTY as CALL_GET_PROPERTY,
  PUBLISH_LISTING,
  RATE_GENERATED_DESCRIPTION,
  REMOVE_PHOTO,
  START_UPLOAD_PHOTO,
  UPDATE_LISTING as CALL_UPDATE_LISTING,
  UPDATE_QUEUE_PHOTO,
  UPLOAD_PHOTO as CALL_UPLOAD_PHOTO,
  UPLOAD_PHOTO_FAILED,
  UPLOAD_PHOTO_SUCCESS,
  UPLOAD_PHOTO_TIMEOUT,
  USER_INPUT_PHOTO,
  UPLOAD_ALL_PHOTOS,
} from 'containers/create-listing/const'
import { compressImage } from 'utils/image-compression'
import { MAX_PHOTOS_ALLOWED } from '../../components/organisms/property-information-form/consts'

import {
  generateDescriptionFailed,
  generateDescriptionSuccess,
  getPropertyInformationFailed,
  getPropertyInformationSuccess,
  publishListingFailed,
  publishListingStart,
  publishListingSuccess,
  rateGeneratedDescriptionFailed,
  rateGeneratedDescriptionSuccess,
  resetGeneratedDescription,
  setErrorMessage,
  startGenerateDescription,
  startGetProperty,
  startRateGeneratedDescription,
  startUpdateListing,
  startUploadPhoto,
  toggleIdvFailure,
  toggleIdvModal,
  updateListingFailed,
  updateListingSuccess,
  updateQueuePhoto,
  uploadPhotoFailed,
  uploadPhotoSuccess,
  autoCreateListingSyndicationSuccess,
  autoCreatedListingSyndicationFailed,
} from './actions'

import { updateAgentBrokerageName } from '../agent-profile/actions'
import {
  GENERATE_DESCRIPTION_API,
  PUBLISH_LISTING_API,
  RATE_GENERATED_DESCRIPTION_API,
  UPDATE_LISTING,
  UPLOAD_LISTING_PHOTO_ENDPOINT,
} from './routes'
import { selectPhoto, selectSubdomain } from './selectors'
const listingFields = [
  'propertyType',
  'bedrooms',
  'bathrooms',
  'squareFeet',
  'yearBuilt',
  'description',
  'photos',
]
const leasingFields = [
  'dateAvailable',
  'term',
  'termAmountOfMonths',
  'rentAmount',
  'securityDeposit',
  'isInsuranceRequired',
  'isSmokingAllowed',
  'isPetAllowed',
  'allowedPets',
]
const utilitiesFields = [
  'hasDedicatedParkingSpot',
  'paidAmenities',
  'hvacAmenities',
  'unitAmenities',
  'sharedAmenities',
]

const hasErrorOnStep = (stepFields, errorFields) =>
  errorFields.find(field => stepFields.includes(field))

export const generateRedirectPath = ({ propertyId, error = {} }) => {
  const errorFields = error?.details?.errorFields || []
  const parsedErrorFields = errorFields.map(({ field }) => [...field].pop())

  if (hasErrorOnStep(listingFields, parsedErrorFields)) {
    return buildPath(PATH.CREATE_LISTING_INFORMATION_STEP, {
      propertyId,
    })
  }

  if (hasErrorOnStep(leasingFields, parsedErrorFields)) {
    return buildPath(PATH.CREATE_LEASING_INFORMATION_STEP, {
      propertyId,
    })
  }

  if (hasErrorOnStep(utilitiesFields, parsedErrorFields)) {
    return buildPath(PATH.CREATE_UTILITIES_AMENITIES_STEP, {
      propertyId,
    })
  }

  return ''
}

export const getProperty = (routeParameters, queryParameters) =>
  apiInstance.get(buildPath(PATH.PROPERTY_DETAIL_V2, routeParameters, queryParameters))

export const updateListing = (routeParameters, payload) =>
  apiInstance.put(buildPath(UPDATE_LISTING, routeParameters), payload)

/**
 * Makes a request to publish a listing.
 * @param {object} params
 * @param {string} params.propertyId
 * @returns {Promise<{status:LISTING_APPROVAL_STATUS, success:true}>}

 */
export const publishListingApi = async ({ propertyId }) => {
  const requestToken = await createRequestToken()
  return apiInstance.post(
    buildPath(PUBLISH_LISTING_API, { propertyId }),
    {},
    {
      headers: {
        'castle-request-token': requestToken,
      },
    },
  )
}

export const autoCreateListingSyndicationApi = ({ propertyId }) =>
  apiInstance.post(buildPath(PATH.CREATE_LISTING_SYNDICATION_API, { propertyId }))

export const uploadPhotoAPI = (data, { source, id, progressFn }) => {
  injectErrorWithSentryApiInstance(apiInstanceWithErrorHandler)
  return apiInstanceWithErrorHandler.post(UPLOAD_LISTING_PHOTO_ENDPOINT, data, {
    timeout: UPLOAD_PHOTO_TIMEOUT,
    cancelToken: source.token,
    transitional: {
      clarifyTimeoutError: true,
    },
    onUploadProgress: progressEvent => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      progressFn(id, percentCompleted)
    },
  })
}

export const generateDescriptionApi = async ({ slug }) => {
  const generatedDescription = await apiInstance.post(buildPath(GENERATE_DESCRIPTION_API, { slug }))
  return generatedDescription
}

export const rateGeneratedDescriptionApi = async ({ slug, generatedId, rating }) => {
  await apiInstance.post(buildPath(RATE_GENERATED_DESCRIPTION_API, { slug, generatedId }), {
    rating,
  })
}

export const updateBrokerageName = async agentBrokerageName => {
  await apiInstance.put(PATH.AGENT_PROFILE_API, {
    contactInformation: { brokerage: { name: agentBrokerageName } },
  })
}

export const autoCreateListingScreeningRequest = async ({ propertyId, subdomain }) => {
  await apiInstance.post(
    buildPath(PATH.AUTO_CREATE_LISTING_SCREENING_REQUEST_API, { propertyId }),
    {
      subdomain,
    },
  )
}

export function* getPropertySaga({ payload }) {
  const { propertyId } = payload
  yield put(startGetProperty())
  try {
    const property = yield call(
      getProperty,
      { propertyId },
      { informationType: 'draftedInformation' },
    )
    const fullNameAddress = generateAddress(property)
    yield put(getPropertyInformationSuccess({ ...property, fullNameAddress }))
  } catch {
    yield put(getPropertyInformationFailed())
    yield call(Scroll.animateScroll.scrollToTop, { smooth: true })
  }
}

export function* updateListingSaga({ payload }) {
  const { propertyId, data, continuePath, isDirectAccess, queryString, agentBrokerageName } =
    payload

  const builtPath = buildPath(continuePath, { propertyId })
  const cleanedData = yield call(cleanDeep, data, { emptyStrings: true })
  yield call(omit, 'propertyDetails.fullNameAddress', cleanedData)
  yield put(startUpdateListing())

  try {
    if (agentBrokerageName) {
      yield call(updateBrokerageName, agentBrokerageName)
    }

    const listing = yield call(
      updateListing,
      { propertyId },
      {
        ...cleanedData,
        listing: { ...cleanedData.listing, video: data?.listing?.video },
      },
    )

    yield put(resetGeneratedDescription())
    yield put(updateListingSuccess(listing))
    yield put(push({ pathname: builtPath, search: stringify(queryString) }, { isDirectAccess }))
    if (agentBrokerageName) {
      yield put(updateAgentBrokerageName(agentBrokerageName))
    }
  } catch (e) {
    console.error(e)
    yield put(updateListingFailed())
    yield put(setErrorMessage('Something went wrong while saving. Please try again'))
  }
  yield call(Scroll.animateScroll.scrollToTop, { smooth: true })
}

export function* publishListingSaga({ payload }) {
  const { propertyId, skipIdvCheck, isAutoCreateTenantScreeningEnabled } = payload

  if (!skipIdvCheck) {
    const idvStatus = yield select(selectIDVStatus)
    // NOTE: TECH-3804 For the following use case will prompt the user to do IDV instead of EmailVerification */}
    if ([IDV_STATUS.VERIFICATION, IDV_STATUS.EMAIL_VERIFICATION].includes(idvStatus)) {
      yield put(toggleIdvModal(true))
      return
    }

    if (idvStatus === IDV_STATUS.VERIFICATION_FAIL) {
      yield put(toggleIdvFailure(true))
      return
    }
  }

  yield put(publishListingStart())

  try {
    yield call(publishListingApi, {
      propertyId,
    })
    try {
      // NOTE: listing approval status is REQUIRED from auto create syndication API to display on the UI
      const response = yield call(autoCreateListingSyndicationApi, { propertyId })
      yield put(autoCreateListingSyndicationSuccess(response?.result))
      yield call(
        [tracker, 'trackEvent'],
        LISTING_SYNDICATION_EVENT.EVENT_NAME.AUTO_SYNDICATION_SUCCESS,
        { property_id: propertyId },
      )
    } catch (error) {
      // NOTE: For handling modal when syndicating with an invalid address
      // TODO: to be improved in https://rentspree.atlassian.net/browse/TECH-24053
      yield put(autoCreatedListingSyndicationFailed())
      const property = yield call(
        getProperty,
        { propertyId },
        { informationType: 'draftedInformation' },
      )
      const fullNameAddress = generateAddress(property)
      yield put(getPropertyInformationSuccess({ ...property, fullNameAddress }))
      // ENDTODO:
      yield call(
        [tracker, 'trackEvent'],
        LISTING_SYNDICATION_EVENT.EVENT_NAME.AUTO_SYNDICATION_FAIL,
        { property_id: propertyId, fail_code: error?.data?.code || 'UNEXPECTED_ERROR' },
      )
    }
    if (isAutoCreateTenantScreeningEnabled) {
      try {
        const subdomain = yield select(selectSubdomain)
        yield call(autoCreateListingScreeningRequest, {
          propertyId,
          subdomain: subdomain ?? 'rentspree',
        })
        yield call(
          [tracker, 'trackEvent'],
          LISTING_MANAGEMENT_EVENT.EVENT_NAME.AUTO_CREATE_LISTING_TENANT_SCREENING_SUCCESS,
          { property_id: propertyId, subdomain },
        )
      } catch (error) {
        yield call(
          [tracker, 'trackEvent'],
          LISTING_MANAGEMENT_EVENT.EVENT_NAME.AUTO_CREATE_LISTING_TENANT_SCREENING_FAIL,
          { property_id: propertyId, fail_code: error?.data?.code || 'UNEXPECTED_ERROR' },
        )
      }
    }
    yield put(publishListingSuccess())
  } catch (error) {
    yield put(publishListingFailed({ error: error.data }))

    const redirectPath = generateRedirectPath({ propertyId, error: error.data })
    yield put(
      setErrorMessage('Sorry, something went wrong. Please refresh the page and try again.'),
    )
    yield put(replace({ pathname: redirectPath }, { isDirectAccess: false }))
    yield call(Scroll.animateScroll.scrollToTop, { smooth: true })
  }
}

export const generateFormData = ({ propertyId, file = {}, fileDetail = {} }) => {
  const formData = new FormData()
  const concatName = `${fileDetail.id}-${file.name}`
  const fileRenamed = new File([file], concatName, {
    type: file.type,
  })
  formData.append('propertyId', propertyId)
  formData.append('file', fileRenamed)
  return formData
}

export function* uploadPhotoFailedSaga({ payload }) {
  const { propertyId, fileDetail } = payload
  yield call(
    Sentry.captureMessage,
    `Unable to upload photo ${fileDetail.imageWidth} x ${fileDetail.imageHeight} for ${propertyId}`,
  )
}

export function* userInputPhotoSaga({ payload }) {
  const { file, fileDetail } = payload
  const { CancelToken } = axios
  const source = CancelToken.source()
  yield put(
    startUploadPhoto({
      ...fileDetail,
      preview: file.url,
      cancelToken: source.cancel,
    }),
  )
}
export function* uploadPhotoSaga({ payload }) {
  const { propertyId, file, fileDetail, progressFn, isListingImageCompressionEnabled } = payload

  const { CancelToken } = axios
  const source = CancelToken.source()

  try {
    let compressedFile = file
    if (isListingImageCompressionEnabled) {
      compressedFile = yield call(compressImage, file, fileDetail, progressFn)
    }

    const formData = yield call(generateFormData, {
      propertyId,
      file: compressedFile,
      fileDetail,
    })
    const { url } = yield call(uploadPhotoAPI, formData, {
      source,
      id: fileDetail.id,
      progressFn,
    })
    yield put(uploadPhotoSuccess({ photo: fileDetail, url }))
  } catch (error) {
    if (!axios.isCancel(error)) {
      yield put(uploadPhotoFailed({ propertyId, fileDetail }))
    }
  }
}

export function* removePhotoSaga() {
  const { CancelToken } = axios
  const source = CancelToken.source()

  const photos = yield select(selectPhoto)

  const uploadedPhoto = photos.filter(photo => !photo.queue).length

  if (uploadedPhoto < MAX_PHOTOS_ALLOWED && photos.length >= MAX_PHOTOS_ALLOWED) {
    const photo = photos.find(item => item.queue === true)

    const { propertyId, file, progressFn } = photo
    const fileDetail = {
      id: photo.id,
      imageHeight: photo.imageHeight,
      imageWidth: photo.imageWidth,
      loadingProgress: photo.loadingProgress,
      preview: photo.preview,
      progress: photo.progress,
    }

    try {
      yield put(
        updateQueuePhoto({
          ...fileDetail,
          cancelToken: source.cancel,
        }),
      )

      const formData = yield call(generateFormData, {
        propertyId,
        file,
        fileDetail,
      })
      const { url } = yield call(uploadPhotoAPI, formData, {
        source,
        id: fileDetail.id,
        progressFn,
      })
      yield put(uploadPhotoSuccess({ photo: fileDetail, url }))
    } catch (error) {
      yield put(uploadPhotoFailed({ propertyId, fileDetail }))
    }
  }
}

export function* logPhotoStateSaga({ type, payload = {} }) {
  const reducerState = yield select(selectPhoto)
  yield call(Sentry.captureEvent, {
    message: `${type} is being called`,
    extra: { payload, reducerState },
    level: 'info',
  })
}

export function* generateDescriptionSaga({ payload }) {
  const { slug } = payload

  try {
    yield put(startGenerateDescription())
    const { generatedId, description, count, hasLimitExceeded } = yield call(
      generateDescriptionApi,
      {
        slug,
      },
    )
    const value = { generatedId, description, hasRated: false }
    yield put(generateDescriptionSuccess({ value, count, hasLimitExceeded }))
  } catch (error) {
    yield put(generateDescriptionFailed())
  }
}

export function* rateGeneratedDescriptionSaga({ payload }) {
  yield put(startRateGeneratedDescription())

  try {
    const { slug, generatedId, rating, currentPage } = payload
    yield call(rateGeneratedDescriptionApi, { slug, generatedId, rating })
    yield put(rateGeneratedDescriptionSuccess({ currentPage, rating }))
  } catch (error) {
    yield put(rateGeneratedDescriptionFailed())
  }
}

export function* watchGetProperty() {
  yield takeLatest(CALL_GET_PROPERTY, getPropertySaga)
}

export function* watchUpdateListing() {
  yield takeLatest(CALL_UPDATE_LISTING, updateListingSaga)
}

export function* watchUploadPhotoFailed() {
  yield takeLatest(UPLOAD_PHOTO_FAILED, uploadPhotoFailedSaga)
}
export function* watchPublishListing() {
  yield takeLatest(PUBLISH_LISTING, publishListingSaga)
}

export function* watchUserInputPhoto() {
  yield takeEvery(USER_INPUT_PHOTO, userInputPhotoSaga)
}

export function* watchUploadPhotoOneByOne() {
  const requestChannel = yield actionChannel(CALL_UPLOAD_PHOTO)
  while (true) {
    const action = yield take(requestChannel)
    yield call(uploadPhotoSaga, action)
  }
}

// NOTE: Controlled by feature flag LISTING_INFORMATION_QUEUE_UPLOAD = OFF
export function* watchUploadAllPhotos() {
  yield takeEvery(UPLOAD_ALL_PHOTOS, uploadPhotoSaga)
}
// ENDNOTE

export function* watchRemovePhoto() {
  yield takeEvery(REMOVE_PHOTO, removePhotoSaga)
}

export function* watchPhotoUpdate() {
  yield takeEvery(
    [
      REMOVE_PHOTO,
      UPLOAD_PHOTO_FAILED,
      UPDATE_QUEUE_PHOTO,
      UPLOAD_PHOTO_SUCCESS,
      START_UPLOAD_PHOTO,
    ],
    logPhotoStateSaga,
  )
}

export function* watchGenerateDescription() {
  yield takeEvery(GENERATE_DESCRIPTION, generateDescriptionSaga)
}

export function* watchRateGeneratedDescription() {
  yield takeEvery(RATE_GENERATED_DESCRIPTION, rateGeneratedDescriptionSaga)
}

export default function* rootSaga() {
  yield all([
    watchGetProperty(),
    watchUpdateListing(),
    watchUploadPhotoOneByOne(),
    watchUploadAllPhotos(),
    watchPublishListing(),
    watchUploadPhotoFailed(),
    watchRemovePhoto(),
    watchPhotoUpdate(),
    watchGenerateDescription(),
    watchRateGeneratedDescription(),
    watchUserInputPhoto(),
  ])
}
