import {
    SUBMIT_RENTAL_CALL,
    SUBMIT_RENTAL_FAILED,
    SUBMIT_RENTAL_REQUEST,
    SUBMIT_RENTAL_SUCCESS,
} from "./constants"

export const submitRentalCall = (rentalId, incomeVerificationRequested) => ({
    type: SUBMIT_RENTAL_CALL,
    rentalId, 
    incomeVerificationRequested
})

export const postRentalSubmissionRequest = () => ({
    type: SUBMIT_RENTAL_REQUEST,
})

export const postRentalSubmissionSuccess = () => ({
    type: SUBMIT_RENTAL_SUCCESS,
})

export const postRentalSubmissionFailed = payload => ({
    type: SUBMIT_RENTAL_FAILED,
    payload,
})