import get from 'lodash/get'
import {
  PARTICIPANT_TYPE,
  SCREENING_OPTION_TYPES,
  SHARED_REPORT_TYPES,
} from 'containers/reports/constants'
import { isEmpty } from 'lodash'
import {
  REPORT_TAB_ID_APPLICATION,
  REPORT_TAB_ID_CREDIT,
  REPORT_TAB_ID_CRIMINAL,
  REPORT_TAB_ID_EVICTION,
  REPORT_TAB_ID_INCOME_VERIFICATION,
} from './const'

export const isReportAvailable = (rentalSubmission, submissionParticipant, report) => {
  const participantType = get(submissionParticipant, 'type')
  const shareOption = get(submissionParticipant, 'shareOption', {})
  const screeningOption = get(rentalSubmission, 'screeningOption', {})
  const isSharedScreeningReport = get(shareOption, SHARED_REPORT_TYPES.SCREENING_REPORT, false)
  const isSharedIncomeVerificationReport = get(
    shareOption,
    SHARED_REPORT_TYPES.INCOME_VERIFICATION_REPORT,
    false,
  )
  if (participantType === PARTICIPANT_TYPE.PARTICIPANT) {
    switch (report) {
      case SCREENING_OPTION_TYPES.CREDIT_REPORT:
      case SCREENING_OPTION_TYPES.CRIMINAL:
      case SCREENING_OPTION_TYPES.EVICTION:
        // NOTE: Need to map with rentalScreeningOption because screeningReport is the group of credit_report, criminal and eviction
        return isSharedScreeningReport ? screeningOption[report] : false
      case SCREENING_OPTION_TYPES.INCOME:
        return isSharedIncomeVerificationReport ? screeningOption[report] : false
      default:
        return shareOption[report]
    }
  }
  return screeningOption[report]
}

export const getReportNameByTabId = tabId => {
  const reportTab = {
    [REPORT_TAB_ID_APPLICATION]: 'application',
    [REPORT_TAB_ID_CREDIT]: 'credit_report',
    [REPORT_TAB_ID_CRIMINAL]: 'criminal',
    [REPORT_TAB_ID_EVICTION]: 'eviction',
    [REPORT_TAB_ID_INCOME_VERIFICATION]: 'income',
  }
  return get(reportTab, tabId, '')
}

const parseScreeningReportSummariesFromStatuses = ({
  isAvailable,
  isRequestedByLandlord,
  needsConditionalAcceptance,
}) => {
  if (!isRequestedByLandlord) {
    return 'notRequested'
  }
  if (needsConditionalAcceptance) {
    return 'pendingConditionalAcceptance'
  }
  return isAvailable ? 'available' : 'pendingAvailability'
}

export const getScreeningReportStatuses = ({
  screeningReports,
  complianceRules = {},
  requestedReports = {},
}) => {
  const screeningReportStatuses = {}
  const reportTypes = ['credit_report', 'criminal', 'eviction']

  reportTypes.forEach(reportType => {
    const allCapsReportType = reportType.toUpperCase()
    let isAvailable = false

    if (reportType === 'credit_report') {
      isAvailable = screeningReports.CreditReport?.length > 0
    } else if (reportType === 'criminal') {
      isAvailable = screeningReports.CriminalRecords?.length > 0
    } else if (reportType === 'eviction') {
      isAvailable = screeningReports.EvictionRecords?.length > 0
    }

    screeningReportStatuses[reportType] = {
      isAvailable,
      isRequestedByLandlord: get(requestedReports, reportType, false),
      compliance: {
        isProhibited: get(complianceRules, 'prohibitedReports', []).includes(allCapsReportType),
        needsConditionalAcceptance:
          get(complianceRules, 'conditionalAcceptanceHiddenReports', []).includes(
            allCapsReportType,
          ) && !isAvailable,
      },
      summaryStatus: '',
    }

    screeningReportStatuses[reportType].summaryStatus = parseScreeningReportSummariesFromStatuses({
      isAvailable,
      isRequestedByLandlord: screeningReportStatuses[reportType].isRequestedByLandlord,
      needsConditionalAcceptance:
        screeningReportStatuses[reportType].compliance.needsConditionalAcceptance,
    })
  })

  return screeningReportStatuses
}

export const getComplianceStatusFromRules = ({ tabName, complianceRules, screeningReports }) => {
  const prohibitedReports = get(complianceRules, 'prohibitedReports', [])
  const conditionalAcceptanceHiddenReports = get(
    complianceRules,
    'conditionalAcceptanceHiddenReports',
    [],
  )
  const recordData = {
    CRIMINAL: get(screeningReports, 'CriminalRecords[0].reportData', {}),
    EVICTION: get(screeningReports, 'EvictionRecords[0].reportData', {}),
  }
  const recordsHidden = isEmpty(recordData[tabName], true)

  return {
    isProhibited: prohibitedReports.includes(tabName),
    needsConditionalAcceptance:
      conditionalAcceptanceHiddenReports.includes(tabName) && recordsHidden,
  }
}

export const getReportPending = (screeningReports, tabName) => {
  switch (tabName) {
    case 'CRIMINAL':
      return isEmpty(screeningReports.CriminalRecords)
    case 'EVICTION':
      return isEmpty(screeningReports.EvictionRecords)
    default:
      return false
  }
}
