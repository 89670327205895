import React from 'react'
import get from 'lodash/get'
import { COLOR } from 'styles/settings'
import { convert } from 'legacy/components/helper/convert'
import {
  FlexInfoContainer,
  ScreeningInfoContentWrapper,
} from 'components/reports/screening-reports/share/responsive-doc-style'
import { HalfWidthInfoField } from 'components/reports/screening-reports/share/responsive-doc-components'
import styled from 'styled-components'

const CaseResultContainer = styled.div`
  border: solid 2px ${COLOR.lightTextGrey};
  border-bottom: solid 1px ${COLOR.lightTextGrey};
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
`
const CaseHeaderWrapper = styled.div`
  font-size: 17px;
  margin-left: 25px;
  margin-top: 10px;
`
const AnnotationWrapper = styled.div`
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 40px;
`
export const CaseAnnotationLine = styled.span`
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  color: ${COLOR.black};
`
const CaseDetailsWrapper = styled.div`
  width: 100%;
`

export const EvictionCase = ({ fullName, eviction, footnote }) => (
  <div>
    <EvictionCaseSection fullName={fullName} eviction={eviction} footnote={footnote} />
    <AnnotationWrapper>
      <CaseAnnotationLine>*{footnote}</CaseAnnotationLine>
    </AnnotationWrapper>
  </div>
)

const CaseHeader = ({ eviction }) => (
  <CaseHeaderWrapper>
    <b>Case #: </b> {get(eviction, 'caseNumber')}
  </CaseHeaderWrapper>
)

const EvictionCaseSection = ({ fullName, eviction }) => (
  <CaseDetailsWrapper>
    <CaseHeader eviction={eviction} />
    <CaseResultContainer>
      <EvictionCaseDetails fullName={fullName} eviction={eviction} />
    </CaseResultContainer>
  </CaseDetailsWrapper>
)

const EvictionCaseDetails = ({ fullName, eviction }) => {
  const subject = get(eviction, 'subject')

  return (
    <FlexInfoContainer>
      <ScreeningInfoContentWrapper>
        <HalfWidthInfoField label="Defendant" value={fullName} />
        <HalfWidthInfoField label="Plaintiff" value={get(eviction, 'plaintiff')} />
        <HalfWidthInfoField
          label="File Date"
          value={convert(get(eviction, 'fileDate'), 'date', 'YYYY-MM-DD')}
        />
        <HalfWidthInfoField label="Address" value={get(subject, 'address')} />
        <HalfWidthInfoField
          label="City & State"
          value={`${get(subject, 'city') || ' - '}, ${get(subject, 'state') || ' - '}`}
        />
        <HalfWidthInfoField label="Zip" value={get(subject, 'zip')} />
        <HalfWidthInfoField label="Court" value={get(eviction, 'court')} />
        <HalfWidthInfoField label="Court State" value={get(eviction, 'courtState')} />
        <HalfWidthInfoField label="Judgment" value={get(eviction, 'judgment.judgment')} />
        <HalfWidthInfoField
          label="Court Reported Amount*"
          value={convert(get(eviction, 'judgment.courtReportedAmount'), '$', '0,0.00')}
        />
        <HalfWidthInfoField
          label="Judgment Date"
          value={convert(get(eviction, 'judgment.date'), 'date', 'YYYY-MM-DD')}
        />
        <HalfWidthInfoField
          label="Judgment Release Date"
          value={convert(get(eviction, 'judgment.releaseDate'), 'date', 'YYYY-MM-DD')}
        />
      </ScreeningInfoContentWrapper>
    </FlexInfoContainer>
  )
}
