import React from 'react'
import { USER_API_URL } from 'env'
import generateApiCall from 'utils/generate-api-call'
export const GET_REPORTS_AVAILABLE_CALL = 'GET_REPORTS_AVAILABLE_CALL'
export const GET_REPORTS_AVAILABLE_REQUEST = 'GET_REPORTS_AVAILABLE_REQUEST'
export const GET_REPORTS_AVAILABLE_SUCCESS = 'GET_REPORTS_AVAILABLE_SUCCESS'
export const GET_REPORTS_AVAILABLE_FAILURE = 'GET_REPORTS_AVAILABLE_FAILURE'

export const SHARE_REPORT_CALL = 'SHARE_REPORT_CALL'
export const SHARE_REPORT_REQUEST = 'SHARE_REPORT_REQUEST'
export const SHARE_REPORT_SUCCESS = 'SHARE_REPORT_SUCCESS'
export const SHARE_REPORT_FAILURE = 'SHARE_REPORT_FAILURE'

export const SHARE_REPORT_API = 'api/v2/rental-submissions/:rentalId/shares'
export const GET_SCREENING_AVAILABLE_API = 'api/v2/rental-submissions/:rentalId/shares/available'
export const UPDATE_USER_PREFERENCE_API = `${USER_API_URL}/api/v2/user/me/preference`

export const GET_DEFAULT_SHARE_OPTION_CALL = 'GET_DEFAULT_SHARE_OPTION_CALL'
export const GET_USER_PREFERENCE_REQUEST = 'GET_USER_PREFERENCE_REQUEST_SHARE_REPORT'
export const GET_USER_PREFERENCE_SUCCESS = 'GET_USER_PREFERENCE_SUCCESS_SHARE_REPORT'
export const GET_USER_PREFERENCE_FAILURE = 'GET_USER_PREFERENCE_FAILURE_SHARE_REPORT'

export const TOGGLE_SHARE_REPORT_MODAL_CALL = 'TOGGLE_SHARE_REPORT_MODAL_CALL'
export const TOGGLE_SHARE_REPORT_MODAL = 'TOGGLE_SHARE_REPORT_MODAL'

export const SET_FORM_VALUES = 'SET_FORM_VALUES'
export const SUBMIT_SHARE_REPORT_FORM = 'SUBMIT_SHARE_REPORT_FORM'
export const UPDATE_DEFAULT_SHARE_OPTION = 'UPDATE_DEFAULT_SHARE_OPTION'

export const getReportsAvailableApiCall = generateApiCall([
  GET_REPORTS_AVAILABLE_REQUEST,
  GET_REPORTS_AVAILABLE_SUCCESS,
  GET_REPORTS_AVAILABLE_FAILURE,
])

export const shareReportApiCall = generateApiCall([
  SHARE_REPORT_REQUEST,
  SHARE_REPORT_SUCCESS,
  SHARE_REPORT_FAILURE,
])

export const getUserPreferenceApiCall = generateApiCall([
  GET_USER_PREFERENCE_REQUEST,
  GET_USER_PREFERENCE_SUCCESS,
  GET_USER_PREFERENCE_FAILURE,
])

export const CLOSE_ERROR = 'CLOSE_ERROR'

export const REPORT_UNAVAILABLE_MESSAGE = {
  APPLICATION: 'A rental application was not requested.',
  // TODO: remove this when we support share LRA application
  // TODO: wording
  LRA_APPLICATION: 'LRA application cannot be shared yet.',
  SCREENING_REPORT: {
    NOT_REQUEST: 'The screening reports are not available.',
    EXPIRED: 'These screening reports expired',
    IDMA_EXPIRED: 'The screening reports are not available.',
    ALREADY_SHARED: 'The screening reports can only be shared once on RentSpree.',
  },
  REFERENCE_CHECKS: 'This document is not available.',
  OTHER_DOCS: 'This document was not requested and cannot be shared.',
}

export const REPORT_UNAVAILABLE_MESSAGE_V2 = {
  APPLICATION: 'A rental application was not requested.',
  EXPIRED: 'Expired',
  SCREENING_REPORT: {
    IDMA_EXPIRED: 'IDMA expired',
    ALREADY_SHARED: 'Already shared once',
  },
}

export const MODAL_PAGES = {
  RECIPIENT: 'RECIPIENT',
  REPORT_SELECTION: 'REPORT_SELECTION',
}

export const MODAL_TITLES = {
  SHARE_APPLICATION_PACKAGE: 'Share application package',
  EDIT_REPORT_SELECTION: 'Edit report selection',
}

export const MESSAGE_CHARACTER_LIMIT = 500

export const ALERT_ERROR_MESSAGE_TITLE = 'We’re experiencing an error.'

export const ALERT_ERROR_MESSAGE_SUBTITLE = () => (
  <>
    Please try refreshing the page or return in a few minutes.
    <br />
    If the problem persists, please{' '}
    <a href="https://support.rentspree.com/en/contact-us">contact support</a>.
  </>
)
