import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@rentspree/component-2023.components.atoms.checkbox'
import TextLink from 'components/atoms/text-link'
import { COLOR } from 'components/constants'
import { B12 } from 'components/typography'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import { calculateTermsAndConditionsTypography } from 'components/reports/helpers'

const TermAndConditionLabel = ({ infoText, link, termsText, errorText, isError, dataTestKey }) => (
  <div data-testid={`${dataTestKey}-term-and-condition-wrapper`}>
    <Typography variant={calculateTermsAndConditionsTypography()}>
      {infoText}{' '}
      <TextLink
        data-testid={`${dataTestKey}-term-and-condition-text-link`}
        onClick={() => window.open(link, '_blank')}
        hovercolor={COLOR.thinBlue}
        underline
      >
        {termsText}
      </TextLink>
    </Typography>
    {isError && (
      <B12
        margin="0"
        fontFamily="Poppins"
        color={COLOR.negativeRed}
        data-testid={`${dataTestKey}-term-and-condition-error-label`}
      >
        {errorText}
      </B12>
    )}
  </div>
)

export const TermsAndConditionsCheckbox = ({ onChange, disabled, checked, hasError }) => (
  <div style={{ marginRight: '16px' }}>
    <div data-testid="apply_reviewReport_termsConditions_container">
      <FormControlLabel
        sx={{ margin: '0' }}
        labelPlacement="start"
        control={
          <Checkbox
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            color={hasError ? 'error' : 'default'}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 26 } }}
          />
        }
        label={
          <TermAndConditionLabel
            data-testid="apply_reviewReport_termsConditions_label"
            infoText="I've reviewed my reports and accept the "
            link="https://www.rentspree.com/terms-of-use"
            termsText="Terms of Service"
            errorText="Agree to the terms above to submit your application."
            isError={hasError}
          />
        }
      />
    </div>
  </div>
)
