import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { parsePhoneNumber } from 'libphonenumber-js'
import { SCREENING_REPORT_TYPES } from 'containers/reports/constants'
import cleanDeep from 'clean-deep'
import useMobile from 'hooks/use-mobile'

// NOTE: This helper requires phone number to have country code in order to format correctly
// e.g., Thailand 66804549476, US 16318552000
export const formatNationalPhoneNumber = phoneNumber => {
  try {
    return parsePhoneNumber(`+${phoneNumber}`).formatNational()
  } catch (e) {
    return '-'
  }
}

const { CREDIT_REPORT } = SCREENING_REPORT_TYPES

export const getScreeningReportStatuses = (
  screeningOption,
  requestedScreeningOption,
  inLieuOfCredit,
) => {
  const screeningReportStatuses = {}

  Object.values(SCREENING_REPORT_TYPES).forEach(key => {
    if (
      !isEmpty(requestedScreeningOption) &&
      requestedScreeningOption[key] === true &&
      screeningOption[key] === false
    ) {
      screeningReportStatuses[key] = 'Records restricted by jurisdiction'
    } else if (key === CREDIT_REPORT && screeningOption[CREDIT_REPORT] && inLieuOfCredit) {
      // TODO: Refactor the utils; too many cognitive complexity
      screeningReportStatuses[CREDIT_REPORT] = 'Not Available'
    } else {
      screeningReportStatuses[key] = screeningOption[key] ? 'Attached' : 'Not Requested'
    }
  })
  return screeningReportStatuses
}

export const evictionCaseMapper = evictionRecords =>
  cleanDeep([...evictionRecords], {
    emptyArrays: false,
    emptyObjects: false,
    emptyStrings: false,
    nullValues: true,
    undefinedValues: true,
  })

export const parseTUJsonRecordData = records => {
  const reportNumber = get(records, 'reportNumber')
  const completedDate = get(records, 'completedOn')
  const status = get(records, 'status')
  const searchCompletedDate = get(records, 'housing.completionDate')
  const activities = get(records, 'housing.activities', null)
  const evictions = activities != null ? evictionCaseMapper(activities) : null

  return { reportNumber, completedDate, status, searchCompletedDate, evictions }
}

export const parseRenterData = renter => {
  const email = get(renter, 'email')
  const phoneNumber = get(renter, 'homePhoneNumber')
  const fullName = get(renter, 'fullName')
  const city = get(renter, 'address.city')
  const state = get(renter, 'address.state')
  const streetAddress = get(renter, 'address.streetAddressLineOne')
  const zip = get(renter, 'address.zip')
  const dateOfBirth = get(renter, 'dateOfBirth')

  return { city, dateOfBirth, email, phoneNumber, fullName, state, streetAddress, zip }
}

export const parseSearchStrategy = jsonArray => {
  const searchStrategies = []

  if (jsonArray.includes('NATIONAL')) {
    searchStrategies.push('National Search')
  }
  if (jsonArray.includes('RECORD_RESEARCH')) {
    searchStrategies.push('Record Research')
  }
  if (jsonArray.includes('STATEWIDE')) {
    searchStrategies.push('Statewide Search')
  }
  if (jsonArray.includes('SINGLE_COUNTY')) {
    searchStrategies.push('Single County Search')
  }

  // if there are multiple search strategies (e.g. searchStrategies: ['NATIONAL, RECORD_RESEARCH])
  // then we want to refer to them all with commas "National Search, Record Research"
  const searchStrategyString = searchStrategies.length > 0 ? searchStrategies.join(', ') : ''
  return searchStrategyString
}

export const parseCasesLocation = casesArray => {
  const updatedCityAndStateArray = casesArray.map((item, index, arr) => {
    if (index > 0 && arr[index - 1].cityAndState === item.cityAndState) {
      // remove the cityAndState value if it's the same as the previous
      return { ...item, cityAndState: undefined }
    }
    return item
  })

  return updatedCityAndStateArray
}

export const calculateTermsAndConditionsTypography = () => {
  let typography = 'body-medium'
  const isMidMobile = useMobile(655)
  const isSmallMobile = useMobile(412)

  if (isSmallMobile) {
    typography = 'caption'
    return typography
  }

  if (isMidMobile) {
    typography = 'body'
    return typography
  }

  return typography
}
