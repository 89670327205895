import React from 'react'
import {
  PrintPageBreak,
  ReportWrapper,
} from 'components/reports/screening-reports/share/responsive-doc-style'
import HeaderPrint from 'containers/reports/header'
import {
  ApplicantOverview,
  ReportsBody,
} from 'components/reports/screening-reports/share/responsive-doc-components'
import { HeaderDoc } from 'components/reports/screening-reports/share/doc-components'
import CompanyInfoFooter from 'legacy/components/reports-v2/share/company-info-footer'
import { NoReport } from 'legacy/components/reports/share/doc-components'
import isEmpty from 'lodash/isEmpty'
import CriminalIdentity from 'components/reports/screening-reports/shareable/criminal-report/criminal-identity'
import RequestedConsumerInformation from 'components/reports/screening-reports/shareable/criminal-report/requested-consumer'
import { Attention } from 'components/reports/screening-reports/shareable/criminal-report/doc-components/attention'

const OldJsonFormattedReport = ({
  customBorder,
  customMargin,
  showHeaderComponent,
  headerDocProps,
  headerProps,
  criminalReportData,
}) => (
  <ReportWrapper border={customBorder} margin={customMargin}>
    {showHeaderComponent && <HeaderPrint />}
    <ReportsBody>
      <HeaderDoc {...headerDocProps} />
      <ApplicantOverview {...headerProps} withIncorrectInfo />
      <ReportMapping criminalReportData={criminalReportData} />
      <CompanyInfoFooter isTUReport />
      <Attention remarks={criminalReportData?.remarks} />
    </ReportsBody>
  </ReportWrapper>
)

const ReportMapping = ({ criminalReportData }) => {
  if (criminalReportData) {
    if (!isEmpty(criminalReportData.identities)) {
      return (
        <>
          <RequestedConsumerInformation
            requestedConsumer={criminalReportData.requestedConsumer}
            records={criminalReportData.records}
          />
          <PrintPageBreak />
          {criminalReportData.identities.map((identity, index) => (
            <CriminalIdentity
              key={`criminalReport-identity-${index}`}
              identity={identity}
              index={index}
            />
          ))}
        </>
      )
    }

    return <NoReport text="No records to return" />
  }

  return <NoReport text="No records to return" />
}

export default OldJsonFormattedReport
