import styled from 'styled-components'

import { COLOR } from 'components/constants'
import Button from '@rentspree/component-2023.components.atoms.button'

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: ${COLOR.white};
  padding: 6rem 0rem 4rem 0rem;
  row-gap: 1.5rem;

  @media (max-width: 991px) {
    padding: 38px 20px 34px 20px;
    width: 100%;
  }

  > div {
    width: 100%;
    max-width: 712px;

    @media (max-width: 760px) {
      width: 100%;
    }
  }
`
export const SubmitContainer = styled.div`
  justify-content: flex-end;
  width: 724px;
  display: flex;
  @media (max-width: 760px) {
    width: 100%;
    justify-content: center;
  }

  @media (max-width: 655px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
  }
`
export const SubmitButton = styled(Button)`
  float: right;
  width: 190px;
  font-size: 16px;
  @media (max-width: 655px) {
    font-size: 14px;
  }
  @media (max-width: 412px) {
    font-size: 12px;
  }
`
