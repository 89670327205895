import dayjs from 'dayjs'
import { number, object, string } from 'yup'

import { utcStartOfDay } from 'v3/containers/overhaul-rent-payment/pages/utils'

import { PAYMENT_TYPES, PaymentCategories, MAX_MONTHS_FOR_END_DATE } from '../../constants'

const NOW = utcStartOfDay()

const minDate = (value, minDateValue) => {
  if (!value || value === '') {
    return true
  }
  const date = dayjs(value)
  return date.isSame(dayjs(minDateValue), 'day') || date.isAfter(dayjs(minDateValue), 'day')
}

const maxDate = (value, maxDateValue) => {
  if (!value || value === '') {
    return true
  }

  const date = dayjs.utc(value)
  const maxDateVal = dayjs.utc(maxDateValue)

  return date.isBefore(maxDateVal, 'day')
}

const isValidDate = value => {
  if (!value) {
    return true
  }
  return dayjs(value).isValid()
}

const basePaymentValidationSchema = object({
  startDate: string()
    .required('Starts on date is required')
    .test('val-date', 'Enter a valid date', value => isValidDate(value))
    .test('min-date', 'Select today’s date or a future date', value => minDate(value, NOW)),
  category: string().required(),
  type: string().oneOf(Object.values(PAYMENT_TYPES)).required(),
  amount: number()
    .min(3, 'Enter an amount between $3 and $10,000')
    .max(10000, 'Enter an amount between $3 and $10,000')
    .required('Amount is required'),
  description: string(),
})

export const oneTimePaymentValidationSchema = basePaymentValidationSchema.concat(
  object({
    dueDate: string()
      .required('Due date is required')
      .test('min-date', 'Select today’s date or a future date', value => minDate(value, NOW)),
  }),
)

export const recurringPaymentValidationSchema = basePaymentValidationSchema.concat(
  object({
    endDate: string().when('startDate', startDate => {
      return string()
        .test('after-date', 'Select an end date that is after the start date', value =>
          minDate(value, startDate),
        )
        .test('max-date', 'Select an end date less than 3 years from start date', value =>
          maxDate(value, dayjs(startDate).add(MAX_MONTHS_FOR_END_DATE, 'month')),
        )
    }),
    repeat: string().oneOf(['monthly', 'biweekly', 'weekly']),
    dueOn: number().required(),
    term: string().oneOf(['fixed', 'perpetual']),
  }),
)

export const otherCategoryPaymentValidationSchema = object({
  // 'name' is the User name for this input field, but customCategory is the object field name
  customCategory: string().when('category', category => {
    const baseCustomCategory = string()
      .max(75, 'Enter a fee name that is under 75 characters')
      .test(
        'not-other',
        "Enter a fee name besides 'other'",
        value => value?.toLocaleLowerCase() !== 'other',
      )
      // with "Add Rent" being a single-payment editing page, duplicate rent entries cause display/logical issues
      .test(
        'not-rent',
        "Enter a fee name besides 'rent'",
        value => value?.toLocaleLowerCase() !== 'rent',
      )
    if (category === PaymentCategories.OTHER) {
      return baseCustomCategory.required('Fee name is required')
    }
    return baseCustomCategory
  }),
})
