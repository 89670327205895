import React from 'react'

import { RENTSPREE_REQUEST_SUPPORT_LINK } from 'v3/containers/overhaul-rent-payment/constants'

export const GENERAL_ERROR_PAYMENTS = {
  TITLE: "We're experiencing technical issues",
  MESSAGE: (
    <div>
      Please try again. If the problem persists,&nbsp;
      <a href={RENTSPREE_REQUEST_SUPPORT_LINK} target="_blank" rel="noopener noreferrer">
        contact RentSpree
      </a>
    </div>
  ),
}
