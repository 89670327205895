import { API_URL, USER_API_URL } from 'env'

export const GET_SCREENING_PLANS_API = `${API_URL}/api/v2/screening-plans`
export const PROPERTY_API_V2 = `${API_URL}/api/v2/agent/properties`
export const PROPERTY_DETAIL_V2 = `${PROPERTY_API_V2}/:propertyId`
export const GET_USER_PREFERENCE_API = `${USER_API_URL}/api/v2/user/me/screening-preference`
export const UPDATE_USER_PREFERENCE_API = `${USER_API_URL}/api/v2/user/me/preference`
export const CREATE_SCREENING_REQUEST_API = `${API_URL}/api/v2/agent/properties/:propertyId/screening-requests`

export const GET_SCREENING_PLANS_CALL = 'GET_SCREENING_PLANS_CALL_V2.2'
export const GET_SCREENING_PLANS_REQUEST = 'GET_SCREENING_PLANS_REQUEST_TENANT_SCREENING_V2.2'
export const GET_SCREENING_PLANS_SUCCESS = 'GET_SCREENING_PLANS_SUCCESS_TENANT_SCREENING_V2.2'
export const GET_SCREENING_PLANS_FAILURE = 'GET_SCREENING_PLANS_FAILURE_TENANT_SCREENING_V2.2'

export const GET_PROPERTY_CALL = 'GET_PROPERTY_CALL_TENANT_SCREENING_V2.2'
export const GET_PROPERTY_REQUEST = 'GET_PROPERTY_REQUEST_TENANT_SCREENING_V2.2'
export const GET_PROPERTY_SUCCESS = 'GET_PROPERTY_SUCCESS_TENANT_SCREENING_V2.2'
export const GET_PROPERTY_FAILURE = 'GET_PROPERTY_FAILURE_TENANT_SCREENING_V2.2'

export const CREATE_SCREENING_REQUEST_CALL = 'CREATE_SCREENING_REQUEST_CALL_V2.2'
export const CREATE_SCREENING_REQUEST_REQUEST = 'CREATE_SCREENING_REQUEST_REQUEST_V2.2'
export const CREATE_SCREENING_REQUEST_SUCCESS = 'CREATE_SCREENING_REQUEST_SUCCESS_V2.2'
export const CREATE_SCREENING_REQUEST_FAILURE = 'CREATE_SCREENING_REQUEST_FAILURE_V2.2'

export const GET_USER_PREFERENCE_CALL = 'GET_USER_PREFERENCE_CALL_V2.2'
export const GET_USER_PREFERENCE_REQUEST = 'GET_USER_PREFERENCE_REQUEST_TENANT_SCREENING_V2.2'
export const GET_USER_PREFERENCE_SUCCESS = 'GET_USER_PREFERENCE_SUCCESS_TENANT_SCREENING_V2.2'
export const GET_USER_PREFERENCE_FAILURE = 'GET_USER_PREFERENCE_FAILURE_TENANT_SCREENING_V2.2'

export const UPDATE_USER_PREFERENCE_CALL = 'UPDATE_USER_PREFERENCE_CALL_V2.2'
export const UPDATE_USER_PREFERENCE_REQUEST = 'UPDATE_USER_PREFERENCE_REQUEST_V2.2'
export const UPDATE_USER_PREFERENCE_SUCCESS = 'UPDATE_USER_PREFERENCE_SUCCESS_V2.2'
export const UPDATE_USER_PREFERENCE_FAILURE = 'UPDATE_USER_PREFERENCE_FAILED_V2.2'

export const SELECT_APPLICATION = 'SELECT_APPLICATION_V2.2'
export const SELECT_CREDIT_REPORT = 'SELECT_CREDIT_REPORT_V2.2'
export const SELECT_CRIMINAL = 'SELECT_CRIMINAL_V2.2'
export const SELECT_EVICTION = 'SELECT_EVICTION_V2.2'

export const SELECT_PAYER_TYPE = 'SELECT_PAYER_TYPE_V2.2'
export const SELECT_BUNDLE_TYPE = 'SELECT_BUNDLE_TYPE_V2.2'
export const SELECT_APPLICATION_TYPE = 'SELECT_APPLICATION_TYPE_V2.2'
export const SELECT_CREDIT_REPORT_ITEMS = 'SELECT_CREDIT_REPORT_ITEMS_V2.2'

// export const CREATE_SCREENING_REQUEST_API = `${API_URL}/api/v2/agent/screening-requests`

export const CREATE_SCREENING_REQUEST_WO_PROPERTY_API = `${API_URL}/api/v2/agent/screening-requests`
export const CREATE_SCREENING_REQUEST_WO_PROPERTY_CALL =
  'CREATE_SCREENING_REQUEST_WO_PROPERTY_CALL_V2.2'
export const CREATE_SCREENING_REQUEST_WO_PROPERTY_REQUEST =
  'CREATE_SCREENING_REQUEST_WO_PROPERTY_REQUEST_V2.2'
export const CREATE_SCREENING_REQUEST_WO_PROPERTY_SUCCESS =
  'CREATE_SCREENING_REQUEST_WO_PROPERTY_SUCCESS_V2.2'
export const CREATE_SCREENING_REQUEST_WO_PROPERTY_FAILURE =
  'CREATE_SCREENING_REQUEST_WO_PROPERTY_FAILURE_V2.2'

export const GENERATE_PERMALINK_REQUESTED = 'GENERATE_PERMALINK_REQUESTED_V2.2'
export const GENERATE_PERMALINK_SUCCESS = 'GENERATE_PERMALINK_SUCCESS_V2.2'
export const GENERATE_PERMALINK_FAILED = 'GENERATE_PERMALINK_FAILED_V2.2'

export const CREATE_LISTING_SCREENING_REQUEST_REQUESTED =
  'CREATE_LISTING_SCREENING_REQUEST_REQUESTED_V2.2'
export const CREATE_LISTING_SCREENING_REQUEST_SUCCESS =
  'CREATE_LISTING_SCREENING_REQUEST_SUCCESS_V2.2'
export const CREATE_LISTING_SCREENING_REQUEST_FAILED =
  'CREATE_LISTING_SCREENING_REQUEST_FAILED_V2.2'

export const GET_SCREENING_REQUEST_LANDING_CALL = 'GET_SCREENING_REQUEST_LANDING_CALL_V2.2'
export const GET_SCREENING_REQUEST_LANDING_REQUESTED =
  'GET_SCREENING_REQUEST_LANDING_REQUESTED_V2.2'
export const GET_SCREENING_REQUEST_LANDING_SUCCESS = 'GET_SCREENING_REQUEST_LANDING_SUCCESS_V2.2'
export const GET_SCREENING_REQUEST_LANDING_FAILED = 'GET_SCREENING_REQUEST_LANDING_FAILED_V2.2'

export const SET_SOURCE = 'SET_SOURCE_V2.2'

export const ACCEPT_BACKGROUND_DISCLAIMER = 'ACCEPT_BACKGROUND_DISCLAIMER_V2.2'

export const UPDATE_DOCUMENT_PRO_OPTION = 'UPDATE_DOCUMENT_PRO_OPTION_V2.2'
export const UPDATE_REF_CHECK_PRO_OPTION = 'UPDATE_REF_CHECK_PRO_OPTION_V2.2'
export const UPDATE_INCOME_VERIFICATION_OPTION = 'UPDATE_INCOME_VERIFICATION_OPTION_V2.2'
export const UPDATE_USER_PLAN = 'UPDATE_USER_PLAN'
export const UPDATE_REQUEST_STEP_CONTROL = 'UPDATE_REQUEST_STEP_CONTROL_V2.2'
export const SAVE_CUSTOM_DOCUMENT = 'SAVE_CUSTOM_DOCUMENT'

export const CLEAR_REQUEST_OPTIONS = 'CLEAR_REQUEST_OPTIONS_V2.2'
export const CLEAR_SCREENING_WITHOUT_PROPERTY_PRO_OPTIONS =
  'CLEAR_SCREENING_WITHOUT_PROPERTY_PRO_OPTIONS_V2.2'
export const CLEAR_SCREENING_WITH_PROPERTY_PRO_OPTIONS =
  'CLEAR_SCREENING_WITH_PROPERTY_PRO_OPTIONS_V2.2'
export const CLEAR_PROPERTY = 'CLEAR_PROPERTY_V2.2'

export const CALL_AUTO_CREATE_TENANT_SCREENING_REQUEST = 'CALL_AUTO_CREATE_TENANT_SCREENING_REQUEST'
export const AUTO_CREATE_TENANT_SCREENING_REQUEST_START =
  'AUTO_CREATE_TENANT_SCREENING_REQUEST_START'
export const AUTO_CREATE_TENANT_SCREENING_REQUEST_SUCCESS =
  'AUTO_CREATE_TENANT_SCREENING_REQUEST_SUCCESS'
export const AUTO_CREATE_TENANT_SCREENING_REQUEST_FAILURE =
  'AUTO_CREATE_TENANT_SCREENING_REQUEST_FAILURE'

export const AGENT_TYPES = {
  LISTING_AGENT: 'listing_agent',
  RENTER_AGENT: 'renter_agent',
  LANDLORD: 'landlord',
}

export const PAYER_TYPE = {
  APPLICANTS: 'Applicants',
  ME: 'Me',
}

export const PAYER_TYPE_MAPPER = {
  [PAYER_TYPE.APPLICANTS]: 'renter',
  [PAYER_TYPE.ME]: 'landlord',
}

export const BUNDLE_TYPE = {
  PREMIUM: 'Premium',
  STANDARD: 'Standard',
  APPLICATION_ONLY: 'Application Only',
}

export const CREDIT_REPORT_SUBTITLE = {
  [BUNDLE_TYPE.PREMIUM]: 'Includes Applicant Identity Verification.',
  [BUNDLE_TYPE.STANDARD]: 'Includes Applicant Identity Verification.',
  [BUNDLE_TYPE.APPLICATION_ONLY]: '',
}

export const APPLICATION_TYPE = {
  RENTSPREE: 'standard_rental_application',
  CAR_LRA: 'car_lra',
  NO_APPLICATION: 'no_application',
}

export const PAYER_SECTION_TEXT = {
  applicants: {
    title: 'Applicants',
  },
  applicant: {
    title: 'Applicant(s)',
  },
  me: {
    title: 'Me',
  },
}

export const BUNDLE_SECTION_TEXT = {
  standard: {
    title: 'Standard',
    subTitle: 'Application and screening reports (Credit, Criminal, and Eviction)',
  },
  premium: {
    title: 'Premium',
    subTitleHTML:
      'Standard, <strong>PLUS</strong> Income Verification, Reference Checks, and Document Upload',
  },
  application_only: {
    title: 'Application Only',
    subTitle: '',
  },
}

export const ADJUSTED_BUNDLE_SECTION_TEXT = {
  basic: {
    title: 'Basic',
    subTitle: 'Application and screening reports (Credit, Criminal, and Eviction)',
  },
  standard: {
    title: 'Standard',
    subTitleHTML:
      'Basic, <strong>PLUS</strong> Income Verification, Reference Checks, and Document Upload',
  },
  lite: {
    title: 'Lite',
    subTitle: 'Application Only',
  },
}

export const APPLICATION_SECTION_TEXT = {
  car_lra: {
    title: 'C.A.R. Rental Application (LRA)',
    subTitle: 'Exclusive for members of the California Association of REALTORS® (C.A.R.)',
  },
  application: {
    title: 'Rental Application',
    subTitle: '',
  },
  no_application: {
    title: 'No application',
    subTitle: 'If you want Reference Checks, select an application.',
  },
  income_verification: {
    title: 'income_verification',
    text: 'Income Verification Documents',
    subtext:
      'Reference Checks and Document Upload: Government-issued ID, Proof of Income, Bank Statement, W-2s',
  },
  reference_check: {
    title: 'reference_check',
    text: 'Reference Checks',
    subtext:
      'Automatically request verification from references provided by the applicant, including personal, employers, and landlords.',
  },
  other_doc: {
    title: 'other_doc',
    text: 'Documents',
    subtext: 'Request proof of income, bank statements, ID copies, and more.',
  },
  proof_of_income: {
    testId: 'paystubs',
    title: 'paystubs',
    text: 'Proof of income',
    subtext: '',
  },
  bank_statements: {
    testId: 'bank-statement',
    title: 'bank_statement',
    text: 'Bank Statements',
    subtext: 'Request proof of income, bank statements, ID copies, and more.',
  },
  tax_returns: {
    testId: 'tax-return',
    title: 'tax_return',
    text: 'Tax Returns',
    subtext: 'Request proof of income, bank statements, ID copies, and more.',
  },
  w2s: {
    testId: 'w2s',
    title: 'w2s',
    text: 'W-2s',
    subtext: '',
  },
  government_issued_id: {
    testId: 'passport',
    title: 'passport',
    text: 'Government Issued ID',
    subtext: '',
  },
  other: {
    testId: 'other',
    title: 'other',
    text: 'Other',
    subtext: '',
  },
}

export const BOX_ID = {
  PAYER_BOX: 'payerBox',
  BUNDLE_BOX: 'bundleBox',
  APPLICATION_TYPE_BOX: 'applicationTypeBox',
  CUSTOM_SCREENING_BOX: 'customScreeningBox',
}

// TODO: Refactor this
export const MAP_BOX_SUBTITLE = (boxId, requestOptions) => {
  switch (boxId) {
    case BOX_ID.PAYER_BOX:
      switch (requestOptions.payerType) {
        case PAYER_TYPE.APPLICANTS:
          return 'Applicants'
        case PAYER_TYPE.ME:
          return 'Me'
        default:
          return ''
      }
    case BOX_ID.BUNDLE_BOX:
      switch (requestOptions.bundleType) {
        case BUNDLE_TYPE.STANDARD:
          return 'Standard'
        case BUNDLE_TYPE.PREMIUM:
          return 'Premium'
        case BUNDLE_TYPE.APPLICATION_ONLY:
          return 'Application Only'
        default:
          return ''
      }
    case BOX_ID.APPLICATION_TYPE_BOX:
      switch (requestOptions.applicationType) {
        case APPLICATION_TYPE.CAR_LRA:
          return 'C.A.R. Rental Application (LRA)'
        case APPLICATION_TYPE.NO_APPLICATION:
          return 'No Application'
        case APPLICATION_TYPE.RENTSPREE:
          return 'Rental Application'
        default:
          return ''
      }
    case BOX_ID.CUSTOM_SCREENING_BOX:
      // eslint-disable-next-line no-case-declarations
      let subtitle = 'Credit Report & Score'
      if (requestOptions.eviction) {
        subtitle = subtitle.concat(', Eviction Related Proceedings')
      }
      if (requestOptions.criminal) {
        subtitle = subtitle.concat(', Criminal Background Check')
      }
      if (requestOptions.bundleType === BUNDLE_TYPE.PREMIUM) {
        subtitle = subtitle.concat(', Income Verification')
      }
      return subtitle
    default:
      return ''
  }
}

export const RESTRICTION_TEXT =
  'Reports are provided by TransUnion and are subject to laws and regulations that may limit information reported, application fee amount, and when criminal and eviction reports are released. Learn more about TransUnion’s criminal background check and '

export const USER_EXPERIENCE = {
  FIRST_TIME_SCREENING: 'first_time_screening',
  SCREENING_EXPERIENCED: 'screening_experienced',
  SCREENING_V2_EXPERIENCED: 'screening_v2_experienced',
}

export const PRO_PLAN = 'PRO'

export const INCOME_VERIFICATION_ANNOUNCEMENT_MODAL_CONTENT = {
  HEADER_TEXT: 'New! Income verification report',
  SUBHEADER_TEXT:
    'Get  a bank-verified report showing an applicant’s income and employment data—powered by Finicity, a Mastercard company.',
  DESCRIPTIONS: [
    'Confirm proof of funds ',
    'Verify income and employment',
    'Choose tenants you can trust',
  ],
  SAMPLE_REPORT_CTA_TEXT: 'View a sample income report',
  CTA_TEXT: 'Get report',
}

export const INCOME_VERIFICATION_ANNOUNCEMENT_TYPE = 'VIEW_MODAL'
export const INCOME_VERIFICATION_ANNOUNCEMENT_SEEN_GROUP = 'income-verification-announcement-modal'

export const DOCUMENT_OPTIONS = {
  PAYSTUBS: { key: 'paystubs', name: 'Proof of Income' },
  BANK_STATEMENT: { key: 'bank_statement', name: 'Bank Statement' },
  PASSPORT: { key: 'passport', name: 'Government Issued ID' },
  W2S: { key: 'w2s', name: 'W-2s' },
  TAX_RETURN: { key: 'tax_return', name: 'Tax Returns' },
  OTHER: { key: 'other', name: 'Other' },
}

export const DEFAULT_DOCUMENT_OPTIONS = [
  { type: 'paystubs', note: '' },
  { type: 'bank_statement', note: '' },
  { type: 'w2s', note: '' },
  { type: 'passport', note: '' },
]

export const SAMPLE_INCOME_REPORT_URL =
  'https://cdn.rentspree.com/static-files/report/sample-income-report-202410111028.pdf'
