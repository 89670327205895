export const REVIEW = {
  TITLE: 'Review and send payment request',
  DESCRIPTION:
    'Make sure everything looks good. We’ll notify the tenant once you send the request.',
  TENANT_AND_PROPERTY: 'Tenant and property',
  PAYMENT_DETAILS: {
    title: 'Payment details',
    description: 'Funds are deposited within 2-5 business days after the tenant makes the payment.',
    description_myself:
      'Get payouts in as little as 2 days while keeping cash flow steady. A $3 processing fee per recurring payment is deducted automatically for secure, seamless rent collection.',
    description_agent:
      'Funds are deposited within 2-5 business days, with a $3 processing fee deducted per recurring payment for a secure and seamless transaction.',
  },
  MONTHLY_PAYMENTS: 'Monthly payments',
  ONE_TIME_PAYMENTS: 'One-time payments',
  RECIPIENT_DETAILS: 'Recipient details',
  INVITE_BY_TEXT: 'inviteByText',
}
