import { get } from 'lodash'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import { OVERHAUL_RENT_PAYMENT } from 'tracker/const'
import { ingestQuotation } from 'v3/containers/overhaul-rent-payment/components/payment-forms/utils'
import {
  feeField,
  PaymentCategories,
  rentField,
} from 'v3/containers/overhaul-rent-payment/constants'
import { PageIndexesContext, PaymentInfoContext } from 'v3/containers/overhaul-rent-payment/context'
import { RentPaymentComponent } from 'v3/containers/overhaul-rent-payment/pages/payment-details/page-states/add-rent'
import { DepositAndFees } from 'v3/containers/overhaul-rent-payment/pages/payment-details/page-states/deposit-fees'
import { LandingPageComponent } from 'v3/containers/overhaul-rent-payment/pages/payment-details/page-states/landing'
import { useGetOrCreateDraftRentalPayment } from 'v3/containers/rent-payment/shared/hooks'
import { trackClick } from 'v3/utils/tracker-utils'

export const selectionField = 'payment-selections'
export const paymentsField = 'payments'

export const handlePaymentDetailsProgression = (
  { increment = false, decrement = false },
  curPageIndexes,
  setPageIndexes,
  pageField,
  curPaymentInfo,
) => {
  const indexChanges = {
    pageL1Index: curPageIndexes.pageL1Index,
    pageL2Index: curPageIndexes.pageL2Index,
    drawerOpen: curPageIndexes.drawerOpen,
  }
  let shouldSaveData = false
  const selections = curPaymentInfo?.[pageField]?.[selectionField] || {
    [rentField]: false,
    [feeField]: false,
  }
  const numPagePanels = 1 + get(selections, rentField, false) + get(selections, feeField, false)

  // shortcut invalid scenarios
  if (increment === decrement) {
    /*
     * logging an error, but this can still be consumer facing, so staying vague
     * need to find direct DD log avenue for debug details if possible
     */
    console.error('Unusual state for Payment Details page progression request')
  } else if (curPageIndexes.drawerOpen) {
    // whether we're going forward or backwards, we want the drawer to close first
    indexChanges.drawerOpen = false
  } else {
    if (increment) {
      if (indexChanges.pageL2Index === 0) {
        let checkCategory = null
        if (selections.fees && selections.rent) {
          checkCategory =
            OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.CHECK_CATEGORY.RENT_DEPOSIT_OTHER_FEES
        } else if (selections.fees) {
          checkCategory = OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.CHECK_CATEGORY.DEPOSIT_OTHER_FEES
        } else if (selections.rent) {
          checkCategory = OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.CHECK_CATEGORY.RENT
        }

        trackClick(OVERHAUL_RENT_PAYMENT.EVENT_NAME.COLLECT_PAYMENTS_CLICK, {
          step: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.STEP.PAYMENT_DETAILS,
          destination: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.STEP.PAYMENT_DETAILS,
          click_text: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.CLICK_TEXT.NEXT,
          check_category: checkCategory,
        })
      }
      /*
       * always start by assuming L2 progression and
       * validate that one of the checkboxes is selected to progress
       */
      if (selections.fees || selections.rent) {
        indexChanges.pageL2Index += 1
      }
      if (indexChanges.pageL2Index >= numPagePanels) {
        indexChanges.pageL1Index += 1
        shouldSaveData = true

        trackClick(OVERHAUL_RENT_PAYMENT.EVENT_NAME.COLLECT_PAYMENTS_CLICK, {
          step: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.STEP.PAYMENT_DETAILS,
          destination: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.DESTINATION.STEP_4_REVIEW,
          click_text: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.CLICK_TEXT.NEXT,
        })
      }
      /*
       * TODO: drawer opening?
       * JB and I were talking about controlling that via sub-page logic
       */
    }
    if (decrement) {
      indexChanges.pageL2Index -= 1
      if (indexChanges.pageL2Index < 0) {
        indexChanges.pageL1Index -= 1
        // quick don't-send-invalid-data reset
        indexChanges.pageL2Index = 0

        trackClick(OVERHAUL_RENT_PAYMENT.EVENT_NAME.COLLECT_PAYMENTS_CLICK, {
          step: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.STEP.PAYMENT_DETAILS,
          destination: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.DESTINATION.STEP_2_PROPERTY_TENANT,
          click_text: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.CLICK_TEXT.BACK,
        })
      }
    }
  }

  /*
   * All decrement states for this page involve going back to the root of the flow
   * Absorbing errored-state traversal handling as well
   */
  setPageIndexes(indexChanges)
  return shouldSaveData
}

export const PaymentDetailsPage = ({ pageField, updateDataHandler, setIsValid, isValid }) => {
  const [pageIndexes, setPageIndexes] = useContext(PageIndexesContext)
  const { paymentInfo } = useContext(PaymentInfoContext)

  const setDrawerOpen = isOpen => {
    setPageIndexes({ drawerOpen: isOpen })
  }

  const pageInfo = paymentInfo?.[pageField] || {}
  const payments = pageInfo?.[paymentsField] || []
  const paymentSelections = pageInfo?.[selectionField] || {
    [rentField]: false,
    [feeField]: false,
  }

  useEffect(() => {
    setIsValid(!!pageInfo?.[selectionField]?.rent || !!pageInfo?.[selectionField]?.fees)
  }, [pageInfo?.[selectionField]?.[rentField], pageInfo?.[selectionField]?.[feeField], pageIndexes])

  const { data: draftPayments, status: existingPaymentFetchStatus } =
    useGetOrCreateDraftRentalPayment()

  // abstract entered payments from the context obj so payment selections can toggle rent or fees without losing entries
  const [localPayments, setLocalPayments] = useState([...payments])
  const setNextLocalPayments = nextPayments => {
    ;[...nextPayments].sort((pmt1, pmt2) => {
      const cat1 = (pmt1?.category || '').toLowerCase()
      const cat2 = (pmt2?.category || '').toLowerCase()

      /*
       * cat1.localCompare(cat2) is throwing localCompare() DNEs, even after confirming
       * categories 1 & 2 are strings? Manually creating the equivalent logic
       */
      let sortFlag = 0
      if (cat1 < cat2) {
        sortFlag = 1
      } else if (cat1 > cat2) {
        sortFlag = -1
      }
      return sortFlag
    })

    setLocalPayments(nextPayments.map(nextPayment => ingestQuotation(nextPayment)))
  }

  const paymentsJSON = JSON.stringify(localPayments)
  useEffect(() => {
    const filteredPayments = localPayments.filter(el => {
      // jic API values are different, for now -- // remember to add LATE_FEE
      const rentOptions = [PaymentCategories.RENT].map(val => val.toLowerCase())
      const isRent = rentOptions.includes(el?.category.toLowerCase())
      const isFee = !isRent

      // only keep rent entries if rent is checked; ditto for fees
      return (isRent && paymentSelections?.[rentField]) || (isFee && paymentSelections?.[feeField])
    })

    updateDataHandler({
      ...pageInfo,
      [paymentsField]: filteredPayments,
    })
  }, [
    localPayments.length,
    paymentsJSON,
    paymentSelections?.[rentField],
    paymentSelections?.[feeField],
  ])

  const setPaymentSelections = (nextSelections = {}) => {
    const filteredNextSelections = {
      [rentField]: nextSelections?.[rentField] || false,
      [feeField]: nextSelections?.[feeField] || false,
    }
    updateDataHandler({
      ...pageInfo,
      [selectionField]: filteredNextSelections,
    })
  }

  useEffect(() => {
    let isMounted = true
    const existingPayments = draftPayments?.quotations

    // assume for now that if we have a new API call, we want to toss any cached changes
    if (existingPayments !== undefined && isMounted) {
      setNextLocalPayments(existingPayments)

      const existingCategories = draftPayments?.quotations.map(el =>
        (el?.category || '').toLowerCase(),
      )
      const draftRentEntryExists = existingCategories.includes(PaymentCategories.RENT.toLowerCase())
      const draftFeeEntriesExist =
        existingCategories.filter(el => el !== PaymentCategories.RENT.toLowerCase()).length > 0

      setPaymentSelections({
        [rentField]: paymentSelections[rentField] || draftRentEntryExists,
        [feeField]: paymentSelections[feeField] || draftFeeEntriesExist,
      })
    }

    // a cleanup function to keep operations from running after the component unmounts via race condition
    return () => {
      isMounted = false
    }
  }, [existingPaymentFetchStatus])

  const getCurrentPage = useCallback(
    subPageIndex => {
      const pagesArray = [LandingPageComponent]
      if (paymentSelections[rentField]) {
        pagesArray.push(RentPaymentComponent)
      }
      if (paymentSelections[feeField]) {
        pagesArray.push(DepositAndFees)
      }

      // default to LandingPageComponent on errors
      return subPageIndex < pagesArray.length ? pagesArray[subPageIndex] : LandingPageComponent
    },
    [paymentSelections[rentField], paymentSelections[feeField]],
  )

  const CurrentPage = getCurrentPage(pageIndexes.pageL2Index)
  return (
    <CurrentPage
      payments={localPayments}
      setPayments={nextPayments => {
        setNextLocalPayments(nextPayments)
      }}
      pageIndexes={pageIndexes}
      setDrawerOpen={setDrawerOpen}
      paymentSelections={paymentSelections}
      setPaymentSelections={changes => {
        setPaymentSelections({ ...changes })
      }}
      setIsValid={setIsValid}
      isValid={isValid}
    />
  )
}
