import React from 'react'
import { AppNavigation as RSPAppNavigation } from '@rentspree/component-2023.smart-components.app-navigation'
import { AUTH_PROVIDER_DOMAIN, AUTH_APP_CLIENT_ID, AUTH_API_IDENTIFIER, AUTH_APP_SCOPE } from 'env'
import { buildPath } from '@rentspree/path'
import { withRouter, matchPath } from 'react-router-dom'
import { feedbackCollectionInstance } from 'utils/feedback-collection/instance'
import tracker from 'tracker'

export const AppNav = ({ location, history, children, ...props }) => {
  const levelOneUrls = [
    '/',
    '/explore',
    '/tenant-screening',
    '/tenant-screening/landing',
    '/properties',
    '/lease-agreements',
    '/rent-payments',
    '/rent-payments/settings',
    '/client-manager',
    '/tasks',
    '/referral-form',
    '/messaging',
    '/new/home',
  ]
  const current = buildPath(location.pathname).replace('/dashboard/v2', '')
  let currentPath = location.pathname
  const matchPathResult = matchPath(current, {
    path: levelOneUrls.map(url => buildPath(url)),
    exact: true,
  })

  // TO DO: Refactor this workaround to handle the new home page
  if (currentPath.replace('/dashboard/v2', '') === '/new/home') {
    currentPath = '/explore'
  }
  const currentLevel = matchPathResult ? 1 : 0
  const showGenericSurvey = () => feedbackCollectionInstance.showGenericSurvey()

  const subdomain = window.location.hostname.split('.')[0] || 'rentspree'

  const onRedirect = path => {
    const externalPath = ['/agent-tools']
    if (!externalPath.includes(path.url)) {
      history.push(path.url)
    } else {
      window.location.href = path.url
    }
  }

  return (
    <RSPAppNavigation
      currentLevel={currentLevel}
      currentPath={currentPath}
      showFeedbackModal={showGenericSurvey}
      onRedirect={onRedirect}
      tracker={tracker}
      subdomain={subdomain === 'www' || subdomain === 'localhost' ? 'rentspree' : subdomain}
      authConfig={{
        domain: AUTH_PROVIDER_DOMAIN,
        clientId: AUTH_APP_CLIENT_ID,
        scope: AUTH_APP_SCOPE,
        audience: AUTH_API_IDENTIFIER
      }}
      {...props}
    >
      {children}
    </RSPAppNavigation>
  )
}

export const AppNavigation = withRouter(AppNav)
export default AppNavigation
