import React from 'react'
import isEmpty from 'lodash/isEmpty'
import tracker from 'tracker'

import { REFERRAL_EVENT } from 'tracker/const'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import { Box, Grid } from '@mui/material'
import Link from '@rentspree/component-2023.components.atoms.link'
import Button from '@rentspree/component-2023.components.atoms.button'
import HubspotForm from '../../components/forms/hubspot-form'
import ReferralConnect from './referral-form-connect'

import { SUPPORT_FAQ_URL } from '../../constants/route-consts'

const REFERRAL_TOP_IMG =
  'https://cdn.rentspree.com/static-files/gifts/referral-top-pic.png'

@ReferralConnect
export default class ReferralFormPage extends React.Component {
  constructor() {
    super()
    this.state = {
      formSubmitted: 0,
    }
  }

  onFormSubmitted = position => {
    tracker.trackEvent(REFERRAL_EVENT.SEND_REFERRAL_INVITE, { pages: 'Refer & Earn', position })

    this.setState(prevState => ({ formSubmitted: prevState.formSubmitted + 1 }))
  }

  addReferrerHubspot() {
    const { profile } = this.props
    try {
      const inputArr = document.querySelectorAll("input[name='referrer']")
      for (let i = 0; i < inputArr.length; i += 1) {
        inputArr[i].value = profile.email
      }
    } catch (e) {
      console.error('Error update referrer email form hubspot=', e)
    }
  }

  render() {
    const { profile } = this.props
    const inlineMessage = "<span><i class='fas fa-check'></i> We sent your invite.</span>"
    return (
      <Box>
        <Box style={{ marginBottom: '20px' }} id="referralTopBanner" data-testid="image-banner">
          <img src={REFERRAL_TOP_IMG} alt="Referral top banner" />
        </Box>
        <Grid container paddingLeft="16px" paddingRight="16px">
          <Grid item lg={6} md={6} sm={12}>
            <Box
              display="flex"
              flexDirection="column"
              gap="16px"
              alignItems="flex-start"
              data-testid="copy-writing-contianer"
            >
              <Typography variant="h4" component="div">
                Give. Get. Repeat.
              </Typography>
              <Box display="flex" flexDirection="column" gap="16px">
                <Typography variant="body" component="div">
                  Know an agent who could use a smarter, stress-free way to screen tenants? Send them our way!
                </Typography>
                <Typography variant="body" component="div">
                  They get easy, reliable tenant screening.<br/>You get a premium gift as a thank-you.
                </Typography>
                <Typography variant="title" component="div">
                  How it works (it’s ridiculously easy):
                </Typography>
                <Typography variant="body" component="div">
                  <ol style={{ paddingLeft: '20px' }}>
                    <li>
                      <Typography variant="body" component="span" fontWeight="bold">
                        Invite an agent.&nbsp;
                      </Typography> 
                      Drop in their email—we’ll handle the rest.
                    </li>
                    <li>
                      <Typography variant="body" component="span" fontWeight="bold">
                        They screen a tenant.&nbsp;
                      </Typography>
                      Boom—you’re in.
                    </li>
                    <li>
                      <Typography variant="body" component="span" fontWeight="bold">
                        Claim your reward.&nbsp;
                      </Typography>
                      We’ll send you a link to choose your gift.
                    </li>
                  </ol>                
                </Typography>
                <Typography variant="title" component="div">
                  Pick the perfect gift                
                </Typography>
                <Typography variant="body" component="div">
                  We’ve teamed up with Snappy to bring you a curated lineup of awesome gifts—tech, travel, home essentials, gourmet treats, and more. Go fancy, go practical, or go delicious. The choice is yours!
                </Typography>
                <Typography variant="body" component="div">
                  Referring takes seconds. No fine print. No hassle. Just easy rewards for sharing RentSpree.
                </Typography>
              </Box>
              <Link style={{ marginBottom: '40px' }} target="__blank" href={SUPPORT_FAQ_URL} color="secondary" variant="bodySmall">
                Terms apply
              </Link>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} width="100%">
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Box className="hs-form-wrapper">
                <Typography variant="body-large" component="div" marginBottom="8px">
                  Share RentSpree in seconds—fill this out:
                </Typography>
                {!isEmpty(profile) && [
                  <Box key="referee1">
                    <HubspotForm
                      id="referralForm1"
                      formId="5fafdeca-ebda-4485-b72c-b32d0e3bf30f"
                      portalId="3028281"
                      classForm="single-line-form"
                      onFormReady={() => this.addReferrerHubspot()}
                      onFormSubmitted={this.onFormSubmitted}
                      inlineMessage={inlineMessage}
                      submitDelay={500}
                      position={1}
                    />
                  </Box>,
                  <Box key="referee2">
                    <HubspotForm
                      id="referralForm2"
                      formId="992cac0a-6cfd-4ba2-a8e5-31fa4126910c"
                      portalId="3028281"
                      classForm="single-line-form"
                      onFormReady={() => this.addReferrerHubspot()}
                      onFormSubmitted={this.onFormSubmitted}
                      inlineMessage={inlineMessage}
                      submitDelay={500}
                      position={2}
                    />
                  </Box>,
                  <Box key="referee3">
                    <HubspotForm
                      id="referralForm3"
                      formId="d23a6459-b7b1-49cc-923b-00c0f03cb5b4"
                      portalId="3028281"
                      classForm="single-line-form"
                      onFormReady={() => this.addReferrerHubspot()}
                      onFormSubmitted={this.onFormSubmitted}
                      inlineMessage={inlineMessage}
                      submitDelay={500}
                      position={3}
                    />
                  </Box>,
                ]}
                {this.state.formSubmitted === 3 && (
                  <Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => window.location.reload()}
                    >
                      Send more invite
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }
}
