import imageCompression from 'browser-image-compression'
import { MILLION } from 'utils/constants'
const blobToFile = (blob, fileName, mimeType = 'image/jpeg') =>
  new File([blob], fileName, { type: mimeType, lastModified: Date.now() })

/**
 * Compresses an image file using imageCompression library
 * @param {File} file - The image file to compress
 * @param {Object} fileDetail - Optional details about the file including id for progress tracking
 * @param {Function} progressFn - Optional callback function to track compression progress
 * @returns {Promise<File>} Compressed image file
 *
 * The compression config:
 * - Reduces file size to max 5MB
 * - Maintains aspect ratio while limiting max dimension to 3000px
 * - Uses web worker for better performance
 */
export const compressImage = async (file, fileDetail = null, progressFn = null) => {
  const MAX_UNIT = 5
  const MAX_COMPRESSED_SIZE_IN_BYTES = MAX_UNIT * MILLION
  const config = {
    maxSizeMB: MAX_UNIT,
    maxWidthOrHeight: 3000,
    useWebWorker: true,
    fileType: 'image/jpeg',
  }
  if (file.size <= MAX_COMPRESSED_SIZE_IN_BYTES) {
    return file
  }
  if (fileDetail && progressFn) {
    const blob = await imageCompression(file, {
      ...config,
      onProgress: progress => {
        progressFn(fileDetail.id, progress)
      },
    })
    return blobToFile(blob, file.name, file.type)
  }

  const blob = await imageCompression(file, config)

  return blobToFile(blob, file.name, file.type)
}
