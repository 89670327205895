import { put, call, takeLatest, select, delay, all } from 'redux-saga/effects'
import { buildPath } from '@rentspree/path'
import * as Sentry from '@sentry/browser'
import { CancelToken } from 'axios'

import tracker from 'tracker'
import { apiInstanceWithErrorHandler, agentReviewApiInstance } from 'utils/api-interceptor'
import { EVENT_REPORT } from 'tracker/const'

import {
  postRentalSubmissionRequest,
  postRentalSubmissionFailed,
  postRentalSubmissionSuccess,
} from './actions'
import {
  SUBMIT_RENTAL_API_URL,
  SUBMIT_RENTAL_CALL,
  CREATE_AGENT_REVIEW_PLACEHOLDER_TIMEOUT,
} from './constants'
import { SubmitRentalSubmissionError } from './error-exception'
import { selectRentalDetail } from '../selectors'

export const submitRentalApi = rentalId =>
  apiInstanceWithErrorHandler.post(buildPath(SUBMIT_RENTAL_API_URL, { rentalId }))

export const createAgentReviewPlaceholderApi = async (
  data,
  cancelTokenSource = CancelToken.source(),
) => {
  // Set a timeout to make sure that creating an agent review after the renter submits the report is non-blocking.
  const timeout = setTimeout(() => {
    cancelTokenSource.cancel()
    console.error(
      `Create agent review placeholder API canceled due to exceeding the timeout of ${CREATE_AGENT_REVIEW_PLACEHOLDER_TIMEOUT}ms`,
    )
  }, CREATE_AGENT_REVIEW_PLACEHOLDER_TIMEOUT)

  try {
    await agentReviewApiInstance.post(buildPath(`/placeholder`), data, {
      cancelToken: cancelTokenSource.token,
      timeout: CREATE_AGENT_REVIEW_PLACEHOLDER_TIMEOUT,
    })
  } catch (error) {
    console.error('[Error] Cannot create agent review placeholder', error)
  } finally {
    clearTimeout(timeout)
  }
}

export const redirectToNextPage = (applyLink, incomeVerificationRequested) => {
  if (incomeVerificationRequested) {
    window.location.replace(`${applyLink}/income-verification`)
  }
  else {
    window.location.replace(`${applyLink}/finish/?show_agent_review=true`)
  }
}

export function* postSubmitRental({ rentalId, incomeVerificationRequested }) {
  yield put(postRentalSubmissionRequest())
  try {
    const response = yield call(submitRentalApi, rentalId)
    yield call([tracker, 'trackEvent'], EVENT_REPORT.submitReportSuccess)
    yield delay(200)
    const createAgentReviewPlaceholderPayload = {
      // eslint-disable-next-line camelcase
      reviewee: { userId: response?.landlord_id },
      rentalSubmissionId: response?._id,
      createdFrom: 'tenant_screening',
    }
    yield call(createAgentReviewPlaceholderApi, createAgentReviewPlaceholderPayload)
    yield put(postRentalSubmissionSuccess())
    const { applyLink } = yield select(selectRentalDetail)
    yield call(redirectToNextPage, applyLink, incomeVerificationRequested)
  } catch (err) {
    yield call(Sentry.captureException, new SubmitRentalSubmissionError(JSON.stringify(err)))
    yield put(postRentalSubmissionFailed(err))
    yield call([tracker, 'trackEvent'], EVENT_REPORT.submitReportFail)
  }
}

export function* watchSubmitRental() {
  yield takeLatest(SUBMIT_RENTAL_CALL, postSubmitRental)
}

export function* rootSaga() {
  yield all([watchSubmitRental()])
}

export default rootSaga
