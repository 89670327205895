import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { capitalize, get } from 'lodash'
import styled from 'styled-components'
import { DialogContent, DialogContentText, DialogActions } from '@mui/material'
import Dialog from '@rentspree/component-2023.components.atoms.dialog'
import DialogTitle from '@rentspree/component-2023.components.atoms.dialog-title'
import Button from '@rentspree/component-2023.components.atoms.button'
import { buildPath } from '@rentspree/path'

import { ReportWrapper } from 'components/reports/screening-reports/share/responsive-doc-style'
import passportsSVG from 'images/passports.svg'
import womanWalkWithEnvelopesSVG from 'images/woman-walk-with-envelopes.svg'
import * as ROUTES from 'constants/route'
import { breakpoints } from 'styles/settings'
import {
  SecondaryContainedButton,
  MessageBody,
  TabSubtitle,
  SecondaryTextButton,
} from '../components'
import { screeningReportNames } from '../constants'

const StyledLink = styled(Link)`
  text-decoration: underline !important;
  color: black;
  font-size: 18px;
  font-family: 'Source Sans Pro';
  line-height: 24px;

  ${breakpoints.mobile(`
    font-family: Inter;
    font-size: 18px;
    line-height: 24px;
  `)}
`

const AllSubtitle = ({ reportName, refType, match, reportType }) => (
  <p>
    {`${reportName} can be accessed if the applicant has been conditionally accepted, following
      local regulations. `}
    <br></br>
    <StyledLink
      to={{
        pathname: buildPath(ROUTES.APPLICATION_SCREENING_REPORTS, {
          ...match.params,
          type: reportType,
        }),
      }}
    >
      {`Access ${refType}`}
    </StyledLink>
  </p>
)

const Modal = ({ reportName, handleConfirmClick, onClose, isOpen }) => (
  <Dialog size="large" open={isOpen} onClose={onClose}>
    <DialogTitle handleOnClose={onClose}>
      Can you confirm this applicant has been conditionally accepted?
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        {`${reportName} are only available for applicants who have been conditionally accepted,
    following local regulations.`}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <SecondaryTextButton text="No, go back" handleClick={onClose} />
      <Button variant="contained" color="secondary" size="medium" onClick={handleConfirmClick}>
        Yes, confirm
      </Button>
    </DialogActions>
  </Dialog>
)

const PendingConditionalAcceptance = ({ reportType, match, handleConditionalAcceptance }) => {
  /** State */
  const [isOpen, setIsOpen] = useState(false)

  /** Variables */
  const path = get(match, 'path', '')
  const isPrintingScreen =
    path.includes(ROUTES.PRINT_REPORTS_PAGE) || path.includes(ROUTES.PRINT_REPORTS_RENTER_PAGE)
  const isAllTabOrPrint = get(match, 'params.type', '') === 'all' || isPrintingScreen
  const MessageContainer = isAllTabOrPrint ? ReportWrapper : Fragment
  const reportName = capitalize(screeningReportNames[reportType])
  const refType = screeningReportNames[reportType].split(' ')[1]

  /** Functions */
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)
  const handleConfirmClick = () => {
    handleConditionalAcceptance()
    closeModal()
  }

  /** tab props */
  const tabProps = {
    title: `Ready to access these ${refType}?`,
    subtitle: <TabSubtitle />,
    imgSrc: passportsSVG,
    imgAltText: 'three cartoon passports',
    buttons: <SecondaryContainedButton text={`View ${refType}`} handleClick={openModal} />,
  }
  const allOrPrintProps = {
    title: `${reportName} available`,
    subtitle: (
      <AllSubtitle
        reportName={reportName}
        refType={refType}
        match={match}
        reportType={reportType}
      />
    ),
    imgSrc: womanWalkWithEnvelopesSVG,
    imgAltText: 'woman walking with envelopes',
  }

  const { title, subtitle, imgSrc, imgAltText, buttons } = isAllTabOrPrint
    ? allOrPrintProps
    : tabProps

  return (
    <MessageContainer>
      <MessageBody
        imgSrc={imgSrc}
        imgAltText={imgAltText}
        title={title}
        subtitle={subtitle}
        buttons={buttons}
      />
      {isAllTabOrPrint ? null : (
        <Modal
          reportName={reportName}
          handleConfirmClick={handleConfirmClick}
          onClose={closeModal}
          isOpen={isOpen}
        />
      )}
    </MessageContainer>
  )
}

export default PendingConditionalAcceptance
