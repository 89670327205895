import React from 'react'
import { compose } from 'redux'
import { get } from 'lodash'
import { TopNavbar } from '@rentspree/navbar'
import CircularProgress from '@rentspree/component-2023.components.organisms.circular-progress'

import tracker from 'tracker'
import { REVIEW_REPORT_EVENT } from 'tracker/const'
import { PageContainer, SubmitContainer, SubmitButton } from 'components/reports/reviewing'
import { TermsAndConditionsCheckbox } from 'components/reports/reviewing/terms-and-conditions'
import { ReportSectionHeader } from 'components/reports/reviewing/report-section-header'
import { TitleWithSubtitle } from 'components/molecules/title-with-subtitle'
import { getPartnerLogoDashboardSrc } from 'utils/subdomain'
import useMobile from 'hooks/use-mobile'
import { PARTNER_NAME } from 'constants/partner'
import {
  DOMAIN_NAME,
  USER_API_URL,
  API_URL,
  AUTH_PROVIDER_DOMAIN,
  AUTH_APP_CLIENT_ID,
  AUTH_API_IDENTIFIER,
  AUTH_APP_SCOPE,
} from 'env'
import NullDataReport from 'components/reports/screening-reports/shareable/share/null-report'
import StickyFooterTemplate from '@rentspree/component-2023.components.templates.sticky-footer-template'
import { MOBILE_THRESHOLD } from './constants'
import { withReducer, withConnect, withSaga } from './connect'
import { getReportAvailability, getReportsForReview } from './utils'
import withOpen from '../screening-reports/with-open'

export const ReportReviewing = ({
  applicantReports = {},
  errorCreditReport = null,
  getCreditReport,
  isSubmitted = false,
  isSubmitting = false,
  profile = {},
  rentalSubmission = {},
  subdomain = '',
  submitRentalCall,
}) => {
  /** State */
  const [loading, setLoading] = React.useState(true)
  const [hasReportFetchError, setHasReportFetchError] = React.useState(errorCreditReport)
  // a Set of one of "criminal", "eviction", and "credit_report". Reports types are added as "confirmed" when the user clicks the Confirm button
  const [isTermsConditionsAccepted, toggleTermsConditions] = React.useState(false)
  const [isCheckboxVisited, setCheckboxVisited] = React.useState(false)

  /** Constants */
  const isMobile = useMobile(MOBILE_THRESHOLD)

  const screeningOptions = get(rentalSubmission, 'screeningOption', {}) // which reports are or are not requested by the landlord or agent
  const { isCreditAvailable } = getReportAvailability(applicantReports)
  const reportsForReview = getReportsForReview({
    screeningOptions,
    applicantReports,
  })

  const pageTitle = 'Final step: review & submit your reports'
  const pageSubtitle = 'Make sure everything looks right, then submit to complete your application.'
  const buttonText = isSubmitting ? 'Submitting' : 'Submit and continue'

  const isDisabledSubmitBtn = !isTermsConditionsAccepted || isSubmitting || isSubmitted
  const checkboxFormError = isCheckboxVisited && !isTermsConditionsAccepted

  const handleCheckbox = () => {
    if (isCheckboxVisited === false) {
      setCheckboxVisited(true)
    }
    toggleTermsConditions(!isTermsConditionsAccepted)
  }

  const handleSubmit = () => {
    submitRentalCall(rentalSubmission.id, screeningOptions.income)
    tracker.trackEvent(REVIEW_REPORT_EVENT.EVENT_NAME.SUBMIT_REPORT, {
      screening_option: screeningOptions,
      payer_type: screeningOptions.payerType,
      template: rentalSubmission?.application?.type || 'No application',
      template_version: rentalSubmission?.application?.version || 'N/A',
      // eslint-disable-next-line no-underscore-dangle
      rental_id: rentalSubmission._id,
    })
  }

  /** Hooks */
  React.useEffect(() => {
    tracker.trackEvent(REVIEW_REPORT_EVENT.EVENT_NAME.REVIEW_REPORT, {
      screening_option: screeningOptions,
      payer_type: screeningOptions.payerType,
      template: rentalSubmission?.application?.type || 'No application',
      template_version: rentalSubmission?.application?.version || 'N/A',
      // eslint-disable-next-line no-underscore-dangle
      rental_id: rentalSubmission?._id,
    })
  }, [])

  React.useEffect(() => {
    const refetchData = async () => {
      try {
        await getCreditReport({ rentalId: rentalSubmission._id, useScreeningEndpoint: false })
      } catch (error) {
        setHasReportFetchError(true)
      }
      setLoading(false)
    }

    refetchData()

    return () => {
      setLoading(false)
    }
  }, [])

  return (
    <>
      <TopNavbar
        configApi={{
          baseURL: USER_API_URL,
          domain: DOMAIN_NAME,
          apiURL: API_URL,
        }}
        authConfig={{
          domain: AUTH_PROVIDER_DOMAIN,
          clientId: AUTH_APP_CLIENT_ID,
          scope: AUTH_APP_SCOPE,
          audience: AUTH_API_IDENTIFIER,
        }}
        partnerLogoSrc={
          subdomain === PARTNER_NAME.RENTALS ? getPartnerLogoDashboardSrc(subdomain) : ''
        }
        profile={profile}
        isShowRenterSupport
        domain={DOMAIN_NAME}
        authBaseURL={USER_API_URL}
        tracker={tracker}
      />
      <PageContainer>
        <TitleWithSubtitle title={pageTitle} subtitle={pageSubtitle} mb="8" />
        {loading || !isCreditAvailable ? (
          <CircularProgress data-testid="apply_reportReview_loadingIndicator" />
        ) : (
          <div data-testid="apply_reportReview_area">
            {hasReportFetchError && (
              <NullDataReport
                data-testid="apply_reportReview_errorFetchingCreditReport"
                generatedOn=""
                headerDoc=""
              />
            )}
            {!hasReportFetchError &&
              reportsForReview.map((report, i) => {
                const reportType = report.type
                const summaryTitle = `${i + 1}. ${report.title}` // rendering the reportOrder is not zero-indexed

                return (
                  <div style={{ marginBottom: '1rem' }}>
                    {report.isAvailable && (
                      <>
                        <ReportSectionHeader
                          title={summaryTitle}
                          isMobile={isMobile}
                          reportType={reportType}
                        />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {report.content}
                        </div>
                      </>
                    )}
                  </div>
                )
              })}
          </div>
        )}
      </PageContainer>
      <StickyFooterTemplate
        sx={{ justifyContent: 'center' }}
        data-testid="apply_reviewReport_submitApproval_stickyFooter"
      >
        <SubmitContainer>
          <TermsAndConditionsCheckbox
            checked={isTermsConditionsAccepted}
            onChange={handleCheckbox}
            hasError={checkboxFormError}
            disabled={!isCreditAvailable}
          />
          <SubmitButton
            style={{ marginRight: '16px' }}
            aria-label={buttonText}
            data-testid="report-reviewing-submit-btn-id"
            variant="contained"
            size="small"
            disabled={isDisabledSubmitBtn}
            startIcon={
              isSubmitting && (
                <CircularProgress
                  size="icon"
                  color="primary"
                  data-testid="apply_reviewReport_loading_indicator"
                />
              )
            }
            onClick={handleSubmit}
          >
            {buttonText}
          </SubmitButton>
        </SubmitContainer>
      </StickyFooterTemplate>
    </>
  )
}

// TODO_EMILY: I think we can remove withOpen?
export default compose(withOpen, withReducer, withConnect, withSaga)(ReportReviewing)
