import React, { useState, useEffect } from 'react'
import { LoadingWithCenter } from 'components/layout/main'
import { AUTH_PROVIDER_DOMAIN, AUTH_APP_CLIENT_ID, AUTH_API_IDENTIFIER, AUTH_APP_SCOPE } from 'env'
import { feedbackCollectionInstance } from 'utils/feedback-collection/instance'
import { authService } from './services/auth-service'

export const AuthProvider = ({ children }) => {
  const [isReady, setIsReady] = useState(false)
  useEffect(() => {
    const initAuth = async () => {
      await authService.init({
        domain: AUTH_PROVIDER_DOMAIN,
        clientId: AUTH_APP_CLIENT_ID,
        cacheLocation: 'localstorage',
        useRefreshTokens: true,
        authorizationParams: {
          scope: AUTH_APP_SCOPE,
          audience: AUTH_API_IDENTIFIER,
        },
      })
      
      const isAuthenticated = await authService.isAuthenticated()

      if (isAuthenticated) {
        feedbackCollectionInstance.setAuthConfiguration({getAccessToken: authService.getAccessToken.bind(authService)})
      }

      setIsReady(true)
    }

    initAuth()
  }, [])
  if (!isReady) return <LoadingWithCenter />
  return <>{children}</>
}
