import { useFeatureGate } from '@statsig/react-bindings'
import { isEmpty } from 'lodash'
import { useEffect } from 'react'

import { USER_ROLE_FOR_PROPERTY } from 'containers/request/step-create-property/constants'
import { useMutateUpdateUserRoleForProperty } from 'hooks'

import { useGetMyProperty } from './use-get-property'

export const ONBOARDING_NEW_USER =
  'init-463_new_user_onboarding_set_partner_agent_user_role_for_property'
/*
 * This hook will check if a flag from partner onboarding flow as well as checking new user onboarding feature flag.
 * It should run its implementation only one time to update user role for property of
 * partner agents which are not included in new user onboarding flow.
 */
export const useSetPartnerAgentUserRoleForProperty = isPartnerOnboardingVisible => {
  const { value: isNewUserOnboardingEnabled } = useFeatureGate(ONBOARDING_NEW_USER)
  const properties = useGetMyProperty({ page: 1, perPage: 50 })
  const updateProperty = useMutateUpdateUserRoleForProperty()
  useEffect(() => {
    if (isNewUserOnboardingEnabled && isPartnerOnboardingVisible) {
      if (!isEmpty(properties.data?.data)) {
        const { data: queryResult } = properties
        queryResult.data.forEach(property => {
          updateProperty.mutateAsync({
            propertyId: property._id,
            userRoleForProperty: USER_ROLE_FOR_PROPERTY.LISTING_AGENT,
          })
        })
      }
    }
  }, [isNewUserOnboardingEnabled, isPartnerOnboardingVisible, properties?.data?.data?.length])
}
