import { PaymentCategories } from 'v3/containers/overhaul-rent-payment/constants'
import { capitalize, utcStartOfDay } from 'v3/containers/overhaul-rent-payment/pages/utils'
import { MONTH_TO_MONTH_LABEL } from 'v3/containers/rent-payment/constants'
import { generateQuotationByType } from 'v3/containers/rent-payment/setup-page/set-up-for-myself/steps/payment-details/generate-quotation'

export const ingestQuotation = quotation => {
  const nextQuotation = { ...quotation }
  // check for API payments with OTHER/custom categories
  const categoryCustom = !Object.values(PaymentCategories).includes(nextQuotation?.category)

  nextQuotation.customCategory = ''
  if (categoryCustom) {
    nextQuotation.customCategory = nextQuotation.category
    nextQuotation.category = PaymentCategories.OTHER
  }

  // sync the start & due dates, so a user can switch between recurring/one-time types
  const beginningDate = nextQuotation?.startDate || nextQuotation?.dueDate
  nextQuotation.startDate = beginningDate
  nextQuotation.dueDate = beginningDate

  return nextQuotation
}

export const generateAPIQuotation = (payment, isPaymentFeeAdjustmentsEnabled) => {
  const endDateDisabled = ['oneTime', undefined].includes(payment?.type)
  const dueDate = payment?.dueDate || payment?.startDate

  const inputs = {
    category: payment?.category,
    customCategory: capitalize(payment?.customCategory),
    monthlyDescription: payment?.description,
    oneTimeDescription: payment?.description,
    amount: payment?.amount,
    paymentType: payment?.type,
    firstPayment: payment?.startDate,
    lastPayment: !endDateDisabled && payment?.endDate ? payment?.endDate : MONTH_TO_MONTH_LABEL,
    dueOn: dueDate ? utcStartOfDay(dueDate).date() : null,
    dueDate,
    selectedFeeCollection: payment?.feeCollection,
    isPaymentFeeAdjustmentsEnabled,
  }

  const quotation = generateQuotationByType(inputs)

  if (payment?.type === 'recurring') {
    quotation.repeat = 'monthly'
  }

  return quotation
}
