import React from 'react'
import { Td } from 'components/table/table-style'
import { Checkbox } from '../../../../legacy/components/inputs/checkbox'

export const RequestCheckBoxCol = ({
  request,
  requestSelected,
  requestDisabled,
  requestSubmitted,
}) => (
  <Td className="icon-col" data-testid="doc-check-box-col">
    <Checkbox
      id={`request-checkbox-${request.id}`}
      value={request.type}
      checked={requestSelected}
      disabled={requestDisabled || requestSubmitted}
      data-testid="doc-check-box"
    />
  </Td>
)
