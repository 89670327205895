import React from 'react'
import get from 'lodash/get'
import styled from 'styled-components'
import { useGateValue } from '@statsig/react-bindings'

import { COLOR } from 'styles/settings'
import NullDataReport from 'components/reports/screening-reports/share/null-report'
import PendingConditionalAcceptance from 'components/reports/screening-reports/share/pending-conditional-acceptance/pending-conditional-acceptance'
import { PendingReport } from 'components/reports/screening-reports/share/pending-report'
import {
  ScreeningSectionHeader,
  ScreeningSubSectionHeader,
  ScreeningSubSectionContainer,
  Label,
  Value,
  ScreeningInfoContentWrapper,
} from 'components/reports/screening-reports/share/responsive-doc-style'
import { isEmptyArray } from 'legacy/components/reports-v2/share/function'
import { convert } from 'helpers/convert'
import { REPORT_TYPES } from 'containers/reports/constants'
import {
  parseRenterData,
  parseTUJsonRecordData,
} from 'components/reports/helpers'
import ScreeningReportTemplate from 'components/reports/screening-reports/shareable/screening-report-template'
import featureFlags from 'constants/feature-flags'
import { EvictionCase } from './eviction-report/eviction-record'
import { REPORT_STATUSES } from '../share/constants'
import PendingAvailability from '../share/pending-availability'

const EvictionSearchTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${COLOR.grayLv2};
  width: 310px;
`
const SearchResultContainer = styled.div`
  border: solid 2px ${COLOR.lightTextGrey};
  border-bottom: solid 1px ${COLOR.lightTextGrey};
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
`
const HeaderInfoLine = styled.div`
  padding-top: 0px;
  min-height: 0px;
  width: 50%;
  display: flex;
  flex-direction: row;
  gap: 10px;
`
export const EvictionHousingRecords = ({
  evictionReport,
  evictionCases,
  fullName,
  completedDate,
}) => (
  <div>
    <ScreeningSectionHeader>Housing Records</ScreeningSectionHeader>
    {!isEmptyArray(evictionCases) ? (
      <div>
        <ScreeningSubSectionHeader color={COLOR.tuDarkOrange}>
          Needs Review
        </ScreeningSubSectionHeader>
        <ScreeningSubSectionContainer color={COLOR.tuOrange}>
          <EvictionSearchHeader completedDate={completedDate} />
          {evictionCases.map((eviction, index) => (
            <EvictionCase
              fullName={fullName}
              eviction={eviction}
              key={index.toString()}
              footnote={get(evictionReport, 'housing.courtReportedAmountNote')}
            />
          ))}
        </ScreeningSubSectionContainer>
      </div>
    ) : (
      <div>
        <ScreeningSubSectionHeader color={COLOR.tuGreen}>
          No Reportable Records
        </ScreeningSubSectionHeader>
        <ScreeningSubSectionContainer color={COLOR.tuGreen}>
          <EvictionSearchHeaderContents completedDate={completedDate} />
        </ScreeningSubSectionContainer>
      </div>
    )}
  </div>
)

export const EvictionSearchHeader = ({ completedDate }) => (
  <SearchResultContainer>
    <EvictionSearchHeaderContents completedDate={completedDate} />
  </SearchResultContainer>
)

export const EvictionSearchHeaderContents = ({ completedDate }) => (
  <ScreeningInfoContentWrapper>
    <EvictionSearchTitle>Housing Records Search</EvictionSearchTitle>
    <HeaderInfoLine>
      <Label width={120}>Completion Date</Label>
      <Value isBold={false}>{convert(completedDate, 'dateOfLocal', 'MM/DD/YYYY')}</Value>
    </HeaderInfoLine>
  </ScreeningInfoContentWrapper>
)

export default function EvictionReportComponent({
  applicationCreationDate,
  customBorder,
  customMargin,
  evictionRecords,
  generatedOn,
  match,
  rentalDetail,
  renter,
  reportViewingLocked = false,
  handleConditionalAcceptance,
  userType,
  reportName,
}) {
  const property = get(rentalDetail, 'property')
  const { fullName } = parseRenterData(renter)
  const { reportNumber, completedDate, status, searchCompletedDate, evictions } = parseTUJsonRecordData(evictionRecords)
  const reportInProcess = !evictionRecords || status === REPORT_STATUSES.IN_PROCESS
  const applyCriminalMigrationV2 = useGateValue(featureFlags.applyCriminalMigrationV2)

  if (reportViewingLocked) {
    return (
      <PendingConditionalAcceptance
        match={match}
        reportType={REPORT_TYPES.EVICTION}
        handleConditionalAcceptance={handleConditionalAcceptance}
      />
    )
  }

  if (reportInProcess) {
    if (!applyCriminalMigrationV2) {
      return <PendingReport reportType={REPORT_TYPES.EVICTION} userType={userType} match={match} />
    }
    return <PendingAvailability reportTypes={[REPORT_TYPES.EVICTION]} match={match} />
  }

  if (!evictions) {
    return <NullDataReport generatedOn={generatedOn} headerDoc={reportName} />
  }

  return (
    <ScreeningReportTemplate
      customBorder={customBorder}
      customMargin={customMargin}
      applicant={renter}
      reportNumber={reportNumber}
      completedDate={completedDate}
      orderedOn={applicationCreationDate}
      packageName="Criminal and Public Record"
      property={property}
      reportType={REPORT_TYPES.EVICTION}
    >
      <EvictionHousingRecords
        evictionReport={evictionRecords}
        evictionCases={evictions}
        fullName={fullName}
        completedDate={searchCompletedDate}
      />
    </ScreeningReportTemplate>
  )
}
