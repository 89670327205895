import isEmpty from 'lodash/isEmpty'

import {
  PERMALINK_ACTIONS_ID,
  LEASE_AGREEMENT_REQUEST,
  LEASE_AGREEMENT_LIST,
  SINGLE_PROPERTY_APPLICATION_LIST,
  RENT_PAYMENT_PROPERTY_PAGE,
  CREATE_LISTING_INFORMATION_STEP,
  CREATE_LEASING_INFORMATION_STEP,
  CREATE_UTILITIES_AMENITIES_STEP,
  CREATE_LISTING_DESCRIPTION_STEP,
  CREATE_PREVIEW_STEP,
  LISTING_MANAGE,
  IMPORT_MLS_LISTING,
  RENT_PAYMENT_SELECT_PAYMENT_RECEIVER,
} from 'constants/route'
import {
  EVENT_REQUEST_SCREENING,
  EVENT_ACTION_DROPDOWN,
  LEASE_AGREEMENTS,
  RENT_PAYMENT,
  EVENT_TENANT_SCREENING,
} from 'tracker/const'

import {
  E_SIGNATURE_FEATURE_ID,
  RENT_PAYMENTS_ID,
  LISTING_MANAGEMENT_FEATURE_ID,
} from 'utils/feature-toggle/constants'

import { CREATE_LISTING_SOURCE } from 'containers/constants'
import { IMPORT_LISTING_STATUS } from 'containers/import-listing/constants'
import { stringify } from '@rentspree/path'
import { LISTING_STATUS_STATE } from '../create-listing/const'

function buildActionEvent(eventName, propertyName, otherProperties = {}) {
  return {
    name: eventName,
    properties: {
      [propertyName]: 'listing_overview',
      ...otherProperties,
    },
  }
}

export const createListingLink = (listing, queryString = {}) => {
  const {
    status,
    id,
    slug,
    importStatus,
    utilitiesAndAmenities = undefined,
    leasingInformation = undefined,
    description = undefined,
  } = listing || {}
  const link = path =>
    `${path}${stringify({
      source: CREATE_LISTING_SOURCE.PROPERTY_OVERVIEW,
      ...queryString,
    })}`

  if (!status) return link(IMPORT_MLS_LISTING)

  // NOTE: Have 2 conditions because getting property by id endpoint does not call to listing-service like getting property list endpoint
  const isPublished = status === LISTING_STATUS_STATE.PUBLISHED || !!listing.publishedInformation
  if (isPublished) return link(LISTING_MANAGE)

  const isImported = !id || !slug || importStatus === IMPORT_LISTING_STATUS.IMPORTED
  if (isImported) return link(CREATE_LISTING_INFORMATION_STEP)

  const hasLeasing = !isEmpty(leasingInformation)
  if (!hasLeasing) return link(CREATE_LEASING_INFORMATION_STEP)

  const hasUtil = !!utilitiesAndAmenities
  if (!hasUtil) return link(CREATE_UTILITIES_AMENITIES_STEP)

  const hasDescription = !!description
  if (!hasDescription) return link(CREATE_LISTING_DESCRIPTION_STEP)

  const isDrafted = status === LISTING_STATUS_STATE.DRAFT
  if (isDrafted) return link(CREATE_PREVIEW_STEP)

  return link(IMPORT_MLS_LISTING)
}

export const LISTING_AGENT_MOVE_IN_FEE_COLLECTION_FEATURE_ID = 'listingAgentMoveInFeeCollection'
export const FEE_COLLECTION_FEATURE_ID = 'feeCollection'
export const PAYMENT_COLLECTION_DESC =
  'Securely collect and track all of your payments online—rent, deposit, and one-time fees. Easy to use for agents and landlords.'
export const LISTING_MANAGEMENT_DESCRIPTION = {
  UNAUTHORIZED:
    'Manage all things related to your property listing page. Edit, archive, and enable or disable tenant screening.',
  DISABLED: 'Easily market your rental for free across major listing sites to attract more leads.',
  ENABLED:
    'Update listing details, customize screening preferences, and manage contact information.',
}

export const FEATURES = [
  {
    title: 'Tenant screening',
    description:
      'Access TransUnion credit reports, background checks, and eviction related proceedings with our rental application. <br/>Learn about TransUnion’s <a href="https://support.rentspree.com/en/screening-restrictions-and-limitations">screening restrictions.</a>',
    query: { screeningMethod: 'email' },
    id: 'tenantScreening',
    disabledFeatureIds: ['tenantScreening'],
    titlePath: SINGLE_PROPERTY_APPLICATION_LIST,
    togglePropertyFeatureSettings: {
      tooltip: {
        mobileTitle: 'Enable/Disable Tenant Screening',
        title: '',
        description:
          'Turn ON to accept new applicants.\nTurn OFF to stop accepting new applicants.',
        position: 'TOP',
      },
    },
    btnBuilder: ({ isView, notificationNumber, isTogglePropertyFeature }) =>
      isView
        ? {
          btn: {
            text: 'View reports',
            type: 'white',
            id: 'viewReportBTN',
            path: SINGLE_PROPERTY_APPLICATION_LIST,
            notificationNumber,
            event: buildActionEvent(EVENT_REQUEST_SCREENING.CLICK_SCREENING_MENU, 'click_from'),
          },
          subBtn: {
            text: 'Screen new tenants',
            id: 'screenNewTenantsSubBTN',
            path: PERMALINK_ACTIONS_ID,
            isTogglePropertyFeature,
            event: [
              buildActionEvent(
                EVENT_TENANT_SCREENING.EVENT_NAME.START_SCREENING_REQUEST,
                'click_from',
                { has_screening: true },
              ),
              buildActionEvent(EVENT_ACTION_DROPDOWN.SCREEN_FROM_CURRENT_PROPERTY, 'click_from'),
            ],
          },
        }
        : {
          btn: {
            text: 'Screen new tenants',
            type: isTogglePropertyFeature ? 'white' : 'primary',
            path: PERMALINK_ACTIONS_ID,
            icon: 'far fa-plus',
            id: 'screenNewTenantsBTN',
            isTogglePropertyFeature,
            event: [
              buildActionEvent(
                EVENT_TENANT_SCREENING.EVENT_NAME.START_SCREENING_REQUEST,
                'click_from',
                { has_screening: false },
              ),
              buildActionEvent(EVENT_ACTION_DROPDOWN.SCREEN_FROM_CURRENT_PROPERTY, 'click_from'),
            ],
          },
        },
    titleLinkEvent: buildActionEvent(EVENT_REQUEST_SCREENING.CLICK_SCREENING_MENU, 'click_from'),
  },
  {
    featureId: LISTING_MANAGEMENT_FEATURE_ID,
    title: 'Listing',
    description: LISTING_MANAGEMENT_DESCRIPTION.DISABLED,
    id: 'listingManagement',
    disabledFeatureIds: ['listingManagement'],
    titlePath: LISTING_MANAGE,
    shouldDisplayToggleListingSyndication: true,
    isListingSyndicationEnabled: false,
    btnBuilder: ({ optionProps }) => {
      const { propertyListing } = optionProps || {}
      const listingLink = createListingLink(propertyListing)

      switch (propertyListing?.status) {
        case LISTING_STATUS_STATE.PUBLISHED:
          return {
            btn: {
              text: 'Manage listing',
              type: 'white',
              path: listingLink,
              id: 'manageListingBTN',
            },
          }
        case LISTING_STATUS_STATE.DRAFT: {
          return {
            btn: {
              text: 'Finish listing',
              type: 'white',
              path: listingLink,
              id: 'continueListingBTN',
            },
          }
        }
        default: {
          return {
            btn: {
              text: 'Market property',
              type: 'primary',
              path: listingLink,
              icon: 'far fa-plus',
              id: 'newListingBTN',
            },
          }
        }
      }
    },
  },
  {
    featureId: E_SIGNATURE_FEATURE_ID,
    title: 'Lease agreement',
    description: 'Upload and send lease documents for digital signature through RentSpree.',
    id: 'eSignDocuments',
    disabledFeatureIds: ['eSignDocuments'],
    titlePath: LEASE_AGREEMENT_LIST,
    btnBuilder: ({ isView, notificationNumber }) =>
      isView
        ? {
          btn: {
            text: 'View documents',
            type: 'white',
            path: LEASE_AGREEMENT_LIST,
            notificationNumber,
            id: 'viewDocumentsBTN',
            event: buildActionEvent(LEASE_AGREEMENTS.CLICK_LEASE_MENU, 'click_from'),
          },
          subBtn: {
            icon: 'far fa-plus',
            text: 'Set up lease',
            path: LEASE_AGREEMENT_REQUEST,
            id: 'newTractionSubBTN',
            event: buildActionEvent(LEASE_AGREEMENTS.CREATE_LEASE, 'click_from'),
          },
        }
        : {
          btn: {
            text: 'Set up lease',
            type: 'primary',
            path: LEASE_AGREEMENT_REQUEST,
            icon: 'far fa-plus',
            id: 'requestSignatureBTN',
            event: buildActionEvent(LEASE_AGREEMENTS.CREATE_LEASE, 'click_from'),
          },
        },
    titleLinkEvent: buildActionEvent(LEASE_AGREEMENTS.CLICK_LEASE_MENU, 'click_from'),
  },
  {
    title: 'Payment collection',
    description: PAYMENT_COLLECTION_DESC,
    id: 'rentalPayments',
    disabledFeatureIds: ['rentalPayments'],
    titlePath: RENT_PAYMENT_PROPERTY_PAGE,
    featureId: RENT_PAYMENTS_ID,
    btnBuilder: ({ isView, notificationNumber, optionProps }) =>
      isView
        ? {
          btn: {
            text: 'View payments',
            type: 'white',
            path: `${RENT_PAYMENT_PROPERTY_PAGE}?source=property-page`,
            notificationNumber,
            id: 'viewRentPaymentBTN',
            event: buildActionEvent(RENT_PAYMENT.EVENT_NAME.ENTER_RENT_PAYMENT_MENU, 'source'),
          },
          subBtn: {
            text: 'Collect payments',
            path: `${RENT_PAYMENT_SELECT_PAYMENT_RECEIVER}?property=${optionProps?.property?._id}&source=${RENT_PAYMENT.EVENT_PROPERTY.CLICK_FROM.PROPERTY_OVERVIEW}`,
            id: 'newRentPaymentBTN',
            event: buildActionEvent(
              RENT_PAYMENT.EVENT_NAME.CLICK_SETUP_RENT_PAYMENT,
              'click_from',
              {
                user_type: RENT_PAYMENT.EVENT_PROPERTY.USER_TYPE.LANDLORD,
                optimized_flow: optionProps?.isLandlordOptimizationFlow,
                is_first_time_user: optionProps?.isFirstTimeRentPaymentUser,
              },
            ),
          },
        }
        : {
          btn: {
            icon: 'far fa-plus',
            text: 'Collect payments',
            path: `${RENT_PAYMENT_SELECT_PAYMENT_RECEIVER}?property=${optionProps?.property?._id}&source=${RENT_PAYMENT.EVENT_PROPERTY.CLICK_FROM.PROPERTY_OVERVIEW}`,
            type: 'primary',
            id: 'rentPaymentBTN',
            event: buildActionEvent(
              RENT_PAYMENT.EVENT_NAME.CLICK_SETUP_RENT_PAYMENT,
              'click_from',
              {
                user_type: RENT_PAYMENT.EVENT_PROPERTY.USER_TYPE.LANDLORD,
                optimized_flow: optionProps?.isLandlordOptimizationFlow,
                is_first_time_user: optionProps?.isFirstTimeRentPaymentUser,
              },
            ),
          },
        },
    titleLinkEvent: buildActionEvent(RENT_PAYMENT.EVENT_NAME.ENTER_RENT_PAYMENT_MENU, 'source'),
  },
]

export const OLD_LISTING_MANAGEMENT_FEATURE = {
  featureId: LISTING_MANAGEMENT_FEATURE_ID,
  title: 'Listing',
  description: LISTING_MANAGEMENT_DESCRIPTION.UNAUTHORIZED,
  id: 'listingManagement',
  disabledFeatureIds: ['listingManagement'],
  titlePath: LISTING_MANAGE,
  btnBuilder: ({ optionProps }) => {
    const { propertyListing } = optionProps || {}
    const listingLink = createListingLink(propertyListing)

    switch (propertyListing?.status) {
      case LISTING_STATUS_STATE.PUBLISHED:
        return {
          btn: {
            text: 'Manage listing',
            type: 'white',
            path: listingLink,
            id: 'manageListingBTN',
          },
        }
      case LISTING_STATUS_STATE.DRAFT: {
        return {
          btn: {
            text: 'Finish listing',
            type: 'white',
            path: listingLink,
            id: 'continueListingBTN',
          },
        }
      }
      default: {
        return {
          btn: {
            text: 'Create listing page',
            type: 'primary',
            path: listingLink,
            icon: 'far fa-plus',
            id: 'newListingBTN',
          },
        }
      }
    }
  },
}
