import { CDN_URL } from '../env'

export const getPartnerLogoDashboardSrc = subdomain =>
  subdomain && typeof subdomain === 'string' && subdomain !== 'rentspree'
    ? `${CDN_URL}/static-files/logos/${subdomain}-logo.png`
    : ''

/**
 * Extracts the subdomain from the current window location.
 * This function returns `rentspree` if the subdomain does not exist.
 * Handles various environment-specific domains and normalizes them.
 *
 * @returns The subdomain string from the current host
 */
export const getSubdomain = () => {
  const host = window.location.host
    .replace('localhost:3000', 'rentspree.dev')
    .replace('dev.rentspree', 'rentspree')
    .replace('uat.rentspree', 'rentspree')
    .replace('www.rentspree', 'rentspree')
    .replace('prelogin.rentspree', 'rentspree')

  return host.split('.')[0]
}
