import tracker from 'tracker'
import { OVERHAUL_RENT_PAYMENT } from 'tracker/const'

export const trackClick = (eventName, additionalProps) => {
  tracker.trackEvent(eventName, {
    page_url: window.location.href,
    main_feature: OVERHAUL_RENT_PAYMENT.EVENT_PROPERTY.MAIN_FEATURE.RENT_PAYMENT,
    ...additionalProps,
  })
}
