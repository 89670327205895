export const DASHBOARD_OVERVIEW = '/dashboard/overview'
export const SUBMIT_RENTAL_CALL = 'SUBMIT_RENTAL_CALL'
export const SUBMIT_RENTAL_REQUEST = 'SUBMIT_RENTAL_REQUEST'
export const SUBMIT_RENTAL_SUCCESS = 'SUBMIT_RENTAL_SUCCESS'
export const SUBMIT_RENTAL_FAILED = 'SUBMIT_RENTAL_FAILED'
export const SUBMIT_RENTAL_API_URL = '/api/v2/rental-submissions/:rentalId/submit'

export const MOBILE_THRESHOLD = 768

// TODO_EMILY: remove from here, make those places reference the router instead
export const CREATE_AGENT_REVIEW_PLACEHOLDER_TIMEOUT = 10000
export const DOCUMENT_UPLOAD_PATH = '/apply/:rentalAppId/documents'
export const INCOME_VERIFICATION_PATH = '/apply/:rentalAppId/income-verification'
