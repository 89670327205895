import { DialogContentText, Box as MuiBox, Stack as MuiStack } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@rentspree/component-2023.components.atoms.button'
import Dialog from '@rentspree/component-2023.components.atoms.dialog'
import DialogTitle from '@rentspree/component-2023.components.atoms.dialog-title'
import Sheet, { SheetHeader } from '@rentspree/component-2023.components.templates.sheet'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import IconButton from '@rentspree/component-2023.components.atoms.icon-button'
import VideocamIcon from '@mui/icons-material/Videocam'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import React from 'react'

import tracker from 'tracker'
import { ONBOARDING_PARTNER_AGENT_EVENT } from 'tracker/const'
import { useScreenSize } from 'hooks/use-screen-size'

export const OnboardingPartnerAgentVideoContentModal = ({
  isOpen,
  onClose,
  onClickScreenTenants,
  onClickViewSampleReport,
}) => {
  const [isWatch, setIsWatch] = React.useState(false)
  const onClickPlayButton = () => {
    setIsWatch(true)
    tracker.trackEvent(ONBOARDING_PARTNER_AGENT_EVENT.EVENT_NAME.CLICK_PLAY_ONBOARDING_AGENT_VIDEO)
  }

  const handleClose = () => {
    setIsWatch(false)
    onClose()
  }

  const title = 'Quick guide to tenant screening'
  const contentText =
    "Let's cover everything you need to start screening with RentSpree — from sharing your link to receiving your first report."

  const { isDesktop } = useScreenSize()

  return isDesktop ? (
    <Dialog
      data-testid="onboarding-partner-agent-video-content-modal"
      open={isOpen}
      onClose={handleClose}
      size="large"
    >
      <DialogTitle size="large" handleOnClose={handleClose}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <DialogContent>
        <MuiStack direction="row" gap="8px" sx={{ color: 'var(--rsp_ref_palette_neutral100_74p)' }}>
          <VideocamIcon sx={{ fontSize: '24px' }} />
          <Typography variant="body-medium">1 min video</Typography>
        </MuiStack>
      </DialogContent>
      <DialogContent sx={{ position: 'relative' }}>
        <VideoContent margin="8px 24px" isWatch={isWatch} onClickPlayButton={onClickPlayButton} />
      </DialogContent>
      <DialogActions sx={{ gap: '16px' }} disableSpacing>
        <Button
          data-testid="view-sample-report-button"
          variant="text"
          color="secondary"
          className="underline"
          onClick={onClickViewSampleReport}
        >
          View sample report
        </Button>
        <Button
          data-testid="screen-tenants-button"
          variant="contained"
          color="secondary"
          onClick={onClickScreenTenants}
        >
          Screen tenants
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <Sheet
      data-testid="onboarding-partner-agent-video-content-modal"
      open={isOpen}
      onClose={handleClose}
      showCloseButton
      actionSection={
        <MuiBox display="flex" flexDirection="column" gap="16px">
          <Button
            data-testid="screen-tenants-button"
            variant="contained"
            fullWidth
            color="secondary"
            onClick={onClickScreenTenants}
          >
            Screen tenants
          </Button>
          <Button
            data-testid="view-sample-report-button"
            variant="text"
            color="secondary"
            fullWidth
            className="underline"
            onClick={onClickViewSampleReport}
          >
            View sample report
          </Button>
        </MuiBox>
      }
    >
      <SheetHeader header={title} />
      <Typography padding="16px" variant="body-medium" component="div">
        {contentText}
      </Typography>
      <MuiStack
        padding="8px 16px"
        direction="row"
        gap="8px"
        sx={{
          color: 'var(--rsp_ref_palette_neutral100_74p)',
        }}
      >
        <VideocamIcon sx={{ fontSize: '24px' }} />
        <Typography variant="body-medium" component="div">
          1 min video
        </Typography>
      </MuiStack>
      <MuiBox padding="8px 16px" marginBottom="16px" position="relative">
        <VideoContent margin="8px 16px" isWatch={isWatch} onClickPlayButton={onClickPlayButton} />
      </MuiBox>
    </Sheet>
  )
}

const VideoContent = ({ margin, isWatch, onClickPlayButton }) =>
  !isWatch ? (
    <>
      <MuiBox
        data-testid="onboarding-partner-agent-play-button"
        onClick={onClickPlayButton}
        position="absolute"
        margin={margin}
        display="flex"
        justifyContent="center"
        alignItems="center"
        top={0}
        bottom={0}
        left={0}
        right={0}
      >
        <IconButton size="medium" color="black">
          <PlayCircleIcon />
        </IconButton>
      </MuiBox>
      <img
        src="https://img.youtube.com/vi/jJVFjTBz2vA/maxresdefault.jpg"
        alt="video cover"
        style={{ objectFit: 'cover', maxWidth: '100%' }}
      />
    </>
  ) : (
    <iframe
      src="https://www.youtube.com/embed/jJVFjTBz2vA?autoplay=1"
      title="Getting started with RentSpree: A quick guide to tenant screening"
      allow="autoplay"
      width="100%"
      height="100%"
      style={{
        aspectRatio: '16/9',
      }}
      frameBorder="0"
      allowFullScreen
    />
  )
