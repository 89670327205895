import axios from 'axios'
import { axiosResponse } from '@rentspree/axios'
import get from 'lodash/get'
import getHandleAuthorization from '@rentspree/axios-refresh-token'
import { request } from 'utils/api-interceptor'
import { DOMAIN_NAME, USER_API_URL, API_URL } from 'env'
import { sweetAlert, ALERT_PRESET } from 'legacy/libs/sweet-alert/sweet-alert'
import { API_ERRORS } from 'constants/error-messages'

/* This is a property API interceptor that use for the legacy get property API only (see property.api.js)

  Please do not use it in any where else
*/

const propertyHandleAuthorization = getHandleAuthorization({
  afterRetry: error => Promise.reject(get(error, 'response', error)),
  afterRefreshTokenFailure: error => Promise.reject(get(error, 'response', error)),
  domain: DOMAIN_NAME,
  authAPIBase: USER_API_URL,
})

async function propertyHandleApiErrors(error) {
  const statusCode = get(error, 'status')
  switch (statusCode) {
    case 404:
    case 401:
      sweetAlert(ALERT_PRESET.UNAUTHORIZED, API_ERRORS[401].option, API_ERRORS[401].callback)
      break
    case 500:
      sweetAlert(ALERT_PRESET.ERROR, API_ERRORS[500].option, API_ERRORS[500].callback)
      break
    default:
      break
  }
  return Promise.reject(error)
}

const propertyAPIInstance = axios.create({
  baseURL: API_URL,
})

propertyAPIInstance.interceptors.request.use(request)

propertyAPIInstance.interceptors.response.use(axiosResponse.response, error =>
  propertyHandleAuthorization(error),
)

propertyAPIInstance.interceptors.response.use(
  r => r,
  error => propertyHandleApiErrors(error),
)
export { propertyAPIInstance }
