import React from 'react'
import styled from 'styled-components'
import Button from '@rentspree/component-2023.components.atoms.button'
import Link from '@rentspree/component-2023.components.atoms.link'

import { breakpoints } from 'styles/settings'

export { SecondaryContainedButton, MessageBody, SecondaryTextButton, TabSubtitle }

const conditionalAcceptanceInfoUrl = 'https://support.rentspree.com/en/conditional-acceptance'

const SecondaryContainedButton = ({ text = '', handleClick, sx = {} }) => (
  <Button variant="contained" color="secondary" size="small" onClick={handleClick} sx={sx}>
    {text}
  </Button>
)

const SecondaryTextButton = ({ text = '', handleClick, sx = {} }) => (
  <Button
    variant="text"
    size="medium"
    color="secondary"
    className="underline"
    onClick={handleClick}
    sx={sx}
  >
    {text}
  </Button>
)

const TabSubtitle = ({ variant = {} }) => (
  <p>
    By continuing, you confirm that this applicant has been conditionally accepted, following{' '}
    <Link variant={variant} target="_blank" href={conditionalAcceptanceInfoUrl} color="black">
      local regulations.
    </Link>
  </p>
)

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  margin-top: 92px;
  margin-bottom: 92px;

  * {
    max-width: 500px;
  }

  p {
    margin: 0;
    font-size: 18px;
    font-family: 'Source Sans Pro';
    word-break: break-word;
    word-wrap: break-word;
    text-align: ${props => props.textAlignment};
    line-height: 24px;
  }

  .title {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
  }

  ${breakpoints.mobile(`
    margin-top: 24px;
    margin-bottom: 24px;
    font-family: Inter;
    padding: 16px;
    
    .title {
      font-size: 36px;
      line-height: 42px;
    }
    
    p {
      font-size: 18px;
      line-height: 24px;
    }
  `)}
`

const MessageBody = ({
  imgSrc = '',
  imgAltText = '',
  title = '',
  subtitle = '',
  buttons = null,
  textAlignment = 'center',
}) => (
  <StyledDiv textAlignment={textAlignment}>
    {imgSrc ? <img src={imgSrc} alt={imgAltText} /> : null}
    <div>
      <p className="title">{title}</p>
      {subtitle}
    </div>
    {buttons ? <>{buttons}</> : null}
  </StyledDiv>
)
