import React from 'react'

import ShareableCreditReport from '../screening-reports/shareable/credit-report'
import ShareableCriminalReport from '../screening-reports/shareable/criminal'
import ShareableEvictionReport from '../screening-reports/shareable/eviction'

const tenantScreeningReports = [
  {
    type: 'credit_report',
    title: 'Credit Report & Score',
    isAvailable: false,
    content: (
      <ShareableCreditReport
        data-testid="apply_reportReview_credit_reportReport"
        showHeaderComponent={false}
        customMargin="0"
      />
    ),
  },
  {
    type: 'criminal',
    title: 'Background Check',
    isAvailable: false,
    content: (
      <ShareableCriminalReport
        data-testid="apply_reportReview_criminalReport"
        showHeaderComponent={false}
        customMargin="0"
      />
    ),
  },
  {
    type: 'eviction',
    title: 'Eviction-Related Proceedings',
    isAvailable: false,
    content: (
      <ShareableEvictionReport
        data-testid="apply_reportReview_evictionReport"
        showHeaderComponent={false}
        customMargin="0"
      />
    ),
  },
]

export const getReportAvailability = applicantReports => {
  const isCreditAvailable = applicantReports.CreditReport?.length > 0
  const isEvictionAvailable = applicantReports.EvictionRecords?.length > 0
  const isCriminalAvailable = applicantReports.CriminalRecords?.length > 0
  return { isCreditAvailable, isEvictionAvailable, isCriminalAvailable }
}

// gets report info + report availability for any reports that were requested by the landlord/agent
export const getReportsForReview = ({ screeningOptions = {}, applicantReports = {} }) => {
  const { isCreditAvailable, isCriminalAvailable, isEvictionAvailable } =
    getReportAvailability(applicantReports)

  const reportAvailability = {
    credit_report: isCreditAvailable,
    criminal: isCriminalAvailable,
    eviction: isEvictionAvailable,
  }

  return tenantScreeningReports
    .filter(report => screeningOptions[report.type] === true)
    .map(requestedReport => ({
      ...requestedReport,
      isAvailable: reportAvailability[requestedReport.type],
    }))
}
