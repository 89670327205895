import React from 'react'
import swal2 from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { LoadingModalContent } from 'components/modal/loading-modal-content'

const sweetalert2 = withReactContent(swal2)

const customClassProps = {
  htmlContainer: 'custom-htmlcontainer',
  title: 'custom-title',
  actions: 'custom-actions',
  popup: 'custom-popup',
}

export const openLoadingModal = ({ Content = LoadingModalContent, options = {}, ...rest }) => {
  sweetalert2.close()
  // let timerInterval
  sweetalert2.fire({
    html: <Content {...rest} />,
    showConfirmButton: false,
    allowEscapeKey: false,
    padding: '20px',
    width: '350px',
    height: '100%',
    scrollbarPadding: false,
    allowOutsideClick: false,
    ...options,
  })
}

export const closeSweetAlertModal = () => {
  sweetalert2.close()
}

export const openSuccessModalPromise = ({ title, subtitle, mixin, options, ...rest }) => {
  const modal = mixin ? sweetalert2.mixin(mixin) : sweetalert2
  modal.close()
  return modal.fire({
    padding: '20px',
    width: '350px',
    height: '100%',
    icon: 'success',
    title,
    text: subtitle,
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    ...options,
    ...rest,
  })
}

export const openSuccessModal = ({ title, subtitle, mixin, options, ...rest }) => {
  const modal = mixin ? sweetalert2.mixin(mixin) : sweetalert2
  modal.close()
  modal.fire({
    padding: '20px',
    width: '350px',
    height: '100%',
    icon: 'success',
    title,
    text: subtitle,
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    ...options,
    ...rest,
  })
}

export const openErrorModal = ({ title, subtitle, mixin, options, ...rest }) => {
  const modal = mixin ? sweetalert2.mixin(mixin) : sweetalert2
  modal.close()
  return modal.fire({
    padding: '20px',
    width: '350px',
    height: '100%',
    icon: 'error',
    title,
    text: subtitle,
    showConfirmButton: true,
    customClass: {
      confirmButton: 'custombutton custombutton-error',
      closeButton: 'custom-closebutton',
      ...customClassProps,
    },
    ...options,
    ...rest,
  })
}

export const openWarningModalPromise = ({ title, subtitle, mixin, options, ...rest }) => {
  const modal = mixin ? sweetalert2.mixin(mixin) : sweetalert2
  modal.close()
  return modal.fire({
    padding: '20px',
    width: '350px',
    height: '100%',
    icon: 'warning',
    title,
    text: subtitle,
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    ...options,
    ...rest,
  })
}

export default sweetalert2
