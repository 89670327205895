import React from 'react'
import get from 'lodash/get'
import size from 'lodash/size'
import {
  sumArray,
  groupTradelines,
  creditLimitPercent,
  pluralizeWordOnlyUnit,
  isTradelineClosed,
} from 'legacy/components/reports-v2/share/function'
import {
  PanelArea,
  TradelineGroupPaymentHistory,
  TradelineGroupPaymentSummary,
  Table,
} from 'legacy/components/reports-v2/share/doc-components'
import { TableRow, TableHeader } from 'legacy/components/reports-v2/share/doc-style'
import {
  INDUSTRY_CODES,
  ACCOUNT_TYPE,
  REMARK_CODES,
  LOAN_TYPE_CODES,
  ACCOUNT_RAILING_CODES,
  ACCOUNT_DESIGNATOR,
} from 'legacy/constants/credit-report-code'
import { convert } from 'legacy/components/helper/convert'
import { COLOR, breakpoints } from 'styles/settings'
import styled from 'styled-components'
import {
  InformationLine,
  SectionHeader,
  SubSectionHeader,
} from 'components/reports/screening-reports/share/responsive-doc-components'
import {
  Content,
  InformationPanel,
  Section,
  SubSection,
} from 'components/reports/screening-reports/share/responsive-doc-style'
import { REMOVE_HTML_TAG_FROM_STRING_REGEX } from 'components/reports/screening-reports/share/constants'
import {
  AccountHeader,
  CreditDetailContainer,
  CountBox,
  TotalCountBoxesContainer,
  CreditDetailInformationLine,
  BalanceDetailInformationLine,
  TableCell,
} from './shared'

export const TradelineSummary = ({ path, profileSum }) => (
  <TradelineTableRow>
    {displayTradeLineValue(get(profileSum, `${path}.count`))}
    {displayTradeLineValue(get(profileSum, `${path}.highCredit`), '$')}
    {displayTradeLineValue(get(profileSum, `${path}.creditLimit`), '$')}
    {displayTradeLineValue(get(profileSum, `${path}.balance`), '$')}
    {displayTradeLineValue(get(profileSum, `pastDueItems.${path}PastDue`), '$')}
    {displayTradeLineValue(get(profileSum, `${path}.monthlyPayment`), '$')}
    {displayTradeLineValue(get(profileSum, `${path}.percentCreditAvail`), '%')}
  </TradelineTableRow>
)

const TradelineTableCell = styled(TableCell)`
  text-align: right;

  ${breakpoints.mobileReport(`
    text-align: center;
  `)}
`

const TradelineTableCellContent = styled.div`
  border-bottom: 0.5px solid ${COLOR.borderGray};
  padding: 0 8px;
`

const displayTradeLineValue = (value, type) => (
  <TradelineTableCell padding="0">
    <TradelineTableCellContent>{value ? convert(value, type) : 'N/A'}</TradelineTableCellContent>
  </TradelineTableCell>
)

export const Trade = ({ index, tradeline }) => {
  const { balanceAmount, creditLimit } = tradeline
  const balance = balanceAmount ? convert(balanceAmount, '$') : 'N/A'
  const detail = (
    balanceAmount && creditLimit ? creditLimitPercent(balanceAmount, creditLimit) : 'N/A'
  ).concat(' of Credit Limit')

  const BoxHeaderProps = {
    detail,
    balance,
    isActive: !isTradelineClosed(tradeline),
    title: `${index + 1}. ${tradeline.subscriberName}`,
    status: isTradelineClosed(tradeline) ? 'Closed' : 'Active',
  }

  const accountType = ACCOUNT_TYPE[tradeline.accountType] || tradeline.accountType
  const loanTermFormatList = []
  if (accountType) {
    loanTermFormatList.push(accountType)
  }
  if (tradeline.terms) {
    loanTermFormatList.push(tradeline.terms)
  }
  if (tradeline.termsFrequencyOfPayment) {
    loanTermFormatList.push(tradeline.termsFrequencyOfPayment)
  }
  const loanTermText = loanTermFormatList.join(' ') || null

  return (
    <Content>
      <InformationPanel>
        <AccountHeader
          title={BoxHeaderProps.title}
          status={BoxHeaderProps.status}
          balance={BoxHeaderProps.balance}
          detail={BoxHeaderProps.detail}
        />
        <CreditDetailContainer>
          <InformationPanel>
            <CreditDetailInformationLine
              label="Last Reported"
              value={convertToMMMYYYY(tradeline.dateReported)}
            />
            <CreditDetailInformationLine
              label="Account Designator"
              data-testid="account-designator"
              value={ACCOUNT_DESIGNATOR[tradeline.accountDesignator] || tradeline.accountDesignator}
            />
            <CreditDetailInformationLine
              label="Industry"
              value={INDUSTRY_CODES[tradeline.industryCode] || tradeline.industryCode}
            />
            <CreditDetailInformationLine
              label="Loan Type"
              value={LOAN_TYPE_CODES[tradeline.loanType] || tradeline.loanType}
            />
            <CreditDetailInformationLine
              label="Credit Limit"
              value={convert(tradeline.creditLimit, '$')}
            />
            <CreditDetailInformationLine label="Loan Term" value={loanTermText} />
            <CreditDetailInformationLine
              label="Payment Amount"
              value={convert(tradeline.termsAmountOfPayment, '$')}
            />
            <CreditDetailInformationLine
              label="Highest Balance"
              value={convert(tradeline.highCredit, '$')}
            />
            <CreditDetailInformationLine
              label="Status"
              value={ACCOUNT_RAILING_CODES[tradeline.currentMOP] || tradeline.currentMOP}
            />
            <CreditDetailInformationLine
              label="Past Due"
              value={convert(tradeline.amountPastDue, '$')}
              type="$"
            />
            <CreditDetailInformationLine
              label="Remarks"
              value={REMARK_CODES[tradeline.remarksCode] || tradeline.remarksCode}
            />
            <CreditDetailInformationLine
              label="Notes"
              value={
                tradeline.notes
                  ? tradeline.notes.replace(REMOVE_HTML_TAG_FROM_STRING_REGEX, '')
                  : '-'
              }
            />
          </InformationPanel>
          <InformationPanel>
            <SubSection>
              <CreditDetailInformationLine
                label="Opened Date"
                value={convertToMMMYYYY(tradeline.dateOpened)}
                stripe={false}
              />
              <CreditDetailInformationLine
                label="Closed Date"
                value={convertToMMMYYYY(tradeline.dateClosed)}
                stripe={false}
              />
              <CreditDetailInformationLine
                label="Paid out Date"
                value={convertToMMMYYYY(tradeline.datePaidOut)}
                stripe={false}
              />
              <CreditDetailInformationLine
                label="Verified Date"
                value={convertToMMMYYYY(tradeline.dateVerified)}
                stripe={false}
              />
            </SubSection>
            <SubSection>
              <InformationLine label="Payment History" />
              <TradelineGroupPaymentHistory
                paymentPatternStartDate={tradeline.paymentPatternStartDate}
                paymentPattern={get(tradeline, 'paymentPattern', '')}
              />
            </SubSection>
            <InformationLine
              label={`Late Payment Summary (${size(get(tradeline, 'paymentPattern', ''))} months)`}
              labelWidth="100%"
            />
            <TradelineGroupPaymentSummary paymentPattern={get(tradeline, 'paymentPattern', '')} />
          </InformationPanel>
        </CreditDetailContainer>
      </InformationPanel>
    </Content>
  )
}

const convertType = 'date'
const convertFormat = 'MMM, YYYY'
const convertToMMMYYYY = date => convert(date, convertType, convertFormat)

const TradelineSummaryTableTitles = {
  header: ['Count', 'High Credit', 'Credit Limit', 'Balance', 'Past Due', 'Payment', 'Available'],
  body: [
    { title: 'Revolving', path: 'revolving' },
    { title: 'Installment', path: 'installment' },
    { title: 'Mortgage', path: 'mortgage' },
    { title: 'Open', path: 'open' },
    { title: 'Closed w Bal', path: 'closedWithBal' },
    { title: 'Total', path: 'total' },
  ],
}

const TradelineSummaryTable = styled(Table)`
  width: 100%;
  ${breakpoints.mobileReport(`
    min-width: 700px;
  `)}
`

const TradelineSummaryPanel = styled(PanelArea)`
  display: flex;
  align-items: flex-start;
`

const TradelineSummaryTableContainer = styled.div`
  width: 100%;
  ${breakpoints.mobileReport(`
    overflow-x: scroll;
  `)}

  @media print {
    overflow-x: visible;
  }
`

const TradeLineSummaryTableFirstColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100px;
`

const TradeLineSummaryTableFirstColumn = () => (
  <TradeLineSummaryTableFirstColumnContainer>
    <TableRow style={{ borderBottom: `0.5px solid ${COLOR.borderGray}` }}>
      <TableHeader />
    </TableRow>
    {TradelineSummaryTableTitles.body.map(body => (
      <TableCell key={body.title} padding="0">
        <TradelineTableCellContent>{body.title}</TradelineTableCellContent>
      </TableCell>
    ))}
  </TradeLineSummaryTableFirstColumnContainer>
)

const TradelineTableHeader = styled(TableHeader)`
  ${breakpoints.mobileReport(`
    text-align: center;
  `)}
`
const TradelineTableHeaderRow = styled(TableRow)`
  border: none !important;
  border-bottom: 0.5px solid ${COLOR.borderGray};
  background: none !important;
`

const TradelineTableRow = styled(TableRow)`
  ${breakpoints.mobileReport(`
    > td {
      text-align: center !important;
    }
  `)}
`

const TradeLines = ({ tradelines, profileSum }) => {
  const groupedTradelines = tradelines ? groupTradelines(tradelines) : []
  const activeTradelines = get(groupedTradelines, 'active', [])
  const closedTradelines = get(groupedTradelines, 'closed', [])

  return (
    <>
      <Section>
        <SectionHeader title="Tradelines" />
        <SubSection>
          <SubSectionHeader title="Tradeline Summary" />
          <TradelineSummaryPanel NonePaddingT>
            <TradeLineSummaryTableFirstColumn />
            <TradelineSummaryTableContainer>
              <TradelineSummaryTable minWidth="700px">
                <TradelineTableHeaderRow>
                  {TradelineSummaryTableTitles.header.map((head, index) => (
                    <TradelineTableHeader textAlign="right" padding="0" key={index.toString()}>
                      <TradelineTableCellContent>{head}</TradelineTableCellContent>
                    </TradelineTableHeader>
                  ))}
                </TradelineTableHeaderRow>
                {TradelineSummaryTableTitles.body.map((v, index) => (
                  <TradelineSummary {...v} profileSum={profileSum} key={index.toString()} />
                ))}
              </TradelineSummaryTable>
            </TradelineSummaryTableContainer>
          </TradelineSummaryPanel>
        </SubSection>
      </Section>
      <Section>
        <SubSection>
          <SubSectionHeader title="Tradeline Total" />
          <CreditDetailContainer>
            <TotalCountBoxesContainer>
              <CountBox
                header={`Active ${pluralizeWordOnlyUnit('Account', activeTradelines.length)}`}
                score={activeTradelines.length}
                isActive
              />
              <CountBox
                header={`Closed ${pluralizeWordOnlyUnit('Account', closedTradelines.length)}`}
                score={closedTradelines.length}
              />
            </TotalCountBoxesContainer>
            <InformationPanel>
              <BalanceDetailInformationLine
                label="Balance Total"
                labelWidth="calc(100% - 80px)"
                value={convert(sumArray(tradelines, 'balanceAmount'), '$')}
                mobileGap="10px"
              />
              <BalanceDetailInformationLine
                label="Total Credit Limit"
                labelWidth="calc(100% - 80px)"
                value={convert(sumArray(tradelines, 'creditLimit'), '$')}
                mobileGap="10px"
              />
              <BalanceDetailInformationLine
                label="Total Past Due"
                labelWidth="calc(100% - 80px)"
                value={convert(sumArray(tradelines, 'amountPastDue'), '$')}
                mobileGap="10px"
              />
            </InformationPanel>
          </CreditDetailContainer>
        </SubSection>
      </Section>
      <Section>
        <SubSection>
          <SubSectionHeader title="Trades" />
          {activeTradelines.map((tradeline, index) => (
            <Trade tradeline={tradeline} index={index} key={`active-${index.toString()}`} />
          ))}
          {closedTradelines.map((tradeline, index) => (
            <Trade
              tradeline={tradeline}
              index={index + activeTradelines.length}
              key={`close-${index.toString()}`}
            />
          ))}
        </SubSection>
      </Section>
    </>
  )
}

export default TradeLines
