import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import injectReducer from 'utils/inject-reducer'
import injectSaga from 'utils/inject-saga'

import {
  setCarModalState,
  linkAccountWithoutSSO,
} from 'containers/envelope/select-options-step/actions'
import {
  selectIsCarWithPartnerIDIntegrated,
  selectIsFetchingLinkAccountData,
} from 'containers/request/step-screening-options/selectors'
import { getLinkAccountDataCall } from 'containers/request/step-screening-options/actions'
import { selectAgentLicenses } from 'containers/agent-profile/selectors'
import saga from './saga'
import {
  getScreeningPlans,
  selectPayer,
  selectBundle,
  selectApplication,
  selectCreditReportItems,
  createScreeningRequest,
  createScreeningRequestWithoutProperty,
  acceptedBackgroundDisclaimer,
  updateRequestStepControl,
  getProperty,
  updateDocument,
  updateReferenceCheck,
  getUserPreference,
  updateUserPreference,
  clearRequestOptions,
  clearProperty,
  setSource,
  getScreeningLandingRequest,
  updateIncomeVerification,
  saveCustomDocument,
  updateUserPlan,
  callAutoCreateTenantScreeningRequest,
} from './actions'
import reducer from './reducer'
import {
  selectRequestOptions,
  selectIsAcceptedBackgroundDisclaimer,
  selectScreeningPlan,
  selectIsFetchingScreeningPlan,
  selectIsFetchingUserPreference,
  selectUserExperience,
  selectUserPreference,
  selectDocumentOption,
  selectAutoCreateTenantScreening,
} from './selectors'

const mapStateToProps = createStructuredSelector({
  requestOptions: selectRequestOptions,
  isAcceptedBackgroundDisclaimer: selectIsAcceptedBackgroundDisclaimer,
  isCarWithPartnerIDIntegrated: selectIsCarWithPartnerIDIntegrated(),
  isFetchingLinkAccountData: selectIsFetchingLinkAccountData(),
  isFetchingScreeningPlan: selectIsFetchingScreeningPlan,
  isFetchingUserPreference: selectIsFetchingUserPreference,
  agentLicense: selectAgentLicenses,
  screeningPlan: selectScreeningPlan,
  userExperience: selectUserExperience,
  userPreference: selectUserPreference,
  selectedDocuments: selectDocumentOption,
  autoCreateTenantScreening: selectAutoCreateTenantScreening,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getProperty,
      getScreeningPlans,
      selectPayer,
      selectBundle,
      selectApplication,
      selectCreditReportItems,
      createScreeningRequest,
      createScreeningRequestWithoutProperty,
      acceptedBackgroundDisclaimer,
      updateRequestStepControl,
      updateDocument,
      updateReferenceCheck,
      updateIncomeVerification,
      updateUserPlan,
      getUserPreference,
      updateUserPreference,
      clearRequestOptions,
      clearProperty,
      getLinkAccountDataCall,
      setCarModalState,
      linkAccountWithoutSSO,
      setSource,
      getScreeningLandingRequest,
      saveCustomDocument,
      callAutoCreateTenantScreeningRequest,
    },
    dispatch,
  ),
})

export const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const withSaga = injectSaga({ key: 'tenantScreeningRequestV2.2', saga })
export const withReducer = injectReducer({
  key: 'tenantScreeningRequestV2.2',
  reducer,
})
