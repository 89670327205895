import React from 'react'
import { Box } from '@mui/material'
import IdentityVerification from '@rentspree/component-2023.smart-components.identity-verification'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import { compose } from 'redux'
import tracker from 'tracker'
import verification from 'images/report/identity-verification/verification.svg'
import { authService } from 'services/auth-service'
import { withConnect } from './connect'
import { CLICK_TEXT, EVENT_STEP, EVENT_LOCATION, EVENT_MAIN_FEATURE } from './constants'

export const IdentityVerificationPage = ({ actions, profile, rentalDetail }) => {
  const { refreshProfile } = actions
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img
        width={170}
        height={170}
        src={verification}
        alt="required verification"
        style={{ marginTop: '120px', marginBottom: '24px' }}
        data-event="element_visibility"
        data-page_url={window.location.href}
        data-step={EVENT_STEP.START}
        data-location={EVENT_LOCATION.VIEW_REPORT}
        data-pagename={EVENT_MAIN_FEATURE.IDENTITY_VERIFICATION}
        className="visibility-tracking element_visibility identity-verification-logo"
      />
      <Typography variant="title" sx={{ marginBottom: '8px' }}>{`${rentalDetail?.renter?.firstName}'s application is ready`}</Typography>
      <Typography variant="body-medium" align='center' sx={{ marginBottom: '24px', maxWidth: '520px' }}>To access their screening reports, we’ll just need to verify your identity.</Typography>
      <IdentityVerification
        authConfig={{getAccessToken: authService.resolveAccessTokenGetter()}}
        tracker={tracker}
        preVerificationButtonProps={{
          text: CLICK_TEXT,
          variant: 'contained',
          color: 'secondary',
          onClick: () => {
            tracker.trackButtonClick({
              location: EVENT_LOCATION.VIEW_REPORT,
              click_text: CLICK_TEXT,
              main_feature: EVENT_MAIN_FEATURE.IDENTITY_VERIFICATION,
            })
          }
        }}
        // eslint-disable-next-line no-underscore-dangle
        userId={profile._id}
        onSuccessCallback={() => {
          refreshProfile()
        }}
      />
    </Box>
  )
}

export default compose(withConnect)(IdentityVerificationPage)