import React from 'react'

import { buildPath } from '@rentspree/path'
import { useMutation } from '@tanstack/react-query'
import { apiInstance } from 'utils/api-interceptor'
import * as ROUTES from 'constants/route'

const mutationFn = ({ userId, creditReportId, rentalSubmissionId }) =>
  apiInstance.post(buildPath(ROUTES.COMPLIANCE_ACTIONS_UPDATE), {
    userId,
    actionType: 'CONDITIONAL_ACCEPTANCE',
    creditReportId,
    rentalSubmissionId,
  })

export const useUpdateComplianceActionsMutation = options =>
  useMutation({
    mutationFn,
    ...options,
  })

// TODO_EMILY: creating this HOC just for use in class-based component `application-detail`
export const withComplianceRulesMutation = WrappedComponent => props => {
  const updateComplianceActionsMutation = useUpdateComplianceActionsMutation()
  return (
    <WrappedComponent
      {...props}
      updateComplianceActionsMutation={updateComplianceActionsMutation}
    />
  )
}
