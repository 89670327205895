import React from 'react'
import { compose } from 'redux'

import Button from '@rentspree/component-2023.components.atoms.button'
import Sheet, { SheetHeader } from '@rentspree/component-2023.components.templates.sheet'
import { Dialog } from '@rentspree/component-2023.components.atoms.dialog'
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Box } from '@mui/material'

import SubtitleWithList from 'components/molecules/subtitle-with-list'
import { useMediaQuery } from 'v3/hooks/use-media-query'
import Body from './body'

import { withConnect, withSaga } from './connect'
import { MODAL_STATUS, DISABLE_FEATURE_LIST } from './constants'
const { ARCHIVE, UNARCHIVE } = MODAL_STATUS

const titles = {
  archive: 'Archive property',
  unarchive: 'Unarchive property',
}

export const ToggleArchivePropertyModal = ({ actions, isOpen, isLoading, type, propertyId }) => {
  const isMobile = useMediaQuery('(max-width: 1016px)')

  const handleConfirm = () => {
    if (type === ARCHIVE) {
      actions.archiveProperty(propertyId)
    } else if (type === UNARCHIVE) {
      actions.unarchiveProperty(propertyId)
    }
  }

  const handleClose = () => {
    actions.clearArchiveModal()
  }

  if (isMobile) {
    const SheetButtons = (
      <Box sx={{ display: 'flex', justifyContent: 'end', gap: '16px' }}>
        <Button variant="text" className="underline" color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="secondary" onClick={handleConfirm} disabled={isLoading}>
          Confirm
        </Button>
      </Box>
    )
    return (
      <Sheet
        actionSection={SheetButtons}
        open={isOpen}
        onClose={handleClose}
        data-testid="toggle-archive-property-mobile"
        hideActionDivider
      >
        <Box
          sx={{
            minHeight: '8vh',
          }}
        >
          <SheetHeader header={titles[type]} />
          <Box sx={{ padding: '16px', display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Body type={type} />
            {type === ARCHIVE && (
              <SubtitleWithList
                subtitle={DISABLE_FEATURE_LIST.subtitle()}
                list={DISABLE_FEATURE_LIST.list}
              />
            )}
          </Box>
        </Box>
      </Sheet>
    )
  }
  return (
    <Dialog
      size="large"
      scroll="paper"
      open={isOpen}
      onClose={handleClose}
      sx={{ display: 'block' }}
      data-testid="toggle-archive-property-desktop"
    >
      <DialogTitle handleOnClose={handleClose}>{titles[type]}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Body type={type} />
            {type === ARCHIVE && (
              <SubtitleWithList
                subtitle={DISABLE_FEATURE_LIST.subtitle()}
                list={DISABLE_FEATURE_LIST.list}
              />
            )}
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'end' }}>
        <Button variant="text" className="underline" color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="secondary" onClick={handleConfirm} disabled={isLoading}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default compose(withConnect, withSaga)(ToggleArchivePropertyModal)
