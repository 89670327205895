import React from 'react'

import isEmpty from 'lodash/isEmpty'
import Menu from '@rentspree/component-2023.components.atoms.menu'
import MenuItem from '@rentspree/component-2023.components.atoms.menu-item'
import Divider from '@rentspree/component-2023.components.atoms.divider'
import IconButton from '@rentspree/component-2023.components.atoms.icon-button'
import Sheet from '@rentspree/component-2023.components.templates.sheet'
import MoreVert from '@mui/icons-material/MoreVert'
import { Box } from '@mui/material'
import List from '@mui/material/List'

import { NEXT_ACTION_TYPES } from 'containers/tenant-screening/assign-property/constants'
import { PARTICIPANT_TYPE } from 'containers/reports/constants'
import { ACCEPT_DENY_TYPE } from 'containers/accept-deny/constants'
import { APP_NOT_SUBMIT_STATUS } from 'containers/application/const'

import { useScreenSize } from 'hooks/use-screen-size'
import { isApplicationActionable } from './helpers'

// TODO: [DUPLICATED ALERT] merge this ActionDropdown component and PropertyTableActions component and use it as a shared component
export const ActionDropdown = ({
  rentalSubmission = {},
  source,
  eventSource,
  isAllowAcceptDeny,
  isAllowCarLease,
  onOpenAcceptDenyModal,
  onOpenAssignPropertyModal,
  onClickSetupLease,
  onClickSetupRentPayment,
}) => {
  const [isMenuOpened, setOpeningMenu] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { isDesktop } = useScreenSize()

  const generateAcceptDenyFunction = acceptDenyType => {
    const {
      _id: id,
      status,
      participantType,
      email,
      firstName,
      middleName,
      lastName,
      property,
      propertyAddress,
      participantId,
      screeningResult,
    } = rentalSubmission

    // Disable the accept/deny feature (not submitted yet, not allowed, result type is the same with current result)
    if (APP_NOT_SUBMIT_STATUS.includes(status) || !isAllowAcceptDeny) {
      return undefined
    }
    const acceptDenyProps = {
      rentalSubmission: {
        id,
        email,
        renter: { firstName, middleName, lastName },
      },
      propertyId: property,
      propertyAddress,
      isSharedReport: participantType === PARTICIPANT_TYPE.PARTICIPANT,
      acceptDenyType,
      clickFrom: source,
      screeningResult: screeningResult?.result,
      clickFromWidget: eventSource?.clickFromWidget,
    }
    // if have property, open accept/deny modal
    if (property) {
      return () => onOpenAcceptDenyModal({ ...acceptDenyProps })
    }
    // if no property, open assign property modal first
    return () =>
      onOpenAssignPropertyModal({
        participantId,
        nextAction: NEXT_ACTION_TYPES.ACCEPT_DENY_FROM_TABLE,
        nextActionProps: acceptDenyProps,
      })
  }
  const { property, status, screeningResult } = rentalSubmission
  const isAllowSetupLeaseAgreement =
    !isEmpty(property) && isApplicationActionable(status, screeningResult?.result)
  const isAllowSetupPayment =
    !isEmpty(property) && isApplicationActionable(status, screeningResult?.result)

  const groupOfItems = [
    [
      {
        key: 'accept-applicant',
        label: 'Accept application',
        hidden: !!rentalSubmission?.screeningResult?.result,
        onClick: generateAcceptDenyFunction(ACCEPT_DENY_TYPE.ACCEPT),
      },
      {
        key: 'decline-applicant',
        label: 'Decline application',
        hidden: !!rentalSubmission?.screeningResult?.result,
        onClick: generateAcceptDenyFunction(ACCEPT_DENY_TYPE.DENY),
      },
      {
        key: 'change-decision',
        label: 'Change decision',
        hidden: !rentalSubmission?.screeningResult?.result,
        onClick: generateAcceptDenyFunction(rentalSubmission?.screeningResult?.result),
      },
      {
        key: 'set-up-lease',
        label: 'Set up lease',
        hidden: !isAllowCarLease,
        onClick:
          isAllowSetupLeaseAgreement && (() => onClickSetupLease(rentalSubmission?.property)),
      },
      {
        key: 'set-up-payment',
        label: 'Set up payments',
        onClick:
          isAllowSetupPayment &&
          (() => onClickSetupRentPayment(rentalSubmission?.property, rentalSubmission.email)),
      },
    ],
  ]

  const handleMenuButtonClick = event => {
    event.stopPropagation()
    setOpeningMenu(true)
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = event => {
    if (event) event.stopPropagation()
    setAnchorEl(null)
    setOpeningMenu(false)
  }

  const stopPropagation = event => {
    event.stopPropagation()
  }

  const renderMenuItems = () =>
    groupOfItems.flatMap((group, i) => {
      const isLastGroup = i === groupOfItems.length - 1
      const elements = group
        .filter(item => !item.hidden)
        .map(item => (
          <MenuItem
            label={item.label}
            id={`${source}-${item.key}`}
            data-testid={`${source}-${item.key}-action`}
            key={`${item.key}`}
            disabled={!item.onClick}
            onClick={e => {
              handleCloseMenu(e)
              if (item.onClick) item.onClick()
            }}
            sx={{
              minHeight: 'auto',
              height: '40px',
            }}
          />
        ))
      if (!isLastGroup) {
        elements.push(<Divider light component="li" sx={{ margin: '0 12px' }} />)
      }
      return elements
    })

  return (
    <>
      <IconButton
        id={source}
        data-testid="open-action-button"
        onClick={handleMenuButtonClick}
        color="black"
        size="medium"
      >
        <MoreVert />
      </IconButton>
      {isDesktop ? (
        <Menu
          id={`${source}-menu-items`}
          data-testid={`${source}-menu-items`}
          key={`${source}-menu-items`}
          autoFocus={false}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMenuOpened}
          onClose={handleCloseMenu}
          onClick={stopPropagation}
        >
          {renderMenuItems()}
        </Menu>
      ) : (
        <Sheet
          id={`${source}-menu-items`}
          data-testid={`${source}-menu-items`}
          key={`${source}-menu-items`}
          open={isMenuOpened}
          onClose={handleCloseMenu}
          onClick={stopPropagation}
        >
          <Box
            sx={{
              padding: '48px 8px 0 8px',
            }}
          >
            <List>{renderMenuItems()}</List>
          </Box>
        </Sheet>
      )}
    </>
  )
}

export default ActionDropdown
