import styled, { createGlobalStyle } from 'styled-components'
import { COLOR, MOBILE_REPORT_WIDTH, breakpoints } from 'styles/settings'
import { isDeviceSafari } from 'legacy/components/helper/ua-parser-js'

export const PrintPageGlobalStyle = createGlobalStyle`
  @page {
    size: 8.5in 11in;
    margin: 0.15in 0.25in;
  }
  @media print {
    html, body {
      width: 8.5in;
    }
  }
`

export const ReportWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 760px;
  margin: ${props => (props.margin ? props.margin : '0 auto 40px;')};
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);
  box-sizing: content-box;
  border: ${props => (props.border ? props.border : '1px solid #d8d8d8')};
  @media print {
    padding: 0px;
    width: 100% !important;
    break-inside: avoid !important;
    break-after: auto !important;
    margin-bottom: ${isDeviceSafari ? '700px' : '0'};
    border: none !important;
  }
`

export const DocPanel = styled.div`
  font-family: 'Source Sans Pro';
  background-color: white !important;
  width: 100%;
  max-width: 760px;
  padding: 22px 30px;
  @media print {
    width: 100% !important;
    padding: 0px;
  }
`

export const ReportHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: '0 30px';
`
export const ReportHeaderInformationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`

export const ReportLogo = styled.img`
  height: ${props => (props.height ? props.height : '25px')};

  ${breakpoints.mobileReport(`
    height: 19px;
  `)}
`

export const ReportInformationArea = styled.div`
  display: flex;
  flex-direction: column;
`

export const ReportHeaderInformation = styled.div`
  text-align: right;
`

export const ReportHeaderCustomerInformationContainer = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const CustomerInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const CustomerInformationHeader = styled.p`
  margin: 0 0 5px 0;
  color: ${COLOR.textBlack};
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

  ${breakpoints.mobileReport(`
    font-size: 12px;
  `)}
`

export const CustomerInformationDetail = styled.p`
  margin: 0;
  color: ${COLOR.textBlack};
  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  ${breakpoints.mobileReport(`
    font-size: 10px;
  `)}
`

export const ReportHeaderType = styled.p`
  margin: 0;
  color: ${COLOR.textBlack};
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  ${breakpoints.mobileReport(`
    font-size: 12px;
  `)}
`

export const ReportHeaderDetail = styled.p`
  margin: 0;
  color: ${props => (props.color ? props.color : COLOR.textGrey)};
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  ${breakpoints.mobileReport(`
    font-size: 12px;
  `)}
`

export const ApplicantOverviewContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
`

export const ApplicantName = styled.h1`
  color: ${COLOR.newBlack};
  font-family: Merriweather;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  margin: 0;

  ${breakpoints.mobileReport(`
    font-size: 24px;
    line-height: 30px;
  `)}
`

export const ApplicantAka = styled.p`
  color: ${COLOR.newBlack};
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 10px;

  ${breakpoints.mobileReport(`
    margin-bottom: 3px;
  `)}
`

export const ContactInformationContainer = styled.div`
  display: flex;
  gap: 50px;
  margin-bottom: 20px;

  ${breakpoints.mobileReport(`
    margin-bottom: 10px;
    flex-direction: column;
    gap 4px;
  `)}
`

export const ContactInformation = styled.div`
  display: flex;
  align-items: center;
  color: ${COLOR.newBlack};
  font-family: Source Sans Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;

  ${breakpoints.mobileReport(`
    font-size: 14px;
    line-height: 18px;
    i {
      display: none;
    }
  `)}
`

export const IncorrectInfo = styled.p`
  color: ${COLOR.textGrey};
  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;

  ${breakpoints.mobileReport(`
    margin-bottom: 10px;
  `)}
`

export const ApplicantTypeContainer = styled.p`
  color: ${COLOR.newBlack};
  font-family: Source Sans Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;

  ${breakpoints.mobileReport(`
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
  `)}
`

export const Section = styled.div`
  margin-bottom: 20px;

  ${breakpoints.mobileReport(`
    margin-bottom: 10px;
  `)}

  @media print {
    break-inside: avoid !important;
  }
`

export const SectionHeaderContainer = styled.div`
  padding-left: 20px;
  background-color: ${COLOR.darkerGray};
  margin-bottom: 20px;

  ${breakpoints.mobileReport(`
    padding-left: 16x;
    font-size: 14px;
    margin-bottom: 10px;
  `)}

  @media print {
    background-color: ${COLOR.bgGrey} !important;
  }
`

export const SectionTitle = styled.p`
  color: ${COLOR.textBlack};
  font-family: Source Sans Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 0;

  ${breakpoints.mobileReport(`
    font-size: 14px;
  `)}
`

export const SubSection = styled.div`
  margin-bottom: 20px;

  ${breakpoints.mobileReport(`
    margin-bottom: 10px;
  `)}
`

export const SubSectionHeaderContainer = styled.div`
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 2px solid ${COLOR.lineGray};
  display: flex;
  justify-content: space-between;

  ${breakpoints.mobileReport(`
    margin-bottom: 10px;
    padding-bottom: 0px;
  `)}
`

export const SubSectionTitle = styled.p`
  color: ${COLOR.newBlack};
  font-family: Source Sans Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin: 0px;

  ${breakpoints.mobileReport(`
    font-size: 14px;
    line-height: 22px;
  `)}
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  column-gap: 20px;

  ${breakpoints.mobileReport(`
    flex-direction: column;
    margin-bottom: 10px;
    column-gap: 0;
  `)}

  @media print {
    page-break-inside: avoid !important;
    break-inside: avoid !important;
  }
`

export const GapContent = styled(Content)`
  flex-wrap: wrap;
  gap: 20px;

  ${breakpoints.mobileReport(`
    flex-wrap: nowrap;
    gap: 10px;
  `)}
`

export const MobileGapContent = styled(Content)`
  flex-wrap: wrap;
  column-gap: 0;

  ${breakpoints.mobileReport(`
    flex-wrap: nowrap;
    row-gap: 10px;
  `)}
`

export const GroupSeparator = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  ${breakpoints.mobileReport(`
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 10px;
  `)}
`

export const InformationPanel = styled.div`
  flex: 1;
  gap: 0;
`

export const InformationHalfPanel = styled.div`
  width: calc((100% - 20px) / 2);
  gap: 0;

  ${breakpoints.mobileReport(`
    width: 100%;
  `)}
`

export const InformationAreaContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ isAreaOnMobile }) =>
    isAreaOnMobile
      ? breakpoints.mobileReport(`flex-direction: column;`)
      : breakpoints.mobileReport(`flex-direction: row;`)}
`

export const InformationLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 5px;

  ${breakpoints.mobileReport(`
    gap: 0;
  `)}

  @media (max-width: ${MOBILE_REPORT_WIDTH}) {
    ${({ mobileGap }) => mobileGap && `gap: ${mobileGap};`}
  }
`

export const Label = styled.p`
  width: ${({ width }) => width || '110px'};
  margin: 0;
  color: ${COLOR.newBlack};
  font-family: Source Sans Pro;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  flex-shrink: 0;

  ${({ mobileWidth, width }) =>
    breakpoints.mobileReport(`
    width: auto;
    min-width: ${mobileWidth || width || '110px'}; 
    flex: 1;
    font-size: 12px;
  `)}
`

export const HighlightLabel = styled(Label)`
  ${breakpoints.mobileReport(`
    flex: 1;
    font-size: 15px;
  `)}
`

export const InformationGroupLabel = styled(Label)`
  width: 100%;
  margin-bottom: 10px;

  ${breakpoints.mobileReport(`
    flex: 1;
    font-size: 14px;
  `)}
`

export const Value = styled.p`
  margin: 0;
  color: ${COLOR.newBlack};
  font-family: Source Sans Pro;
  font-size: 15px;
  font-style: normal;
  font-weight: ${({ isBold }) => (isBold ? '600' : '400')};
  line-height: 22px;
  word-break: break-word;

  ${breakpoints.mobileReport(`
    flex: 1;
    font-size: 14px;
  `)}
`

export const InformationGroup = styled.div`
  width: 100%;
`

export const ReportFooter = styled.div`
  width: 100%;
  color: ${COLOR.textGrey};
  text-align: right;
  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const LineBreak = styled.div`
  height: 20px;

  ${breakpoints.mobileReport(`
      height: 10px;
  `)}
`

export const AnnotationLine = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: ${COLOR.black};
`

export const PrintPageBreak = styled.section`
  @media print {
    page-break-after: always;
  }
`

export const SeparateLine = styled.hr`
  stroke-width: 1px;
  stroke: ${COLOR.borderGrey};
  margin-bottom: 20px;

  ${breakpoints.mobileReport(`
    margin-bottom: 15px;
  `)}
`

export const RentspreeFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  max-width: 340px;

  ${breakpoints.mobileReport(`
    align-items: start;
    flex-direction: column;
    gap: 10px;
  `)}
`

export const RentspreeContactContainer = styled.div`
  flex: 1;
`

export const RentspreeFooterLine = styled(Value)`
  font-size: 14px;

  ${breakpoints.mobileReport(`
    font-size: 12px;
  `)}
`

export const DisclaimerText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: ${COLOR.textBlack};
  margin-top: 10px;
  margin-bottom: 0px;

  ${breakpoints.mobileReport(`
    font-size: 12px;
  `)}
`

export const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 10px;

  ${breakpoints.mobileReport(`
    font-size: 12px;
  `)}
`

export const AdditionalIncomePanel = styled(InformationHalfPanel)`
  margin-bottom: 20px;

  ${breakpoints.mobileReport(`
    margin-bottom: 0px;
  `)}
`

// New TU Eviction Styles
export const ReportLogoLarge = styled.img`
  height: 100px;

  ${breakpoints.mobileReport(`
    height: 45px;
  `)}
`
export const FlexInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`
export const HalfWidthInfoFieldContainer = styled.div`
  width: 50%;
  min-width: 120px;
  min-height: 45px;
  ${breakpoints.mobileReport(`
    width: 100%;
  `)}
`
export const ScreeningReportHeaderText = styled.div`
  color: ${COLOR.tuLightBlue};
  margin-top: 30px;
  font-weight: bold;
  ${breakpoints.mobileReport(`
    margin-top: 12px;
  `)}
`
export const Divider = styled.div`
  height: 1px;
  border: solid 1px ${COLOR.tuLightBlue};
`
export const ScreeningReportHeaderWrapper = styled(ReportHeaderInformationContainer)`
  margin-bottom: 20px;
`
export const ScreeningApplicantContainer = styled.div`
  margin-bottom: 10px;
`
export const ApplicantDetailsWrapper = styled(FlexInfoContainer)`
  margin-top: 15px;
`
export const ScreeningApplicantNameWrapper = styled.div`
  color: ${COLOR.tuNavy};
  font-family: Merriweather;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  margin: 0;
  margin-top: 15px;
  display: flex;

  ${breakpoints.mobileReport(`
    font-size: 18px;
    line-height: 30px;
  `)}
`
export const ScreeningNameText = styled.div`
  display: flex;
  margin-left: 15px;
`
export const ScreeningSectionHeader = styled.h2`
  color: ${COLOR.tuNavy};
  font-family: Merriweather;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  margin: 0;

  ${breakpoints.mobileReport(`
    font-size: 15px;
    line-height: 30px;
  `)}
`
export const ScreeningSubSectionHeader = styled.h2`
  color: ${props => (props.color ? props.color : COLOR.textBlack)};
  font-family: Merriweather;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  margin: 0;

  ${breakpoints.mobileReport(`
    font-size: 12px;
    line-height: 30px;
  `)}
`
export const ScreeningSubSectionContainer = styled.div`
  border: solid 2px ${COLOR.lightTextGrey};
  border-left: solid 5px ${props => (props.color ? props.color : COLOR.textLightGrey)};
`
export const ScreeningInfoContentWrapper = styled(FlexInfoContainer)`
  padding: 15px;
  display: flex;
  width: 100%;
  @media print {
    break-inside: avoid;
  }
`
export const ScreeningReportFooterWrapper = styled.div`
  margin-top: 50px;
  display: flex;
`
export const ScreeningReportFooterLineLeft = styled(Divider)`
  float: left;
  width: 100%;
  margin-top: 7px;
`
export const ScreeningReportFooterLineRight = styled(Divider)`
  float: right;
  width: 100%;
  margin-top: 7px;
`
export const ScreeningReportFooterText = styled.div`
  color: ${COLOR.tuLightBlue};
  width: 400px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
`
