import axios from 'axios'
import { axiosResponse } from '@rentspree/axios'
import * as Sentry from '@sentry/browser'
import { setLocalItem, getLocalItem, setOption } from '@rentspree/cookie'
import { v4 as uuidv4 } from 'uuid'
import get from 'lodash/get'
import merge from 'lodash/merge'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import { authService } from 'services/auth-service'
import { API_URL, FILES_URL, USER_API_URL, AGENT_REVIEW_API_BASE_URL } from '../env'
import { handleAuthorization, handleApiErrors, handleESignAPIErrors } from './handle-api-errors'
import { STORAGE, COOKIE_OPTION } from '../constants/cookie'

const timestampConfig = () => ({ params: { t: new Date().getTime() } })

const apiInstance = axios.create({
  baseURL: API_URL,
})

const apiInstanceWithErrorHandler = axios.create({
  baseURL: API_URL,
})

const apiEnvelopeInstance = axios.create({
  baseURL: API_URL,
})

const UserApiInstance = axios.create({
  baseURL: USER_API_URL,
})

const fileApiInstance = axios.create({
  baseURL: FILES_URL,
})

const agentReviewApiInstance = axios.create({
  baseURL: `${API_URL}${AGENT_REVIEW_API_BASE_URL}`,
})

// Auth0 Migration
export const request = async config => {
  const defaultConfig = {
    headers: {
      post: {
        'Content-Type': 'application/json',
      },
    },
  }
  const configMerge = merge(cloneDeep(defaultConfig), config)
  const headers = get(configMerge, 'headers', {})
  let accessToken
  const shouldUseAuthProviderToken = authService.shouldUseAuthProviderToken()
  if (shouldUseAuthProviderToken) {
    accessToken = await authService.getAccessToken()
  } else {
    accessToken = get(getLocalItem(STORAGE.USER_TOKEN), 'access_token')
  }
  if (!isEmpty(accessToken)) {
    headers.Authorization = `Bearer ${accessToken}`
  }
  return { ...configMerge, headers, ...timestampConfig() }
}

const logErrorWithSentry = error => {
  Sentry.captureException(error)
  return Promise.reject(error)
}

const injectHandleResponseApiInstance = ({
  instance,
  handleResolve = res => res,
  handleReject,
}) => {
  instance.interceptors.response.use(handleResolve, handleReject)
}

const injectErrorWithSentryApiInstance = instance =>
  injectHandleResponseApiInstance({
    instance,
    handleReject: logErrorWithSentry,
  })

// Auth0 Migration
// TODO: Check if it should be migrated to esign dashboard
const envelopeRequest = async config => {
  let SSID
  SSID = getLocalItem(STORAGE.SSID)
  if (!SSID) {
    SSID = uuidv4()
    setOption(COOKIE_OPTION)
    setLocalItem(STORAGE.SSID, SSID)
  }
  const defaultConfig = {
    headers: {
      post: {
        'Content-Type': 'application/json',
      },
      ssid: SSID,
    },
  }
  const configMerge = merge(cloneDeep(defaultConfig), config)
  const headers = get(configMerge, 'headers', {})
  let accessToken
  const shouldUseAuthProviderToken = authService.shouldUseAuthProviderToken()
  if (shouldUseAuthProviderToken) {
    accessToken = await authService.getAccessToken()
  } else {
    accessToken = get(getLocalItem(STORAGE.USER_TOKEN), 'access_token')
  }
  if (!isEmpty(accessToken)) {
    headers.Authorization = `Bearer ${accessToken}`
  }
  return { ...configMerge, headers, ...timestampConfig() }
}

apiInstance.interceptors.request.use(request)
UserApiInstance.interceptors.request.use(request)
fileApiInstance.interceptors.request.use(request)

apiInstance.interceptors.response.use(axiosResponse.response, error => handleAuthorization(error))

apiEnvelopeInstance.interceptors.request.use(envelopeRequest)

apiEnvelopeInstance.interceptors.response.use(
  r => r,
  error => handleESignAPIErrors(error),
)

apiInstanceWithErrorHandler.interceptors.request.use(request)

apiInstanceWithErrorHandler.interceptors.response.use(axiosResponse.response, error =>
  handleAuthorization(error),
)

apiInstanceWithErrorHandler.interceptors.response.use(
  r => r,
  error => handleApiErrors(error),
)

agentReviewApiInstance.interceptors.request.use(request)

agentReviewApiInstance.interceptors.response.use(axiosResponse.response, error =>
  handleAuthorization(error),
)

apiEnvelopeInstance.interceptors.response.use(axiosResponse.response)
UserApiInstance.interceptors.response.use(axiosResponse.response, error =>
  handleAuthorization(error),
)
fileApiInstance.interceptors.response.use(axiosResponse.response, error =>
  handleAuthorization(error),
)

export {
  apiInstance,
  apiInstanceWithErrorHandler,
  UserApiInstance,
  injectHandleResponseApiInstance,
  injectErrorWithSentryApiInstance,
  apiEnvelopeInstance,
  fileApiInstance,
  agentReviewApiInstance,
}
