import { Box, MenuItem } from '@mui/material'
import Select from '@rentspree/component-2023.components.atoms.select'
import { get } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { selectProfile } from 'containers/user/selectors'
import { useSnackbar } from 'v3/components/snackbar'
import { tenantToString } from 'v3/containers/overhaul-rent-payment/pages/utils'
import { PROP_AND_TENANT } from 'v3/containers/overhaul-rent-payment/text-constants'
import { CreateTenantForm } from 'v3/containers/rent-payment/setup-page/set-up-for-landlord/create-tenant-form'
import { useCreateTenant } from 'v3/containers/rent-payment/shared/hooks/use-create-tenant'
import { useSearchRenterInfo } from 'v3/containers/rent-payment/shared/hooks/use-search-renter-info'

const selectOwnerEmail = createSelector(selectProfile, state => get(state, 'email', ''))

export const TenantSelectAndForm = props => {
  const { selectedTenant, setTenant, selectedProperty } = props
  const [showTenantForm, setShowTenantForm] = useState(false)
  const [errors, setErrors] = useState(undefined)
  const { showSnackbar } = useSnackbar()
  const { data: tenantList, searchRenterInfo } = useSearchRenterInfo()
  const ownerEmail = useSelector(selectOwnerEmail)

  const { createTenant, status: createTenantApiStatus } = useCreateTenant({
    onSuccess: response => {
      searchRenterInfo({ propertyId: selectedProperty?._id })
      setShowTenantForm(false)
      setTenant(response)
      showSnackbar({
        message: 'New tenant added',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      })
      setErrors(undefined)
    },

    onError: response => {
      // same as CreateTenantModal's handleSubmit() => catch(e) {} block
      if (
        response?.data?.code === 'INPUT_VALIDATION_ERROR' &&
        response?.data?.data?.errors[0]?.field === 'email'
      ) {
        setErrors({ email: 'Invalid email' })
        return
      }

      throw response
    },
  })

  useEffect(() => {
    searchRenterInfo({ propertyId: selectedProperty?._id })
    if (tenantList && !tenantList.some(tenant => tenant.id === selectedTenant?.id)) {
      setTenant(undefined)
    }
  }, [showTenantForm, selectedProperty])

  return (
    <>
      <Box sx={{ margin: 'auto', padding: '10px 0px', width: '100%' }}>
        <Select
          label="Tenant"
          value={selectedTenant?.id || ''}
          style={{ fontSize: '1.75rem', textAlign: 'left' }}
          required
        >
          <MenuItem
            key="new"
            onClick={() => {
              // don't use the debounced version, the progression handler won't see the intended data changes
              setTenant(undefined)
              setShowTenantForm(true)
            }}
          >
            {PROP_AND_TENANT.ADD_NEW_TENANT}
          </MenuItem>
          {(tenantList || []).map(val => {
            const name = tenantToString(val)
            return (
              <MenuItem key={val.id} onClick={() => setTenant(val)} value={val.id}>
                {name}
              </MenuItem>
            )
          })}
        </Select>
      </Box>
      <CreateTenantForm
        isOpen={showTenantForm}
        ownerEmail={ownerEmail}
        onSubmit={createTenant}
        createTenantApiStatus={createTenantApiStatus}
        onClose={() => setShowTenantForm(false)}
        apiErrors={errors}
      />
    </>
  )
}
