import { takeLatest, takeEvery, call, put, all, select } from 'redux-saga/effects'
import axios from 'axios'
import get from 'lodash/get'
import trim from 'lodash/trim'
import FileSaver from 'file-saver'
import { buildPath } from '@rentspree/path'
import { getLocalItem } from '@rentspree/cookie'
import { STORAGE } from 'constants/cookie'
import { apiInstance } from 'utils/api-interceptor'
import {
  CANCEL_DOCUMENT_REQUEST_API,
  DOWNLOAD_DOCUMENT_API,
  DOWNLOAD_DOCUMENT_FILE_API,
  GET_OTHER_DOCS,
  REQUEST_OTHER_DOCUMENTS_API,
} from 'constants/route'
import { OTHER_DOCUMENTS } from 'legacy/constants/error-messages'
import { isIOS } from 'legacy/components/helper/ua-parser-js'
import { addToast } from 'containers/toast/actions'
import { authService } from 'services/auth-service'
import {
  DOCUMENT_CANCEL_SOURCE,
  FETCH_DOWNLOAD_TOKEN_CALL,
  GET_OTHER_DOCS_CALL,
  REQUEST_OTHER_DOC_CALL,
  REQUEST_DOCS_SUCCESS_TOAST_CONFIG,
  REQUEST_DOCS_FAILED_TOAST_CONFIG,
  CANCEL_DOCUMENT_REQUEST_SUCCESS_TOAST_CONFIG,
  CANCEL_DOCUMENT_REQUEST_FAILED_TOAST_CONFIG,
  CANCEL_DOCUMENT_REQUEST_CALL,
  DOCUMENT_STATUS,
  DOWNLOAD_DOCUMENT_FILE_CALL,
} from './constants'
import { selectRentalDetail } from '../selectors'
import { selectDocument } from './selectors'
import {
  requestOtherDocs as requestOtherDocsAction,
  downloadOtherDocs as downloadOtherDocsAction,
  cancelDocumentRequest as cancelDocumentRequestAction,
  alertError,
  getDocSets,
  downloadDocumentFileAction,
} from './actions'

export const requestOtherDocsAPI = ({ rentalSubmissionId, documents }) =>
  apiInstance.post(buildPath(REQUEST_OTHER_DOCUMENTS_API), {
    rentalSubmissionId,
    documents,
  })

export const cancelDocumentRequestAPI = ({ documentSetId, documentId, message }) =>
  apiInstance.put(
    buildPath(CANCEL_DOCUMENT_REQUEST_API, {
      documentSetId,
      documentId,
    }),
    {
      message,
      cancelSource: DOCUMENT_CANCEL_SOURCE.AGENT,
    },
  )

export const getDownloadZipApi = async (rentalId, documentId, profile) => {
  const downloadPayload = { rentalId, documentId }
  // Auth0 Migration
  let accessToken
  const shouldUseAuthProviderToken = authService.shouldUseAuthProviderToken()
  if (shouldUseAuthProviderToken) {
    accessToken = await authService.getAccessToken()
  } else {
    accessToken = get(getLocalItem(STORAGE.USER_TOKEN), 'access_token')
  }
  let url = buildPath(DOWNLOAD_DOCUMENT_API, downloadPayload, profile)
  if (isIOS()) {
    url = buildPath(DOWNLOAD_DOCUMENT_API, downloadPayload, {
      ...profile,
    })
    window.location.href = url
    return Promise.resolve(url)
  }

  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${accessToken}` },
    responseType: 'blob',
    credentials: 'omit',
  })
  const header = get(response.headers, 'content-disposition', '')
  const fileName = header.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/) || []
  return {
    otherDocDownloadData: response.data,
    fileName: fileName[1],
  }
}

export const downloadDocumentFileApi = async ({ rentalId, fileId, documentId, profile }) => {
  const downloadPayloadParams = { rentalId, fileId, documentId }
  // Auth0 Migration
  let accessToken
  const shouldUseAuthProviderToken = authService.shouldUseAuthProviderToken()
  if (shouldUseAuthProviderToken) {
    accessToken = await authService.getAccessToken()
  } else {
    accessToken = get(getLocalItem(STORAGE.USER_TOKEN), 'access_token')
  }
  let url = buildPath(DOWNLOAD_DOCUMENT_FILE_API, downloadPayloadParams, profile)
  if (isIOS()) {
    url = buildPath(DOWNLOAD_DOCUMENT_FILE_API, downloadPayloadParams, {
      ...profile,
    })
    window.location.href = url
    return Promise.resolve(url)
  }

  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${accessToken}` },
    responseType: 'blob',
    credentials: 'omit',
  })
  const header = get(response.headers, 'content-disposition', '')
  const fileName = header.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/) || []
  return {
    data: response.data,
    fileName: fileName[1],
  }
}

export const getOtherDocsApi = async rentalId => {
  // Auth0 Migration
  let accessToken
  const shouldUseAuthProviderToken = authService.shouldUseAuthProviderToken()
  if (shouldUseAuthProviderToken) {
    accessToken = await authService.getAccessToken()
  } else {
    accessToken = get(getLocalItem(STORAGE.USER_TOKEN), 'access_token')
  }
  const url = buildPath(GET_OTHER_DOCS, {}, { rentalSubmissionIds: rentalId })
  const { data } = await axios.get(url, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
  return data.result[0]
}

export function* requestOtherDocs({ payload }) {
  const rentalSubmission = yield select(selectRentalDetail)
  const { otherDocs: prevDocuments } = yield select(selectDocument)
  const { documents: requestedDocuments } = payload
  const { _id: rentalSubmissionId } = rentalSubmission || {}
  let currentDocuments = []

  try {
    const currentDocumentSet = yield call(getOtherDocsApi, rentalSubmissionId)
    currentDocuments = currentDocumentSet?.documents
  } catch (error) {
    yield put(getDocSets.failure())
  }

  const isDocumentDiff = requestedDocuments.some(requestedDocument => {
    const { type: requestedType } = requestedDocument
    const prevDocument = prevDocuments?.find(document => document.type === requestedType)
    const currentDocument = currentDocuments?.find(document => document.type === requestedType)
    return (
      currentDocument?.status === DOCUMENT_STATUS.SUBMITTED &&
      prevDocument?.status !== DOCUMENT_STATUS.SUBMITTED
    )
  })
  if (isDocumentDiff) {
    yield put(requestOtherDocsAction.failure())
    yield put(addToast(REQUEST_DOCS_FAILED_TOAST_CONFIG))
    return
  }

  yield put(requestOtherDocsAction.request())
  const requestedDocumentPayload = requestedDocuments.map(document => ({
    type: document.type,
    note: trim(document.note),
  }))
  const body = { rentalSubmissionId, documents: requestedDocumentPayload }
  try {
    const response = yield call(requestOtherDocsAPI, body)
    yield put(requestOtherDocsAction.success(response.result || {}))
    yield put(addToast(REQUEST_DOCS_SUCCESS_TOAST_CONFIG))
    const clearSelectedDocs = get(payload, 'clearSelectedDocs')
    yield call(clearSelectedDocs)
  } catch (err) {
    yield put(requestOtherDocsAction.failure())
    yield put(addToast(REQUEST_DOCS_FAILED_TOAST_CONFIG))
  }
}

export function* downloadDocumentFile({ payload }) {
  const rentalSubmission = yield select(selectRentalDetail)
  const rentalId = get(rentalSubmission, '_id')
  const fileId = get(payload, 'fileId')
  const documentId = get(payload, 'documentId')
  const profile = get(payload, 'renter')
  yield put(downloadDocumentFileAction.request())
  try {
    const { data, fileName } = yield call(downloadDocumentFileApi, {
      fileId,
      profile,
      rentalId,
      documentId,
    })
    yield put(downloadDocumentFileAction.success())
    if (data && fileName) {
      yield call(FileSaver.saveAs, data, fileName)
    }
  } catch (err) {
    yield put(downloadDocumentFileAction.failure())
    yield put(
      alertError({
        title: OTHER_DOCUMENTS.DOWNLOAD_DOCUMENT.TITLE,
        message: OTHER_DOCUMENTS.DOWNLOAD_DOCUMENT.MESSAGE,
      }),
    )
  }
}

export function* downloadOtherDocs({ payload }) {
  const rentalAppId = yield call(get, payload, 'rentalAppId')
  const document = yield call(get, payload, 'document')
  const documentId = yield call(get, document, '_id')
  const profile = yield call(get, payload, 'renter')
  const clearDownloadingDocs = yield call(get, payload, 'clearDownloadingDocs')
  yield put(downloadOtherDocsAction.request())
  try {
    const data = yield call(getDownloadZipApi, rentalAppId, documentId, profile)
    yield put(downloadOtherDocsAction.success(document))
    if (data && data.otherDocDownloadData) {
      yield call(FileSaver.saveAs, data.otherDocDownloadData, data.fileName)
    }
    yield call(clearDownloadingDocs, documentId)
  } catch (err) {
    yield call(clearDownloadingDocs, documentId)
    yield put(downloadOtherDocsAction.failure())
    yield put(
      alertError({
        title: OTHER_DOCUMENTS.DOWNLOAD_DOCUMENT.TITLE,
        message: OTHER_DOCUMENTS.DOWNLOAD_DOCUMENT.MESSAGE,
      }),
    )
  }
}

export function* getOtherDocsSaga({ payload }) {
  try {
    const { rentalId } = payload
    const response = yield call(getOtherDocsApi, rentalId)
    yield put(getDocSets.success(response))
  } catch (error) {
    yield put(getDocSets.failure())
  }
}

export function* cancelDocumentRequest({ payload }) {
  const documentSetId = yield call(get, payload, 'documentSetId')
  const documentId = yield call(get, payload, 'documentId')
  const message = yield call(get, payload, 'message')
  yield put(cancelDocumentRequestAction.request())
  try {
    const response = yield call(cancelDocumentRequestAPI, { documentSetId, documentId, message })
    yield put(cancelDocumentRequestAction.success(response.result || {}))
    yield put(addToast(CANCEL_DOCUMENT_REQUEST_SUCCESS_TOAST_CONFIG))
  } catch (err) {
    yield put(cancelDocumentRequestAction.failure())
    yield put(addToast(CANCEL_DOCUMENT_REQUEST_FAILED_TOAST_CONFIG))
  }
}

export function* watchRequestOtherDocs() {
  yield takeLatest(REQUEST_OTHER_DOC_CALL, requestOtherDocs)
}

export function* watchDownloadOtherDocs() {
  yield takeEvery(FETCH_DOWNLOAD_TOKEN_CALL, downloadOtherDocs)
}

export function* watchGetOtherDocsSaga() {
  yield takeLatest(GET_OTHER_DOCS_CALL, getOtherDocsSaga)
}

export function* watchCancelDocumentRequestSaga() {
  yield takeLatest(CANCEL_DOCUMENT_REQUEST_CALL, cancelDocumentRequest)
}

export function* watchDownloadDocumentFileSaga() {
  yield takeLatest(DOWNLOAD_DOCUMENT_FILE_CALL, downloadDocumentFile)
}

function* rootSaga() {
  yield all([
    watchRequestOtherDocs(),
    watchDownloadOtherDocs(),
    watchGetOtherDocsSaga(),
    watchCancelDocumentRequestSaga(),
    watchDownloadDocumentFileSaga(),
  ])
}

export default rootSaga
