import React from 'react'
import get from 'lodash/get'
import styled from 'styled-components'
import { COLOR } from 'styles/settings'
import { generateHeaderName } from 'legacy/components/reports-v2/share/function'

import {
  FlexInfoContainer,
  ScreeningInfoContentWrapper,
} from 'components/reports/screening-reports/share/responsive-doc-style'
import { HalfWidthInfoField } from 'components/reports/screening-reports/share/responsive-doc-components'
import { parseRenterData } from 'components/reports/helpers'

const CaseResultContainer = styled.div`
  border: solid 2px ${COLOR.lightTextGrey};
  border-bottom: solid 1px ${COLOR.lightTextGrey};
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 50px;
`

const CaseHeaderWrapper = styled.div`
  font-size: 17px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;
  height: 55px;
`

const CaseHeader = ({ sanction }) => (
  <CaseHeaderWrapper style={{ background: '#f5f5f5' }}>
    <div>
      <p style={{ padding: '15px' }}>{sanction.sanctionsList}</p>
    </div>
    {get(sanction, 'caseNumber')}
  </CaseHeaderWrapper>
)

export const CaseAnnotationLine = styled.span`
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  color: ${COLOR.black};
`

export const SanctionCase = ({ completedDate, sanction, searchStrategy }) => (
  <div>
    <SanctionCaseSection
      completedDate={completedDate}
      sanction={sanction}
      searchStrategy={searchStrategy}
    />
  </div>
)

const SanctionCaseSection = ({ completedDate, sanction, searchStrategy }) => (
  <div style={{ width: '100%' }}>
    <CaseHeader sanction={sanction} />
    <CaseResultContainer>
      <SanctionCaseDetails
        completedDate={completedDate}
        sanction={sanction}
        searchStrategy={searchStrategy}
      />
    </CaseResultContainer>
  </div>
)

const SanctionCaseDetails = ({ completedDate, sanction, searchStrategy }) => {
  const sanctionAddress = sanction.address
  const sanctionName = sanction.name
  const cityAndState = `${sanctionAddress?.city || '-'}, ${sanctionAddress?.state || '-'}`
  const { day, month, year } = sanction.partialDob || '-'
  const sanctionFullName = `${sanctionName?.firstName || '-'} ${sanctionName?.middleName || ''} ${
    sanctionName?.lastName || '-'
  } `

  return (
    <FlexInfoContainer>
      <ScreeningInfoContentWrapper>
        <HalfWidthInfoField label="Name" value={sanctionFullName} />
        <HalfWidthInfoField label="DOB" value={`${day || '-'}/${month || '-'}/${year || '-'}`} />
        <HalfWidthInfoField label="SSN" value="***-***-****" />
        <HalfWidthInfoField label="Street Address" value={sanctionAddress?.streetName || '-'} />
        <HalfWidthInfoField label="City & State" value={cityAndState} />
        <HalfWidthInfoField label="County" value={sanctionAddress?.county || '-'} />
        <HalfWidthInfoField label="Zip Code" value={sanctionAddress?.zip || '-'} />
        <HalfWidthInfoField label="Search Strategy" value={searchStrategy} />
        <HalfWidthInfoField label="Date Completed" value={completedDate} />
      </ScreeningInfoContentWrapper>
    </FlexInfoContainer>
  )
}

export const SexOffenseCase = ({ completedDate, sexOffenseCase, searchStrategy }) => (
  <div>
    <SexOffenseCaseSection
      completedDate={completedDate}
      sexOffenseCase={sexOffenseCase}
      searchStrategy={searchStrategy}
      sexOffenseLocation={sexOffenseCase.state}
    />
  </div>
)

const SexOffenseCaseSectionHeader = ({ sexOffenseLocation }) => (
  <CaseHeaderWrapper>
    <div>
      <p style={{ padding: '15px' }}>{sexOffenseLocation}</p>
    </div>
  </CaseHeaderWrapper>
)

const SexOffenseCaseSection = ({
  completedDate,
  sexOffenseLocation,
  sexOffenseCase,
  searchStrategy,
}) => (
  <div style={{ width: '100%' }}>
    <SexOffenseCaseSectionHeader sexOffenseLocation={sexOffenseLocation} />
    <CaseResultContainer>
      <SexOffenseCaseDetails
        completedDate={completedDate}
        sexOffenseCase={sexOffenseCase}
        searchStrategy={searchStrategy}
      />
    </CaseResultContainer>
  </div>
)

const SexOffenseCaseDetails = ({ completedDate, sexOffenseCase, searchStrategy }) => {
  // the address being passed back is actually null so we have to ? it
  const sexOffenseAddress = sexOffenseCase.defendant.address
  const cityAndState = `${sexOffenseAddress?.city || '-'}, ${sexOffenseAddress?.state || '-'}`
  const { day, month, year } = sexOffenseCase.defendant.partialDob

  return (
    <FlexInfoContainer>
      <ScreeningInfoContentWrapper>
        <HalfWidthInfoField
          label="Defendant Name"
          value={generateHeaderName(sexOffenseCase.defendant.name)}
        />
        <HalfWidthInfoField label="DOB" value={`${day}/${month}/${year}`} />
        <HalfWidthInfoField label="Street Address" value={sexOffenseAddress} />
        <HalfWidthInfoField label="City & State" value={cityAndState} />
        <HalfWidthInfoField label="County" value={sexOffenseCase.metadata.location.county} />
        <HalfWidthInfoField label="Zip" value={sexOffenseAddress?.zip} />
        <HalfWidthInfoField label="Search Strategy" value={searchStrategy} />
        <HalfWidthInfoField label="Location" value={sexOffenseCase.metadata.location.state} />
        <HalfWidthInfoField label="Completion Date" value={completedDate} />
      </ScreeningInfoContentWrapper>
    </FlexInfoContainer>
  )
}

export const CriminalCase = ({ completedDate, criminalCase, searchStrategy }) => (
  <div>
    <div>
      <CriminalCaseSection
        completedDate={completedDate}
        criminalCase={criminalCase}
        searchStrategy={searchStrategy}
      />
    </div>
    <div>
      {criminalCase.offenses?.map((offense, index) => (
        <OffenseCase key={index} offenseCase={offense} />
      ))}
    </div>
  </div>
)

const CriminalCaseSectionHeader = ({ criminalCase }) => (
  <CaseHeaderWrapper>
    <>
      <b style={{ fontSize: '16px' }}>{criminalCase.cityAndState}</b>
      <div>
        <p>
          <b>Case #: </b>
          {criminalCase.caseNumber}
        </p>
      </div>
    </>
  </CaseHeaderWrapper>
)

const CriminalCaseSection = ({ completedDate, criminalCase, searchStrategy }) => (
  <div style={{ width: '100%' }}>
    <CriminalCaseSectionHeader criminalCase={criminalCase} />
    <CaseResultContainer>
      <CriminalCaseDetails
        completedDate={completedDate}
        criminalCase={criminalCase}
        searchStrategy={searchStrategy}
      />
    </CaseResultContainer>
  </div>
)

const CriminalCaseDetails = ({ completedDate, criminalCase, searchStrategy }) => {
  const { day, month, year } = criminalCase.defendant.partialDob
  const ssn = criminalCase.defendant.partialSsn
  const criminalCaseAddress = criminalCase.defendant.address
  const cityAndState = `${criminalCaseAddress?.city || '-'}, ${criminalCaseAddress?.state || '-'}`

  return (
    <FlexInfoContainer>
      <ScreeningInfoContentWrapper>
        <HalfWidthInfoField
          label="Defendant Name"
          value={generateHeaderName(criminalCase.defendant.name)}
        />
        <HalfWidthInfoField label="DOB" value={`${day}/${month}/${year}`} />
        <HalfWidthInfoField
          label="SSN"
          value={`${ssn.firstThree || '-'}-${ssn.middleTwo || '-'}-${ssn.lastFour || '-'}`}
        />
        <HalfWidthInfoField label="Gender" value={criminalCase.defendant.gender} />
        <HalfWidthInfoField label="Street Address" value={criminalCaseAddress?.addressLine1} />
        <HalfWidthInfoField label="City & State" value={cityAndState} />
        <HalfWidthInfoField label="Court Name" value={criminalCase.court.name} />
        <HalfWidthInfoField label="Court County" value={criminalCase.court.county} />
        <HalfWidthInfoField label="Zip" value={criminalCaseAddress?.zip} />
        <HalfWidthInfoField label="File Date" value={criminalCase.fileDate} />
        <HalfWidthInfoField label="Search Strategy" value={searchStrategy} />
        <HalfWidthInfoField label="Date Completed" value={completedDate} />
      </ScreeningInfoContentWrapper>
    </FlexInfoContainer>
  )
}

export const OffenseCase = ({ offenseCase }) => (
  <div>
    <OffenseSection offenseCase={offenseCase} />
  </div>
)

const OffenseSection = ({ offenseCase }) => (
  <div style={{ width: '100%' }}>
    <CaseResultContainer>
      <OffenseDetails offenseCase={offenseCase} />
    </CaseResultContainer>
  </div>
)

const OffenseDetails = ({ offenseCase }) => (
  <FlexInfoContainer>
    <ScreeningInfoContentWrapper>
      <HalfWidthInfoField label="Offense" value={offenseCase.description} />
      <HalfWidthInfoField label="Level" value={offenseCase.level} />
      <HalfWidthInfoField label="Disposition" value={offenseCase.disposition} />
      <HalfWidthInfoField label="Disposition Date" value={offenseCase.dispositionDate} />
    </ScreeningInfoContentWrapper>
  </FlexInfoContainer>
)
