import { TextInput } from '@rentspree/component-2023.components.atoms.text-input'

import useMobile from 'hooks/use-mobile'
import { MOBILE_THRESHOLD } from 'utils/constants'
import React from 'react'
import './styles.scss'

// props type: TextInputProps + below declared props
export const DateInputWithComputedMonths = props => {
  const {
    value,
    computedMonths,
    name,
    label,
    error,
    helperText,
    InputLabelProps,
    inputProps,
    endAdornment,
  } = props

  const isMobile = useMobile(MOBILE_THRESHOLD)
  const showComputedMonths = Boolean(computedMonths && computedMonths > 0) && !isMobile 

  return (
    <div className="date-input-with-computed-months">
      <TextInput
        {...props}
        type="date"
        endAdornment={endAdornment}
        defaultValue=""
        value={value}
        label={label}
        name={name}
        error={error}
        helperText={helperText}
        InputLabelProps={{
          ...InputLabelProps,
          shrink: true,
        }}
        inputProps={{
          ...inputProps,
          role: 'textbox',
        }}
      />
      {showComputedMonths && (
        <div className="computed-months">
          ({computedMonths} months)
        </div>
      )}
    </div>
  )
}