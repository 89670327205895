import React from 'react'

import Typography from '@rentspree/component-2023.components.atoms.typography'
import { MODAL_STATUS } from './constants'

const { ARCHIVE, UNARCHIVE } = MODAL_STATUS

const Body = ({ type }) => {
  switch (type) {
    case ARCHIVE:
      return (
        <Typography variant="body-medium">
          The listing page will remain visible to the public but will be shown as unavailable. The
          property will move to the archived properties section of your dashboard.
        </Typography>
      )
    case UNARCHIVE:
      return (
        <Typography variant="body-medium">
          This property will be active and move to the all properties section.
        </Typography>
      )
    default:
      return <Typography variant="body-medium">something went wrong, please try again</Typography>
  }
}

export default Body
