import React, { useState, useCallback } from 'react'
import debounce from 'lodash/debounce'
import Box from '@mui/material/Box'
import {
  FilterButton,
  FilterText,
  FilterSelect,
} from '@rentspree/component-2023.components.atoms.filter'
import MenuItem from '@mui/material/MenuItem'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import SearchIcon from '@mui/icons-material/Search'
import { Sheet, SheetHeader } from '@rentspree/component-2023.components.templates.sheet'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@rentspree/component-2023.components.atoms.radio'
import RadioGroup from '@rentspree/component-2023.components.atoms.radio-group'
import FilterListIcon from '@mui/icons-material/FilterList'
import { useMediaQuery } from 'v3/hooks/use-media-query'

import './filter-bar.scss'

export const TenantScreeningFilterBar = ({
  filterBarStatus,
  filterBarOptions,
  handleFilterBarStatus,
  handleFilterBarSearch,
  searchQueries,
}) => {
  const [inputValue, setInputValue] = useState(searchQueries.search)
  const [isOpenedSheet, setOpenSheet] = useState(false)
  const isMobile = useMediaQuery('(max-width: 1016px)')

  const statusLabel =
    filterBarOptions?.find(option => option.value === filterBarStatus)?.label || ''

  const debouncedSearch = useCallback(
    debounce(query => handleFilterBarSearch(query), 300),
    [],
  )

  const handleSearchBar = e => {
    e.preventDefault()
    const searchText = e.target.value
    setInputValue(searchText)
    debouncedSearch(searchText)
  }

  const handleSheetOnOpen = () => {
    setOpenSheet(true)
  }

  const handleSheetOnClose = () => {
    setOpenSheet(false)
  }

  return (
    <Box className="screening-table-filter-bar-container">
      <FilterSelect
        data-testid="screening-table-filter-bar-desktop-status"
        className="filter-bar-desktop-status"
        defaultValue={filterBarStatus}
        onChange={handleFilterBarStatus}
      >
        {filterBarOptions.map((option, index) => (
          <MenuItem key={`screening-table-filter-bar-option-${index}`} value={option.value}>
            <FiberManualRecordIcon
              sx={{ color: `${option.iconColor} !important`, marginRight: '8px' }}
            />{' '}
            {option.label}
          </MenuItem>
        ))}
      </FilterSelect>
      <FilterText
        className="screening-table-filter-search-text"
        value={inputValue}
        startAdornment={<SearchIcon />}
        placeholder="Search"
        inputProps={{
          'data-testid': 'screening-table-filter-bar-search-input',
          maxLength: 50,
        }}
        onChange={handleSearchBar}
      />
      <Box
        className="filter-bar-mobile-status"
        data-testid="screening-table-filter-bar-mobile-status"
      >
        <FilterButton
          data-testid="screening-table-filter-bar-mobile-button"
          className="screening-table-filter-status-mobile-button"
          size="small"
          startIcon={<FilterListIcon />}
          onClick={handleSheetOnOpen}
          sx={{ width: '170px' }}
        >
          {statusLabel}
        </FilterButton>
        <Sheet
          data-testid="screening-table-filter-bar-mobile-sheet"
          open={isOpenedSheet}
          onOpen={handleSheetOnOpen}
          onClose={handleSheetOnClose}
        >
          <SheetHeader header="Status" />
          <RadioGroup
            data-testid="screening-table-filter-bar-mobile-radio-group"
            sx={{ alignContent: 'left', padding: '16px' }}
            defaultValue={filterBarStatus}
            onChange={e => {
              setOpenSheet(false)
              handleFilterBarStatus(e)
            }}
          >
            {filterBarOptions.map((option, index) => (
              <FormControlLabel
                key={`screening-table-filter-bar-mobile-radio-${index}`}
                value={option.value}
                control={
                  <Radio data-testid={`screening-table-filter-bar-mobile-radio-${option.label}`} />
                }
                label={option.label}
              />
            ))}
          </RadioGroup>
        </Sheet>
      </Box>
    </Box>
  )
}
