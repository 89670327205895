import { PAYMENT_TYPES } from 'v3/containers/overhaul-rent-payment/constants'
import { abbreviatedDate, nthDay } from 'v3/containers/overhaul-rent-payment/pages/utils'

export const computeAmount = ({ type, amount, startDate }) => {
  const amountDue = `${parseFloat(amount)?.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })} due`

  if (type === PAYMENT_TYPES.RECURRING) {
    return `${amountDue} ${nthDay(startDate)} of the month`
  }
  return `${amountDue} ${abbreviatedDate(startDate)}`
}

export const computeDue = ({ type, startDate, endDate }) => {
  if (type === PAYMENT_TYPES.RECURRING) {
    if (endDate) return `${abbreviatedDate(startDate)} - ${abbreviatedDate(endDate)}`
    return `Month-to-month starting ${abbreviatedDate(startDate)}`
  }
  return ''
}
