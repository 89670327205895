import { B14, B16, B18, S16, S18, T36 } from '@rentspree/component-v2'
import React from 'react'
import styled from 'styled-components'
import * as Styled from './text-style'

export const Text = ({ children, ...props }) => (
  <Styled.TextInput {...props}>{children}</Styled.TextInput>
)

export const Label = ({ children, ...props }) => <Styled.Label {...props}>{children}</Styled.Label>

export const I = ({ children, ...props }) => <Styled.I {...props}>{children}</Styled.I>

export const P = ({ children, ...props }) => <Styled.P {...props}>{children}</Styled.P>

export const PNormal = ({ children, ...props }) => (
  <Styled.WithP {...props}>{children}</Styled.WithP>
)

export const TextDiv = ({ children, ...props }) => (
  <Styled.TextDiv {...props}>{children}</Styled.TextDiv>
)

export const SOURCE_SANS_PRO_FONT_FAMILY = `font-family: "Source Sans Pro",sans-serif;`

export const B12 = styled.p`
  ${SOURCE_SANS_PRO_FONT_FAMILY}
  ${props => props.fontFamily && `font-family: "Source Sans Pro",${props.fontFamily};`}
  ${props => (props.fontSize ? `font-size: ${props.fontSize}` : '12px')};
  font-weight: 400;
  line-height: 16px;
  ${props => props.color && `color: ${props.color}`};
  ${props => props.margin && `margin: ${props.margin}`};
`

export const S14 = styled.h4`
  ${SOURCE_SANS_PRO_FONT_FAMILY}
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  ${props => props.color && `color: ${props.color}`};
  ${props => props.margin && `margin: ${props.margin}`};
`

export const S28 = styled.h2`
  ${SOURCE_SANS_PRO_FONT_FAMILY}
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  ${props => props.center && `text-align: center`};
  ${props => props.color && `color: ${props.color}`};
  ${props => props.margin && `margin: ${props.margin}`};
  @media (max-width: 991px) {
    margin: ${props => props.mMargin};
  }
`

export const SourceSansProB14 = styled(B14)`
  ${SOURCE_SANS_PRO_FONT_FAMILY}
`
export const SourceSansProB16 = styled(B16)`
  ${SOURCE_SANS_PRO_FONT_FAMILY}
`
export const SourceSansProB18 = styled(B18)`
  ${SOURCE_SANS_PRO_FONT_FAMILY}
`
export const SourceSansProS16 = styled(S16)`
  ${SOURCE_SANS_PRO_FONT_FAMILY}
`
export const SourceSansProS18 = styled(S18)`
  ${SOURCE_SANS_PRO_FONT_FAMILY}
`

export const SourceSansProT36 = styled(T36)`
  ${SOURCE_SANS_PRO_FONT_FAMILY}
`
