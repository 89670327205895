import React from 'react'
import styled from 'styled-components'
import { B16 } from '@rentspree/component-v2'

import { COLOR } from 'components/constants'
import { TU_CONTACT } from 'constants/partner'

const BannerContainer = styled.div`
  text-align: center;
  background-color: ${COLOR.mediumGrey};
  text-wrap: wrap;
  padding: 5px 50px;
`

const ContactTransUnionBanner = ({ isMobile, reportType = '' }) => (
  <BannerContainer>
    <B16
      data-testid={`apply_reportReview_${reportType}ContactBanner`}
      lineHeight="20px"
      margin="auto"
      color={COLOR.black}
    >
      {`If you find an error${isMobile ? '' : ' below'}, call TransUnion at ${TU_CONTACT.PHONE}.`}
    </B16>
  </BannerContainer>
)

const ReportHeaderContainer = styled.div`
  margin: 0;
  width: 100%;
`
const Title = styled.div`
  background-color: ${COLOR.newBlack};
  color: white;
  padding: 0.5em;
  padding-left: 1em;
  border-radius: 10px 10px 0px 0px;
  font-weight: bold;
  font-size: 18px;
`

export const ReportSectionHeader = ({title, isMobile, reportType}) => (
  <ReportHeaderContainer data-testid={`apply_reportReview_${reportType}SectionHeader`}>
    <Title>{title}</Title>
    <ContactTransUnionBanner isMobile={isMobile} reportType={reportType}/>
  </ReportHeaderContainer>
)