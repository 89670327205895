export const LOCAL_STORAGE_KEYS = [
    // User session
    "queryParams",
    "rentspreeSubscriptions",
    "rentspreeUserTokenTemp",
    "rentspreeTempUser",
    // Agent dashboard application,
    "contact_detail_collapse_state", // rcm
    "contact_dont_show_redirect_countdown", // rcm
    "share_prompt_property_skip_list", // share report landlord reminder
    "rp.overridePayeeIdvUntilTimestamp", // rent payment
    "rp.defaultFeeCollection", // rent payment
    "drafting_for_landlord", // rent payment
    "notifyListingSyndication", // announcement
    "notifyCollectRent", // announcement
  ]
  
  // TODO: Set standard for local storage with prefix key, implement clear all prefix local storage in a library
  export const removeAllLocalStorageItems = () => {
    LOCAL_STORAGE_KEYS.forEach(key => {
      localStorage.removeItem(key)
    })
  }