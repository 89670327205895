/* eslint-disable camelcase */
import React, { useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import isEmpty from 'lodash/isEmpty'

import disableFeature from 'utils/feature-toggle/instance'
import * as PATH from 'constants/route'
import { replaceURLToFormat } from 'utils/path'
import tracker from 'tracker'
import { EVENT_ROUTE } from 'tracker/const'
import AcceptDenyModal from 'containers/accept-deny'
import InviteLandlordRentPaymentModal from 'containers/rent-payment/invite-landlord-modal'
import AssignPropertyModal from 'containers/tenant-screening/assign-property/modal'
import { LastLocationProvider } from 'react-router-last-location'
import CreateListingStepper from 'containers/create-listing/create-listing-stepper/loadable'
import CreateListingRedirect from 'containers/create-listing/create-listing-redirect/loadable'
import Widget from 'containers/contacts/import-csv/widget'
import TenantScreeningPaymentProcessing from 'containers/tenant-screening-payment-processing'
import RentEstimatePaymentProcessing from 'containers/rent-estimate-payment-processing'
import ImportListingLanding from 'containers/import-listing'
import { useBadge } from 'hooks/use-badge'
import GenerateLimitPage from 'containers/reports/generate-limit'
import ReportPollingDownload from 'containers/reports/generate/report-polling-download'
import AutoImportListing from 'containers/import-listing/auto-import'
import { useSelector } from 'react-redux'
import { selectProfile, selectIsFetchedUser } from 'containers/user/selectors'
import ViewDocumentPage from 'containers/view-document-page/loadable'
import SelectAgentType from 'containers/tenant-screening/request/select-agent-type'
import { RentPaymentRouter } from 'v3/containers/rent-payment/router'
import { DisableFeatureProvider } from '@rentspree/feature-toggle'
import { PREFIX_V3 } from 'v3/routers/path'

import AppNavigation from 'containers/app-navigation/app-navigation'

import { SnackbarProvider } from 'v3/components/snackbar'

import { MainContentRoute } from './main-content-route'
import RequestApp from './legacy/containers/request/request-main'
import AgentWrapper from './legacy/containers/layout/wrapper'
import ReportDownload from './legacy/containers/report/report-download'
import GettingStarted from './legacy/containers/static-page/getting-started'
import DocumentDownloadContainer from './legacy/containers/document/document-download'
import { BodyContent } from './components/layout/main'
import { withTracker } from './legacy/containers/with-tracker'
import SSOHandler from './legacy/containers/sso-handler'
import { withGlobalStyle } from './legacy/styles'
import { ProfileWrapper } from './containers/wrapper/profile-wrapper'
import { TenantScreeningRoute } from './containers/tenant-screening/router'
import ReferralFormPage from './legacy/containers/static-page/referral-form'
import RouteProContainer from './legacy/containers/pro/route-pro'
import Messaging from './containers/messaging'
import PrintReportPage from './containers/print-report-page'
import RentEstimate from './containers/rent-estimate/loadable'
import Popper from './containers/popper'
import PopperStyle from './containers/popper/popper-global-style'
import Explore from './containers/explore/loadable'
import GetStarted from './containers/get-started/get-started-postponer'
import MessagingWithNavbar from './containers/messaging/with-navbar'
import LeaseAgreementFormPage from './containers/lease-agreement-form/loadable'
import LeaseAgreementEditForm from './containers/lease-agreement-edit/loadable'
import PropertyUpdatePropertyTypeContainer from './containers/property-propertyType'
import GetPropertyListContainer from './containers/renters-insurance-property'
import RenterInsuranceRequest from './containers/renter-insurance-request/loadable'
import AgreementPage from './containers/agreement/loadable'
import RenterInsuranceLandingPage from './containers/renter-insurance-landing-page/loadable'
import ContactsRoute from './containers/contacts/router'
import ContactRedirectPage from './containers/contacts/redirect/loadable'
import TasksRoute from './containers/tasks/router'
import AssignProperty from './containers/tenant-screening/assign-property/loadable'
import RequestTenantScreeningStepActions from './containers/tenant-screening/request/step-actions'
import RequestTenantScreeningStepActionV2_2 from './containers/tenant-screening/request-v2.2/step-actions'
import RequestWithOutPropertyStepOptions from './containers/tenant-screening/request/loadable'
import SelectProperty from './containers/tenant-screening/request/select-property/loadable'
import ImportCsv from './containers/contacts/import-csv/loadable'
import Toast from './containers/toast'
import ListingLanding from './containers/select-property-listing'
import ListingRequestCreateScreeningOptions from './containers/create-listing/listing-request-create-screening-options'
import ShareApplyLink from './containers/share-apply-link'
import ShareListing from './containers/share-listing'
import { LazyCreateProperty } from './v3/containers/create-property-form'

import RouterV3 from './v3/routers'

const RedirectRequestPath = props => (
  <Redirect to={{ pathname: PATH.REQUEST, search: props.location.search }} />
)

const RedirectGettingStarted = props => (
  <Redirect to={{ pathname: PATH.HOW_TO, search: props.location.search }} />
)

const RedirectESignPath = ({ location }) => {
  const eSignURL = location.pathname.replace('/rental-documents', PATH.LEASE)
  return <Redirect to={{ pathname: eSignURL, search: location.search }} />
}

const PrintReportsPage = withTracker(SSOHandler(PrintReportPage))

export const Router = ({ history }) => {
  const profile = useSelector(selectProfile)
  const isUserFetched = useSelector(selectIsFetchedUser())
  // TODO: Not being used, mark for delete
  useBadge()

  useEffect(() => {
    const { pathname = '' } = history.location
    const urlFormat = replaceURLToFormat(pathname)

    if (isUserFetched && !isEmpty(profile)) {
      tracker.trackEvent(EVENT_ROUTE.ENTER_DASHBOARD, { url: urlFormat })
    }
  }, [isUserFetched])

  const subdomain = window.location.host.split('.')[0]

  return (
    <ConnectedRouter history={history}>
      <LastLocationProvider>
        <Switch>
          {/* ===================== Print Reports Pages Zone ===================== */}
          {/**
           * TODO: should remove old print reports page in the future after releasing the new print reports page
           */}
          <Route path={PATH.OLD_PRINT_REPORTS_PAGE} component={PrintReportsPage} />
          <Route path={PATH.OLD_PRINT_REPORTS_IFRAME_PAGE} component={PrintReportsPage} />
          <Route path={PATH.PRINT_REPORTS_PAGE} component={PrintReportsPage} />
          <Route path={PATH.PRINT_REPORTS_RENTER_PAGE} component={PrintReportsPage} />
          <Route path={PATH.PRINT_REPORTS_IFRAME_PAGE} component={PrintReportsPage} />
          {/* ===================== End of Print Reports Pages Zone ===================== */}

          {/* ===================== Download Reports Pages Zone ===================== */}
          <Route path={PATH.DOCUMENT} component={withTracker(DocumentDownloadContainer)} />
          <Route path={PATH.REPORT_TYPE} component={withTracker(ReportDownload)} />
          <Route path={PATH.GENERATE_REPORTS_PAGE} component={withTracker(ReportPollingDownload)} />
          {/* ===================== End of Download Reports Pages Zone ===================== */}

          {/* ===================== Reports Error Pages Zone ===================== */}
          <Route path={PATH.REPORT_GENERATION_LIMIT_PAGE} component={GenerateLimitPage} />
          {/* ===================== End of Reports Error Pages Zone ===================== */}

          {/* ===================== Lease Agreement Pages Zone ===================== */}
          <Route
            path={`${PATH.PROPERTY}/:propertyId/rental-documents`}
            render={RedirectESignPath}
          />
          {/* ===================== End of Lease Agreement Pages Zone ===================== */}

          {/* ===================== Messaging Tool Page Zone ===================== */}
          <Route path={PATH.PUBLIC_MESSAGING_PAGE} component={MessagingWithNavbar} />
          {/* ===================== End of Messaging Tool Page Zone ===================== */}

          {/* ===================== Contact Redirect Page Zone ===================== */}
          <Route path={PATH.CONTACT_REDIRECT} component={ContactRedirectPage} />
          {/* ===================== End of Contact Redirect Page Zone ===================== */}
          <ProfileWrapper>
            <SnackbarProvider>
              <AppNavigation>
                <DisableFeatureProvider
                  subdomain={subdomain || 'rentspree'}
                  disableFeature={disableFeature}
                >
                  <AcceptDenyModal />
                  <InviteLandlordRentPaymentModal />
                  <AssignPropertyModal />
                </DisableFeatureProvider>
                <Toast />
                <LazyCreateProperty />
                <Widget />
                <Switch>
                  <Route exact path={PATH.VIEW_DOCUMENT} component={ViewDocumentPage} />
                  <Route exact path={PATH.VIEW_SINGLE_DOCUMENT_FILE} component={ViewDocumentPage} />
                  <Route path={PATH.AGREEMENT_PAGE} component={AgreementPage} />
                  <AgentWrapper id="wrapper">
                    <Switch>
                      <Route
                        exact
                        path={PATH.SINGLE_APPLICATION_PAYMENT_PROCESSING}
                        component={TenantScreeningPaymentProcessing}
                      />
                      <Route
                        exact
                        path={PATH.TENANT_SCREENING_WITH_RENTAL_ID_PAYMENT_PROCESSING}
                        component={TenantScreeningPaymentProcessing}
                      />
                      <Route
                        exact
                        path={PATH.RENT_ESTIMATE_PAYMENT_PROCESSING}
                        component={RentEstimatePaymentProcessing}
                      />
                      <Route path={PATH.REQUEST} component={withTracker(RequestApp)} />
                      <Route
                        path={PATH.RENTER_INSURANCE_PROPERTY}
                        component={GetPropertyListContainer}
                      />
                      <Route
                        exact
                        path={PATH.PROPERTY_TYPE_FORM_PAGE}
                        component={PropertyUpdatePropertyTypeContainer}
                      />
                      <Route path="/request-application" render={RedirectRequestPath} />
                      {/* ===================== Lease Agreements Page Zone ===================== */}
                      <Route
                        exact
                        path={PATH.LEASE_AGREEMENT_EDIT}
                        component={LeaseAgreementEditForm}
                      />
                      {/* TODO: [ESign-Legacy-Improvement] - Deprecated this component -> go to new ESIGN_DASHBOARD_SELECT_TEMPLATE */}
                      <Route
                        exact
                        path={PATH.LEASE_AGREEMENT_REQUEST}
                        component={LeaseAgreementFormPage}
                      />
                      {/* ===================== End of Lease Agreements Page Zone ===================== */}
                      <Route
                        path={PATH.RENTER_INSURANCE_REQUEST_PAGE}
                        component={RenterInsuranceRequest}
                      />
                      <Route path={PATH.RENT_PAYMENT_REQUEST_PAGE} component={RentPaymentRouter} />
                      <Route
                        exact
                        path={[
                          PATH.RENT_PAYMENT_ONBOARDING_REDIRECT,
                          PATH.RENT_PAYMENT_VERIFY_TAX,
                          PATH.RENT_PAYMENT_VERIFY_TAX_WITH_STRIPE_EMBEDDED,
                        ]}
                        component={RentPaymentRouter}
                      />

                      <Route exact path={PATH.ASSIGN_PROPERTY} component={AssignProperty} />

                      <Route
                        path={PATH.SCREENING_NEW_TENANT_ACTION}
                        component={RequestTenantScreeningStepActions}
                      />
                      <Route
                        path={PATH.SCREENING_NEW_TENANT_ACTION_V2}
                        component={RequestTenantScreeningStepActionV2_2}
                      />
                      <Route
                        exact
                        path={PATH.SCREENING_NEW_TENANT}
                        component={RequestWithOutPropertyStepOptions}
                      />
                      <Route
                        exact
                        path={PATH.SELECT_PROPERTY_BEFORE_SCREENING_NEW_TENANT}
                        component={SelectProperty}
                      />
                      <Route exact path={PATH.CONTACT_IMPORT_CSV} component={ImportCsv} />
                      <Route exact path={PATH.SELECT_AGENT_TYPE} component={SelectAgentType} />
                      <Route
                        exact
                        path={PATH.SELECT_AGENT_TYPE_WITH_PROPERTY_ID}
                        component={SelectAgentType}
                      />

                      {/* ===================== Listing Syndication Page Zone ===================== */}

                      <Route
                        path={PATH.LISTING_LANDING_SELECT_PROPERTY_PAGE}
                        component={ListingLanding}
                      />
                      <Route
                        path={PATH.CREATE_LISTING_REDIRECT}
                        component={CreateListingRedirect}
                      />
                      <Route
                        path={PATH.CREATE_LISTING_INFORMATION_STEP}
                        component={CreateListingStepper}
                      />
                      <Route
                        path={PATH.CREATE_LEASING_INFORMATION_STEP}
                        component={CreateListingStepper}
                      />
                      <Route
                        path={PATH.CREATE_LISTING_DESCRIPTION_STEP}
                        component={CreateListingStepper}
                      />
                      <Route
                        path={PATH.CREATE_UTILITIES_AMENITIES_STEP}
                        component={CreateListingStepper}
                      />
                      <Route path={PATH.SHARE_LISTING_STEP} component={ShareListing} />
                      <Route path={PATH.SHARE_APPLY_LINK} component={ShareApplyLink} />
                      <Route path={PATH.CREATE_PREVIEW_STEP} component={CreateListingStepper} />

                      <Route path={PATH.IMPORT_MLS_LISTING} component={ImportListingLanding} />
                      <Route
                        path={PATH.LISTING_REQUEST_CREATE_SCREENING_OPTIONS}
                        component={ListingRequestCreateScreeningOptions}
                      />

                      <Route path={PATH.AUTO_IMPORT_MLS_LISTING} component={AutoImportListing} />
                      {/* ===================== End of Listing Syndication Page Zone ===================== */}

                      <BodyContent>
                        <Switch>
                          <Route path={PATH.MESSAGING_PAGE} component={Messaging} />
                          <Route path={PATH.GETTING_STARTED} render={RedirectGettingStarted} />
                          <Route path={PATH.HOW_TO} component={withTracker(GettingStarted)} />
                          <Route
                            path={PATH.REFERRAL_FORM_PAGE}
                            component={withTracker(ReferralFormPage)}
                          />
                          <Route path={PATH.PRO_PAGE} component={withTracker(RouteProContainer)} />
                          <Route exact path={PATH.RENT_ESTIMATE_PAGE} component={RentEstimate} />

                          {/* ===================== Start of Rental Payment - Main Listing ===================== */}
                          <Route path={PATH.RENT_PAYMENT} component={RentPaymentRouter} />
                          {/* ===================== End of Rental Payment - Main Listing ===================== */}

                          <Route path={PATH.TENANT_SCREENING} component={TenantScreeningRoute} />
                          <Route path={PATH.CONTACTS} component={ContactsRoute} />
                          <Route
                            exact
                            path={PATH.RENTER_INSURANCE}
                            component={RenterInsuranceLandingPage}
                          />
                          <Route exact path={PATH.EXPLORE_PAGE} component={Explore} />
                          <Route path={PATH.TASKS} component={TasksRoute} />
                          <Route path={PREFIX_V3} component={RouterV3} />
                          <MainContentRoute />
                        </Switch>
                        <GetStarted />
                        {/* <BadgeContainer /> */}
                      </BodyContent>
                    </Switch>
                  </AgentWrapper>
                </Switch>
              </AppNavigation>
            </SnackbarProvider>
          </ProfileWrapper>
        </Switch>
        <PopperStyle />
        <Popper />
      </LastLocationProvider>
    </ConnectedRouter>
  )
}

export default withGlobalStyle(Router)
