export const LEASE_AGREEMENT_PAGE_LEVEL = {
  L1: 'l1',
  L2: 'l2',
}

export const ORIGIN_REFERER = {
  ORIGIN_LEASE_AGREEMENT_L1: 'lease-agreement-l1',
  ORIGIN_LEASE_AGREEMENT_L2: 'lease-agreement-l2',
  ORIGIN_PROPERTY_L1: 'property-l1',
  ORIGIN_APPLICATION_L1: 'application-l1',
  ORIGIN_APPLICATION_L2: 'application-l2',
  ORIGIN_APPLICATION_DETAIL: 'application-detail',
}
