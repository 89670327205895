import React from 'react'
import { string, array, number, object, addMethod, ref } from 'yup'
import moment from 'moment/moment'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { isEmail } from 'validator'
import { FormattedMessage as Message } from 'react-intl'
import { invalidChars } from '../helpers/validator-helper'
import date from '../helpers/yup-date-type'

import message from './error-form-messages'

export const requiredYup = (required = false, yupType = string(), type = 'required') => {
  if (required) {
    let errorMessage = message[type]
    if (type === 'radio') {
      errorMessage = message.radioRequired
    }
    return yupType.required(<Message {...errorMessage} />)
  }
  return yupType
}

export const validateLength = (required = true, min = 2, max = 35, requiredType) => {
  const validate = string()
    .min(min, <Message {...message.minChar} values={{ min }} />)
    .max(max, <Message {...message.maxChar} values={{ max }} />)

  return requiredYup(required, validate, requiredType)
}

export const validateLengthWithTrim = (required = true, min = 2, max = 35) => {
  const validate = string()
    .trim()
    .min(min, <Message {...message.minChar} values={{ min }} />)
    .max(max, <Message {...message.maxChar} values={{ max }} />)

  return requiredYup(required, validate)
}

export const validateDate = (required = true) => {
  const validate = date().typeError(<Message {...message.invalidDate} />)
  return requiredYup(required, validate)
}

export const validateNoFutureDate = (required = true) => {
  const validate = date()
    .typeError(<Message {...message.invalidDate} />)
    .test({
      name: 'dob',
      message: <Message {...message.dobNoFuture} />,
      test: value => moment().diff(moment(value)) >= 0,
    })

  return requiredYup(required, validate)
}

export const validateDob = (required = true) => {
  const validate = date()
    .typeError(<Message {...message.invalidDate} />)
    .min(moment().subtract('125', 'years').format('MM/DD/YYYY'), <Message {...message.maxAge} />)
    .test({
      name: 'dob',
      message: <Message {...message.dobNoFuture} />,
      test: value => moment().diff(moment(value)) >= 0,
    })
    .max(moment().subtract('18', 'years').format('MM/DD/YYYY'), <Message {...message.minAge} />)

  return requiredYup(required, validate)
}
export const validateMaxEndDate = (required = true, r, dateName = 'start date') =>
  requiredYup(required, date())
    .typeError(<Message {...message.invalidDate} />)
    .maxEndDate(ref(r), <Message {...message.endLessThanStart} values={{ dateName }} />)

export const validatePhone = (required = true) => {
  const validate = string().min(10, <Message {...message.invalidPhone} />)
  return requiredYup(required, validate)
}

export const validateContactNumber = (required = true, min = 0, max = 20) => {
  const regex = /^[0-9-()+ ]*$/
  const validate = string()
    .min(min, <Message {...message.minChar} values={{ min }} />)
    .max(max, <Message {...message.maxChar} values={{ max }} />)
    .matches(regex, message.invalidContactNumber.defaultMessage)

  return requiredYup(required, validate)
}

export const validateZipCode = (required = true) => {
  const validate = string()
    .min(5, <Message {...message.invalidZipCode} />)
    .max(5, <Message {...message.invalidZipCode} />)
  return requiredYup(required, validate)
}

export const validateState = (required = true) => {
  const validate = string()
    .min(2, <Message {...message.invalidState} />)
    .max(2, <Message {...message.invalidState} />)
  return requiredYup(required, validate)
}

export const validateTenantEmailIsSameLandlord = (value, LandlordEmail) => {
  if (value === LandlordEmail) {
    return false
  }
  return true
}

export const validateEmailForRentPayment = (required = true, email) => {
  const validate = string()
    .email(<Message {...message.invalidEmail} />)
    .max(255, <Message {...message.maxChar} values={{ max: 255 }} />)
    .test({
      name: 'invalidDuplicatedEmail',
      message: <Message {...message.invalidDuplicatedEmail} />,
      test: values => !email || validateTenantEmailIsSameLandlord(values, email),
    })
    .test({
      name: 'invalidEmail',
      message: <Message {...message.invalidEmail} />,
      test: values => {
        const [localPart, domainPart] = values.split('@')
        if (domainPart !== 'rentspree.com' && localPart.includes('+')) {
          return false
        }
        return /^[a-zA-Z0-9.+-_]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(values)
      },
    })
  return requiredYup(required, validate)
}

export const validateEmail = (required = true, max = 255) => {
  const validate = string()
    .email(<Message {...message.invalidEmail} />)
    .max(max, <Message {...message.maxChar} values={{ max }} />)
  return requiredYup(required, validate)
}
function uniquePropertyTest(value, propertyName, displayMessage) {
  if (
    this.parent
      .filter(v => v !== value)
      .some(
        v => get(v, propertyName, '').toLowerCase() === get(value, propertyName, '').toLowerCase(),
      )
  ) {
    throw this.createError({
      path: `${this.path}.${propertyName}`,
      displayMessage,
    })
  }

  return true
}

function uniqueProperty(propertyName, displayMessage) {
  return this.test('unique', displayMessage, function call(value) {
    return uniquePropertyTest.call(this, value, propertyName, displayMessage)
  })
}

addMethod(object, 'uniqueProperty', uniqueProperty)

export const leaseAgreementRecipientListValidation = () => {
  const recipient = array().of(
    object()
      .shape({
        fullName: validateLength(true, 2, 60),
        email: validateEmail(),
      })
      .uniqueProperty('email', <Message {...message.duplicateEmails} />),
  )
  return recipient
}

export const validateTuEmail = (required = true, min = 0, max = 50) => {
  const validate = string()
    .email(<Message {...message.invalidEmail} />)
    // disallow double quote according to Hubspot
    .matches(/^[^".*"@.*]/, <Message {...message.invalidEmail} />)
    // disallow special characters
    .matches(/^[^!#$%&'*/=?^`{|}~]*$/, <Message {...message.invalidEmailChar} />)
    .min(min, <Message {...message.tuEmailLength} values={{ min }} />)
    .max(max, <Message {...message.tuEmailLength} values={{ max }} />)

  return requiredYup(required, validate)
}

export const validateSharedEmail = (required = true, userEmail, renterEmail) =>
  validateEmail(required, 50)
    .test(
      'isShareWithThemself',
      <Message {...message.sharedWithThemself} />,
      (value = '') => value.toLowerCase() !== userEmail.toLowerCase(),
    )
    .test(
      'isShareWithOwner',
      <Message {...message.sharedWithOwner} />,
      (value = '') => value.toLowerCase() !== renterEmail.toLowerCase(),
    )

export const validateRequestReportPhone = (required = true) => {
  const validate = array()
    .of(
      string().test({
        name: 'invalidPhone',
        message: <Message {...message.invalidPhone} />,
        test: values => {
          const isPhoneNumberCorrectly = values.replace(/\D+/g, '').length !== 10
          if (isPhoneNumberCorrectly) return false
          return true
        },
      }),
    )
    .test({
      name: 'duplicatePhoneNumbers',
      message: <Message {...message.duplicatePhoneNumbers} />,
      test: values => validateDuplicateValues(values),
    })
  return requiredYup(required, validate)
}

export const validateRequestReportEmail = (required = true) => {
  const validate = array()
    .of(
      string().test({
        name: 'validateEmailFormat',
        message: <Message {...message.invalidEmail} />,
        test: value => isEmail(value.trim()),
      }),
    )
    .test({
      name: 'duplicateEmails',
      message: <Message {...message.duplicateEmails} />,
      test: values => validateDuplicateValues(values),
    })
  return requiredYup(required, validate)
}

export const validateDuplicateValues = values => {
  if (Array.isArray(values) && values.length > 0) return new Set(values).size === values.length
  return true
}

export const tuMailingAddressValidation = {
  fullName: {
    regex: /^[a-zA-Z\-\s',]+$/,
    message: "Must match the following: a-z A-Z '-,",
  },
  streetAddress: {
    regex: /^[a-zA-Z0-9\s'#]+$/,
    message: "Must match the following: a-z A-Z 0-9 #'",
  },
  hasNumber: {
    regex: /[0-9]/,
    message: 'Must contain at least 1 number',
  },
  hasLetter: {
    regex: /[a-zA-Z]/,
    message: 'Must contain at least 1 letter',
  },
  city: {
    regex: /^[a-zA-Z\s.'-]+$/,
    message: "Must match the following: a-z A-Z '-.",
  },
  minThreeLetters: {
    regex: /^(?=(?:.*?[a-zA-Z]){3,}).*$/,
    message: 'Must contain at least 3 letters',
  },
  unitNumber: {
    regex: /^[a-zA-Z0-9\s.'#-]+$/,
    message: "Must match the following: a-z A-Z 0-9 '#-",
  },
}

export const commonValidation = {
  streetAddress: {
    regex: /^[a-zA-Z0-9\s#()&,.\-_'~\\/\\*+]+$/,
    message: "Must match the following: a-z A-Z 0-9 #()&,.-_'~/*+",
  },
  streetAddress2: {
    regex: /^[a-zA-Z0-9\s#()&,.\-_'~\\/\\*+]+$/,
    message: "Must match the following: a-z A-Z 0-9 #()&,.-_'~/*+",
  },
}

export const getInvalidTuPropertyMessage = data => {
  const invalidCharacters = invalidChars(data.value, data.regex)
  return (
    <Message
      {...message.invalidCharacterTuProperty}
      values={{ invalidCharacter: invalidCharacters[0] }}
    />
  )
}

export const getHasNumberErrorMessage = () => <Message {...message.hasNumber} />

export const getHasLetterErrorMessage = () => <Message {...message.hasLetter} />

/** @type <T>(value: T | undefined, fallback: T) => T */
const getValueOrFallback = (value, fallback) => (value !== undefined ? value : fallback)

/**
 *  @param {string} field the name of the field
 *  @param {(boolean|undefined)} isRequired if the field value is required
 */
export const updateInfoValidation = (field, isRequired) => {
  switch (field) {
    case 'firstName':
      return validateLengthWithTrim(getValueOrFallback(isRequired, true), 2, 50).matches(
        tuMailingAddressValidation.fullName.regex,
        tuMailingAddressValidation.fullName.message,
      )
    case 'lastName':
      return validateLengthWithTrim(getValueOrFallback(isRequired, true), 2, 50).matches(
        tuMailingAddressValidation.fullName.regex,
        tuMailingAddressValidation.fullName.message,
      )
    case 'phone':
      return validatePhone()
    case 'street':
      return validateLength(getValueOrFallback(isRequired, true), 2, 100).matches(
        commonValidation.streetAddress.regex,
        commonValidation.streetAddress.message,
      )
    case 'unitNumber':
      return validateLength(getValueOrFallback(isRequired, false), 0, 50).matches(
        commonValidation.streetAddress2.regex,
        commonValidation.streetAddress2.message,
      )
    case 'city':
      return validateLength(getValueOrFallback(isRequired, true), 3, 50)
        .matches(tuMailingAddressValidation.city.regex, tuMailingAddressValidation.city.message)
        .matches(
          tuMailingAddressValidation.minThreeLetters.regex,
          tuMailingAddressValidation.minThreeLetters.message,
        )
    case 'state':
      return validateState()
    case 'zip':
      return validateZipCode()
    default:
      return requiredYup(getValueOrFallback(isRequired, false))
  }
}

export const requestPropertyValidation = field => {
  switch (field) {
    case 'street':
      return validateLength(true, 2, 100).matches(
        /^[a-zA-Z0-9\s#()&,.\-_'~\\/\\*+\u00C0-\u00FF\u0100-\u017F]+$/u,
        commonValidation.streetAddress.message,
      )
    case 'unitNumber':
      return validateLength(false, 0, 50)
    case 'city':
      return validateLength(true, 0, 50)
    case 'state':
      return validateState()
    case 'zipcode':
      return validateZipCode()
    default:
      return requiredYup(false)
  }
}

export const rentEstimateValidation = field => {
  switch (field) {
    case 'street':
      return validateLength(true, 2, 100).matches(/^[a-zA-Z0-9\s]*$/, getInvalidTuPropertyMessage)
    case 'unitNumber':
      return validateLength(false, 0, 50).matches(/^[a-zA-Z0-9\s]*$/, getInvalidTuPropertyMessage)
    case 'city':
      return requestPropertyValidation('city')
    case 'state':
      return requestPropertyValidation('state')
    case 'zipCode':
      return requestPropertyValidation('zipcode')
    case 'propertyType':
      return requiredYup(
        true,
        string().oneOf([
          'apartment',
          'singleFamilyHome',
          'townhouse',
          'manufacturedHome',
          'dormitory',
          'commercial',
        ]),
      )
    case 'bedroom':
      return requiredYup(true, number().oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]))
    case 'bathroom':
      return requiredYup(true, number().oneOf([0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6]))
    case 'area':
      return requiredYup(true, number())
    case 'yearBuilt':
      return requiredYup(
        false,
        string()
          .min(4, <Message {...message.yearBuilt} />)
          .max(4, <Message {...message.yearBuilt} />),
      )
    default:
      return requiredYup(false)
  }
}

export const allowEmptyString = validate =>
  validate.transform((currentVal, originalVal) => (isEmpty(originalVal) ? undefined : currentVal))

export const validateNameTU = updateInfoValidation('firstName')
export const validateNameTUWithRequiredFlag = isRequired =>
  updateInfoValidation('firstName', isRequired)

export const validateDobTaxInformation = (required = true) => {
  const validate = date()
    .typeError(<Message {...message.invalidDate} />)
    .min(moment('1900-01-01').format('MM/DD/YYYY'), <Message {...message.maxAgeTaxInformation} />)
    .test({
      name: 'dob',
      message: <Message {...message.dobNoFuture} />,
      test: value => moment().diff(moment(value)) >= 0,
    })
    .max(moment().subtract('18', 'years').format('MM/DD/YYYY'), <Message {...message.minAge} />)

  return requiredYup(required, validate)
}

export const validateNrdsId = (required = true) => {
  const validate = string().matches(/^\*.*\*-(\d{9})$|^\d{9}$/, 'Invalid NRDS ID Format')

  return requiredYup(required, validate)
}
