import get from 'lodash/get'
import { authService } from 'services/auth-service'
import { getAccessToken } from '../../reducers/persist-state.reducer'
import { ALERT_PRESET } from '../../libs/sweet-alert/sweet-alert'
import * as Types from '../../constants/action-types'
import * as ERRORS from '../../constants/error-messages'
import * as env from '../../../env'

export const curriedResolve = (api, opt0, ...opts) =>
  typeof api === 'function' ? curriedResolve(api(opt0), ...opts) : api

export default (api, [REQUEST, SUCCESS, ERROR], successData = {}) =>
  (...args) =>
  // eslint-disable-next-line consistent-return
  async (dispatch, getState) => {
    // Dispatch REQUEST action before acquiring access token to trigger loading state
    dispatch({
      type: REQUEST,
    })

    // Auth0 Migration
    const state = getState()
    let accessToken
    const shouldUseAuthProviderToken = authService.shouldUseAuthProviderToken()
    if (shouldUseAuthProviderToken) {
      accessToken = await authService.getAccessToken()
    } else {
      accessToken = getAccessToken(state.persistState)
    }
    const bearer = {
      accessToken,
    }

    return curriedResolve(api(...args), bearer)
      .then(data => {
        dispatch({
          type: SUCCESS,
          ...successData(data),
        })
        return { ...successData(data) }
      })
      .catch(e => {
        if (
          e.name === 'Unauthorized' &&
          state.persistState.auth &&
          state.persistState.auth.accessToken
        ) {
          if (Types.RENTSPREE_PRO_REQUESTS.includes(REQUEST)) {
            dispatch({
              type: Types.CLEAR_SUBSCRIPTIONS_AUTH,
            })
          } else {
            dispatch({
              type: Types.OPEN_SWEET_ALERT,
              preset: ALERT_PRESET.UNAUTHORIZED,
              option: {
                title: ERRORS.USER.UNAUTHORIZED.TITLE,
                confirmButtonText: ALERT_PRESET.UNAUTHORIZED.button.text,
                content: {
                  element: 'div',
                  attributes: {
                    innerHTML: ERRORS.USER.UNAUTHORIZED.MESSAGE,
                  },
                },
                className: 'rentspree-sweetalert',
                closeOnClickOutside: false,
              },
              promise: () => {
                dispatch({
                  type: Types.REMOVE_ACCESS_TOKEN,
                })
              },
            })
            return { then() {} }
          }
        }
        // TODO:- log error out when there's error on rendering that goes through here
        // ** there's a case where render in list.map, which received from API call, throws error.
        // ** the error thrown, was somehow, goes through here
        if (env.NODE_ENV !== 'production') {
          console.error(e)
        }
        const errorObject = {
          meta: (e && e.errors && e.errors.meta) || e.errors || e.error || {},
          status: e.status,
        }
        dispatch({
          type: ERROR,
          message: get(e, 'message', null),
          ...errorObject,
        })
        return {
          success: false,
          error: e.message || null,
          ...errorObject,
        }
      })
  }
