export const GET_PROPERTY_LIST_REQUEST = 'GET_PROPERTY_LIST_REQUEST'
export const GET_PROPERTY_LIST_SUCCESS = 'GET_PROPERTY_LIST_SUCCESS'
export const GET_PROPERTY_LIST_FAILED = 'GET_PROPERTY_LIST_FAILED'
export const CLEAR_PROPERTY_LIST = 'CLEAR_PROPERTY_LIST'
export const GET_PROPERTY_DROP_DOWN_REQUEST = 'GET_PROPERTY_DROP_DOWN_REQUEST'
export const GET_PROPERTY_DROP_DOWN_SUCCESS = 'GET_PROPERTY_DROP_DOWN_SUCCESS'
export const GET_PROPERTY_DROP_DOWN_FAILED = 'GET_PROPERTY_DROP_DOWN_FAILED'

export const FILTER_PROPERTY = {
  ALL: 'all',
  ACTIVE: 'active',
  ARCHIVED: 'archived',
}
