/* eslint-disable camelcase */
import React from 'react'
import { clsx } from 'clsx'
import { Typography } from '@rentspree/component-2023.components.atoms.typography'

import { CLICK_FROM } from 'containers/accept-deny/constants'

import { generateAddress } from '@rentspree/helper'
import { Box } from '@mui/material'
import CardElement from '@rentspree/component-2023.components.organisms.card-element'
import Card from '@rentspree/component-2023.components.organisms.card'
import { COLOR } from 'styles/settings'
import { getReportVoiceSpeech } from 'utils/screen-reader/voice-speech-generator'
import { APPLICATION_PROGRESS_TEXT_COLOR, SCREEN_WITHOUT_PROPERTY } from '../../constants'

import ActionDropdown from '../../action-dropdown'
import { generateApplicationProgress, generateSubmissionDate } from '../../helpers'

import './table-mobile-card.scss'

export default function TableMobileCard({
  index,
  rentalSubmission,
  eventSource,
  isAllowAcceptDeny,
  isAllowCarLease,
  onOpenAcceptDenyModal,
  onOpenAssignPropertyModal,
  onClickSetupLease,
  onClickSetupRentPayment,
  onClickRentalSubmissionRow,
  hidePropertyAddress,
}) {
  const {
    _id: appId,
    status,
    submitted_at: submittedAt,
    email,
    firstName,
    lastName,
    agentType,
    credit_report: creditReport,
    screeningResult,
    propertyAddress,
  } = rentalSubmission

  const displayName = firstName ? `${firstName} ${lastName}` : email

  const fullAddress = (propertyAddress && generateAddress(propertyAddress)) || ''

  const applicationProgress = generateApplicationProgress(status, screeningResult?.result)
  const statusColor = APPLICATION_PROGRESS_TEXT_COLOR[applicationProgress] ?? COLOR.statusNeutral

  const submissionDate = generateSubmissionDate(
    submittedAt,
    creditReport?.generated_at,
    creditReport?.verified_idma_at,
  )

  const speechContent = getReportVoiceSpeech({
    firstName,
    lastName,
    email,
    status,
  })

  const propertyLabelClass = 'screening-table-mobile-property-label'
  const screenWithoutPropertyClass = !fullAddress ? 'screen-without-property-label' : ''
  const propertyLabelCombinedClass = clsx(propertyLabelClass, screenWithoutPropertyClass)

  return (
    <Card
      className="screening-table-mobile-card"
      variant="outlined"
      onClick={() => onClickRentalSubmissionRow(rentalSubmission)}
      id={`applicationTableRow${index}`}
      data-testid={`application-table-row-${appId}`}
      key={appId}
      tabIndex="0"
      onKeyPress={keyInput => {
        if (keyInput.key === 'Enter') {
          onClickRentalSubmissionRow(rentalSubmission)
        }
      }}
      aria-label={speechContent}
    >
      <CardElement className="screening-table-mobile-card-container">
        <Box className="screening-table-mobile-content-container">
          <Box className="screening-table-mobile-title-container">
            <Typography variant="body-medium" className="screening-table-mobile-card-title">
              {displayName}
            </Typography>
          </Box>
          <Box className="screening-table-mobile-description-container">
            {!hidePropertyAddress && (
              <Box>
                <Typography variant="body" className={propertyLabelCombinedClass}>
                  {fullAddress || SCREEN_WITHOUT_PROPERTY}
                </Typography>
              </Box>
            )}
            <Box>
              <Typography variant="body" color={statusColor}>
                {applicationProgress}
              </Typography>
              {submissionDate && (
                <Typography variant="body" color={COLOR.statusNeutral}>
                  {' '}
                  • Date: {submissionDate}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box className="screening-table-mobile-button-container">
          <ActionDropdown
            rentalSubmission={rentalSubmission}
            source={CLICK_FROM.TENANT_SCREENING}
            eventSource={eventSource}
            isAllowAcceptDeny={isAllowAcceptDeny(agentType)}
            isAllowCarLease={isAllowCarLease}
            onOpenAcceptDenyModal={onOpenAcceptDenyModal}
            onOpenAssignPropertyModal={onOpenAssignPropertyModal}
            onClickSetupLease={onClickSetupLease}
            onClickSetupRentPayment={onClickSetupRentPayment}
          />
        </Box>
      </CardElement>
    </Card>
  )
}
