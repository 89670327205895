import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'

import { selectProfile } from 'containers/user/selectors'
import { getMyProperty } from 'v3/services/property'

/**
 * useGetMyProperty get my properties using my userId.
 *
 * @param {object} queryParams
 * @param {number} queryParams.page
 * @param {number} queryParams.perPage
 * @param {import('@tanstack/react-query').UseQueryOptions<Awaited<ReturnType<typeof getMyProperty>>>} options
 */
export const useGetMyProperty = (queryParam = { page: 1, perPage: 5 }, options = {}) => {
  const userProfile = useSelector(selectProfile)

  return useQuery({
    queryKey: ['useGetMyProperty', userProfile?.id],
    staleTime: 3000,
    queryFn: () => getMyProperty(queryParam),
    refetchOnWindowFocus: false,
    ...options,
  })
}
