import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import injectReducer from 'utils/inject-reducer'
import injectSaga from 'utils/inject-saga'

import { makeSelectSubdomain } from 'containers/navbar/selectors'
import { selectRentalDetail, selectUser } from '../selectors'
import { selectCreditReportErrorMessage, selectApplicant } from '../screening-reports/selectors'
import { selectIsSubmitting, selectIsSubmitted } from './selectors'
import { submitRentalCall } from './actions'
import reducer from './reducer'
import saga from './saga'
import { getCreditReport } from '../screening-reports/actions'

export const mapStateToProps = createStructuredSelector({
  rentalSubmission: selectRentalDetail,
  applicantReports: selectApplicant,
  profile: selectUser,
  isSubmitting: selectIsSubmitting,
  isSubmitted: selectIsSubmitted,
  subdomain: makeSelectSubdomain(),
  errorCreditReport: selectCreditReportErrorMessage,
})

export const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCreditReport,
      submitRentalCall,
    },
    dispatch,
  )

export const withReducer = injectReducer({
  key: 'reviewReport',
  reducer,
})

export const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const withSaga = injectSaga({ key: 'submitRental', saga })
