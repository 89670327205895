import get from 'lodash/get'
import React from 'react'

import { ReportWrapper } from 'components/reports/screening-reports/share/responsive-doc-style'
import {
  ApplicantOverview,
  ReportsBody,
} from 'components/reports/screening-reports/share/responsive-doc-components'
import HeaderPrint from 'containers/reports/header'
import CompanyInfoFooter from 'legacy/components/reports-v2/share/company-info-footer'
import { creditReportMapper } from 'legacy/components/reports-v2/credit-report/data-mapper'
import { convert } from 'helpers/convert'
import { isEmptyArray, generateHeaderName } from 'legacy/components/reports-v2/share/function'

import PersonalInfo from './credit-report/personal'
import ProfileSummary from './credit-report/summary'
import FraudIndicator from './credit-report/indicator'
import TradeLines from './credit-report/tradeline'
import Inquiries from './credit-report/inquiries'
import PublicRecord from './credit-report/record'
import Collection from './credit-report/collection'
import FrozenCreditReport from './credit-report/frozen'
import ConsumerStatement from './credit-report/statement'
import NullDataReport from '../share/null-report'
import { HeaderDoc } from '../share/doc-components'
import InLieuOfCreditUnavailableCreditReport from './credit-report/in-lieu-of-credit-unavailable'

const CreditReportComponent = ({
  creditReport,
  isFrozenFileOfCreditReport,
  generatedOn,
  expiresOn,
  renter,
  landlord,
  showHeaderComponent,
  customBorder,
  customMargin,
  shouldRenderInLieuOfCreditUnavailable,
  reportName,
}) => {
  if (!creditReport) {
    return <NullDataReport generatedOn={generatedOn} headerDoc={reportName} />
  }

  const creditData = creditReportMapper(get(creditReport, 'applicants[0]')) || {}
  const email = get(renter, 'email', '-')
  const renterFullName = generateHeaderName(creditData, true)
  const phone = get(renter, 'homePhoneNumber', '-')
  const records = get(creditData, 'publicRecords', [])
  const tradelines = get(creditData, 'tradeLines', [])
  const collections = get(creditData, 'collections', [])
  const profileSummary = get(creditData, 'profileSummary', {})
  const inquiries = get(creditData, 'inquirySubscriber', [])
  const consumerStatement = get(creditData, 'consumerStatement', [])
  const consumerRightsStatements = get(creditData, 'consumerRightsStatements', [])
  const fraudIndicators = get(creditData, 'fraudIndicators', [])
  const headerDocProps = {
    text: reportName,
    ssnMessage: creditData.sSNMessage,
    transUnion: { expiresOn, generatedOn },
    renter,
    landlord,
  }

  const headerProps = {
    email,
    phone: convert(phone, 'phoneNumber'),
    name: renterFullName,
    akas: get(creditData, 'akas', []),
  }

  const personalInfoProps = {
    scores: get(creditData, 'scoreModel.score', {}),
    employments: get(creditData, 'employments', []),
    addressReport: get(creditData, 'addresses', []),
  }

  const profileSummaryProps = {
    tradelines,
    collections,
    profileSum: profileSummary,
  }

  const tradelinesProps = {
    tradelines,
    profileSum: profileSummary,
  }

  if (shouldRenderInLieuOfCreditUnavailable) {
    return (
      <InLieuOfCreditUnavailableCreditReport
        customBorder={customBorder}
        customMargin={customMargin}
        showHeaderComponent={showHeaderComponent}
      />
    )
  }

  // TODO: 1972 check credit report null here
  if (isFrozenFileOfCreditReport) {
    return (
      <FrozenCreditReport
        email={email}
        phone={phone}
        expiresOn={expiresOn}
        generatedOn={generatedOn}
        applicantName={renterFullName}
      />
    )
  }
  return (
    <ReportWrapper border={customBorder} margin={customMargin}>
      {showHeaderComponent && <HeaderPrint />}
      <ReportsBody>
        <HeaderDoc {...headerDocProps} />
        <ApplicantOverview {...headerProps} withIncorrectInfo />
        <PersonalInfo {...personalInfoProps} />
        <ProfileSummary {...profileSummaryProps} />
        {!isEmptyArray(fraudIndicators) && <FraudIndicator fraudIndicators={fraudIndicators} />}
        {!isEmptyArray(tradelines) && <TradeLines {...tradelinesProps} />}
        {!isEmptyArray(inquiries) && <Inquiries inquiries={inquiries} />}
        {!isEmptyArray(collections) && <Collection collections={collections} />}
        {!isEmptyArray(records) && <PublicRecord publicRecords={records} />}
        {(!isEmptyArray(consumerStatement) || !isEmptyArray(consumerRightsStatements)) && (
          <ConsumerStatement
            consumers={consumerStatement}
            consumerRights={consumerRightsStatements}
          />
        )}
        <CompanyInfoFooter isTUReport />
      </ReportsBody>
    </ReportWrapper>
  )
}

export default CreditReportComponent
