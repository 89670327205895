import React from 'react'
import LockIcon from '@mui/icons-material/Lock'
import UpdateIcon from '@mui/icons-material/Update'

import {
  ButtonPDFMobile,
  ButtonPrintMobile,
  ButtonViewMobile,
  ButtonShareMobile,
} from 'legacy/components/reports/share/doc-components'
import * as Styled from './mobile-panel-style'
import { ProBadge, I } from '../typography'

export const MReportPanel = ({
  onView,
  onClickShare,
  isShareAvailable,
  reportViewingLocked = false,
  reportPending = false,
  applyCriminalMigrationV2,
  reportType,
  onPendingClick = () => {},
  ...props
}) => {
  let subtitle = ''
  let propsClass = ['mReportPanel', '-black']
  switch (props.value) {
    case 'all':
      propsClass = 'mReportPanel'
      break
    case 'application':
      subtitle = `Monthly Income ${props.monthlyIncome}`
      break
    // Note: Hide the total of TU report section to not interfere on TU data and prevent invalid TU data
    // TODO: Remove the total of TU report number section when we redesign the all report on mobile
    case 'creditReport':
      subtitle = ''
      break
    case 'criminalReport':
      subtitle = ''
      break
    case 'evictionReport':
      subtitle = ''
      break
    case 'refChecks':
      subtitle = `${props.refVerified || 0} Verified`
      break
    default:
      break
  }
  const isRenderShareReportButton = isShareAvailable && props.value === 'all'
  const handlePendingReportClick = () => {
    if (applyCriminalMigrationV2 && reportPending) {
      onPendingClick({
        reportAvailabilityPending: reportPending,
        reportViewingLocked,
        reportType,
      })
    }
  }
  return (
    <Styled.mReportPanel
      {...props}
      mPadding="12px 10px 12px 20px"
      onClick={handlePendingReportClick}
    >
      <Styled.MobileOnlyTopPanel>
        <Styled.MobileOnlyLeftPanel
          haveShareButton={
            isRenderShareReportButton && (!reportPending || !applyCriminalMigrationV2)
          }
        >
          <div className="text">
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {reportViewingLocked && <LockIcon />}
              {reportPending && applyCriminalMigrationV2 && (
                <div style={{ paddingRight: '10px' }}>
                  <UpdateIcon fontSize="large" />
                </div>
              )}
              {props.text}
            </span>
          </div>
          <div className="sub-text">{props.reportExpired ? props.expiredOn : subtitle}</div>
        </Styled.MobileOnlyLeftPanel>
        {!props.reportExpired &&
          !props.reportNotAvailable &&
          (!reportPending || !applyCriminalMigrationV2) && (
            <Styled.MobileOnlyRightPanel haveShareButton={isRenderShareReportButton}>
              {isRenderShareReportButton && (
                <ButtonShareMobile propsClass={propsClass} onClick={onClickShare} />
              )}
              <ButtonViewMobile propsClass={propsClass} onView={onView} />
              <ButtonPrintMobile propsClass={propsClass} onPrint={props.onPrint} />
              <ButtonPDFMobile
                additionalClass={['-mobile', propsClass]}
                onClick={props.download.onMobileClick}
                isFetching={props.download.isFetching}
              />
            </Styled.MobileOnlyRightPanel>
          )}
      </Styled.MobileOnlyTopPanel>
    </Styled.mReportPanel>
  )
}

export const TabText = ({ text, isPro, reportViewingLocked = false, ...props }) => (
  <Styled.tabText {...props}>
    <span style={{ display: 'flex', alignItems: 'center' }}>
      {reportViewingLocked && <LockIcon />}
      {text}
    </span>
    {isPro && (
      <ProBadge>
        <I>PRO</I>
      </ProBadge>
    )}
  </Styled.tabText>
)
