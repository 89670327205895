import React from 'react'
import HeaderPrint from 'containers/reports/header'
import {
  ReportsBody,
  ScreeningReportHeader,
  ScreeningApplicantSection,
  ScreeningReportFooter,
} from 'components/reports/screening-reports/share/responsive-doc-components'
import {
  ReportWrapper,
  Divider,
} from 'components/reports/screening-reports/share/responsive-doc-style'
import { REPORT_TYPES } from 'containers/reports/constants'

const ScreeningReportTemplate = ({
  customBorder,
  customMargin,
  showHeaderComponent,
  applicant,
  completedDate,
  orderedOn,
  reportNumber,
  property,
  packageName,
  children,
  reportType,
}) => (
  <ReportWrapper border={customBorder} margin={customMargin}>
    {showHeaderComponent && <HeaderPrint />}
    <ReportsBody>
      <ScreeningReportHeader pendingEvictionReport={reportType === REPORT_TYPES.EVICTION} />
      <Divider />
      <ScreeningApplicantSection
        applicant={applicant}
        reportNumber={reportNumber}
        completedDate={completedDate}
        orderedOn={orderedOn}
        packageName={packageName}
        property={property}
      />
      {children}
      <ScreeningReportFooter />
    </ReportsBody>
  </ReportWrapper>
)

export default ScreeningReportTemplate
