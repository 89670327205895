import { Grid } from '@mui/material'
import StickyFooterTemplate from '@rentspree/component-2023.components.templates.sticky-footer-template'
import React, { useContext } from 'react'

import { COMPONENT_2023_BREAKPOINT_MOBILE } from 'containers/constants'
import useMobile from 'hooks/use-mobile'

import { PageIndexesContext, PageProgressionContext } from '../../context'

import { CreateButtons } from './utils'

export const RentPaymentFooter = ({
  buttonLayout,
  textOverrides,
  onBack,
  onNext,
  isNextEnabled,
}) => {
  /*
   * Leverages StickyFooterTemplate for the bottom sticky bar, and then a Grid/flexbox
   * layout for the various button configurations.
   */
  const isMobile = useMobile(COMPONENT_2023_BREAKPOINT_MOBILE)
  const [pageIndexes] = useContext(PageIndexesContext)
  const progressionHandler = useContext(PageProgressionContext)

  return (
    <StickyFooterTemplate
      position="fixed"
      variant="navigation"
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        width: isMobile ? '100%' : 'calc(100% - 88px)',
        '& hr': { marginLeft: '-88px' },
      }}
      className="PaymentBottomFooter"
    >
      <Grid container direction="row" spacing={2}>
        {CreateButtons(
          pageIndexes,
          progressionHandler,
          buttonLayout,
          textOverrides,
          isNextEnabled,
          isMobile,
          onBack,
          onNext,
        )}
      </Grid>
    </StickyFooterTemplate>
  )
}
