import React from 'react'
import { ReportWrapper } from 'components/reports/screening-reports/share/responsive-doc-style'
import { ReportsBody } from 'components/reports/screening-reports/share/responsive-doc-components'
import HeaderPrint from 'containers/reports/header'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import { HeaderDoc } from '../../share/doc-components'

const IcraaDisclaimerReport = ({
  customBorder,
  customMargin,
  renter,
  landlord,
  expiresOn,
  generatedOn,
  reportName,
}) => {
  const DisclaimerBulletPoint = { marginLeft: '16px' }
  const defaultHeaderDocProps = {
    text: reportName || 'Rental Application',
    ssnMessage: 'Exact SSN Match',
    transUnion: { expiresOn, generatedOn },
    renter,
    landlord,
  }

  return (
    <ReportWrapper border={customBorder} margin={customMargin}>
      <HeaderPrint />
      <ReportsBody>
        <HeaderDoc includeCustomerInformation={false} {...defaultHeaderDocProps} />
        <Typography data-testid="icraa-disclaimer-typography-container" variant="caption">
          <div>
            <p>
              <b data-testid="icraa-bold-disclaimer-english-text">
                DISCLAIMER: This report does not guarantee the accuracy or truthfulness of the
                information as to the subject of the investigation, but only that it is accurately
                copied from public records, and information generated as a result of identity theft,
                including evidence of criminal activity, may be inaccurately associated with the
                consumer who is the subject of this report.
              </b>
            </p>
            <p>
              <div>Information about you is being obtained from:</div>
              <div>TransUnion Rental Screening Solutions, Inc. (TURSS)</div>
              <div>P.O. Box 800</div>
              <div>Woodlyn, PA 19094</div>
            </p>
            <p>
              <div>(800) 230-9376</div>
              <div style={{ textDecoration: 'underline' }}>TURSSDispute@transunion.com</div>
            </p>
            <p>
              Under California law you are entitled to inspect all files maintained about you by
              TURSS and to receive a copy of any investigative consumer report(s) provided about you
              within the last 2 years by TURSS, upon request and presentation of proper
              identification during normal business hours and on reasonable notice as follows:
              <li style={DisclaimerBulletPoint}>
                By telephone. A summary of all information contained in TURSS’s file(s) about you
                will be provided to you via telephone, if you have made a written request for
                telephone disclosure, and the toll charge, if any, for the telephone call is prepaid
                by or charged directly to you.
              </li>
              <li style={DisclaimerBulletPoint}>
                By certified mail. You may make a written request for copies to be sent to a
                specified addressee, which TURSS will provide to you via certified mail. Please note
                that complying with requests for certified mailings does not make TURSS liable for
                disclosures to third parties caused by mishandling of mail after such mailings leave
                TURSS.
              </li>
              “Proper Identification” includes documents such as a valid driver’s license, social
              security account number, military identification card, and credit cards. If TURSS is
              unable to reasonably identify you based on these documents, we may require additional
              information (e.g.: confirm information about your employment or personal or family
              history) to verify your identity.
            </p>
            <p>
              TURSS will not charge a fee for a disclosure that you have requested in accordance
              with the above requirements.
            </p>
            <p>
              <b data-testid="icraa-bold-disclaimer-spanish-text">
                DESCARGO DE RESPONSABILIDAD: Este informe no garantiza la exactitud o veracidad de
                la información sobre el tema de la investigación, sino solo que se copie con
                precisión de los registros públicos, y la información generada como resultado del
                robo de identidad, incluida la evidencia de actividad delictiva, puede asociarse
                incorrectamente con el consumidor que es objeto de este informe.
              </b>
            </p>
            <p>
              <div>La información sobre usted se obtiene de:</div>
              <div>TransUnion Rental Screening Solutions, Inc. (TURSS)</div>
              <div>Apartado de correos 800</div>
              <div>Woodlyn, PA 19094</div>
              <div>(800) 230-9376</div>
              <div style={{ textDecoration: 'underline' }}>TURSSDispute@transunion.com</div>
            </p>
            <p>
              Según la ley de California, usted tiene derecho a inspeccionar todos los archivos
              mantenidos sobre usted por TURSS y a recibir una copia de cualquier informe de
              investigación del consumidor proporcionado sobre usted dentro de los últimos 2 años
              por TURSS, previa solicitud y presentación de una identificación adecuada durante el
              horario comercial normal y con un aviso razonable de la siguiente manera:{' '}
              <li style={DisclaimerBulletPoint}>
                Por teléfono. Se le proporcionará un resumen de toda la información contenida en los
                archivos de TURSS sobre usted por teléfono, si ha realizado una solicitud por
                escrito para la divulgación telefónica, y el cargo de peaje, si lo hubiera, por la
                llamada telefónica es pagado por adelantado o se le cobra directamente a usted.
              </li>
              <li style={DisclaimerBulletPoint}>
                Por correo certificado. Puede hacer una solicitud por escrito para que se envíen
                copias a un destinatario específico, que TURSS le proporcionará por correo
                certificado. Tenga en cuenta que cumplir con las solicitudes de envíos certificados
                no hace que TURSS sea responsable de las divulgaciones a terceros causadas por el
                mal manejo del correo después de que dichos correos salgan de TURSS.
              </li>
              La "identificación adecuada" incluye documentos como una licencia de conducir válida,
              un número de cuenta de seguro social, una tarjeta de identificación militar y tarjetas
              de crédito. Si TURSS no puede identificarlo razonablemente con base en estos
              documentos, es posible que requiramos información adicional (por ejemplo: confirmar
              información sobre su empleo o antecedentes personales o familiares) para verificar su
              identidad.
            </p>
            <p>
              TURSS no cobrará una tarifa por una divulgación que usted haya solicitado de acuerdo
              con los requisitos anteriores.
            </p>
          </div>
        </Typography>
      </ReportsBody>
    </ReportWrapper>
  )
}

export default IcraaDisclaimerReport
