import { createAuth0Client } from '@auth0/auth0-spa-js'
import { AUTH_APP_CLIENT_ID, AUTH_API_IDENTIFIER, AUTH_APP_SCOPE } from 'env'
import { AUTH_SESSION_TIMEOUT } from "constants/route"
class AuthService {
  constructor() {
    this.auth0 = null
    this.userLocalStorageKey = {}
    this.userLocalStorageKey = `@@auth0spajs@@::${AUTH_APP_CLIENT_ID}::@@user@@`
    this.accessTokenLocalStorageKey = `@@auth0spajs@@::${AUTH_APP_CLIENT_ID}::${AUTH_API_IDENTIFIER}::${AUTH_APP_SCOPE}`
  }

  async init(options) {
    this.auth0 = await createAuth0Client(options)
    this.userLocalStorageKey = `@@auth0spajs@@::${options.clientId}::@@user@@`
    this.accessTokenLocalStorageKey = `@@auth0spajs@@::${options.clientId}::${options.authorizationParams.audience}::${options.authorizationParams.scope}`
  }

  async isAuthenticated() {
    return this.auth0.isAuthenticated()
  }

  async getAccessToken() {
    try {
      const accessToken = await this.auth0.getTokenSilently()
      return accessToken
    } catch(error) {
      window.location.replace(
        `${window.location.origin}${AUTH_SESSION_TIMEOUT}`,
      )
      return null
    }
  }

  shouldUseAuthProviderToken() {
    return !!localStorage.getItem(this.accessTokenLocalStorageKey)
  }

  // NOTE: use this one cautiously, this will not gaurantee the validity of the token (eg. expired)
  getLocalAccessToken() {
    return JSON.parse(localStorage.getItem(this.accessTokenLocalStorageKey))?.body?.access_token
  }

  resolveAccessTokenGetter() {
    if (this.shouldUseAuthProviderToken()) {
      return this.getAccessToken.bind(this)
    }
    return null
  }
}

export const authService = new AuthService()
