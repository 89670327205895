import {
  GET_PROPERTY,
  START_UPDATE_LISTING,
  UPDATE_LISTING_FAILED,
  UPDATE_LISTING_SUCCESS,
  UPDATE_LISTING,
  START_GET_PROPERTY,
  GET_PROPERTY_INFORMATION_SUCCESS,
  GET_PROPERTY_INFORMATION_FAILED,
  CLOSE_ERROR_PANEL,
  CLOSE_SUCCESSFUL_PANEL,
  UPLOAD_PHOTO_SUCCESS,
  UPLOAD_PHOTO_FAILED,
  START_UPLOAD_PHOTO,
  UPLOAD_PHOTO,
  REMOVE_PHOTO,
  SORT_PHOTO,
  PUBLISH_LISTING,
  PUBLISH_LISTING_START,
  PUBLISH_LISTING_SUCCESS,
  PUBLISH_LISTING_FAILED,
  SET_ERROR_MESSAGE,
  QUEUE_PHOTO,
  UPDATE_QUEUE_PHOTO,
  UPDATE_IS_IMAGE_UPLOADING,
  GENERATE_DESCRIPTION,
  GENERATE_DESCRIPTION_SUCCESS,
  START_GENERATE_DESCRIPTION,
  GENERATE_DESCRIPTION_FAILED,
  UPDATE_UPLOAD_PHOTO_PROGRESS,
  RATE_GENERATED_DESCRIPTION_SUCCESS,
  RATE_GENERATED_DESCRIPTION,
  RATE_GENERATED_DESCRIPTION_FAILED,
  START_RATE_GENERATED_DESCRIPTION,
  RESET_GENERATED_DESCRIPTION,
  UPDATE_GENERATED_DESCRIPTION,
  SHOW_IDV_MODAL,
  CLOSE_IDV_MODAL,
  SHOW_IDV_FAILURE,
  CLOSE_IDV_FAILURE,
  AUTO_CREATE_LISTING_SYNDICATION_SUCCESS,
  AUTO_CREATE_LISTING_SYNDICATION_FAILED,
  USER_INPUT_PHOTO,
  UPLOAD_ALL_PHOTOS,
} from './const'

export const updateListing = payload => ({
  type: UPDATE_LISTING,
  payload,
})

export const getProperty = propertyId => ({
  type: GET_PROPERTY,
  payload: { propertyId },
})
export const startGetProperty = () => ({
  type: START_GET_PROPERTY,
})

export const getPropertyInformationSuccess = payload => ({
  type: GET_PROPERTY_INFORMATION_SUCCESS,
  payload,
})

export const getPropertyInformationFailed = () => ({
  type: GET_PROPERTY_INFORMATION_FAILED,
})

export const startUpdateListing = () => ({
  type: START_UPDATE_LISTING,
})

export const updateListingSuccess = payload => ({
  type: UPDATE_LISTING_SUCCESS,
  payload,
})

export const updateListingFailed = () => ({
  type: UPDATE_LISTING_FAILED,
})

export const closeErrorPanel = () => ({
  type: CLOSE_ERROR_PANEL,
})

export const closeSuccessfulPanel = () => ({
  type: CLOSE_SUCCESSFUL_PANEL,
})

export const uploadPhoto = payload => ({
  type: UPLOAD_PHOTO,
  payload,
})

export const uploadAllPhotos = payload => ({
  type: UPLOAD_ALL_PHOTOS,
  payload,
})

export const queuePhoto = payload => ({
  type: QUEUE_PHOTO,
  payload,
})

export const updateQueuePhoto = payload => ({
  type: UPDATE_QUEUE_PHOTO,
  payload,
})

export const startUploadPhoto = payload => ({
  type: START_UPLOAD_PHOTO,
  payload,
})

export const userInputPhoto = payload => ({
  type: USER_INPUT_PHOTO,
  payload,
})

export const uploadPhotoSuccess = payload => ({
  type: UPLOAD_PHOTO_SUCCESS,
  payload,
})

export const uploadPhotoFailed = payload => ({
  type: UPLOAD_PHOTO_FAILED,
  payload,
})

export const updateUploadPhotoProgress = payload => ({
  type: UPDATE_UPLOAD_PHOTO_PROGRESS,
  payload,
})

export const removePhoto = payload => ({
  type: REMOVE_PHOTO,
  payload,
})

export const updateIsImageUploading = () => ({
  type: UPDATE_IS_IMAGE_UPLOADING,
})

export const sortPhoto = payload => ({
  type: SORT_PHOTO,
  payload,
})

export const publishListing = payload => ({
  type: PUBLISH_LISTING,
  payload,
})

export const publishListingStart = () => ({
  type: PUBLISH_LISTING_START,
})

export const publishListingSuccess = () => ({
  type: PUBLISH_LISTING_SUCCESS,
})

export const autoCreateListingSyndicationSuccess = payload => ({
  type: AUTO_CREATE_LISTING_SYNDICATION_SUCCESS,
  payload,
})

export const autoCreatedListingSyndicationFailed = () => ({
  type: AUTO_CREATE_LISTING_SYNDICATION_FAILED,
})

export const publishListingFailed = payload => ({
  type: PUBLISH_LISTING_FAILED,
  payload,
})

export const setErrorMessage = payload => ({
  type: SET_ERROR_MESSAGE,
  payload,
})

export const generateDescription = payload => ({
  type: GENERATE_DESCRIPTION,
  payload,
})

export const startGenerateDescription = () => ({
  type: START_GENERATE_DESCRIPTION,
})

export const generateDescriptionSuccess = payload => ({
  type: GENERATE_DESCRIPTION_SUCCESS,
  payload,
})

export const generateDescriptionFailed = () => ({
  type: GENERATE_DESCRIPTION_FAILED,
})

export const resetGeneratedDescription = () => ({
  type: RESET_GENERATED_DESCRIPTION,
})

export const rateGeneratedDescription = payload => ({
  type: RATE_GENERATED_DESCRIPTION,
  payload,
})

export const startRateGeneratedDescription = () => ({
  type: START_RATE_GENERATED_DESCRIPTION,
})

export const rateGeneratedDescriptionSuccess = payload => ({
  type: RATE_GENERATED_DESCRIPTION_SUCCESS,
  payload,
})

export const rateGeneratedDescriptionFailed = () => ({
  type: RATE_GENERATED_DESCRIPTION_FAILED,
})

export const updateGeneratedDescription = payload => ({
  type: UPDATE_GENERATED_DESCRIPTION,
  payload,
})

export const toggleIdvModal = status => ({
  type: status ? SHOW_IDV_MODAL : CLOSE_IDV_MODAL,
})

export const toggleIdvFailure = status => ({
  type: status ? SHOW_IDV_FAILURE : CLOSE_IDV_FAILURE,
})
