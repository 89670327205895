import React from 'react'
import propTypes from 'prop-types'

import { Li } from 'components/atoms/list'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import { Box } from '@mui/material'

const SubtitleWithList = ({ subtitle, list }) => (
  <Box>
    <Typography variant="body-large">{subtitle}</Typography>
    <Typography variant="body-medium">
      {list.map(item => (
        <Li key={item}>{item}</Li>
      ))}
    </Typography>
  </Box>
)

export default SubtitleWithList

SubtitleWithList.propTypes = {
  subtitle: propTypes.node,
  list: propTypes.array,
}

SubtitleWithList.defaultProps = {
  list: [],
}
