import { Drawer } from '@mui/material'
import React, { useContext } from 'react'

import { COMPONENT_2023_BREAKPOINT_MOBILE } from 'containers/constants'
import useMobile from 'hooks/use-mobile'
import { PageIndexesContext } from 'v3/containers/overhaul-rent-payment/context'

const sharedSx = {
  padding: '24px',
  paddingBottom: 0,
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'hidden',
  boxSizing: 'border-box',
}

const MobileBottomDrawer = ({ ...attrs }) => (
  <Drawer
    {...{
      ...attrs,
      PaperProps: {
        sx: {
          ...sharedSx,
          width: '100%',
          maxHeight: '90vh',
          borderRadius: '16px 16px 0 0',
        },
      },
    }}
  />
)

const BrowserRightDrawer = ({ ...attrs }) => (
  <Drawer
    {...{
      ...attrs,
      PaperProps: {
        sx: {
          ...sharedSx,
          width: '480px',
        },
      },
    }}
  />
)

export const PaymentsDrawer = ({ children }) => {
  const isMobile = useMobile(COMPONENT_2023_BREAKPOINT_MOBILE)
  const [pageIndexes, debouncedSetPageIndexes] = useContext(PageIndexesContext)
  const setDrawerOpen = nextDrawerOpen => debouncedSetPageIndexes({ drawerOpen: nextDrawerOpen })

  if (isMobile) {
    return (
      <MobileBottomDrawer
        open={pageIndexes.drawerOpen}
        onClose={() => setDrawerOpen(false)}
        anchor="bottom"
      >
        {children}
      </MobileBottomDrawer>
    )
  }

  return (
    <BrowserRightDrawer
      open={pageIndexes.drawerOpen}
      onClose={() => setDrawerOpen(false)}
      anchor="right"
      style={{ zIndex: 3100 }}
    >
      {children}
    </BrowserRightDrawer>
  )
}
