export const SELECT_PROPERTY = 'SELECT_PROPERTY'
export const AUTO_SELECT_PROPERTY = 'AUTO_SELECT_PROPERTY'
export const APPEND_PROPERTY_LIST = 'APPEND_PROPERTY_LIST'

export const GET_PROPERTY_LIST_CALL = 'GET_PROPERTY_LIST_CALL'
export const GET_PROPERTY_LIST_REQUEST = 'GET_PROPERTY_LIST_REQUEST'
export const GET_PROPERTY_LIST_SUCCESS = 'GET_PROPERTY_LIST_SUCCESS'
export const GET_PROPERTY_LIST_FAILURE = 'GET_PROPERTY_LIST_FAILURE'

export const REQUESTED_FEATURE = {
  LEASE_AGREEMENT: 'LEASE_AGREEMENT',
}

export const REQUESTED_FEATURE_NEXT_PATH_NAVIGATION = {
  EXTERNAL: 'external',
  INTERNAL: 'internal',
}
