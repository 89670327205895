import React from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import chunk from 'lodash/chunk'
import isEqual from 'lodash/isEqual'
import isArray from 'lodash/isArray'
import { generateAddress } from '@rentspree/helper'
import { Span } from '@rentspree/component-v2'
import { ATTENTION_WORDING } from '../../../constants/criminal-report'
import { COLOR } from '../../../styles/settings'

import {
  FontReport,
  PanelContainer,
  RentSpreeLogo,
  TULogo,
  Icon,
  ImgSubTitle,
  Panel,
  ImgTitle,
  Section,
  DocPanel,
  TableRow,
  BoxColor,
  Space,
  TableCell,
  TableStyle,
  Check,
  Column,
  PanelApplicant,
  H4,
  CriminalImageContainer,
  CriminalNoTattooImg,
  CriminalNoImg,
  CriminalStatePanel,
} from './doc-style'
import {
  convertToCommaAndDecimal,
  convertToMonthDayYear,
  generatePaymentHistory,
  generateAkas,
  countAccountRating,
  convertPhoneForm,
  isEmptyArray,
  getStateHeaderData,
} from './function'

import {
  REJECT_MESSAGE,
  INDUSTRY_CODES,
  ACCOUNT_TYPE,
  REMARK_CODES,
  ACCOUNT_RAILING_CODES,
} from '../../../constants/credit-report-code'
import { isChromeOrSafari } from '../../helper/ua-parser-js'
import {
  MISCELLANEOUS,
  INCORRECT_INFO_TEXT,
  NO_CREDIT_SCORE_MESSAGE,
} from '../../../constants/report-consts'
import { convert, addUnitFormat } from '../../helper/convert'
const rentImg = require('../../../../images/logos/rentspree-logo.svg')
const transImg = require('../../../images/credit-report/TU-logo.png')
const subTitle = require('../../../images/icons/application/Subtitle.png')
const imgTitle = require('../../../images/icons/application/Title.png')
const imgTitleGray = require('../../../images/icons/application/TitleGray.png')

export const ReportsBody = ({ children }) => <DocPanel>{children}</DocPanel>

export const TextApplicantType = ({ type }) => (
  <PanelApplicant>
    <H4 weight="400">Submitting application as </H4>&nbsp;
    <H4>{type}</H4>
  </PanelApplicant>
)

export const HeaderDoc = ({ submittedAt, text = 'Rental Application', transUnion }) => {
  const isTransUnionReport = !isEmpty(transUnion)
  const generatedOn = get(transUnion, 'generatedOn')
  const expiresOn = get(transUnion, 'expiresOn')
  const formatDate = 'MMM DD, YYYY'
  return (
    <Panel Row Section Header>
      <PanelContainer Row>
        {isTransUnionReport ? <TULogo src={transImg} /> : <RentSpreeLogo src={rentImg} />}
      </PanelContainer>
      <PanelContainer rowFlex>
        <FontReport HeaderB>{text}</FontReport>
        {submittedAt && (
          <FontReport HeaderN WhiteGray EtcDetail>{`Submitted on ${convert(
            submittedAt,
            'dateOfLocal',
            formatDate,
          )}`}</FontReport>
        )}
        {generatedOn && (
          <FontReport HeaderN WhiteGray Small>{`Generated on ${convert(
            generatedOn,
            'dateOfLocal',
            formatDate,
          )}`}</FontReport>
        )}
        {expiresOn && (
          <FontReport HeaderN WhiteGray Small>{`Expires on  ${convert(
            expiresOn,
            'dateOfLocal',
            formatDate,
          )}`}</FontReport>
        )}
      </PanelContainer>
    </Panel>
  )
}

export const Header = ({ Name, Email, Phone, Akas, withIncorrectInfo, converter = convert }) => (
  <Panel Section MarginContact={!withIncorrectInfo}>
    <FontReport Name Inline={Akas}>
      {Name}
    </FontReport>
    {!isEmptyArray(Akas) && (
      <FontReport EtcDetail MarginLeft Inline>
        aka. {generateAkas(Akas)}
      </FontReport>
    )}
    <Contact email={Email} phone={converter(Phone, 'phoneNumber')} />
    {withIncorrectInfo && <IncorrectInfo text={INCORRECT_INFO_TEXT} />}
  </Panel>
)

export const Contact = ({ email, phone }) => (
  <Panel Row Center Warp>
    <Icon className="icon-envelope" />
    <FontReport MarginTen>{email}</FontReport>
    <Icon className="icon-phone" />
    <FontReport>{phone}</FontReport>
  </Panel>
)

export const Title = ({ text }) =>
  isChromeOrSafari ? (
    <Panel Row Title>
      <ImgTitle src={imgTitle} />
      <FontReport Title White>
        {text}
      </FontReport>
    </Panel>
  ) : (
    <Panel Row Title>
      <ImgTitle Gray src={imgTitle} />
      <FontReport Title Gray>
        {text}
      </FontReport>
      <ImgTitle GrayPrint src={imgTitleGray} />
      <FontReport Title GrayPrint>
        {text}
      </FontReport>
    </Panel>
  )

export const SubTitle = ({ text, padding, children }) =>
  isEmpty(padding) ? (
    <PanelContainer rowFlex panelSubTitle>
      <ImgSubTitle src={subTitle} />
      <FontReport DarkGray>{text}</FontReport>
      {children}
    </PanelContainer>
  ) : (
    <PanelContainer rowFlex panelSubTitle margin>
      <ImgSubTitle src={subTitle} />
      <FontReport DarkGray>{text}</FontReport>
      {children}
    </PanelContainer>
  )

export const PanelColumn = ({ children, ...props }) => (
  <Panel Row {...props}>
    {children}
  </Panel>
)

export const Detail = ({ head, detail, sty, fullDetail, large, oneLine, marginB10 }) => {
  const detailData = detail || '-'
  return isEmpty(sty) ? (
    <PanelContainer Row MarginB10={marginB10}>
      {!isEmpty(head) && (
        <FontReport LargeHeaderDetail={large} HeaderDetail oneLine={oneLine}>
          {head}
        </FontReport>
      )}
      {isEmpty(head) ? (
        <FontReport LargeHeaderDetail={large} Full Detail>
          {detailData}
        </FontReport>
      ) : (
        <FontReport calWidth Detail>
          {detailData}
        </FontReport>
      )}
      {!isEmpty(fullDetail) && <FontReport Detail>{fullDetail}</FontReport>}
    </PanelContainer>
  ) : (
    <PanelContainer MarginB10={marginB10}>
      <FontReport LargeHeaderDetail={large} HeaderDetail>
        {head}
      </FontReport>
      <FontReport Detail>{detailData}</FontReport>
    </PanelContainer>
  )
}

export const DetailFull = ({ head, detail }) => (
  <PanelContainer rowFlex calWidth>
    <FontReport HeaderDetail Full>
      {head}
    </FontReport>
    <FontReport Detail Full>
      {detail}
    </FontReport>
  </PanelContainer>
)

export const PanelArea = ({ children, padding, ...props }) =>
  isEmpty(padding) ? (
    <Panel NonePaddingB Section {...props}>
      {children}
    </Panel>
  ) : (
    <Panel Section {...props}>
      {children}
    </Panel>
  )

export const PanelAreaNoneT = ({ children }) => (
  <Panel NonePaddingT Section>
    {children}
  </Panel>
)

export const PanelAreaHalf = ({ children, sty }) => {
  switch (sty) {
    case 'paddingBZero':
      return (
        <Panel Half Section NonePaddingB>
          {children}
        </Panel>
      )
    case 'paddingTZero':
      return (
        <Panel Half Section NonePaddingT>
          {children}
        </Panel>
      )
    default:
      return (
        <Panel Half Section>
          {children}
        </Panel>
      )
  }
}

// TODO: [CRAFT | TECH-3844] - Remove unused report component
export const PersonalDetail = ({
  firstName = '',
  lastName = '',
  email,
  relationship,
  address,
  occupation,
  phoneNumber,
  acquaintanceLength,
  hasAcquaintanceLength,
  hasOccupation,
  isSkipEmail = false,
  converter = convert,
}) => (
  <Panel Row>
    <PanelAreaHalf>
      <div>
        <Detail head="Name" detail={`${firstName} ${lastName}`.trim()} />
        {isEmpty(phoneNumber) ? (
          ''
        ) : (
          <Detail head="Phone no." detail={converter(phoneNumber, 'phoneNumber')} />
        )}
        <Detail head="Email" detail={isSkipEmail || !email ? '-' : email} />
        {isEmpty(relationship) ? '' : <Detail head="Relationship" detail={relationship} />}
        {hasOccupation && <Detail head="Occupation" detail={occupation} />}
        {hasAcquaintanceLength && (
          <Detail
            head="Length of Acquaintance"
            detail={isNumber(acquaintanceLength) ? addUnitFormat(acquaintanceLength, 'Years') : '-'}
          />
        )}
      </div>
    </PanelAreaHalf>
    <PanelAreaHalf>
      <Detail head="Address" detail={address} sty="full" />
    </PanelAreaHalf>
  </Panel>
)

export const OccupantCoSigner = ({ Data, Value }) => (
  <div>
    {chunk(Data, Value).map((data, index) => (
      <Section Row key={`cosigners-section-${index.toString()}`}>
        {data.map((value, i) => (
          <PersonalOc {...value} key={`cosigner-${i.toString()}`} />
        ))}
      </Section>
    ))}
  </div>
)

export const RenterAgent = ({ Data, converter = convertPhoneForm }) => (
  <Section>
    <PanelAreaHalf>
      <Detail head="Name" detail={Data.fullName.trim()} />
      <Detail head="Email" detail={Data.email || '-'} />
      <Detail head="Phone Number" detail={Data.phoneNumber ? converter(Data.phoneNumber) : '-'} />
    </PanelAreaHalf>
  </Section>
)

const PersonalOc = ({ firstName = '', lastName = '', dateOfBirth, email }) => (
  <PanelAreaHalf>
    <Detail head="Name" detail={`${firstName} ${lastName}`.trim()} />
    <Detail head="Date of Birth" detail={convertToMonthDayYear(dateOfBirth)} />
    <Detail head="Email" detail={email || '-'} />
  </PanelAreaHalf>
)

export const AdditionalIncome = ({ source, amount, order }) => (
  <PanelAreaHalf>
    <div>
      <Detail
        head={order === 0 ? 'Income source' : 'Other income source(s)'}
        detail={source}
        oneLine={order !== 0}
      />
      <Detail head="Amount per month" detail={convertToCommaAndDecimal(amount)} />
    </div>
  </PanelAreaHalf>
)

export const PetDetail = ({ Data, Value }) => (
  <div>
    {chunk(Data, Value).map((data, index) => (
      <Section Row key={`pets-section-${index.toString()}`}>
        {data.map((value, i) => (
          <Pet {...value} key={`pet-${i.toString()}`} />
        ))}
      </Section>
    ))}
  </div>
)

const Pet = ({ name, breed, weight }) => (
  <PanelAreaHalf>
    <Detail head="Name" detail={name || '-'} />
    <Detail head="Type/Breed" detail={breed} />
    <Detail head="Weight" detail={isNumber(weight) ? addUnitFormat(weight, 'lbs') : '-'} />
  </PanelAreaHalf>
)

export const Vehicle = ({ Data, Value }) => (
  <div>
    {chunk(Data, Value).map((data, index) => (
      <Section Row key={`vehicles-section-${index.toString()}`}>
        {data.map((value, i) => (
          <Vehicles {...value} key={`vehicle-${i.toString()}`} />
        ))}
      </Section>
    ))}
  </div>
)

export const Vehicles = ({ make, model, year, color, licensePlate, state }) => (
  <PanelAreaHalf>
    <div>
      <Detail head="Make" detail={make || '-'} />
      <Detail head="Model" detail={model || '-'} />
      <Detail head="Year" detail={year || '-'} />
      <Detail head="Color" detail={color || '-'} />
      <Detail head="LicensePlate" detail={licensePlate || '-'} />
      <Detail head="State" detail={state || '-'} />
    </div>
  </PanelAreaHalf>
)

export const AdditionalInfo = ({ status, title, topic }) => (
  <PanelContainer Row MarginB10>
    {status === MISCELLANEOUS.STATUS.YES ? (
      <Icon className="icon-exclamation" />
    ) : (
      <Icon className="icon-emotsmile" />
    )}
    <DetailFull head={topic} detail={title} calWidth />
  </PanelContainer>
)

export const Footer = ({ text }) => (
  <Section PaddingT="10px">
    <FontReport Footer>{text}</FontReport>
  </Section>
)

export const IncorrectInfo = ({ text }) => (
  <Section PaddingT="15px">
    <FontReport WhiteGray Small>
      {text}
    </FontReport>
  </Section>
)

export const ScoreFactor = ({ isHaveFactor, scoreFactor, rejectCode, score }) =>
  isHaveFactor && isEmpty(rejectCode) && !isEqual(score, 0) && !isEqual(score, -1) ? (
    <Panel score>
      <FontReport Detail DarkGray>
        Score Factor(s)
      </FontReport>
      <ScoreFactorDetails scoreFactor={scoreFactor} />
    </Panel>
  ) : (
    <Panel score>
      <FontReport marginB Detail DarkGray>
        No Score because of:
      </FontReport>
      <NoScoreFactorDetails
        rejectCode={rejectCode}
        isHaveFactor={isHaveFactor}
        scoreFactor={scoreFactor}
      />
    </Panel>
  )

export const NoScoreFactorDetails = ({ isHaveFactor, scoreFactor, rejectCode }) =>
  !isHaveFactor || !isEmpty(rejectCode) ? (
    <NoScoreMessage rejectCode={rejectCode} />
  ) : (
    <ScoreFactorDetails scoreFactor={scoreFactor} />
  )

export const ScoreFactorDetails = ({ scoreFactor }) => (
  <Table MarginT="10px" MarginB="0px">
    {
      // use index as a key because their is no unique property
      isArray(scoreFactor) ? (
        scoreFactor.map((factor, index) => (
          <TableRow WhiteGray key={`scoreFactor-${index.toString()}`}>
            <TableCell>{factor}</TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow WhiteGray key="scoreFactor-0">
          <TableCell>{scoreFactor}</TableCell>
        </TableRow>
      )
    }
  </Table>
)

export const NoScoreMessage = ({ rejectCode }) =>
  !isEmpty(rejectCode) ? (
    <FontReport EtcDetail>{REJECT_MESSAGE[rejectCode]}</FontReport>
  ) : (
    <div>
      <FontReport lineHeight={1.57} EtcDetail>
        {NO_CREDIT_SCORE_MESSAGE}
      </FontReport>
      <FontReport FourTeen rentspreePhoneNumber>
        (866) 775-0961.
      </FontReport>
    </div>
  )

// TODO: lnr-2991 clean up legacy component
export const Employment = ({ employments }) => (
  <div>
    <Section>
      <PanelArea>
        <SubTitle text="Employment" />
      </PanelArea>
      {employments.map((employment, index) => (
        <Section key={`${employment.employerName}-${index.toString()}`}>
          <EmploymentDetail
            {...employment}
            address={generateAddress(employment)}
            isPaddingTop={index !== 0}
          />
        </Section>
      ))}
    </Section>
  </div>
)

export const EmploymentDetail = ({
  employerName,
  dateEmployed,
  dateVerified,
  address,
  isPaddingTop,
}) => (
  <Panel Row>
    <PanelAreaHalf sty={!isPaddingTop && 'paddingTZero'}>
      <div>
        <Detail head="Employer" detail={employerName || '-'} />
        <Detail
          head="Employed on"
          detail={dateEmployed ? convertToMonthDayYear(dateEmployed) : '-'}
        />
        <Detail
          head="Verified on"
          detail={dateVerified ? convertToMonthDayYear(dateVerified) : '-'}
        />
      </div>
    </PanelAreaHalf>
    <PanelAreaHalf sty={!isPaddingTop && 'paddingTZero'}>
      <Detail head="Address" detail={address || '-'} sty="full" />
    </PanelAreaHalf>
  </Panel>
)

// TODO: lnr-2991 clean up if unused
export const Address = ({ addressReport }) => (
  <div>
    <Section>
      <PanelArea>
        <SubTitle text="Address(es) Reported" />
      </PanelArea>
      {
        // use index as a key because there is no unique property
        addressReport.map((address, index) => (
          <Section key={`scoreFactor-${index.toString()}`}>
            <AddressDetail
              {...address}
              address={generateAddress(address)}
              isPaddingTop={index !== 0}
            />
          </Section>
        ))
      }
    </Section>
  </div>
)
// TODO: lnr-2991 clean up if unused
export const AddressDetail = ({ address, dateReported, isPaddingTop }) => (
  <Panel Row>
    <PanelAreaHalf sty={!isPaddingTop && 'paddingTZero'}>
      <Detail head="Address" detail={address} sty="full" />
    </PanelAreaHalf>
    <PanelAreaHalf sty={!isPaddingTop && 'paddingTZero'}>
      <Detail
        head="Reported on"
        detail={dateReported ? convertToMonthDayYear(dateReported) : '-'}
      />
    </PanelAreaHalf>
  </Panel>
)

export const BoxData = ({ header, detail, icon, color, borderColor, iconMarginRight }) => (
  <BoxColor SpaceBetween AlignItem="center" color={color} borderColor={borderColor}>
    <Space>
      <FontReport Detail DarkGray>
        {header}
      </FontReport>
      <FontReport SmallDetail>{detail}</FontReport>
    </Space>
    {icon && (
      <Space mRight={iconMarginRight}>
        <img src={icon} alt={header} />
      </Space>
    )}
  </BoxColor>
)

// TODO: lnr-2991 clean up if unused
export const BoxScore = ({ header, score, sty }) =>
  isEqual(sty, 'Blue') ? (
    <BoxColor Blue Score>
      <FontReport SmallDetail>{header}</FontReport>
      <FontReport TwentyEeight Blue AlignRight>
        {score}
      </FontReport>
    </BoxColor>
  ) : (
    <BoxColor Score>
      <FontReport SmallDetail>{header}</FontReport>
      <FontReport TwentyEeight DarkGray AlignRight>
        {score}
      </FontReport>
    </BoxColor>
  )

// TODO: lnr-2991 clean up if not being used anymore
export const BoxHeader = ({ title, status, balance, detail, isActive }) =>
  isActive ? (
    <BoxColor Blue SpaceBetween>
      <Space>
        <FontReport DarkGray>{title}</FontReport>
        <FontReport SmallDetail>{status}</FontReport>
      </Space>
      <Space>
        <FontReport Blue AlignRight>
          {balance}
        </FontReport>
        <FontReport SmallDetail AlignRight>
          {detail}
        </FontReport>
      </Space>
    </BoxColor>
  ) : (
    <BoxColor SpaceBetween>
      <Space>
        <FontReport DarkGray>{title}</FontReport>
        <FontReport SmallDetail>{status}</FontReport>
      </Space>
      <Space>
        <FontReport BalanceGrey AlignRight>
          {balance}
        </FontReport>
        <FontReport SmallDetail AlignRight>
          {detail}
        </FontReport>
      </Space>
    </BoxColor>
  )

export const DetailScore = ({ title, score, half }) => (
  <Panel Section Half={half} SpaceBetween NonePaddingB NonePaddingT>
    <FontReport SmallDetail>{title}</FontReport>
    <FontReport Score>{score}</FontReport>
  </Panel>
)

export const Table = ({ children, ...props }) => (
  <TableStyle {...props}>
    <tbody>{children}</tbody>
  </TableStyle>
)

export const TableSpace = ({
  title,
  detail,
  type,
  format,
  NoDetail,
  CycleColumn,
  Subtitle,
  Blank,
  paddingLeft,
  cellPadding,
  stripe = true,
}) => (
  <TableRow WhiteGray={stripe}>
    <TableCell Row SpaceB padding={cellPadding}>
      {Blank ? null : (
        <FontReport
          SmallDetail={!Subtitle}
          DarkGray={!Subtitle && !CycleColumn}
          Detail={Subtitle}
          Blue={Subtitle}
          paddingLeft={paddingLeft}
        >
          {title}
        </FontReport>
      )}
      {Subtitle || NoDetail || Blank ? null : (
        <FontReport SmallDetail Half AlignRight>
          {convert(detail, type, format)}
        </FontReport>
      )}
    </TableCell>
  </TableRow>
)

export const CycleTableSpace = ({ title, detail, type, format, paddingLeft }) => (
  <TableRow WhiteGray>
    <TableCell Row>
      <FontReport FourTeen Cycle CycleHeaderDetail paddingLeft={paddingLeft}>
        {title}
      </FontReport>
      <FontReport FourTeen Cycle Half WhiteSpace>
        {convert(detail, type, format)}
      </FontReport>
    </TableCell>
  </TableRow>
)

export const TradelineGroupPaymentHistory = ({ paymentPatternStartDate, paymentPattern }) => {
  const paymentHistory = generatePaymentHistory(paymentPatternStartDate, paymentPattern)
  const Months = ['', 'J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']
  return (
    <TableStyle>
      <thead>
        <TableRow White>
          {Months.map((month, index) => (
            <TableCell key={index.toString()} PaymentDetail PaymentHeader>
              {month}
            </TableCell>
          ))}
        </TableRow>
      </thead>
      <tbody>
        {paymentHistory.map((data, index) => (
          <TableRow White key={`paymentHistory-${index.toString()}`}>
            <TableCell PaymentYear>{data.year}</TableCell>
            {data.monthsRevers.map((month, i) => (
              <PaymentHistoryValue month={month} key={i.toString()} />
            ))}
          </TableRow>
        ))}
      </tbody>
    </TableStyle>
  )
}

const PAYMENT_HISTORY = {
  1: {
    color: COLOR.fontBlue,
    content: '\\F00C',
    fontFamily: 'Font Awesome 6 Free',
  },
  2: {
    bgColor: COLOR.grayLight,
    color: COLOR.fontYellowDeep,
    content: '30',
  },
  3: {
    bgColor: COLOR.grayLight,
    color: COLOR.fontOrange,
    content: '60',
  },
  4: {
    bgColor: COLOR.grayLight,
    color: COLOR.fontRed,
    content: '90',
  },
  5: {
    bgColor: COLOR.grayLight,
    color: COLOR.fontRedDeep,
    content: '120',
  },
  X: {
    bgColor: COLOR.grayLight,
    color: 'gray',
    content: 'N/A',
  },
}

export const TradelineGroupPaymentSummary = ({ paymentPattern }) => (
  <Table>
    <TableRow PaymentSummary>
      <TableCell PaymentSummary>
        <Check {...PAYMENT_HISTORY['5']} Summary />
        {countAccountRating(paymentPattern, '5')}
      </TableCell>
      <TableCell PaymentSummary>
        <Check {...PAYMENT_HISTORY['4']} Summary />
        {countAccountRating(paymentPattern, '4')}
      </TableCell>
      <TableCell PaymentSummary>
        <Check {...PAYMENT_HISTORY['3']} Summary />
        {countAccountRating(paymentPattern, '3')}
      </TableCell>
      <TableCell PaymentSummary>
        <Check {...PAYMENT_HISTORY['2']} Summary />
        {countAccountRating(paymentPattern, '2')}
      </TableCell>
    </TableRow>
  </Table>
)

export const PaymentHistoryValue = ({ month }) => {
  const stylePaymentHistory = get(PAYMENT_HISTORY, month)
  return (
    <TableCell PaymentDetail>{stylePaymentHistory && <Check {...stylePaymentHistory} />}</TableCell>
  )
}

export const TSTableCell = ({ text, type, alignRight = true, ...props }) => (
  <TableCell AlignRight={alignRight} {...props}>
    {convert(text, type)}
  </TableCell>
)

export const CollectionDetails = ({ collection, index }) => (
  <Section>
    <PanelArea padding="i">
      <BoxHeader
        title={`${index + 1}. ${collection.collectionAgencyName}`}
        status={isEmpty(collection.dateClosed) ? 'Open' : 'Closed'}
        balance={`Balance: ${convert(collection.currentBalance, '$')}`}
        detail={`Original Balance: ${convert(collection.highCredit, '$')}`}
        isActive={isEmpty(collection.dateClosed) && isEmpty(collection.dateClosedIndicator)}
      />
    </PanelArea>
    <PanelColumn>
      <Column half>
        <Table>
          <TableSpace
            title="Last Reported"
            detail={collection.dateReported}
            type="date"
            format="MMM DD, YYYY"
          />
          <TableSpace title="Industry" detail={INDUSTRY_CODES[collection.industryCode]} />
          <TableSpace title="Loan Type/Term" detail={ACCOUNT_TYPE[collection.accountType]} />
          <TableSpace title="Status" detail={ACCOUNT_RAILING_CODES[collection.currentMOP]} />
          <TableSpace title="Remarks" detail={REMARK_CODES[collection.remarksCode]} />
        </Table>
      </Column>
      <Column half>
        <Table>
          <TableSpace
            title="Opened Date"
            detail={collection.dateOpened}
            type="date"
            format="MMM DD, YYYY"
          />
          <TableSpace
            title="Closed Date"
            detail={collection.dateClosed}
            type="date"
            format="MMM DD, YYYY"
          />
          <TableSpace
            title="Paid out Date"
            detail={collection.datePaidOut}
            type="date"
            format="MMM DD, YYYY"
          />
          <TableSpace
            title="Verified Date"
            detail={collection.dateVerified}
            type="date"
            format="MMM DD, YYYY"
          />
        </Table>
      </Column>
    </PanelColumn>
  </Section>
)

// CRIMINAL
export const CriminalImage = ({ src }) => (
  <CriminalImageContainer noneImg={!src}>
    {src ? (
      <img src={src} alt="Profile Img" style={{ width: '100%' }} />
    ) : (
      <CriminalNoImg className="icon-user" />
    )}
  </CriminalImageContainer>
)

export const CriminalTattooImage = ({ src }) => (
  <CriminalImageContainer trait noneImg={!src}>
    {src ? (
      <img src={src} alt="Profile Img" style={{ width: '100%' }} />
    ) : (
      <CriminalNoTattooImg>TATTOO</CriminalNoTattooImg>
    )}
  </CriminalImageContainer>
)

export const StateHeader = ({ index, state, category, id, date, isEviction }) => {
  const data = getStateHeaderData(category, isEviction)
  return (
    <CriminalStatePanel>
      <BoxColor StateHeader StateCount AlignItem="center">
        <FontReport TwentyFour Bold>
          {index}
        </FontReport>
      </BoxColor>
      <BoxColor StateHeader StateDetail AlignItem="center">
        <Space FlexColumn>
          <FontReport Small>State</FontReport>
          <FontReport Center Twenty Bold>
            {state}
          </FontReport>
        </Space>
      </BoxColor>
      <BoxColor StateHeader SpaceBetween AlignItem="center">
        <Space>
          <FontReport Twenty Bold>
            {data.category}
          </FontReport>
          <Space Flex>
            <FontReport EtcDetail Bold>
              {data.idLabel}
            </FontReport>
            <FontReport MarginLeft EtcDetail>
              {id}
            </FontReport>
          </Space>
        </Space>
        <Space SpaceBetween>
          <FontReport EtcDetail Bold>
            {data.dateLabel}&nbsp;
          </FontReport>
          <FontReport EtcDetail MarginLeft>
            {convert(date, 'date', 'MMM DD, YYYY')}
          </FontReport>
        </Space>
      </BoxColor>
    </CriminalStatePanel>
  )
}

export const Attention = () => (
  <Section>
    <Title text="Attention" />
    <PanelArea>
      <FontReport Attention>{ATTENTION_WORDING[0]}</FontReport>
    </PanelArea>
    <PanelArea>
      <FontReport Attention Bold>
        {ATTENTION_WORDING[1]}
      </FontReport>
    </PanelArea>
  </Section>
)

export const SpanBoldText = ({ children }) => (
  <Span size="14px" weight={600}>
    {children}
  </Span>
)

export const EmptySection = ({ text }) => (
  <div>
    <Section Row key="cosigners-section-empty">
      <PanelAreaNoneT>
        <Detail detail={`${text}`.trim()} />
      </PanelAreaNoneT>
    </Section>
  </div>
)
