export const CREATE_LISTING_SOURCE = {
  PROPERTY_OVERVIEW: 'property_overview',
  LISTING_MANAGEMENT: 'listing_management',
  GET_STARTED: 'feature_notice',
  SCREENING: 'screening'
}

export const LISTING_MANAGEMENT_SOURCE = {
  EDIT_LISTING: 'edit_listing_page',
  PROPERTY_OVERVIEW: 'property_overview',
  PUBLIC_LISTING_PAGE: 'public_listing_page',
  CREATE_LISTING_PAGE: 'create_listing_page',
  LISTING_MENU: 'listing_menu',
  SHARE_LISTING_PAGE: 'share_listing_page',
  PROPERTY_LIST: 'property_list',
}
export const SCREENING_REQUEST_SOURCE = {
  LISTING: 'listing',
  PROPERTY: 'property',
}

export const LISTING_IMPORT_STATUS = {
  ERROR: 'ERROR',
  LOADING: 'LOADING',
  IMPORTED: 'IMPORTED',
}

export const COMPONENT_2023_BREAKPOINT_MOBILE = 991
