import React from 'react'
import { ReportWrapper } from 'components/reports/screening-reports/share/responsive-doc-style'
import Button from '@rentspree/component-2023.components.atoms.button'
import womanWalkWithEnvelopesSVG from 'images/woman-walk-with-envelopes.svg'
import { MessageBody } from 'components/reports/screening-reports/share/components'

const AccessReportsComponent = ({ screeningReportStatuses }) => {
  const currentPath = window.location.href
  const lastSlashIndex = currentPath.lastIndexOf('/')
  const viewingAllReportsPath = currentPath.substring(lastSlashIndex)

  const handleAccessReportsClick = () => {
    const redirectUserPath = currentPath.replace(viewingAllReportsPath, '')

    if (screeningReportStatuses.credit_report.isAvailable) {
      window.location.assign(`${redirectUserPath}/credit-report`)
    } else if (screeningReportStatuses.criminal.isAvailable) {
      window.location.assign(`${redirectUserPath}/criminal`)
    } else if (screeningReportStatuses.eviction.isAvailable) {
      window.location.assign(`${redirectUserPath}/eviction`)
    }
  }

  return (
    <ReportWrapper>
      <div style={{ textAlign: 'center' }}>
        <MessageBody
          imgSrc={womanWalkWithEnvelopesSVG}
          imgAltText="woman with envelope"
          title="Tenant screening reports available"
          subtitle={`To access these reports, you'll need to confirm that required disclosures have been made to the applicant.`}
          buttons={
            <Button
              variant="text"
              color="secondary"
              size="medium"
              className="underline"
              onClick={handleAccessReportsClick}
            >
              Access reports
            </Button>
          }
        />
      </div>
    </ReportWrapper>
  )
}

export default AccessReportsComponent
