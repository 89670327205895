import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import Anchor from 'components/atoms/anchor'
import * as PATH from 'constants/route'
import history from 'utils/history'
import tracker from 'tracker'
import styled from 'styled-components'
import { buildPath, parse } from '@rentspree/path'
import { DialogContentText, Box as MuiBox } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@rentspree/component-2023.components.atoms.button'
import Dialog from '@rentspree/component-2023.components.atoms.dialog'
import DialogTitle from '@rentspree/component-2023.components.atoms.dialog-title'
import Sheet, { SheetHeader } from '@rentspree/component-2023.components.templates.sheet'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import IconButton from '@rentspree/component-2023.components.atoms.icon-button'
import { getSubdomain } from 'utils/subdomain'
import { useScreenSize } from '../../../hooks/use-screen-size'
const Image = styled.img`
  padding: 62px 96px;
  background-color: #eaeaef;
  min-width: 552px;
  width: 100%;
  object-fit: cover;
  @media (max-width: 991px) {
    padding: 25px 8px;
    align-self: center;
    height: auto;
    min-width: 100%;
  }
`
export const DATA_TEST_ID = {
  MARKET_PROPERTY_MODAL: 'market-property-modal',
  MARKET_PROPERTY_SHEET: 'market-property-sheet',
  MARKET_PROPERTY_BUTTON: 'market-property-button',
  LISTING_PAGE_KB_LINK: 'listing-page-kb-link',
}

export const MarketPropertyModal = ({ isOpen, onClose, propertyId }) => {
  const TRACKING = {
    PAGENAME: 'properties application',
    LOCATION: 'market property modal',
    MAIN_FEATURE: 'screening set up',
    SUB_FEATURE: 'generic',
  }
  const MARKET_PROPERTY_TEXT = 'Market Property'
  const URL_TO_LISTING_PAGE_KB = 'https://support.rentspree.com/en/how-do-i-use-listing-pages'
  const isPersistent = true

  const handleClose = (_, reason) => {
    if (['escapeKeyDown', 'backdropClick'].includes(reason)) return

    tracker.trackButtonClick({
      click_text: 'Close Icon',
      location: TRACKING.LOCATION,
      main_feature: TRACKING.MAIN_FEATURE,
      sub_feature: TRACKING.SUB_FEATURE,
    })

    onClose()
  }

  const handleClickMarketProperty = () => {
    const queryString = parse(history.location.search)

    const url = buildPath(
      PATH.IMPORT_MLS_LISTING,
      {
        propertyId,
      },
      { ...queryString },
    )
    tracker.trackButtonClick({
      click_text: MARKET_PROPERTY_TEXT,
      click_url: url,
      location: TRACKING.LOCATION,
      main_feature: TRACKING.MAIN_FEATURE,
      sub_feature: TRACKING.SUB_FEATURE,
    })
    history.push(url)
  }
  const handleClickLearnMore = () => {
    tracker.trackButtonClick({
      click_text: 'Learn about listing pages',
      click_url: URL_TO_LISTING_PAGE_KB,
      location: TRACKING.LOCATION,
      main_feature: TRACKING.MAIN_FEATURE,
      sub_feature: TRACKING.SUB_FEATURE,
    })
  }
  const title = (
    <>
      Screening is set up!
      <br />
      Now reach more renters.
    </>
  )
  const contentText = (
    <>
      Advertise your rental on top listing sites—for free! More visibility means more qualified
      applicants, faster.
      <br />
      <Anchor
        data-testid={DATA_TEST_ID.LISTING_PAGE_KB_LINK}
        color="inherit"
        hoverColor="inherit"
        to={URL_TO_LISTING_PAGE_KB}
        underline
        target="_self"
        onClick={handleClickLearnMore}
      >
        Learn about listing pages
      </Anchor>
    </>
  )
  const MARKET_PROPERTY_IMAGE_URL =
    'https://cdn.rentspree.com/static-files/get-started-image/market-property.png'

  const { isDesktop } = useScreenSize()
  return isDesktop ? (
    <Dialog
      data-testid={DATA_TEST_ID.MARKET_PROPERTY_MODAL}
      className="visibility-tracking element_visibility"
      data-event="element_visibility"
      data-location={TRACKING.LOCATION}
      data-pagename={TRACKING.PAGENAME}
      data-main_feature={TRACKING.MAIN_FEATURE}
      data-sub_feature={TRACKING.SUB_FEATURE}
      data-partner={getSubdomain()}
      open={isOpen}
      onClose={handleClose}
      size="large"
      disableEscapeKeyDown={isPersistent}
    >
      <IconButton
        data-testid="close-button"
        color="black"
        aria-label="close button"
        size="large"
        onClick={handleClose}
        style={{ position: 'absolute', top: 10, right: 10 }}
      >
        <CloseIcon />
      </IconButton>
      <Image src={MARKET_PROPERTY_IMAGE_URL} alt="market-property-partners" />
      <DialogTitle size="large" sx={{ whiteSpace: 'pre-line' }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ display: 'inline' }}>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ gap: '16px' }} disableSpacing>
        <Button
          data-testid={DATA_TEST_ID.MARKET_PROPERTY_BUTTON}
          variant="contained"
          color="primary"
          onClick={handleClickMarketProperty}
        >
          {MARKET_PROPERTY_TEXT}
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <Sheet
      data-testid={DATA_TEST_ID.MARKET_PROPERTY_SHEET}
      className="visibility-tracking element_visibility"
      data-event="element_visibility"
      data-location={TRACKING.LOCATION}
      data-pagename={TRACKING.PAGENAME}
      data-main_feature={TRACKING.MAIN_FEATURE}
      data-sub_feature={TRACKING.SUB_FEATURE}
      data-partner={getSubdomain()}
      open={isOpen}
      onClose={handleClose}
      showCloseButton
      PaperProps={{ sx: { height: '80vh', maxHeight: 'calc(100% - 40px) !important' } }}
      actionSection={
        <MuiBox display="flex" flexDirection="column" gap="16px">
          <Button
            data-testid={DATA_TEST_ID.MARKET_PROPERTY_BUTTON}
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleClickMarketProperty}
          >
            {MARKET_PROPERTY_TEXT}
          </Button>
        </MuiBox>
      }
    >
      <Image src={MARKET_PROPERTY_IMAGE_URL} alt="market-property-partners" />
      <SheetHeader header={title} />
      <Typography padding="16px" variant="body-medium" component="div">
        {contentText}
      </Typography>
    </Sheet>
  )
}
