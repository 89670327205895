import Box from '@mui/material/Box'
import Divider from '@rentspree/component-2023.components.atoms.divider'
import React, { useContext } from 'react'

import { COMPONENT_2023_BREAKPOINT_MOBILE } from 'containers/constants'
import useMobile from 'hooks/use-mobile'

import { PageIndexesContext, PageProgressionContext } from '../../context'

import { CreateButtons } from './utils'

export const DrawerFooter = ({
  buttonLayout,
  textOverrides,
  onProgress,
  onBack,
  onNext,
  nextEnabled = true,
  ...restProps
}) => {
  /*
   * Leverages StickyFooterTemplate for the bottom sticky bar, and then a Grid/flexbox
   * layout for the various button configurations.
   */
  const [pageIndexes] = useContext(PageIndexesContext)
  const contextProgressHandler = useContext(PageProgressionContext)
  const progressionHandler = typeof onProgress === 'function' ? onProgress : contextProgressHandler
  const isMobile = useMobile(COMPONENT_2023_BREAKPOINT_MOBILE)

  return (
    <Box sx={{ 'margin-top': 'auto' }}>
      <Divider />
      <Box
        sx={{
          ...restProps.sx,
          height: '80px',
        }}
      >
        {CreateButtons(
          pageIndexes,
          progressionHandler,
          buttonLayout,
          textOverrides,
          nextEnabled,
          isMobile,
          onBack,
          onNext,
        )}
      </Box>
    </Box>
  )
}
