import numeral from 'numeral'
import trim from 'lodash/trim'
import moment from 'moment'

export const convert = (value, type, format) => {
  if (typeof value === 'undefined' || value === null) return '-'
  try {
    const num = /(\d{3})(\d{3})(\d{4})/.exec(value)
    switch (type) {
      case '$':
        return `$${numeral(value).format(format || '0,0', Math.floor)}`
      case ',':
        return `${numeral(value).format('0,0', Math.floor)}`
      case 'phoneNumber':
        return num ? `(${num[1]}) ${num[2]}-${num[3]}` : '-'
      case 'dateOfUTC':
        return moment.utc(value).format(format || 'DD MMM YYYY')
      case 'dateOfLocal':
        return value ? moment(value).format(format || 'DD MMM YYYY') : '-'
      default:
        if (typeof value === 'object') {
          return 'N/A'
        }
        return value
    }
  } catch (e) {
    return 'N/A'
  }
}

export const addUnitFormat = (value, unit = '', isBegin = false) => {
  if (value) {
    return isBegin ? trim(`${unit} ${value}`) : trim(`${value} ${unit}`)
  }
  return ''
}
export const convertUSD = value => {
  if (value === '-') {
    return value
  }
  return convert(value, '$', '0,0.00')
}

export const convertDateLocal = (value, format) => {
  if (value === '-') {
    return value
  }
  return convert(value, 'dateOfLocal', format || 'MMM DD, YYYY')
}

export const convertDateUTC = (value, format) => {
  if (value === '-') {
    return value
  }
  return convert(value, 'dateOfUTC', format || 'MMM DD, YYYY')
}

export const convertDataTestId = text =>
  typeof text === 'string' ? text.toLocaleLowerCase().split(' ').join('-') : ''

export const convertToMonthAndYear = date => moment.utc(date).format('MMM, YYYY')
export const convertToMonthDayYear = date => moment.utc(date).format('MMM DD, YYYY')

export const convertToCommaAndDecimal = number =>
  number.toLocaleString('en', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
export const capitalizeFirstLetter = string =>
  string ? string.charAt(0).toUpperCase() + string.slice(1) : ''

export const convertToAmountPerMonthText = number => {
  if (number === null) {
    return '-'
  }
  const amount = number.toLocaleString('en', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  return `${amount} per month`
}
