import React, { useState } from 'react'
import { Panel } from 'react-bootstrap'
import Styled from 'styled-components'
import CollapsedToggle from 'images/icons/rent-payment/collapsed-toggle.svg'
import CollapseToggle from 'images/icons/rent-payment/collapse-toggle.svg'
import { COLOR } from 'styles/settings'

import { BOX_ID, MAP_BOX_SUBTITLE } from 'containers/tenant-screening/request-v2.2/constants'

import PayerBox from '../../molecules/payer-box'
import ApplicationTypeBox from '../../molecules/application-type-box'
import CustomScreeningBox from '../../molecules/custom-screening-box'

const Container = Styled.div`
  margin-bottom: 20px;
`

const StepOption = Styled(Panel)`
  background-color: white;
  border-radius: 5px;
  border: 1px solid ${COLOR.newGrey};
`

const PanelHeading = Styled(Panel.Heading)`
  margin: 0px;
  padding: 0px;
`

const PanelHeader = Styled.div`
  padding: ${props => props.padding};
  font-weight: 600;
  font-size: 16px;
  position: relative;
`

const PanelTitle = Styled.div`
  img {
    position: absolute;
    top: 20px;
    right: 20px;
  }
`

const Title = Styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
`

const HeaderSubtitleText = Styled.div`
  color: ${COLOR.fontBlue};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 991px) {
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  `

const PanelBody = Styled(Panel.Body)`
  padding: 0px 20px 0px 20px;
  `

const RequestStepOptions = props => {
  const {
    userPlan,
    requestOptions,
    screeningPlan,
    priceSchema,
    userExperience,
    handleSelectPayer,
    handleSelectApplication,
    handleSelectCreditReportItems,
    selectRequestStepControl,
    handleSelectIncomeVerification,
    handleViewCustomDocument,
  } = props

  const handleSectionRendering = () => {
    const sections = [
      {
        id: BOX_ID.PAYER_BOX,
        dataTestId: 'payer-box',
        header: 'Select who will pay the application fee:',
        subtitle: MAP_BOX_SUBTITLE(BOX_ID.PAYER_BOX, requestOptions),
        isEnabled: selectRequestStepControl.payerBox.isEnabled,
        description: (
          <PayerBox
            boxControl={selectRequestStepControl.payerBox}
            requestOptions={requestOptions}
            handleSelectPayer={handleSelectPayer}
          />
        ),
      },
      {
        id: BOX_ID.APPLICATION_TYPE_BOX,
        dataTestId: 'application-type-box',
        header: 'Select an application type:',
        subtitle: MAP_BOX_SUBTITLE(BOX_ID.APPLICATION_TYPE_BOX, requestOptions),
        isEnabled: selectRequestStepControl.applicationTypeBox.isEnabled,
        description: (
          <ApplicationTypeBox
            boxControl={selectRequestStepControl.applicationTypeBox}
            requestOptions={requestOptions}
            handleSelectApplication={handleSelectApplication}
          />
        ),
      },
      {
        id: BOX_ID.CUSTOM_SCREENING_BOX,
        dataTestId: 'custom-screening-box',
        header: 'Customize screening reports:',
        subtitle: MAP_BOX_SUBTITLE(BOX_ID.CUSTOM_SCREENING_BOX, requestOptions),
        isEnabled: selectRequestStepControl.customScreeningBox.isEnabled,
        description: (
          <CustomScreeningBox
            userPlan={userPlan}
            boxControl={selectRequestStepControl.customScreeningBox}
            requestOptions={requestOptions}
            priceSchema={priceSchema}
            userExperience={userExperience}
            handleSelectCreditReportItems={handleSelectCreditReportItems}
            handleSelectIncomeVerification={handleSelectIncomeVerification}
            handleViewCustomDocument={handleViewCustomDocument}
          />
        ),
      },
    ]

    // Handle case application has been disabled | e.g. CAA partner
    if (screeningPlan[0].application.disabled) {
      return sections.filter(section => section.id !== BOX_ID.APPLICATION_TYPE_BOX)
    }
    // Normal cases | add section number (1. ....)
    return sections.map((section, index) => ({
      ...section,
      header: `${index + 1}. ${section.header}`,
    }))
  }

  const sections = handleSectionRendering()

  const sectionList = sections.map(({ id }) => id)

  const [expandList, setExpandList] = useState(sectionList)

  const HeaderWithIcon = ({ dataTestId, expanded, text, subtitle, openModalButton }) => {
    const isShowModalButton = expanded && openModalButton
    const headerPadding = isShowModalButton ? '20px 20px 10px 20px' : '20px'

    return (
      <PanelHeader padding={headerPadding}>
        <PanelTitle>
          <Title className={expanded ? 'active' : ''}>{text}</Title>
          <img
            src={expanded ? CollapsedToggle : CollapseToggle}
            alt={`collapse${expanded && 'd'} toggle`}
          />
        </PanelTitle>
        {!expanded && (
          <HeaderSubtitleText
            data-testid={`selected-description-${dataTestId}`}
            className={expanded ? 'active' : ''}
          >
            {subtitle}
          </HeaderSubtitleText>
        )}
      </PanelHeader>
    )
  }

  return (
    <>
      {sections.map(section => {
        const expanded = expandList.includes(section.id)

        return section.isEnabled ? (
          <Container id={section.id} data-testid={section.dataTestId}>
            <StepOption eventKey={section.id} key={section.id} expanded={expanded}>
              <div>
                <PanelHeading
                  onClick={() => {
                    setExpandList(
                      expandList.includes(section.id)
                        ? expandList.filter(id => id !== section.id)
                        : [...expandList, section.id],
                    )
                  }}
                >
                  <Panel.Title toggle>
                    <HeaderWithIcon
                      dataTestId={section.dataTestId}
                      text={section.header}
                      subtitle={section.subtitle}
                      expanded={expanded}
                      openModalButton={section.openModalButton}
                    />
                  </Panel.Title>
                </PanelHeading>
              </div>
              <div>
                <PanelBody collapsible>
                  {expanded && section.openModalButton}
                  {section.description}
                </PanelBody>
              </div>
            </StepOption>
          </Container>
        ) : (
          <></>
        )
      })}
    </>
  )
}

export default RequestStepOptions
