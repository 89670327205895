/* eslint-disable no-underscore-dangle */
import get from 'lodash/get'
import React, { useState } from 'react'
import { useGateValue } from '@statsig/react-bindings'

import featureFlags from 'constants/feature-flags'
import NullDataReport from 'components/reports/screening-reports/share/null-report'
import { convert } from 'helpers/convert'
import { generateHeaderName, isEmptyArray } from 'legacy/components/reports-v2/share/function'
import { REPORT_TYPES } from 'containers/reports/constants'
import PendingConditionalAcceptance from 'components/reports/screening-reports/share/pending-conditional-acceptance/pending-conditional-acceptance'
import {
  parseTUJsonRecordData,
  parseRenterData,
  parseSearchStrategy,
  parseCasesLocation,
} from 'components/reports/helpers'
import ScreeningReportTemplate from 'components/reports/screening-reports/shareable/screening-report-template'
import { COLOR } from 'styles/settings'
import {
  ScreeningSectionHeader,
  ScreeningSubSectionHeader,
  ScreeningSubSectionContainer,
  FlexInfoContainer,
} from 'components/reports/screening-reports/share/responsive-doc-style'
import styled from 'styled-components'
import { getReportPending } from 'legacy/containers/application/utils'
import Alert from '@rentspree/component-2023.components.organisms.alert'
import { GENERAL_ERROR } from 'constants/error-messages'

import OldJsonFormattedReport from './share/old-json-formatted-report'
import { criminalMapper } from './criminal-report/mapper'
import { SanctionCase, SexOffenseCase, CriminalCase } from './criminal-report/criminal-record'
import PendingAvailability from '../share/pending-availability'

const SEX_OFFENDER_REGISTRY_LOCATION = 'National Offender Sex Registry'
const NATIONAL_EXPANDED_REGISTRY_LOCATION = 'National Expanded Search Results'

const CriminalReportComponent = ({
  renter,
  landlord,
  applicant,
  generatedOn,
  expiresOn,
  criminalRecords,
  showHeaderComponent,
  customBorder,
  customMargin,
  reportViewingLocked = false,
  match,
  handleConditionalAcceptance,
  applicationCreationDate,
  rentalDetail,
  reportName,
}) => {
  const isReportPending = getReportPending(applicant, 'CRIMINAL')
  const applyCriminalMigrationV2 = useGateValue(featureFlags.applyCriminalMigrationV2)

  if (reportViewingLocked) {
    return (
      <PendingConditionalAcceptance
        match={match}
        reportType={REPORT_TYPES.CRIMINAL}
        handleConditionalAcceptance={handleConditionalAcceptance}
      />
    )
  }
  if (isReportPending && applyCriminalMigrationV2) {
    return <PendingAvailability reportTypes={[REPORT_TYPES.CRIMINAL]} match={match} />
  }

  if (!criminalRecords) {
    return <NullDataReport generatedOn={generatedOn} headerDoc={reportName} />
  }

  const criminalData = criminalMapper(criminalRecords)
  const { identities, requestedConsumer, records } = criminalData
  const fullName = generateHeaderName(requestedConsumer || generateHeaderName(applicant))
  const remarks = get(identities, '[0].Remarks')
  const ssnMessage = get(applicant, 'CreditReport[0].reportData.applicants[0].sSNMessage')
  const isNewJsonFormat = !!get(criminalRecords, 'status')
  const property = get(rentalDetail, 'property')
  const { email, phoneNumber } = parseRenterData(renter)
  const { reportNumber, completedDate } = parseTUJsonRecordData(criminalRecords)

  const headerDocProps = {
    ssnMessage,
    text: reportName,
    transUnion: { expiresOn, generatedOn },
    renter,
    landlord,
  }

  const headerProps = {
    name: fullName,
    email,
    phone: convert(phoneNumber, 'phoneNumber'),
  }

  const criminalReportData = {
    isCriminalReport: true,
    requestedConsumer,
    records,
    identities,
    remarks,
  }

  if (!isNewJsonFormat) {
    return (
      <OldJsonFormattedReport
        customBorder={customBorder}
        customMargin={customMargin}
        showHeaderComponent={showHeaderComponent}
        headerDocProps={headerDocProps}
        headerProps={headerProps}
        criminalReportData={criminalReportData}
      />
    )
  }

  // parsing the new JSON being returned for our criminal records
  const criminalRecordsFull = isNewJsonFormat ? get(criminalRecords, 'criminal') : ''

  /*
  we're expecting TU to send back the required arr of searchResults in criminal and sanction solutions.
  if they don't return that back, we render an error as a failsafe.
  this alert is a placeholder until we get more defined error msgs from design.
  */
  const clearSanctionResults = criminalRecordsFull.sanctionsSolution.result === 'CLEAR'
  const availableSanctionsSolution = !!criminalRecordsFull.sanctionsSolution?.searchResults
  const availableCriminalSolution = !!criminalRecordsFull.criminalSolution?.searchResults

  const ScreeningError = () => (
    <Alert
      id="trans-union-missing-search-results-error-alert"
      title={GENERAL_ERROR.SCREENING_REPORT_ERROR.TITLE}
      sx={{ marginBottom: '24px' }}
    >
      <div>
        {GENERAL_ERROR.SCREENING_REPORT_ERROR.MESSAGE}
        <a href="https://status.rentspree.com" target="_blank">
          RentSpree's Status page.
        </a>
      </div>
    </Alert>
  )

  // if we don't have search results from sanction solutions, but it returns NOT_CLEAR, throw an err

  if (!availableSanctionsSolution && !clearSanctionResults) {
    return <ScreeningError />
  }

  // if we don't have the search results from criminal solutions, throw an err
  if (!availableCriminalSolution) {
    return <ScreeningError />
  }

  const criminalSearchRegionsResults = isNewJsonFormat
    ? criminalRecordsFull.criminalSolution?.searchResults[0]?.criminalSearchRegions
    : []
  const criminalNationalExpandedSearchResults = isNewJsonFormat
    ? criminalRecordsFull.criminalSolution?.searchResults[0]?.nationalExpandedSearchResults
    : []
  const criminalSexOffenderRegistrySearchResults = isNewJsonFormat
    ? criminalRecordsFull.criminalSolution?.searchResults[0]?.sexOffenderRegistrySearchResults
    : []
  const sanctionsGlobalSearchResults = isNewJsonFormat
    ? criminalRecordsFull.sanctionsSolution?.searchResults?.globalSanctionsSearchResults[0]
    : []

  const fullSanctionRecords = isNewJsonFormat
    ? criminalRecordsFull.sanctionsSolution.result !== 'CLEAR'
    : false

  return isNewJsonFormat ? (
    <ScreeningReportTemplate
      customBorder={customBorder}
      customMargin={customMargin}
      applicant={renter}
      reportNumber={reportNumber}
      completedDate={completedDate}
      orderedOn={convert(applicationCreationDate, 'dateOfLocal', 'MM/DD/YYYY')}
      packageName="In-Network"
      property={property}
    >
      <div style={{ display: 'grid', rowGap: '30px' }}>
        <CriminalAndPublicRecords
          nationalData={criminalNationalExpandedSearchResults}
          sexOffenderData={criminalSexOffenderRegistrySearchResults}
          criminalSearchRegionsResults={criminalSearchRegionsResults}
        />
        <SanctionRecords
          sanctionRecords={fullSanctionRecords}
          clearSanctionResults={clearSanctionResults}
          availableSanctionsSolution={availableSanctionsSolution}
          reportCompletedDate={completedDate}
          globalSanctionsData={sanctionsGlobalSearchResults}
          renter={renter}
        />
      </div>
    </ScreeningReportTemplate>
  ) : (
    <OldJsonFormattedReport
      customBorder={customBorder}
      customMargin={customMargin}
      showHeaderComponent={showHeaderComponent}
      headerDocProps={headerDocProps}
      headerProps={headerProps}
      criminalReportData={criminalReportData}
    />
  )
}

const TableDataStyle = { padding: '10px', color: 'dimgray' }
const TableHeaderStyle = { padding: '10px', color: 'gray' }

const ScreeningInfoTableContentWrapper = styled(FlexInfoContainer)`
  display: flex;
  width: 100%;
  @media print {
    break-inside: avoid;
  }
`

const CriminalAndPublicRecords = ({
  nationalData,
  sexOffenderData,
  criminalSearchRegionsResults,
}) => {
  const doesCriminalSearchRegionDataExist = !isEmptyArray(criminalSearchRegionsResults)
  const doesSexOffenderDataExist = sexOffenderData.result !== 'CLEAR'
  const doesNationalDataExist = nationalData.result !== 'CLEAR'
  let criminalSearchRegionData = []
  let sexOffenderRegionData = []
  let nationalExpandedRegionData = []
  const nonReportableCases = []
  const needReviewCases = []

  // populate our criminal search region data
  if (doesCriminalSearchRegionDataExist) {
    criminalSearchRegionData = parseCriminalSearchRegions(criminalSearchRegionsResults)
  }

  // populate our sex offender region data
  if (doesSexOffenderDataExist) {
    sexOffenderRegionData = parseSexOffenderRegistryResults(sexOffenderData.regions)
  } else {
    const nonReportableSexOffenderCase = {
      nonReportableCase: true,
      sexOffenseCase: true,
      completionDate: sexOffenderData.completedOn,
      searchStrategy: sexOffenderData.searchStrategies,
      location: SEX_OFFENDER_REGISTRY_LOCATION,
    }
    sexOffenderRegionData.push(nonReportableSexOffenderCase)
  }

  // populate our national expanded region data
  if (doesNationalDataExist) {
    nationalExpandedRegionData = parseNationalExpandedRegions(nationalData.regions)
  } else {
    const nonReportableNationalExpandedCase = {
      nonReportableCase: true,
      nationalExpandedCase: true,
      completionDate: nationalData.completedOn,
      searchStrategy: nationalData.searchStrategies,
      location: NATIONAL_EXPANDED_REGISTRY_LOCATION,
    }
    nationalExpandedRegionData.push(nonReportableNationalExpandedCase)
  }

  // given our criminalSearchRegionData, lets split the cases that are non-reportable and the cases that need review
  criminalSearchRegionData.forEach(criminalCases => {
    if (criminalCases.nonReportableCase) {
      nonReportableCases.push(criminalCases)
    } else {
      needReviewCases.push(criminalCases)
    }
  })

  // given our sexOffenderRegionData, split the cases between non-reportable and need review
  sexOffenderRegionData.forEach(sexOffense => {
    if (sexOffense.nonReportableCase) {
      nonReportableCases.push(sexOffense)
    } else {
      needReviewCases.push(sexOffense)
    }
  })

  let isNonReportableNationalExpandedCase = false
  // given our nationalExpandedRegionData, split the cases between non-reportable and need review
  nationalExpandedRegionData.forEach(nationalExpandedCase => {
    if (nationalExpandedCase.nonReportableCase) {
      nonReportableCases.push(nationalExpandedCase)
      isNonReportableNationalExpandedCase = true
    } else {
      needReviewCases.push(nationalExpandedCase)
    }
  })

  const criminalCases = []
  const sexOffenseCases = []
  const nationalExpandedCases = []

  needReviewCases.forEach(reviewCase => {
    if (reviewCase.criminalCase) {
      criminalCases.push(reviewCase)
    } else if (reviewCase.sexOffenseCase) {
      sexOffenseCases.push(reviewCase)
    } else {
      nationalExpandedCases.push(reviewCase)
    }
  })

  return (
    <div>
      <ScreeningSectionHeader>Criminal and Public Records</ScreeningSectionHeader>
      {!isEmptyArray(needReviewCases) && (
        <NeedsReviewCasesComponent
          criminalCases={criminalCases}
          sexOffenseCases={sexOffenseCases}
          nationalExpandedCases={nationalExpandedCases}
          productOrLocation="Location"
        />
      )}
      {nonReportableCases && (
        <NonReportableCasesComponent
          nonReportableCases={nonReportableCases}
          productOrLocation="Location"
        />
      )}
      {/* only render this block for national expanded search results */}
      {(isNonReportableNationalExpandedCase || !isEmptyArray(nationalExpandedCases)) && (
        <div style={{ marginTop: '15px' }}>
          <NationalExpandedSearchBlueDotIcon />
          <i>
            National Expanded Search utilizes Asurint's national criminal database to find records
            for locations outside of the candidate's address history.
          </i>
        </div>
      )}
    </div>
  )
}

const NationalExpandedSearchBlueDotIcon = ({ additionalStyle }) => (
  <span
    style={{
      height: '10px',
      width: '10px',
      backgroundColor: 'rgb(61 161 212)',
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: '5px',
      ...additionalStyle,
    }}
  ></span>
)

const SanctionRecords = ({
  sanctionRecords,
  clearSanctionResults,
  availableSanctionsSolution,
  reportCompletedDate,
  globalSanctionsData,
}) => {
  if (!availableSanctionsSolution && clearSanctionResults) {
    // hardcoded data intentionally.
    return (
      <div>
        <ScreeningSubSectionHeader data-testid="no-reportable-records" color={COLOR.tuGreen}>
          No Reportable Records
        </ScreeningSubSectionHeader>
        <ScreeningSubSectionContainer color={COLOR.tuGreen}>
          <ScreeningInfoTableContentWrapper>
            <table style={{ tableLayout: 'fixed', width: '100%' }}>
              <RecordsTableHeader productOrLocation="Product" />
              <RecordsTableRow
                productOrLocation="Global Sanctions"
                completedOn={reportCompletedDate}
                searchStrategy="National Search"
              />
            </table>
          </ScreeningInfoTableContentWrapper>
        </ScreeningSubSectionContainer>
      </div>
    )
  }

  const doesSanctionsDataExist =
    globalSanctionsData?.__typename === 'InternalGlobalSanctionsSearchResults'

  const sanctionSearchStrategy = parseSearchStrategy(globalSanctionsData?.searchStrategies)
  const sanctionCompletedDate = convert(
    globalSanctionsData?.completedOn,
    'dateOfLocal',
    'MM/DD/YYYY',
  )

  return (
    <div>
      <ScreeningSectionHeader>Sanctions</ScreeningSectionHeader>
      {sanctionRecords ? (
        <div>
          <ScreeningSubSectionHeader color={COLOR.tuDarkOrange}>
            Needs Review
          </ScreeningSubSectionHeader>
          <ScreeningSubSectionContainer color={COLOR.tuOrange}>
            <div style={{ height: '30px', marginLeft: '10px', marginTop: '15px' }}>
              <b style={{ padding: '10px', fontSize: '15px' }}>Global Sanctions</b>
            </div>
            {globalSanctionsData.records.map((sanction, index) => (
              <SanctionCase
                sanction={sanction}
                searchStrategy={sanctionSearchStrategy}
                completedDate={sanctionCompletedDate}
                key={index.toString()}
              />
            ))}
          </ScreeningSubSectionContainer>
        </div>
      ) : (
        <div>
          <ScreeningSubSectionHeader data-testid="no-reportable-records" color={COLOR.tuGreen}>
            No Reportable Records
          </ScreeningSubSectionHeader>
          <ScreeningSubSectionContainer color={COLOR.tuGreen}>
            <ScreeningInfoTableContentWrapper>
              <table style={{ tableLayout: 'fixed', width: '100%' }}>
                <RecordsTableHeader productOrLocation="Product" />
                {doesSanctionsDataExist && (
                  <RecordsTableRow
                    productOrLocation="Global Sanctions"
                    completedOn={sanctionCompletedDate}
                    searchStrategy={sanctionSearchStrategy}
                  />
                )}
              </table>
            </ScreeningInfoTableContentWrapper>
          </ScreeningSubSectionContainer>
        </div>
      )}
    </div>
  )
}

const RecordsTableHeader = ({ productOrLocation }) => (
  <tr style={{ borderBottom: `1px solid gray` }}>
    <th style={TableHeaderStyle}>{productOrLocation}</th>
    <th style={TableHeaderStyle}>Completion Date</th>
    <th style={TableHeaderStyle}>Search Strategy</th>
  </tr>
)

const RecordsTableRow = ({ productOrLocation, completedOn, searchStrategy }) => {
  const isNationalExpanded = productOrLocation === NATIONAL_EXPANDED_REGISTRY_LOCATION
  const nationalExpandedAdditionalStyle = {
    position: 'absolute',
    marginLeft: '5px',
    marginTop: '5.5px',
  }

  return (
    <tr>
      {/* Product or Location */}
      <td style={TableDataStyle}>
        <span>
          {productOrLocation}
          {isNationalExpanded && (
            <NationalExpandedSearchBlueDotIcon additionalStyle={nationalExpandedAdditionalStyle} />
          )}
        </span>
      </td>
      {/* Completion Date */}
      <td style={TableDataStyle}>{completedOn}</td>
      {/* Search Strategy */}
      <td style={TableDataStyle}>{searchStrategy}</td>
    </tr>
  )
}

const NeedsReviewCasesComponent = ({ criminalCases, sexOffenseCases, nationalExpandedCases }) => {
  const SEX_OFFENDER_HEADER = 'Sex Offender Registry'
  const CRIMINAL_CASE_HEADER = 'Criminal Cases'
  const NATIONAL_CASE_HEADER = 'National Expanded Search'

  return (
    <div>
      <ScreeningSubSectionHeader color={COLOR.tuDarkOrange}>Needs Review</ScreeningSubSectionHeader>
      {!isEmptyArray(nationalExpandedCases) && (
        <>
          <div style={{ marginTop: '15px', marginBottom: '15px' }}>
            <b style={{ fontSize: '16px' }}>{NATIONAL_CASE_HEADER}</b>
            <NationalExpandedSearchBlueDotIcon additionalStyle={{ marginLeft: '5px' }} />
          </div>
          <ScreeningSubSectionContainer color={COLOR.tuOrange}>
            {parseCasesLocation(nationalExpandedCases).map((nationalCase, index) => (
              // criminal & national cases are shaped w/ the same data
              <CriminalCase
                key={index.toString()}
                completedDate={convert(
                  nationalCase.metadata.completedOn,
                  'dateOfLocal',
                  'MM/DD/YYYY',
                )}
                criminalCase={nationalCase}
                searchStrategy={parseSearchStrategy(nationalCase.metadata.searchStrategy)}
              />
            ))}
          </ScreeningSubSectionContainer>
        </>
      )}
      {!isEmptyArray(sexOffenseCases) && (
        <>
          <div style={{ marginTop: '15px', marginBottom: '15px' }}>
            <b style={{ fontSize: '16px' }}>{SEX_OFFENDER_HEADER}</b>
          </div>
          <ScreeningSubSectionContainer color={COLOR.tuOrange}>
            {sexOffenseCases.map((sexOffenseCase, index) => (
              <SexOffenseCase
                key={index.toString()}
                completedDate={convert(
                  sexOffenseCase.metadata.completedOn,
                  'dateOfLocal',
                  'MM/DD/YYYY',
                )}
                sexOffenseCase={sexOffenseCase}
                searchStrategy={sexOffenseCase.metadata.searchStrategy}
              />
            ))}
          </ScreeningSubSectionContainer>
        </>
      )}
      {!isEmptyArray(criminalCases) && (
        <>
          <div style={{ marginTop: '15px', marginBottom: '15px' }}>
            <b style={{ fontSize: '16px' }}>{CRIMINAL_CASE_HEADER}</b>
          </div>
          <ScreeningSubSectionContainer color={COLOR.tuOrange}>
            {criminalCases.map((criminalCase, index) => (
              <CriminalCase
                key={index.toString()}
                completedDate={convert(
                  criminalCase.metadata.completedOn,
                  'dateOfLocal',
                  'MM/DD/YYYY',
                )}
                criminalCase={criminalCase}
                searchStrategy={parseSearchStrategy(criminalCase.metadata.searchStrategy)}
              />
            ))}
          </ScreeningSubSectionContainer>
        </>
      )}
    </div>
  )
}

const NonReportableCasesComponent = ({ nonReportableCases, productOrLocation }) => (
  <div>
    <ScreeningSubSectionHeader data-testid="no-reportable-records" color={COLOR.tuGreen}>
      No Reportable Records
    </ScreeningSubSectionHeader>
    <ScreeningSubSectionContainer color={COLOR.tuGreen}>
      <ScreeningInfoTableContentWrapper>
        <table style={{ tableLayout: 'fixed', width: '100%' }}>
          <RecordsTableHeader productOrLocation={productOrLocation} />
          {nonReportableCases.map((nonReportableCase, index) => (
            <RecordsTableRow
              key={index.toString()}
              productOrLocation={
                nonReportableCase.criminalCase
                  ? `${nonReportableCase.location.county}, ${nonReportableCase.location.state}`
                  : nonReportableCase.location
              }
              completedOn={convert(nonReportableCase.completionDate, 'dateOfLocal', 'MM/DD/YYYY')}
              searchStrategy={parseSearchStrategy(nonReportableCase.searchStrategy)}
            />
          ))}
        </table>
      </ScreeningInfoTableContentWrapper>
    </ScreeningSubSectionContainer>
  </div>
)

const parseNationalExpandedRegions = nationalExpandedRegionsArray => {
  const allRegions = []

  // parse thru array
  nationalExpandedRegionsArray.forEach(parseNationalRegions)

  // for each index in the array,
  function parseNationalRegions(nationalExpandedRegion) {
    if (nationalExpandedRegion.result !== 'CLEAR') {
      // then we have a case that needs review -> parse the records themselves
      nationalExpandedRegion.cases.forEach(parseNationalExpandedRegion)
    } else {
      // otherwise, it's a non-reportable record
      const nonReportableNationalExpandedCase = {
        nonReportableCase: true,
        nationalExpandedCase: true,
        completionDate: nationalExpandedRegion.completedOn,
        searchStrategy: nationalExpandedRegion.searchStrategies,
        location: NATIONAL_EXPANDED_REGISTRY_LOCATION,
      }
      allRegions.push(nonReportableNationalExpandedCase)
    }

    function parseNationalExpandedRegion(region) {
      // create address
      const caseAddress = region.defendant.address
      const cityAndState = `${caseAddress?.city || region.metadata.location.county || '-'}, ${
        caseAddress?.state || region.metadata.location.state || '-'
      }`

      // build a reportable record
      const needsReviewNationalExpandedCase = {
        nonReportableCase: false,
        nationalExpandedCase: true,
        caseNumber: region.caseNumber,
        court: region.court,
        cityAndState,
        defendant: region.defendant,
        fileDate: region.fileDate,
        metadata: region.metadata,
        offenses: region.offenses,
      }
      allRegions.push(needsReviewNationalExpandedCase)
    }
  }

  return allRegions
}

const parseSexOffenderRegistryResults = sexOffenderRegistryArray => {
  const allRegions = []

  // parse thru array
  sexOffenderRegistryArray.forEach(parseSexOffenderRegions)

  // for each index in the array, check if the result is not clear
  function parseSexOffenderRegions(sexOffenseRegion) {
    if (sexOffenseRegion.result !== 'CLEAR') {
      // then we have a case that needs review -> parse the records themselves
      sexOffenseRegion.records.forEach(parseSexOffenseRegion)
    } else {
      // otherwise, that's a non-reportable record -> return that as such
      const nonReportableSexOffenderCase = {
        nonReportableCase: true,
        sexOffenseCase: true,
        completionDate: sexOffenseRegion.completedOn,
        searchStrategy: sexOffenseRegion.searchStrategies,
        location: SEX_OFFENDER_REGISTRY_LOCATION,
      }
      allRegions.push(nonReportableSexOffenderCase)
    }

    function parseSexOffenseRegion(region) {
      // build a reportable record
      const needsReviewSexOffenderCase = {
        nonReportableCase: false,
        sexOffenseCase: true,
        defendant: region.defendant,
        metadata: region.metadata,
        state: region.state,
      }
      allRegions.push(needsReviewSexOffenderCase)
    }
  }

  return allRegions
}

const parseCriminalSearchRegions = criminalSearchRegionsArray => {
  const allCases = []
  // parse thru array
  criminalSearchRegionsArray.forEach(doCasesExist)

  // for each index in the array, check if the cases arr is empty
  function doCasesExist(criminalCase) {
    // if its empty, thats a non reportable record -> return that as such
    if (isEmptyArray(criminalCase.cases)) {
      const nonReportableCriminalCase = {
        nonReportableCase: true,
        criminalCase: true,
        completionDate: criminalCase.completedOn,
        searchStrategy: criminalCase.searchStrategies,
        location: criminalCase.location,
      }
      allCases.push(nonReportableCriminalCase)
    } else {
      // if its not empty, thats a "needs review" record -> parse the case itself
      criminalCase.cases.forEach(parseCriminalCase)
    }

    function parseCriminalCase(subCriminalCase) {
      const needsReviewCriminalCase = {
        nonReportableCase: false,
        criminalCase: true,
        caseNumber: subCriminalCase.caseNumber,
        court: subCriminalCase.court,
        defendant: subCriminalCase.defendant,
        fileDate: subCriminalCase.fileDate,
        metadata: subCriminalCase.metadata,
        offenses: subCriminalCase.offenses,
      }
      allCases.push(needsReviewCriminalCase)
    }
  }
  return allCases
}
export default CriminalReportComponent
