export const LISTING_STEPS = [
  { title: 'Create Listing Page', key: 'create-listing-page' },
  { title: 'Tenant', key: 'tenants' },
  { title: 'Payment Detail', key: 'payment-detail' },
  { title: 'Receiving Account', key: 'receiving-account' },
  { title: 'Confirmation', key: 'confirmation' },
]

export const LISTING_PUBLISHED_OPTIONS = {
  title: 'Listing Published!',
  text: 'Well done! Your listing is ready to be shared.',
  padding: '25px 20px 20px',
}
export const LISTING_UPDATED_AND_PUBLISHED_OPTIONS = {
  title: 'Listing Updated and Published!',
  text: 'Well done! Your listing is up to date and ready to be shared.',
  padding: '25px 50px 20px',
}
export const LISTING_PENDING_REVIEW = {
  title: 'Your listing is under review',
  text: `All listings go through a quality check that takes up to 48 hours. We’ll email you as soon as it’s live.`,
  padding: '25px 20px 20px',
}

export const LISTING_UPDATED_AND_PENDING_REVIEW = LISTING_PENDING_REVIEW

export const GET_PROPERTY = 'GET_PROPERTY'

export const START_GET_PROPERTY = 'START_GET_PROPERTY'

export const GET_PROPERTY_INFORMATION_SUCCESS = 'GET_PROPERTY_INFORMATION_SUCCESS'

export const GET_PROPERTY_INFORMATION_FAILED = 'GET_PROPERTY_INFORMATION_FAILED'

export const UPDATE_LISTING = 'UPDATE_LISTING'

export const START_UPDATE_LISTING = 'START_UPDATE_LISTING'

export const UPDATE_LISTING_SUCCESS = 'UPDATE_LISTING_SUCCESS'

export const UPDATE_LISTING_FAILED = 'UPDATE_LISTING_FAILED'

export const CLOSE_ERROR_PANEL = 'CLOSE_ERROR_PANEL'

export const CLOSE_SUCCESSFUL_PANEL = 'CLOSE_SUCCESSFUL_PANEL'

export const LISTING_STATUS_STATE = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
}

export const UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS'

export const UPLOAD_PHOTO_FAILED = 'UPLOAD_PHOTO_FAIL'

export const UPDATE_UPLOAD_PHOTO_PROGRESS = 'UPDATE_UPLOAD_PHOTO_PROGRESS'

export const START_UPLOAD_PHOTO = 'START_UPLOAD_PHOTO'

export const UPLOAD_PHOTO = 'UPLOAD_PHOTO'
export const UPLOAD_ALL_PHOTOS = 'UPLOAD_ALL_PHOTOS'
export const QUEUE_PHOTO = 'QUEUE_PHOTO'

export const UPDATE_QUEUE_PHOTO = 'UPDATE_QUEUE_PHOTO'

export const UPDATE_IS_IMAGE_UPLOADING = 'UPDATE_IS_IMAGE_UPLOADING'

export const REMOVE_PHOTO = 'REMOVE_PHOTO'

export const USER_INPUT_PHOTO = 'USER_INPUT_PHOTO'

export const SORT_PHOTO = 'SORT_PHOTO'
export const PUBLISH_LISTING = 'PUBLISH_LISTING'
export const PUBLISH_LISTING_START = 'PUBLISH_LISTING_START'
export const PUBLISH_LISTING_SUCCESS = 'PUBLISH_LISTING_SUCCESS'
export const PUBLISH_LISTING_FAILED = 'PUBLISH_LISTING_FAILED'
export const AUTO_CREATE_LISTING_SYNDICATION_SUCCESS = 'AUTO_CREATE_LISTING_SYNDICATION_SUCCESS'
export const AUTO_CREATE_LISTING_SYNDICATION_FAILED = 'AUTO_CREATE_LISTING_SYNDICATION_FAILED'
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE'
export const UPLOAD_PHOTO_TIMEOUT = 1000 * 60 * 2

export const GENERATE_DESCRIPTION = 'GENERATE_DESCRIPTION'
export const START_GENERATE_DESCRIPTION = 'START_GENERATE_DESCRIPTION'
export const GENERATE_DESCRIPTION_SUCCESS = 'GENERATE_DESCRIPTION_SUCCESS'
export const GENERATE_DESCRIPTION_FAILED = 'GENERATE_DESCRIPTION_FAILED'
export const RESET_GENERATED_DESCRIPTION = 'RESET_GENERATED_DESCRIPTION'

export const RATE_GENERATED_DESCRIPTION = 'RATE_GENERATED_DESCRIPTION'
export const START_RATE_GENERATED_DESCRIPTION = 'START_RATE_GENERATED_DESCRIPTION'
export const RATE_GENERATED_DESCRIPTION_SUCCESS = 'RATE_GENERATED_DESCRIPTION_SUCCESS'
export const RATE_GENERATED_DESCRIPTION_FAILED = 'RATE_GENERATED_DESCRIPTION_FAILED'

export const UPDATE_GENERATED_DESCRIPTION = 'UPDATE_GENERATED_DESCRIPTION'
export const SHOW_IDV_MODAL = 'SHOW_IDV_MODAL'
export const CLOSE_IDV_MODAL = 'CLOSE_IDV_MODAL'
export const SHOW_IDV_FAILURE = 'SHOW_IDV_FAILURE'
export const CLOSE_IDV_FAILURE = 'CLOSE_IDV_FAILURE'

export const REQUEST_IDV_FROM = {
  LISTING: 'listing',
}

export const LISTING_APPROVAL_STATUS = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  BLOCKED: 'BLOCKED',
}
