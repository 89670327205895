// NOTE: [INIT-678] - This can be removed after clean up feature flag
import React from 'react'
import styled from 'styled-components'
import { Col, Row } from 'react-bootstrap'
import get from 'lodash/get'
import { TextArea, RedGradientButton, Button } from '@rentspree/component-v2'
import { withFormik } from 'formik'
import { ButtonGroup } from 'components/shared/form'
import { voidFrom } from '../form-schema'

export const Wrapper = styled.form`
  input {
    font-size: 14px;
  }
  #footerButtonGroup {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    button {
      :last-child {
        margin-left: 10px;
      }
    }
  }
  .voidEnvelopeForm {
    padding: 0px 15px;
  }
`

export const SignButtonGroup = ({ closeModal, handleSubmit, isLoading }) => (
  <ButtonGroup id="footerButtonGroup" height="auto">
    <Button
      google
      small
      semiBold
      fontSize="14px"
      id="CancelVoidBtn"
      text="Cancel"
      type="button"
      disabled={isLoading}
      onClick={closeModal}
      height="35px"
      width="91px"
    />
    <RedGradientButton
      small
      id="SubmitVoidBtn"
      text="Void"
      width="92px"
      height="35px"
      weight="600"
      loading={isLoading}
      onClick={handleSubmit}
    />
  </ButtonGroup>
)

export const Form = props => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    errors,
    values,
    nextButtonText,
    closeModal,
    voidStatus,
  } = props
  return (
    <Wrapper onSubmit={handleSubmit}>
      <Row className="voidEnvelopeForm">
        <Col>
          <TextArea
            id="voidReason"
            name="voidReason"
            type="text"
            isRequired
            label="Reason for voiding document"
            textAreaStyle={{ resize: 'none', height: '80px' }}
            labelStyle={{ fontWeight: 'normal', float: 'left' }}
            onChange={handleChange}
            value={values.voidReason}
            onBlur={handleBlur}
            error={get(touched, 'voidReason') && get(errors, 'voidReason')}
          />
        </Col>
        <SignButtonGroup
          closeModal={closeModal}
          nextButtonText={nextButtonText}
          handleSubmit={handleSubmit}
          isLoading={voidStatus.isLoading}
        />
      </Row>
    </Wrapper>
  )
}

const VoidEnvelopeForm = withFormik({
  mapPropsToValues: () => voidFrom().mapper,
  validationSchema: () => voidFrom().validation,
  handleSubmit: (values, { props }) => {
    const { submitVoid } = props
    submitVoid(values.voidReason)
  },
  enableReinitialize: true,
  displayName: 'voidEnvelopeForm',
})(Form)

export default VoidEnvelopeForm
