import { debounce } from '@mui/material'
import dayjs from 'dayjs'
import timezonePlugin from 'dayjs/plugin/timezone'
import utcPlugin from 'dayjs/plugin/utc'

import { ONE_TIME_FEES, PAYMENT_TYPES } from 'v3/containers/overhaul-rent-payment/constants'
import { pageIndexesTemplate } from 'v3/containers/overhaul-rent-payment/context'
import { generateQuotationByType } from 'v3/containers/rent-payment/setup-page/set-up-for-myself/steps/payment-details/generate-quotation'

import crypto from 'crypto'

dayjs.extend(timezonePlugin)
dayjs.extend(utcPlugin)

export const utcStartOfDay = date => {
  /*
   * A quick reusable method to make sure all dates use UTC
   * WITHOUT keeping local time -- .utc(true) would keep local time offset
   */
  return dayjs.utc(date).startOf('day')
}

export const ordinalSuffix = date => {
  const unit = date % 10
  const specific = date % 100
  let suffix = 'th'
  if (unit === 1 && specific !== 11) suffix = 'st'
  if (unit === 2 && specific !== 12) suffix = 'nd'
  if (unit === 3 && specific !== 13) suffix = 'rd'
  return `${date}${suffix}`
}

export const nthDay = startDate => {
  const date = new Date(startDate)
  const day = date.getUTCDate()
  return `${ordinalSuffix(day)}`
}

// Example: Jan 1, 2025
export const abbreviatedDate = date => utcStartOfDay(date).format('MMM D, YYYY')

// Example: 2025-01-01
export const yearMonthDayDate = date => utcStartOfDay(date).format('YYYY-MM-DD')

export const getMonthsDifference = (startDate, endDate) => {
  if (!startDate || !endDate) return 0

  // Create Date objects from the input strings
  const start = new Date(startDate)
  const end = new Date(endDate)

  // Check if endDate is earlier than startDate
  if (end < start) return 0

  // Calculate the difference in months
  let months = (end.getFullYear() - start.getFullYear()) * 12
  months += end.getMonth() - start.getMonth()

  // Adjust for potential day differences within the same month
  if (end.getDate() < start.getDate()) {
    months -= 1
  }

  return months
}

export const isObject = obj => typeof obj === 'object' && !Array.isArray(obj)
export const isEmptyOrNotObject = obj => {
  return (
    obj === null ||
    obj === undefined ||
    (isObject(obj) && Object.getOwnPropertyNames(obj).length === 0)
  )
}

export const smartNextIndexesCalc = (
  // nextPageIndexes
  { pageL1Index, pageL2Index, drawerOpen },
  prevPageIndexes,
) => {
  /*
   * start from a default state, and add desired states top-down, so sub-page states are fresh if a parent-page progresses
   *  - i.e. if the top L1 page goes from index 1 to 2, and the L2 sub-page was at index 3, set pageL2Index to 0 so L1 page 2 doesn't start with an offset.
   *  - drawer state is considered "the sub-est sub-page" conceptually, for this method's logic
   *
   *   @param pageL1Index: the top-level (Level 1) page's index, optional if no change is desired
   *   @param pageL2Index: the second level (Level 2) page's index, optional if no change is desired
   *   @param drawerOpen: the Drawer's open/closed state flag, optional if no change is desired
   *   @param curPageIndexes: the complete & current object of all page indexes; required
   *   @return: the desired index states to change to
   */

  // if the caller passes nothing in, catch & set values to the blank-slate template
  const currentPageIndexes = prevPageIndexes || pageIndexesTemplate

  const nextPageIndexes = {
    pageL1Index: 0,
    pageL2Index: 0,
    drawerOpen: false,
  }

  // separate for debugging + line length
  const loopEntries = Array.from(
    // we want this "object" to be ordered, leveraging a quick Map
    new Map([
      ['pageL1Index', pageL1Index],
      ['pageL2Index', pageL2Index],
      ['drawerOpen', drawerOpen],
    ]).entries(),
  )
  // some unhelpful linting here; refactoring this loop would be a good idea later
  // eslint-disable-next-line no-restricted-syntax, no-unused-vars
  for (const [key, desiredNextVal] of loopEntries) {
    if (desiredNextVal === undefined) {
      nextPageIndexes[key] = currentPageIndexes[key]
    } else {
      nextPageIndexes[key] = desiredNextVal
      if (desiredNextVal !== currentPageIndexes[key]) {
        // everything "lower level" than this should be reset, so that page level can start fresh; break out of the loop now
        break
      }
    }
  }

  return nextPageIndexes
}

export const createPropertyString = property => {
  const { street, unitNumber, state, city, zip } = property
  const propertyValues = [street, unitNumber, state, Array.join([city, zip], ' ')].filter(
    val => typeof val === 'string' && val !== '',
  )
  return Array.join(propertyValues, ', ')
}

export const tenantToString = (tenant = {}) => {
  const { firstName, lastName } = tenant
  return `${firstName} ${lastName}`.trim()
}

export const isCategoryOneTimeOnly = category => {
  return ONE_TIME_FEES.includes(category)
}

export const getPaymentTemplate = category => {
  const type = isCategoryOneTimeOnly(category) ? PAYMENT_TYPES.ONE_TIME : PAYMENT_TYPES.RECURRING

  const template = {
    ...generateQuotationByType({
      category,
      customCategory: category,
    }),
    type,
    description: null,
    id: `tmp-${crypto.randomBytes(20).toString('hex')}`,
  }

  return template
}

export const findPayment = (payments, desiredCategory, existingId) => {
  /*
   * quickly checks for the Rent category payment in a given array of payments
   *   TODO: consider an object/map
   *
   *     @payments: an array of payment objects to iterate through
   *     @desiredCategory: the category the caller is searching for, for a new payment instantiation
   *     @existingId: the 'id' field value if the caller expects it to exist.
   *                     - note: if this is populated but not found, the caller will
   */
  let paymentIndex = -1

  if (existingId !== undefined) {
    //   - outdated guidance? https://stackoverflow.com/a/72072751 (and vars are, in fact, definitely used)
    // eslint-disable-next-line no-restricted-syntax, no-unused-vars
    paymentIndex = payments.findIndex(payment => {
      return payment?.id === existingId
    })
  }

  /*
   * soft recovery to see if only one instance of a category exists
   * mainly used by the Add Rent flow, currently
   */
  if (paymentIndex < 0) {
    // seems like findIndexes() doesn't exist; using map() + filter() vs looping findIndex() calls
    // eslint-disable-next-line array-callback-return, consistent-return
    const allCategoryIndexes = payments
      .map((val, i) => {
        if (val?.category === desiredCategory) {
          return i
        }
        return undefined
      })
      .filter(val => val !== undefined)

    if (allCategoryIndexes.length === 1) {
      ;[paymentIndex] = allCategoryIndexes
    }
  }

  // light shallow-copy
  const payment = {
    ...(paymentIndex >= 0 ? payments[paymentIndex] : getPaymentTemplate(desiredCategory)),
  }

  return [paymentIndex, payment]
}

export const capitalize = str => {
  if (!str) return ''
  str.trim()
  if (str.length === 0) return ''
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const updatePayment = debounce(({ nextPayment, payments, setPayments, paymentIndex }) => {
  /*
   * Reusable util to set a payment's value within the overall payments array.
   *
   *   TODO: consider an object/map
   *   TODO: use 'findPayment(payment.category)' instead of the paymentIndex parameter
   *
   * @nextPayment: the next value of the payment object to set in the payments array, new or replacing an existing (old) iteration
   * @payments: the existing array of payments; this might include the existing/old version of the 'nextPayment' object
   * @setPayments: the method to set the payments array, likely a setState() handler
   */

  /*
   * Allow the user to specify the index and leave it to them to overwrite
   * category with another *only when intended*,  as that's a needed editing capability
   */
  const desiredIndex = Number.isInteger(paymentIndex)
    ? paymentIndex
    : findPayment(payments, nextPayment?.category)[0]

  const nextPayments = [...payments]
  if (desiredIndex < 0) {
    nextPayments.push(nextPayment)
  } else {
    nextPayments[desiredIndex] = nextPayment
  }

  // only objects should be allowed, especially if debounce logic accidentally sends in 'undefined' via race condition, etc.
  setPayments(nextPayments.filter(val => isObject(val)))
}, 400)
