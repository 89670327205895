import { PROPERTY_TYPES as propertyTypes } from 'containers/property-propertyType/const'

export { BATH_ROOM_CHOICES } from 'containers/rent-estimate/const'

export { BED_ROOM_CHOICES } from 'components/templates/property-details/consts'

export const PROPERTY_TYPES = [
  {
    value: '',
    label: '',
  },
  ...propertyTypes,
]

export const VIDEO_REGEX =
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))(youtube\.com|vimeo\.com|youtu\.be)(\/)?([\w?[a-zA-Z-_%/@#?=&]+)*$/

export const MIN_PHOTOS_ALLOWED = 1
export const MAX_PHOTOS_ALLOWED = 50
