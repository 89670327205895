import { PROPERTY_API_V2 } from 'constants/route'

export const DISABLE_FEATURE = {
  CALL: 'DISABLE_FEATURE_CALL',
  REQUEST: 'DISABLE_FEATURE_REQUEST',
  SUCCESS: 'DISABLE_FEATURE_SUCCESS',
  FAILED: 'DISABLE_FEATURE_FAILED',
}

export const ENABLE_FEATURE = {
  CALL: 'ENABLE_FEATURE_CALL',
  REQUEST: 'ENABLE_FEATURE_REQUEST',
  SUCCESS: 'ENABLE_FEATURE_SUCCESS',
  FAILED: 'ENABLE_FEATURE_FAILED',
}

export const OPEN_TOGGLE_PROPERTY_FEATURE_MODAL = 'OPEN_TOGGLE_PROPERTY_FEATURE_MODAL'
export const CLEAR_TOGGLE_PROPERTY_FEATURE_MODAL = 'CLEAR_TOGGLE_PROPERTY_FEATURE_MODAL'

export const ENABLE_FEATURE_API = `${PROPERTY_API_V2}/:propertyId/enable-feature`
export const DISABLE_FEATURE_API = `${PROPERTY_API_V2}/:propertyId/disable-feature`
export const FEATURE = {
  TENANT_SCREENING: 'tenantScreening',
  LISTING_APPLICATION: 'listingApplication',
}
export const SCREENING_REQUEST_SOURCE = {
  LISTING: 'listing',
  PROPERTY: 'property',
}

export const FEATURE_STATUS = {
  ENABLE: 'enable',
  DISABLED: 'disable',
}

export const RADIO = {
  subtitle: 'Have you finalized tenants for this listing?',
  choices: [
    {
      label: 'Yes, I screened them through RentSpree.',
      id: 'radio-1',
      value: 'accept_rentspree_tenant',
    },
    {
      label: 'Yes, but I screened them another way.',
      id: 'radio-2',
      value: 'accept_another_tenant',
    },
    {
      label: 'No, I am still searching.',
      id: 'radio-3',
      value: 'still_searching',
    },
    {
      label: 'No, the listing will no longer be rented.',
      id: 'radio-4',
      value: 'no_longer_be_rented',
    },
  ],
}

const { DISABLED, ENABLE } = FEATURE_STATUS

export const TITLE = {
  [DISABLED]: 'Pause tenant screening',
  [ENABLE]: 'Resume tenant screening',
}

export const CONFIRM_TEXT = {
  [ENABLE]: 'Resume',
  [DISABLED]: 'Pause',
}

export const SOURCE = {
  TOGGLE_APP_LIST: 'toggle_app_list',
  TOGGLE_OVERVIEW: 'toggle_overview',
  SCREEN_OVERVIEW: 'screen_overview',
  SCREEN_APP_LIST: 'screen_app_list',
  SCREEN_EMPTY: 'screen_empty',
}
