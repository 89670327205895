import { buildPath, parseSubdomain } from '@rentspree/path'
import React, { useEffect } from 'react'

import * as PATH from 'constants/route'
import { MAIN_URL } from 'env'
import { LISTING_MANAGEMENT_SOURCE } from 'containers/constants'
import { compose } from 'redux'
import { withReducer } from 'containers/listing-management/connect'
import { LISTING_APPROVAL_STATUS } from 'containers/create-listing/const'
import { withConnect } from './connect'

export const ShareListing = ({ match, actions, property, subdomain }) => {
  const getListingPageUrl = () => {
    const { listing = {} } = property
    if (!listing?.slug) return null
    return buildPath(`${MAIN_URL}${PATH.LISTING_PUBLIC}`, { slug: listing.slug })
  }

  // TODO: Can be improved to parse localhost:3000 from MAIN URL
  const getListingManagementPath = () => {
    const { propertyId } = property
    const withSubdomain = subdomain ? parseSubdomain(subdomain, MAIN_URL) : MAIN_URL
    return buildPath(
      `${withSubdomain}${PATH.DASHBOARD}${PATH.LISTING_MANAGE}`,
      { propertyId },
      { source: LISTING_MANAGEMENT_SOURCE.SHARE_LISTING_PAGE },
    )
  }

  const openListingManagement = (openNewTab = false) => {
    const listingManagementPath = getListingManagementPath()
    if (listingManagementPath) {
      if (openNewTab) {
        window.open(listingManagementPath, '_blank')
      } else {
        window.location.href = listingManagementPath
      }
    }
  }

  const redirectToPublicListingPage = () => {
    const listingPageUrl = getListingPageUrl()
    if (listingPageUrl) {
      window.location.assign(listingPageUrl)
    }
  }

  useEffect(() => {
    if (!property.propertyId) {
      const { params } = match
      actions.getProperty(params?.propertyId)
    } else {
      // TODO: confirm requirement, should we redirect to public listing page? if not, remove this line
      const openNewTab = property?.listing?.approvalStatus === LISTING_APPROVAL_STATUS.ACTIVE
      openListingManagement(openNewTab)
      if (openNewTab) {
        redirectToPublicListingPage()
      }
    }
  }, [property?.propertyId])

  return <div />
}

export default compose(withConnect, withReducer)(ShareListing)
