import { Box } from '@mui/material'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import React, { useState, useEffect } from 'react'

import { STATSIG, useFeatureFlag } from 'hooks/use-statsig-feature-flag'
// import { AddLateFeeComponent } from 'v3/containers/overhaul-rent-payment/components/payment-forms/late-fee'
import { TellUsAboutPayment } from 'v3/containers/overhaul-rent-payment/components/payment-forms/tell-us-about-payment'
import {
  generateAPIQuotation,
  ingestQuotation,
} from 'v3/containers/overhaul-rent-payment/components/payment-forms/utils'
import { PaymentCategories } from 'v3/containers/overhaul-rent-payment/constants'
import { findPayment, updatePayment } from 'v3/containers/overhaul-rent-payment/pages/utils'
import { ABOUT_PAYMENT } from 'v3/containers/overhaul-rent-payment/text-constants'

export const RentPaymentComponent = ({ payments, setPayments, setIsValid }) => {
  const { isEnabled: isPaymentFeeAdjustmentsEnabled } = useFeatureFlag({
    statsigFeatureFlagKey: 'payment_fee_adjustments',
    featureFlagProvider: STATSIG,
  })

  // we can only have one rent payment, so either grab it or template a fresh one
  const [rentPaymentIndex, rentPayment] = findPayment(payments, PaymentCategories.RENT)

  const [localPayment, setLocalPayment] = useState({
    ...ingestQuotation(rentPayment),
    amount: rentPayment?.amount,
    startDate: rentPayment?.type === 'oneTime' ? rentPayment?.dueDate : rentPayment?.startDate,
  })

  // save a version of the rent payment as it's validated
  const localPaymentJSON = JSON.stringify(localPayment)
  useEffect(() => {
    updatePayment({
      nextPayment: generateAPIQuotation(localPayment, isPaymentFeeAdjustmentsEnabled),
      payments,
      setPayments,
      paymentIndex: rentPaymentIndex,
    })
  }, [localPaymentJSON])

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        textAlign: 'left',
      }}
    >
      <Typography variant="title-medium" sx={{ marginBottom: '32px' }}>
        {ABOUT_PAYMENT.RENT_TITLE}
      </Typography>
      <TellUsAboutPayment
        payment={localPayment}
        setPayment={setLocalPayment}
        setIsValid={setIsValid}
      />
      {/* <AddLateFeeComponent
        rentPayment={rentPayment}
        payments={payments}
        setPayments={setPayments}
        setDrawerOpen={_setDrawerOpen}
      /> */}
    </Box>
  )
}
