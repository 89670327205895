import { call, put, takeLatest } from 'redux-saga/effects'

import { QUOTATION_TYPE } from 'v3/containers/rent-payment/constants'
import { DEFAULT_FEE_COLLECTION_OBJECT } from 'v3/containers/rent-payment/shared/redux/constants'

import { UPDATE_DRAFT_QUOTATIONS, updateDraftQuotationsAPI } from '../../actions'
import { updateDraftQuotationsInstance } from '../../api-instances'

const mapQuotations = quotations =>
  quotations.map(
    ({
      amount,
      description,
      dueDate,
      category,
      type,
      startDate,
      endDate,
      dueOn,
      feeCollection,
      term,
    }) => {
      const normalizedFeeCollection = { ...DEFAULT_FEE_COLLECTION_OBJECT, ...feeCollection }
      if (type === QUOTATION_TYPE.ONE_TIME) {
        return {
          amount: Number(parseFloat(amount).toFixed(2)),
          description,
          dueDate,
          category,
          type,
          feeCollection: normalizedFeeCollection,
        }
      }
      return {
        amount: Number(parseFloat(amount).toFixed(2)),
        description,
        dueOn,
        category,
        type,
        startDate,
        repeat: 'monthly',
        endDate,
        feeCollection: normalizedFeeCollection,
        term,
      }
    },
  )

export function* updateDraftQuotationsSaga({
  payload: { rentalPaymentId, quotations, onSuccess, onError },
}) {
  try {
    yield put(updateDraftQuotationsAPI.request())

    const draftQuotations = yield call(updateDraftQuotationsInstance, {
      rentalPaymentId,
      quotations: mapQuotations(quotations),
    })

    yield put(updateDraftQuotationsAPI.success(draftQuotations))
    yield call(onSuccess, draftQuotations)

    return draftQuotations
  } catch (error) {
    yield put(updateDraftQuotationsAPI.failure(error))
    yield call(onError, error)

    return null
  }
}

export function* watchUpdateDraftQuotationsSaga() {
  yield takeLatest(UPDATE_DRAFT_QUOTATIONS, updateDraftQuotationsSaga)
}
