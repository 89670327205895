import isObject from 'lodash/isObject'
import { setLocalItem, getLocalItem, removeAllLocalItem, setOption } from '@rentspree/cookie'
import { removeAllLocalStorageItems } from 'helpers/local-storage'
import { authService } from 'services/auth-service'
// eslint-disable-next-line import/no-cycle
import { getAccessToken, getRefreshToken, getExpiresIn } from '../../reducers/persist-state.reducer'
import { STORAGE, COOKIE_OPTION } from '../../../constants/cookie'

// TODO: Not being used, mark for delete
export const saveAccessToken = state => {
  const ACCESS_TOKEN = getAccessToken(state)
  const REFRESH_TOKEN = getRefreshToken(state)
  const EXPIRES_IN = getExpiresIn(state)

  if (ACCESS_TOKEN || REFRESH_TOKEN || EXPIRES_IN) {
    const token = {
      access_token: ACCESS_TOKEN,
      refresh_token: REFRESH_TOKEN,
      expires_in: EXPIRES_IN,
    }
    setOption(COOKIE_OPTION)
    setLocalItem(STORAGE.USER_TOKEN, token)
  }
}

export const saveAccessTokenLogin = state => {
  if (state.access_token || state.refresh_token || state.expires_in) {
    const token = Object.assign({}, state)
    delete token.type
    setOption(COOKIE_OPTION)
    setLocalItem(STORAGE.USER_TOKEN, token)
  }
}

export const loadAccessToken = () => {
  const defaultToken = {
    accessToken: null,
    refreshToken: null,
    expiresIn: null,
  }
  try {
    const shouldUseAuthProviderToken = authService.shouldUseAuthProviderToken()
    if (shouldUseAuthProviderToken) {
      return {
        accessToken: authService.getLocalAccessToken(),
        // Refresh token and expires in will not be used for Auth0 refresh process anyway, just to maintain the reducer structure
        refreshToken: '',
        expiresIn: 300,
      }
    }
    const userToken = getLocalItem(STORAGE.USER_TOKEN)
    const o = isObject(userToken) ? userToken : JSON.parse(userToken)
    return {
      accessToken: o.access_token,
      refreshToken: o.refresh_token,
      expiresIn: o.expires_in,
    }
  } catch (e) {
    return defaultToken
  }
}

export const removeAccessToken = () => {
  setOption(COOKIE_OPTION)
  removeAllLocalItem('rentspree')
  removeAllLocalStorageItems()
}
