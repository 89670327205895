import React from 'react'
import { useFeatureGate } from '@statsig/react-bindings'

/**
 * FeatureGateComponentController is a higher-order component that conditionally renders
 * one of two components based on a feature gate.
 *
 * @param {Object} props - The properties passed to the FeatureGateComponentController component.
 * @param {string} props.gateName - The name of the feature gate to check.
 * @param {React.ComponentType} props.GateOnComponent - The component to render if the feature gate is on.
 * @param {React.ComponentType} props.GateOffComponent - The component to render if the feature gate is off.
 * @returns {React.ReactNode} - The rendered component based on the feature gate status.
 */
export const FeatureGateComponentController =
  ({ gateName, GateOnComponent, GateOffComponent }) =>
  props => {
    const { value: isGateOn } = useFeatureGate(gateName)

    if (isGateOn) {
      return <GateOnComponent {...props} />
    }

    return <GateOffComponent {...props} />
  }
