/* eslint-disable camelcase */
import React from 'react'

import tracker from 'tracker'
import { Table } from '@rentspree/component-2023.components.organisms.table'
import { TableBody } from '@rentspree/component-2023.components.organisms.table-body'
import { TableCell } from '@rentspree/component-2023.components.organisms.table-cell'
import { TableHead } from '@rentspree/component-2023.components.organisms.table-head'
import { TableRow } from '@rentspree/component-2023.components.organisms.table-row'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import Badge from '@rentspree/component-2023.components.atoms.badge'
import Button from '@rentspree/component-2023.components.atoms.button'

import './index.scss'
import { buildPath } from '@rentspree/path'
import { PROPERTY_OVERVIEW_PAGE } from 'constants/route'
import { DropdownWithUseDisableFeature } from 'components/property-list/dropdown-with-use-disable-feature'
import { UNARCHIVE_TABLE_BUTTON_LABEL } from 'containers/property/toggle-archive-property/constants'
import { generateAddress } from '@rentspree/helper'
import EmptyState from '@rentspree/component-2023.components.templates.empty-state'
import { Box } from '@mui/material'

import HomeIcon from '@mui/icons-material/HomeOutlined'
import { COLOR } from 'styles/settings'
import { TABLE_HEADER } from '../constants'

export const PropertyListTable = ({
  data,
  isHighlightScreenTenant,
  setIsHighlightScreenTenant,
  onArchiveProperty,
  onUnarchiveProperty,
  history,
  showDataEmptyState,
}) => {
  if (showDataEmptyState) {
    return (
      <Box data-testid="property-table-empty-state">
        <EmptyState
          image={
            <img
              src="https://cdn.rentspree.com/static-files/zero-state/search-not-found-empty-state.svg"
              alt="search property empty state"
            />
          }
          subTitle="No properties match your search and filter criteria."
        />
      </Box>
    )
  }

  return (
    <Box id="propertyTable" className="property-table" isHideDesktop={data.length === 0}>
      <Table className="property-table-container">
        <TableHead>
          <TableRow id="propertyTableHeader">
            {TABLE_HEADER.map(header => (
              <TableCell key={header.value} sx={{ minWidth: header.minWidth ?? 'unset' }}>
                <Box className="property-table-header-cell" sx={{ justifyContent: header.align }}>
                  {header.text}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className="property-table-body-container">
          {data?.map((property, index) => {
            const {
              _id: propertyId,
              archived,
              total_requested,
              total_submitted,
              total_shared,
            } = property
            const isArchived = !!archived

            return (
              <TableRow
                className="property-table-body-row"
                id={`propertyTableRow${index}`}
                data-testid="property-table-row"
                key={index}
                greyRow={isArchived}
                tabIndex="0"
                onClick={() => {
                  const url = buildPath(PROPERTY_OVERVIEW_PAGE, {
                    propertyId,
                  })
                  tracker.trackButtonClick({
                    click_text: 'property address',
                    location: 'l1_properties_table_row',
                    click_url: url,
                    propertyID: propertyId,
                  })
                  history.push(url)
                }}
                sx={{ cursor: 'pointer', ':hover': { backgroundColor: '#f5f5f5' } }}
              >
                <TableCell>
                  <AddressColumn property={property} index={index} />
                </TableCell>
                <TableCell align="center">
                  <PropertyRowLabel isArchived={isArchived}>{total_requested}</PropertyRowLabel>
                </TableCell>
                <TableCell align="center">
                  <PropertyRowLabel isArchived={isArchived}>{total_submitted}</PropertyRowLabel>
                </TableCell>
                <TableCell align="center">
                  <PropertyRowLabel isArchived={isArchived}>{total_shared}</PropertyRowLabel>
                </TableCell>
                <TableCell align="right">
                  {isArchived ? (
                    <Button
                      id={`UnarchiveBtn${index}`}
                      data-testid={`unarchive-btn-${index}`}
                      wrapped
                      variant="text"
                      size="small"
                      color="secondary"
                      className="underline"
                      onClick={e => {
                        e.stopPropagation()
                        onUnarchiveProperty(propertyId)
                      }}
                    >
                      {UNARCHIVE_TABLE_BUTTON_LABEL}
                    </Button>
                  ) : (
                    <DropdownWithUseDisableFeature
                      id={`DropdownAction${index}`}
                      property={property}
                      clickArchiveProperty={onArchiveProperty}
                      history={history}
                      isHighlightScreenTenant={isHighlightScreenTenant && index === 0}
                      setIsHighlightScreenTenant={setIsHighlightScreenTenant}
                    />
                  )}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Box>
  )
}

export const AddressColumn = ({ property, index }) => {
  const address = generateAddress(property)
  const isArchived = !!property.archived
  return (
    <Box id={`propertyItem${index}`} className="address-column-container">
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
        <Box id={`propertyItem${index}Text`} className="address-column-text-content">
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
            <HomeIcon
              name="listing"
              id={`propertyItem${index}Icon`}
              sx={{ fontSize: '24px', color: isArchived ? COLOR.statusNeutral : 'unset' }}
            />
            <PropertyRowLabel isArchived={isArchived}>{address}</PropertyRowLabel>
          </Box>
        </Box>
        {property.total_unopen > 0 && (
          <Badge
            badgeContent={property.total_unopen}
            sx={{ marginLeft: '10px' }}
            id={`propertyItem${index}Noti`}
            data-testid={`propertyItem${index}Noti`}
          />
        )}
      </Box>
    </Box>
  )
}

const PropertyRowLabel = ({ isArchived, children }) => (
  <Typography
    variant="body-medium"
    color={isArchived ? COLOR.statusNeutral : COLOR.blackText}
    className="ellipsis-text"
  >
    {children}
  </Typography>
)
