import generateApiCall from 'utils/generate-api-call'

import {
  GET_LISTING_SYNDICATION_REQUEST,
  GET_LISTING_SYNDICATION_SUCCESS,
  GET_LISTING_SYNDICATION_FAILURE,
  GET_LISTING_SYNDICATION_CALL,
  TOGGLE_LISTING_SYNDICATION_CALL,
  ENABLE_LISTING_SYNDICATION_REQUEST,
  ENABLE_LISTING_SYNDICATION_SUCCESS,
  ENABLE_LISTING_SYNDICATION_FAILURE,
  CLEAR_LISTING_SYNDICATION,
  DISABLE_LISTING_SYNDICATION_REQUEST,
  DISABLE_LISTING_SYNDICATION_SUCCESS,
  DISABLE_LISTING_SYNDICATION_FAILURE,
  INVALID_LISTING_SYNDICATION_ADDRESS,
  UPDATE_CONTACT_DETAILS_CALL,
  UPDATE_CONTACT_DETAILS_REQUEST,
  UPDATE_CONTACT_DETAILS_SUCCESS,
  UPDATE_CONTACT_DETAILS_FAILURE,
  DUPLICATE_LISTING_SYNDICATION_ADDRESS,
  CREATE_LISTING_SYNDICATION_REQUEST,
  CREATE_LISTING_SYNDICATION_SUCCESS,
  CREATE_LISTING_SYNDICATION_FAILURE,
  TOGGLE_LISTING_SYNDICATION_ON_PROPERTY_OVERVIEW_CALL,
} from './constants'

export const getListingSyndication = ({ propertyId }) => ({
  type: GET_LISTING_SYNDICATION_CALL,
  payload: { propertyId },
})
export const getListingSyndicationActions = generateApiCall([
  GET_LISTING_SYNDICATION_REQUEST,
  GET_LISTING_SYNDICATION_SUCCESS,
  GET_LISTING_SYNDICATION_FAILURE,
])

export const clearListingSyndication = () => ({
  type: CLEAR_LISTING_SYNDICATION,
})

export const toggleListingSyndication = ({ propertyId }) => ({
  type: TOGGLE_LISTING_SYNDICATION_CALL,
  payload: { propertyId },
})
export const toggleListingSyndicationOnPropertyOverview = ({ propertyId }) => ({
  type: TOGGLE_LISTING_SYNDICATION_ON_PROPERTY_OVERVIEW_CALL,
  payload: { propertyId },
})
export const createListingSyndicationActions = generateApiCall([
  CREATE_LISTING_SYNDICATION_REQUEST,
  CREATE_LISTING_SYNDICATION_SUCCESS,
  CREATE_LISTING_SYNDICATION_FAILURE,
])
export const enableListingSyndicationActions = generateApiCall([
  ENABLE_LISTING_SYNDICATION_REQUEST,
  ENABLE_LISTING_SYNDICATION_SUCCESS,
  ENABLE_LISTING_SYNDICATION_FAILURE,
])
export const disableListingSyndicationActions = generateApiCall([
  DISABLE_LISTING_SYNDICATION_REQUEST,
  DISABLE_LISTING_SYNDICATION_SUCCESS,
  DISABLE_LISTING_SYNDICATION_FAILURE,
])

export const invalidListingSyndicationAddress = () => ({
  type: INVALID_LISTING_SYNDICATION_ADDRESS,
})

export const duplicateListingSyndicationAddress = () => ({
  type: DUPLICATE_LISTING_SYNDICATION_ADDRESS,
})

export const updateContactDetails = ({ propertyId, contactDetails }) => ({
  type: UPDATE_CONTACT_DETAILS_CALL,
  payload: { propertyId, contactDetails },
})
export const updateContactDetailsActions = generateApiCall([
  UPDATE_CONTACT_DETAILS_REQUEST,
  UPDATE_CONTACT_DETAILS_SUCCESS,
  UPDATE_CONTACT_DETAILS_FAILURE,
])
