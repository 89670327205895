import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import DatePicker from '@rentspree/component-2023.components.organisms.date-picker'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import styled from 'styled-components'

import { yearMonthDayDate } from 'v3/containers/overhaul-rent-payment/pages/utils'

import { DateInputWithComputedMonths } from './date-input-with-computed-months'

/*
 * [TECH-8413] TODO: workaround for date-picker overlay,
 * this should be fix in component-2023 later.
 */
export const InputDatePickerContainer = styled.div`
  .RSPDatePicker {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2101;

    margin: auto;
    height: fit-content;
  }

  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`
// [TECH-8413] TODO: workaround for disabled date, mobile display, and not showing the cancel button but this should be fix in component-2023 later.
export const workaroundDatesOverride = {
  '.Mui-disabled': {
    color: '#bfbdbd !important',
  },
  display: 'block',
  '.MuiButton-textSecondary': {
    visibility: 'hidden',
  },
}

export const DatePickerOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: #0000002a;
  z-index: 2100;
`

export const DatePickerInput = props => {
  const {
    label,
    name,
    error,
    minDate,
    maxDate,
    helperText,
    onDateChange,
    value,
    computedMonths,
    inputProps,
    InputLabelProps,
  } = props
  const [showDatePicker, setShowDatePicker] = useState(false)

  const handlePickerOnEvents = newDate => {
    onDateChange(yearMonthDayDate(newDate))
    setShowDatePicker(false)
  }

  const ClickableCalendarIcon = (
    <div
      onClick={() => setShowDatePicker(!showDatePicker)}
      style={{ cursor: 'pointer' }}
      role="button"
      aria-hidden="true"
      tabIndex={0}
    >
      <CalendarMonthIcon />
    </div>
  )

  return (
    <InputDatePickerContainer>
      <DateInputWithComputedMonths
        {...props}
        type="date"
        endAdornment={ClickableCalendarIcon}
        defaultValue=""
        value={value}
        computedMonths={computedMonths}
        label={label}
        name={name}
        error={error}
        helperText={helperText}
        InputLabelProps={{
          ...InputLabelProps,
          shrink: true,
        }}
        inputProps={{
          ...inputProps,
          role: 'textbox',
        }}
      />
      {showDatePicker && (
        <DatePicker
          sx={workaroundDatesOverride}
          variantAutoBreakpoint="sm"
          handleOnChange={handlePickerOnEvents}
          handleOnAddDate={handlePickerOnEvents}
          handleOnCancel={() => setShowDatePicker(false)}
          value={dayjs(value)}
          acceptButtonLabel="Save"
          minDate={minDate}
          maxDate={maxDate}
        />
      )}
      {showDatePicker && <DatePickerOverlay onClick={() => setShowDatePicker(false)} />}
    </InputDatePickerContainer>
  )
}
