import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { FontReport } from 'legacy/components/reports-v2/share/doc-style'
import { ReportWrapper } from 'components/reports/screening-reports/share/responsive-doc-style'
import { PRINT_REPORTS_PAGE, PRINT_REPORTS_RENTER_PAGE, SUPPORT_URL } from 'constants/route'
import { InfoPanel } from '@rentspree/component-2023.components.organisms.info-panel'
import { REPORT_TYPES } from 'containers/reports/constants'
import { USER_TYPE } from 'constants/user'
import { get } from 'lodash'
import { closePendingReportBanner } from 'containers/reports/screening-reports/actions'
import { selectPendingReportBannersOpen } from 'containers/reports/screening-reports/selectors'
import { ScreeningReportHeader } from 'components/reports/screening-reports/share/responsive-doc-components'
import REPORT_PROCESSING from '../../../../images/tenant-screening/report-processing.svg'

export const PendingContentWrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: ${props => (props.pendingEvictionReport ? '25px' : '125px')};
  margin-bottom: 125px;
`

const Image = styled.img`
  height: 100px;
  margin-bottom: 20px;
`

const PendingContent = styled.div`
  max-width: 600px;
`

const BannerWrapper = styled.div`
  width: 100%;
  position: absolute;
  z-index: 1;
`

const SupportLink = styled.a`
  border-radius: 0;
  border-bottom: 1px solid;
  font-size: 18px;
  color: black;
`

const reportTypeTitleName = {
  [REPORT_TYPES.CREDIT_REPORT]: 'Credit',
  [REPORT_TYPES.CRIMINAL]: 'Background',
  [REPORT_TYPES.EVICTION]: 'Eviction',
}

const PendingReportWrapper = styled(ReportWrapper)`
  max-width: 780px;
  border: none;
  display: flex;
  justify-content: center;
  box-shadow: none;
`

const pendingReportConfig = reportType => ({
  [USER_TYPE.LANDLORD]: {
    title: 'We’re actively preparing the report',
    subTitle1:
      'TransUnion searches millions of records to ensure you have a complete and accurate view of your potential renters.',
    subTitle2: "Reports typically take 1-2 days, and we'll email you as soon as they're ready.",
  },
  [USER_TYPE.RENTER]: {
    title: `${reportTypeTitleName[reportType]} checks in progress`,
    subTitle1: "Don't delay your application—submit it now to keep things moving.",
    subTitle2: 'We’ll notify you by email once this report is ready.',
  },
})

export const PendingReport = ({ reportType, userType, match }) => {
  const dispatch = useDispatch()
  const pendingReportBannersOpen = useSelector(selectPendingReportBannersOpen)

  const path = get(match, 'path', '')
  const isPrintingScreen =
    path.includes(PRINT_REPORTS_PAGE) || path.includes(PRINT_REPORTS_RENTER_PAGE)

  const renderBanner = !isPrintingScreen
  const isBannerOpen = get(pendingReportBannersOpen, reportType, true) && renderBanner

  const handleOnClose = () => {
    dispatch(closePendingReportBanner(reportType))
  }

  const bannerTitle = `${reportTypeTitleName[reportType]} reports are being generated`
  const bannerMessage = "We'll notify you by email once they're ready."
  const isEvictionReportType = reportType === REPORT_TYPES.EVICTION

  return (
    <PendingReportWrapper>
      {isBannerOpen && (
        <BannerWrapper>
          <InfoPanel title={bannerTitle} onClose={handleOnClose}>
            {bannerMessage}
          </InfoPanel>
        </BannerWrapper>
      )}
      <ReportWrapper>
        <ScreeningReportHeader pendingEvictionReport={isEvictionReportType} />
        <PendingContentWrapper pendingEvictionReport={isEvictionReportType}>
          <PendingContent>
            <Image src={REPORT_PROCESSING} alt={bannerTitle} />
            <FontReport marginB Bold Twenty>
              {pendingReportConfig(reportType)[userType].title}
            </FontReport>
            <FontReport marginB>{pendingReportConfig(reportType)[userType].subTitle1}</FontReport>
            <FontReport marginB>{pendingReportConfig(reportType)[userType].subTitle2}</FontReport>
            {userType === USER_TYPE.LANDLORD && (
              <SupportLink href={SUPPORT_URL} target="_blank">
                Learn more
              </SupportLink>
            )}
          </PendingContent>
        </PendingContentWrapper>
      </ReportWrapper>
    </PendingReportWrapper>
  )
}
