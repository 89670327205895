import get from "lodash/get"
import { createSelector } from "reselect"

import { selectProfile } from "../user/selectors"
import { IDV_STATUS } from "./constants"

export const getCheckWhiteListing = state => get(state, "checkWhitelisting", {})

export const selectIDVStatus = createSelector(
  selectProfile,
  getCheckWhiteListing,
  (profile, checkWhitelisting) => {
    const isRequireVerification = get(profile, "requireVerification", false)
    const isEmailVerified = get(profile, "isEmailVerified", false)
    const isWhitelisting = get(checkWhitelisting, "data.isWhitelisting", false)

    const isCompleteWhitelisting = isEmailVerified && isWhitelisting

    if (!isRequireVerification || isCompleteWhitelisting) return IDV_STATUS.NONE

    if (isWhitelisting && !isEmailVerified) return IDV_STATUS.EMAIL_VERIFICATION

    const isVerificationPermitted = get(
      profile,
      "isVerificationPermitted",
      false,
    )
    if (isVerificationPermitted) return IDV_STATUS.VERIFICATION
    return IDV_STATUS.VERIFICATION_FAIL
  },
)
