import React, { Fragment } from 'react'
import { capitalize, get } from 'lodash'
import Link from '@rentspree/component-2023.components.atoms.link'

import { ReportWrapper } from 'components/reports/screening-reports/share/responsive-doc-style'
import personTouchingDocumentsSVG from 'images/person-touching-documents.svg'
import womanWalkWithEnvelopesSVG from 'images/woman-walk-with-envelopes.svg'
import * as ROUTES from 'constants/route'
import { MessageBody } from './components'
import { screeningReportNames } from './constants'

/** Constants */
const supportUrl = 'https://support.rentspree.com/en/faq-erp-cbc-2025'

const PendingAvailabilityTabSubtitle = ({ isAllTabOrPrint = false }) => (
  <>
    <p>
      TransUnion is reviewing millions of records to provide accurate applicant info. This typically
      takes 1-2 days.
    </p>
    <br></br>
    <p>
      {isAllTabOrPrint
        ? `We'll email you as soon as reports are ready.`
        : `We'll email you as soon as it's ready.`}
    </p>
    <p>
      <Link target="_blank" href={supportUrl} color="black">
        View FAQ
      </Link>
    </p>
  </>
)

const getReportNamesForTitle = reportTypes =>
  reportTypes
    .map((reportType, i) => {
      const name = screeningReportNames[reportType]
      return i === 0 ? capitalize(name) : name
    })
    .join(' and ')

const PendingAvailability = ({ reportTypes = [], match }) => {
  /** Variables */
  const path = get(match, 'path', '')
  const isRenterPage = path.includes(ROUTES.PRINT_REPORTS_RENTER_PAGE)
  const isPrintingScreen = path.includes(ROUTES.PRINT_REPORTS_PAGE) || isRenterPage
  const isAllTabOrPrint = get(match, 'params.type', '') === 'all' || isPrintingScreen
  const MessageContainer = isAllTabOrPrint ? ReportWrapper : Fragment
  const reportNames = getReportNamesForTitle(reportTypes)

  /** tab props */
  const pendingAvailabilityProps = {
    title: `${reportNames} will be ready soon`,
    subtitle: <PendingAvailabilityTabSubtitle isAllTabOrPrint={isAllTabOrPrint} />,
    imgSrc: isAllTabOrPrint ? womanWalkWithEnvelopesSVG : personTouchingDocumentsSVG,
    imgAltText: isAllTabOrPrint ? 'woman walking with envelopes' : 'person touching documents',
  }

  const { title, subtitle, imgSrc, imgAltText, buttons } = pendingAvailabilityProps

  return (
    !isRenterPage && (
      <MessageContainer>
        <MessageBody
          imgSrc={imgSrc}
          imgAltText={imgAltText}
          title={title}
          subtitle={subtitle}
          buttons={buttons}
        />
      </MessageContainer>
    )
  )
}

export default PendingAvailability
