import { Box, Typography } from '@mui/material'
import Button from '@rentspree/component-2023.components.atoms.button'
import { buildPath, query } from '@rentspree/path'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import history from 'utils/history'
import { INCENTIVE_V5_QUERY_PARAM_CAMPAIGN_NAME } from 'v3/containers/overhaul-rent-payment/constants'
import { PaymentInfoContext, HistoryContext } from 'v3/containers/overhaul-rent-payment/context'
import { useListPropertiesQuery } from 'v3/containers/rent-payment/apis/property'
import {
  selectProperty,
  selectRenterInfo,
} from 'v3/containers/rent-payment/setup-page/set-up-for-myself/redux/actions'
import {
  useConfirmDraftRentalPayment,
  useGetOrCreateDraftRentalPayment,
  useUpdateDraftQuotations,
  useGetOrCreateRenterInfo,
} from 'v3/containers/rent-payment/shared/hooks'
import { useSearchRenterInfo } from 'v3/containers/rent-payment/shared/hooks/use-search-renter-info'
import { API_STATUS } from 'v3/containers/rent-payment/shared/redux/constants'
import { useMutateAgentInitiatePayment } from 'v3/hooks/use-mutate-agent-initiate-payment'
import { useUpdateLandlordProfileInPropertyUser } from 'v3/hooks/use-update-landlord-profile-in-property-user'

/*
 *Redefining constants here because of circular dependency issue.
 *Need to refactor constants definition and importing for the whole payment overhaul module.
 */
export const buttonLayoutChoices = Object.freeze({
  // an enum to sync button layout options between CreateButtons & the footer's parent renderers
  NEXT: 'next',
  NEXT_BACK: 'next-back',
})
const TENANT_FIELD = 'tenant'
const PAYMENTS_FIELD = 'payments'
const PROPERTY_FIELD = 'property'
const RECIPIENT_INFO_FIELD = 'recipient-info'
const RENT_PAYMENT_PATH = '/rent-payments'
const pageNames = ['recipient-details', 'property-and-tenant', 'payment-details', 'review']
const pageOptions = Object.freeze({
  RECIPIENT_DETAILS: 'recipient-details',
  PROPERTY_AND_TENANT: 'property-and-tenant',
  PAYMENT_DETAILS: 'payment-details',
  REVIEW: 'review',
})
const recipientDetailsOptions = Object.freeze({
  ME: 'me',
  CLIENT: 'client',
})

/*
 * CamelCasing it because,
 * 1. It is a proper component returning jsx
 * 2. we need to use hooks from within this component, and without this pre-commit fails.
 *
 * NOTE: Need to discuss and refactor
 */
export const CreateButtons = (
  pageIndexes,
  progressionHandler,
  buttonLayout,
  textOverrides = {},
  nextEnabled = true,
  isMobile = false,
  onBack = () => {},
  onNext = () => {},
) => {
  /* A dynamic handler for all of the Rental Payment page button configurations we might need. */
  // while this section defines the the layout style, the user might want different text
  const dispatch = useDispatch()
  const { back: backstepText = 'Back', next: forwardText = 'Next' } = textOverrides
  const { paymentInfo, setIsAgentError } = useContext(PaymentInfoContext)
  const historyObj = useContext(HistoryContext)
  const { confirmDraftRentalPayment, status: confirmDraftRentalPaymentStatus } =
    useConfirmDraftRentalPayment()
  const {
    data: quotations,
    updateDraftQuotations,
    status: updateDraftQuotationsStatus,
  } = useUpdateDraftQuotations()
  const { pageL1Index } = pageIndexes
  const { data: renterInfoSearchList, status: searchRenterInfoStatus } = useSearchRenterInfo()
  const listPropertiesQuery = useListPropertiesQuery()
  const { getOrCreateRenterInfo, status: getOrCreateRenterInfoStatus } = useGetOrCreateRenterInfo()
  const {
    getOrCreateDraftRentalPayment,
    data: draftRentalPayment,
    status: getOrCreateDraftRentalPaymentStatus,
  } = useGetOrCreateDraftRentalPayment()
  const initiateDraftPaymentMutate = useMutateAgentInitiatePayment({})

  const {
    [pageOptions.PROPERTY_AND_TENANT]: propertyAndTenantInfo = {},
    [pageOptions.RECIPIENT_DETAILS]: recipientDetailsInfo = {},
    [pageOptions.PAYMENT_DETAILS]: paymentDetailsInfo = {},
    [pageOptions.REVIEW]: reviewInfo = {},
  } = paymentInfo || {}
  const { [PROPERTY_FIELD]: propertyInfo = {}, [TENANT_FIELD]: tenantInfo = {} } =
    propertyAndTenantInfo
  const recipientInfo = recipientDetailsInfo?.[RECIPIENT_INFO_FIELD] || {}
  const quotes = paymentDetailsInfo?.[PAYMENTS_FIELD] || []
  const mutateLandlordProfile = useUpdateLandlordProfileInPropertyUser(
    propertyInfo._id,
    recipientInfo,
  )

  const queryString = query.parse(history?.location?.search)
  const isFromIncentiveV5Campaign =
    queryString.campaignName === INCENTIVE_V5_QUERY_PARAM_CAMPAIGN_NAME

  const savePropertyAndTenantData = async () => {
    const { id: tenantId } = tenantInfo
    const { _id: propertyId } = propertyInfo
    const renterInfoSearch = renterInfoSearchList.find(({ id }) => id === tenantId)
    const property = listPropertiesQuery.data?.data?.find(({ _id }) => _id === propertyId)

    if (renterInfoSearch.renterInfoId) {
      await getOrCreateDraftRentalPayment({
        propertyId,
        renterInfoId: renterInfoSearch.renterInfoId,
      })
      dispatch(selectRenterInfo(renterInfoSearch))
    } else {
      const renterInfo = await getOrCreateRenterInfo(renterInfoSearch)
      await getOrCreateDraftRentalPayment({
        propertyId: property._id,
        renterInfoId: renterInfo.id,
      })
      dispatch(selectRenterInfo(renterInfo))
    }

    dispatch(selectProperty(property))
  }

  useEffect(() => {
    if (
      pageNames[pageL1Index] === pageOptions.REVIEW &&
      recipientDetailsInfo?.recipient === recipientDetailsOptions.ME &&
      confirmDraftRentalPaymentStatus === API_STATUS.SUCCESS
    ) {
      historyObj.push(
        buildPath(
          RENT_PAYMENT_PATH,
          {},
          {
            paymentSetupSuccess: 'landlord',
          },
        ),
      )
    }
  }, [confirmDraftRentalPayment])

  const savePaymentDetailsData = async () => {
    // TODO: not firing after Fees page progression
    await updateDraftQuotations({
      rentalPaymentId: draftRentalPayment.id,
      quotations: quotes,
    })
  }

  // eslint-disable-next-line no-underscore-dangle
  const saveInviteUserByText = async () => {
    const { phone } = tenantInfo
    const { _id: propertyId } = propertyInfo
    const { inviteByText = false } = reviewInfo

    await confirmDraftRentalPayment({
      rentalPaymentId: draftRentalPayment?.id,
      propertyId,
      rentInfo: quotations,
      invitationDetail: {
        sms: inviteByText,
        ...(inviteByText && phone ? { phone } : {}),
      },

      /*
       * always put true when confirming from overhaul flow with query param of incentive v5
       * BE will handle eligibility calculation
       */
      hasEligiblePaymentForIncentiveV5: isFromIncentiveV5Campaign,
    })
  }

  const saveReviewData = async () => {
    await saveInviteUserByText()
  }

  const saveAgentInitiatedFlowData = async () => {
    await mutateLandlordProfile()

    const { _id: propertyId } = propertyInfo
    const { firstName: tenantFirstName, lastName: tenantLastName, email: tenantEmail } = tenantInfo
    const {
      firstName: recipientFirstName,
      lastName: recipientLastName,
      email: recipientEmail,
    } = recipientInfo
    const data = {
      propertyId,
      renterInfo: {
        firstName: tenantFirstName,
        lastName: tenantLastName,
        email: tenantEmail,
      },
      landlordInfo: {
        firstName: recipientFirstName,
        lastName: recipientLastName,
        email: recipientEmail,
      },
      quotations: quotes,
    }

    const initiated = await initiateDraftPaymentMutate.mutateAsync(data)

    if (initiated?.status) {
      historyObj.push(
        buildPath(
          RENT_PAYMENT_PATH,
          {},
          {
            paymentSetupSuccess: 'agent',
          },
        ),
      )
    } else {
      setIsAgentError(true)
    }
  }

  const savePageDataHandler = useMemo(
    () => ({
      [pageOptions.PROPERTY_AND_TENANT]: savePropertyAndTenantData,
      [pageOptions.PAYMENT_DETAILS]: savePaymentDetailsData,
      [pageOptions.REVIEW]: {
        [recipientDetailsOptions.ME]: saveReviewData,
        [recipientDetailsOptions.CLIENT]: saveAgentInitiatedFlowData,
      },
    }),
    [savePropertyAndTenantData, savePaymentDetailsData, saveReviewData],
  )

  const saveAndProgress = async action => {
    try {
      if (await progressionHandler(action)) {
        /*
         * save page level data if the user is going through the landlord flow.
         * save all data at once at the end of the flow if the user is going through the agent flow.
         */
        let savePageData
        if (pageNames[pageL1Index] === pageOptions.REVIEW) {
          savePageData = savePageDataHandler[pageNames[pageL1Index]][recipientDetailsInfo.recipient]
        } else {
          savePageData = savePageDataHandler[pageNames[pageL1Index]]
        }
        if (savePageData) {
          await savePageData()
        } else {
          console.error('Should not reach this state: ', pageNames[pageL1Index])
        }
      }
    } catch (error) {
      console.error('Something went wrong', error)
    }
  }

  const [isSubmitting, setIsSubmitting] = useState(false)

  const backOnClick = async () => {
    if (isSubmitting) return
    setIsSubmitting(true)
    try {
      onBack()
      await saveAndProgress({ decrement: true })
    } finally {
      setIsSubmitting(false)
    }
  }

  const nextOnClick = async () => {
    if (isSubmitting) return
    setIsSubmitting(true)
    try {
      onNext()
      await saveAndProgress({ increment: true })
    } finally {
      setIsSubmitting(false)
    }
  }

  const isNextButtonDisabled = useMemo(() => {
    return (
      !nextEnabled ||
      confirmDraftRentalPaymentStatus === API_STATUS.UPDATING ||
      confirmDraftRentalPaymentStatus === API_STATUS.ERROR ||
      updateDraftQuotationsStatus === API_STATUS.UPDATING ||
      searchRenterInfoStatus === API_STATUS.FETCHING ||
      getOrCreateRenterInfoStatus === API_STATUS.FETCHING ||
      getOrCreateDraftRentalPaymentStatus === API_STATUS.FETCHING
    )
  }, [
    nextEnabled,
    confirmDraftRentalPaymentStatus,
    updateDraftQuotationsStatus,
    searchRenterInfoStatus,
    getOrCreateRenterInfoStatus,
    getOrCreateDraftRentalPaymentStatus,
  ])

  // default error state & styling
  let renderedButtons = <Typography>Button configuration error?</Typography>
  const styles = {
    display: 'flex',
    padding: '16px',
    margin: 'auto',
    width: '100%',
    maxWidth: '1032px',
    justifyContent: 'center',
  }
  if (isMobile) {
    // helps with the TakeOverTemplate's grid container having a -16px margin
    styles.marginLeft = '16px'
  }

  // quick & bulky definition for now, we can refactor later
  if (buttonLayout === buttonLayoutChoices.NEXT) {
    styles.justifyContent = 'flex-end'
    renderedButtons = (
      <Button color="secondary" disabled={!nextEnabled} onClick={nextOnClick} variant="contained">
        {forwardText}
      </Button>
    )
  } else if (buttonLayout === buttonLayoutChoices.NEXT_BACK) {
    styles.justifyContent = 'space-between'
    renderedButtons = (
      <>
        <Button
          sx={{
            textDecoration: 'underline',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
          size="medium"
          variant="text"
          color="secondary"
          onClick={backOnClick}
        >
          {backstepText}
        </Button>
        <Button
          size="medium"
          variant="contained"
          color="secondary"
          disabled={isNextButtonDisabled}
          onClick={() => nextOnClick()}
        >
          {forwardText}
        </Button>
      </>
    )
  } else {
    console.error(`No button configuration for this state! Layout choice '${buttonLayout}'?`)
  }

  return <Box sx={styles}>{renderedButtons}</Box>
}
