import { query } from "@rentspree/path"
import { matchPath } from "react-router-dom"
import {
  IMAGE_PROXY_URL,
  IMAGE_PROXY_RESIZE_WIDTH,
  IMAGE_PROXY_RESIZE_HEIGHT,
  IMAGE_PROXY_HOST,
} from "env"
import { ImageProxy } from "utils/image-proxy"
import { SINGLE_APPLICATION } from "constants/route"
import { GCS_PROTOCOL } from "./constants"

export const isReportPath = pathname =>
  !!matchPath(pathname, {
    path: SINGLE_APPLICATION,
    exact: false,
  })

export const removeQuery = (search, key) => {
  const queryString = query.parse(search)
  key.map(q => delete queryString[q])
  return query.stringify(queryString)
}

const listReplaceIdWithFormat = [
  { key: "properties", replaceAfter: ":propertyId" },
  { key: "application", replaceAfter: ":propertyId" },
  { key: "rental", replaceAfter: ":rentalAppId" },
  { key: "rental-submission", replaceAfter: ":rentalAppId" },
  { key: "rental-submissions", replaceAfter: ":rentalAppId" },
  { key: "documents", replaceAfter: ":documentId" },
  { key: "envelope", replaceAfter: ":envelopeId" },
  { key: "lease-agreements", replaceAfter: ":leaseAgreementId" },
]

export const replaceURLToFormat = pathname => {
  let newText = pathname.concat("/")
  listReplaceIdWithFormat.forEach(({ key, replaceAfter }) => {
    if (newText.includes(key)) {
      const regX = new RegExp(`(${key}\\/).*?(\\/)`)
      newText = newText.replace(regX, `$1${replaceAfter}$2`)
    }
  })
  return newText.substring(0, newText.length - 1)
}

export const parseImgProxyGcsProtocol = url => {
  if (url.includes(IMAGE_PROXY_URL)) {
    return url.replace(`${IMAGE_PROXY_URL}/`, GCS_PROTOCOL)
  }
  return url.replace("/", GCS_PROTOCOL)
}

export const resizeImgProxy = (path, parserUrl = parseImgProxyGcsProtocol) => {
  const imageProxy = new ImageProxy(IMAGE_PROXY_HOST)
  imageProxy.size({
    width: IMAGE_PROXY_RESIZE_WIDTH,
    height: IMAGE_PROXY_RESIZE_HEIGHT,
  })
  const photoUrl = parserUrl(path)
  return imageProxy.generateUrl(photoUrl, true)
}

export const imageProxy = path => {
  if (!path || path.includes("blob")) return path
  return resizeImgProxy(path)
}
