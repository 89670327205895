import { buildPath } from '@rentspree/path'
import * as sentry from '@sentry/browser'

import { apiInstance } from 'utils/api-interceptor'

export const GET_MY_PROPERTY_PATH = '/api/v2/agent/properties'

/**
 * @typedef Property
 * @property {string} street
 * @property {string} unitNumber
 * @property {string} city
 * @property {string} state
 * @property {string} zip
 * @property {string} propertyType
 * @property {string} origin
 * @property {date} createdAt
 * @property {date} updatedAt
 * @property {string} _id
 * @property {string} landlord_id
 * @property {number} total_shared
 * @property {number} total_requested
 * @property {number} total_submitted
 * @property {number} total_unopen
 */

/**
 * getMyProperty get properties using my userId.
 *
 * @param {object} queryParams
 * @param {number} queryParams.page (default: 1)
 * @param {number} queryParams.perPage (default: 5)
 * @returns {Promise<{data: Property[], pagination: {maxPage, page, perPage, total}}>}
 */
export const getMyProperty = async (queryParams = { page: 1, perPage: 5 }) => {
  try {
    const result = await apiInstance.get(buildPath(GET_MY_PROPERTY_PATH, {}, queryParams))
    return result
  } catch (e) {
    sentry.captureException(e)
    return {}
  }
}
