import React from "react"
import { useGateValue } from '@statsig/react-bindings'
import featureFlags from 'constants/feature-flags'
import { ModalDefault } from "./modal-default"
import { OpenCreditReport } from "../../containers/credit-report/open-credit"


export const OpenCreditReportModal = props => {
  const useScreeningEndpoint = useGateValue(featureFlags.shouldCallScreeningServiceFullReportEndpoint)
  return (
    <ModalDefault {...props}>
      <OpenCreditReport closeModal={props.closeModal} useScreeningEndpoint={useScreeningEndpoint} />
    </ModalDefault>
  )
}
