import { TU_CONTACT } from 'constants/partner'

export const REMOVE_HTML_TAG_FROM_STRING_REGEX = /(<([^>]+)>)/gi

export const INCORRECT_INFO_TEXT = `All of the information on the screening reports comes directly from TransUnion. If there is any information on the screening reports that you feel is not correct, you can contact TransUnion at ${TU_CONTACT.PHONE} and provide your email address.`

export const REPORT_STATUSES = {
    COMPLETED: "COMPLETED",
    IN_PROCESS: "IN_PROCESS",
}

export const screeningReportNames = {
  criminal: 'background reports',
  eviction: 'eviction records',
  'credit-report': 'credit reports',
}

export const supportUrl = 'https://support.rentspree.com/en/faq-erp-cbc-2025'
export const icraaSupportUrl = 'https://support.rentspree.com/en/icraa-regulations'
